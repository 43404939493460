import {
  Box,
  Button,
  Divider,
  Fade,
  Modal,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import ContactPageIcon from "@mui/icons-material/ContactPage";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ModalDetalhesUnidade = ({ aberto, handleClose, unidade }) => {
  const [modalAberto, setModalAberto] = useState(aberto);

  useEffect(() => {
    setModalAberto(aberto);
  }, [aberto]);

  return (
    <Paper>
      <Modal
        open={modalAberto}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
      >
        <Fade in={modalAberto}>
          <Box sx={style}>
            <Box>
              <Typography variant={"h2"}>Informações da unidade</Typography>
              <Typography variant={"h5"} sx={{ fontWeight: 500 }}>
                {unidade.nomeUnidade}
              </Typography>
            </Box>
            <Divider />
            <Box my={2}>
              <Stack
                direction={"row"}
                display={"flex"}
                alignItems={"center"}
                gap={1}
              >
                <Box>
                  <InfoIcon />
                </Box>
                <Box>
                  <Typography variant={"h5"}>Detalhes da unidade</Typography>
                </Box>
              </Stack>
              <Stack direction={"column"} my={1}></Stack>
              <Box display={"flex"} flexDirection={"row"} gap={1}>
                <Typography sx={{ fontWeight: 600 }}>Razão social:</Typography>{" "}
                <Typography>{unidade.razaoSocial}</Typography>
              </Box>
              <Box display={"flex"} flexDirection={"row"} gap={1}>
                <Typography sx={{ fontWeight: 600 }}>CNPJ:</Typography>{" "}
                <Typography>{unidade.cnpj}</Typography>
              </Box>
              <Box display={"flex"} flexDirection={"row"} gap={1}>
                <Typography sx={{ fontWeight: 600 }}>
                  Inscrição municipal:
                </Typography>{" "}
                <Typography>{unidade.inscricaoMunicipal}</Typography>
              </Box>
              <Box display={"flex"} flexDirection={"row"} gap={1}>
                <Typography sx={{ fontWeight: 600 }}>
                  Inscrição estadual:
                </Typography>{" "}
                <Typography>{unidade.inscricaoEstadual}</Typography>
              </Box>
              <Box display={"flex"} flexDirection={"row"} gap={1}>
                <Typography sx={{ fontWeight: 600 }}>
                  Tipo de unidade:
                </Typography>{" "}
                <Typography>{unidade.tipoUnidade?.descricao}</Typography>
              </Box>
              <Box display={"flex"} flexDirection={"row"} gap={1}>
                <Typography sx={{ fontWeight: 600 }}>Responsável:</Typography>{" "}
                <Typography>{unidade.responsavel?.name}</Typography>
              </Box>
              <Box my={2}>
                <Stack
                  direction={"row"}
                  display={"flex"}
                  alignItems={"center"}
                  gap={1}
                >
                  <Box>
                    <ContactPageIcon />
                  </Box>
                  <Box>
                    <Typography variant={"h5"}>
                      Informações de contato
                    </Typography>
                  </Box>
                </Stack>
                <Stack
                  direction={"row"}
                  display={"flex"}
                  alignItems={"center"}
                  gap={1}
                >
                    <Box>Nenhum contato cadastrado.</Box>
                </Stack>
              </Box>
            </Box>
            <Box my={1}>
              <Box mt={3} display="flex" justifyContent="space-evenly">
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Fechar
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Paper>
  );
};

export default ModalDetalhesUnidade;
