import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Grid,
  Paper,
  Stack,
  useMediaQuery,
  InputLabel,
  MenuItem,
  TextField,
  OutlinedInput,
  InputAdornment,
  Typography,
  Select,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import ApiService from "app/services/config";
import dayjs from "dayjs";
import { Form, Formik } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import makeAnimated from "react-select/animated";
import * as yup from "yup";
import pt from 'dayjs/locale/pt-br';
import { ptBR } from '@mui/x-date-pickers/locales';
import MoneyInput from "app/components/MoneyInput/MoneyInput";
// import Select from 'react-select';
import TreeSelect from "app/components/TreeSelect/TreeSelect";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import formatMoney from "app/utils/formatMoney";
import CreatableSelect from "react-select/creatable";

const validationSchema = yup.object().shape({
  // data: yup
  //   .string("Insira a Data do lançamento")
  //   .required("Preenchimento obrigatório"),
  // plano_conta_id: yup
  //   .string("Insira a Categoria")
  //   .required("Preenchimento obrigatório"),
});

const AccordionCreateLancamento = ({ updateState }) => {
  const initialValues = {
    observacao: "",
  };
  const { id } = useParams();
  const [lancamento, setLancamento] = useState(initialValues);
  const [categories, setCategories] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  const [nodes, setNodes] = useState(null);
  const [selectedNodeKey, setSelectedNodeKey] = useState(null);

  const [primaryCategory, setPrimaryCategory] = useState(null);
  const [secondaryCategory, setSecondaryCategory] = useState(null);
  const [tertiaryCategory, setTertiaryCategory] = useState(null);
  const [subCategories, setSubCategories] = useState(null);
  const planoContaIdsDemoSetorProdutivo = [23,  24,  25,  26,  27,  28,  29,  31,  32,  34,  46,  48,
    49,  50,  51,  52,  53,  54,  55,  56,  57,  58,  59,  60,
    61,  62,  63,  64,  65,  66,  67,  68,  69,  70,  71,  72,
    73,  74,  75,  76,  77,  78,  79,  87,  89,  90,  91,  92,
    93,  95,  96,  97,  98,  99, 100, 101, 102, 103, 104, 105,
   106, 107, 108, 109, 110, 111];
  console.log('categories', categories)

  // const [quaternaryCategory, setQuaternaryCategory] = useState(null);
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "transparent" : "white",
      border: state.isFocused
        ? "1px solid #005D5F"
        : "1px solid rgba(0, 0, 0, 0.23)",
      borderRadius: "4px",
      padding: "8px",
      boxShadow: "none",
      "&:hover": {
        borderColor: "black",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#005D5F" : "white",
      color: state.isSelected ? "white" : "black",
      "&:hover": {
        backgroundColor: "lightblue",
        color: "white",
      },
    }),
  };

  const Swal = useSwalWrapper();
  const navigate = useNavigate();

  const { theme } = useJumboTheme();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const getLancamento = useCallback(async () => {
    try {
      ApiService.get(`/controlecaixa/${id}`)
        .then((response) => {
          setLancamento({
            ...response.data,
          });
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getCategories = useCallback(async () => {
    try {
      ApiService.get(`/planocontas/categories`)
        .then((response) => {   
          console.log(response.data)       
          setCategories(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const clearCategorySelect = useCallback(async (currentStep) => {
    if(currentStep == 1){
      setSecondaryCategory(null);
      setTertiaryCategory(null);
    } else if(currentStep == 2){
      setTertiaryCategory(null);
    } 
  }, []);


  useEffect(() => {
    if (id) {
      getLancamento();
    }

    getCategories();
  }, []);

  useEffect(() => {
    if(lancamento.plano_conta_id && categories.length > 0){
      const lancamentoCategory = categories.find(opt => opt.id == lancamento.plano_conta_id);
      const categoriesList = lancamentoCategory.lista_plano_mae_id.split(',');
      const length = categoriesList.length;

      const thirdCategory = length == 3 ? categoriesList[0] : lancamento.plano_conta_id
      const fourthCategory = length == 3 ? lancamento.plano_conta_id : null

      setPrimaryCategory(categoriesList[length - 1]);
      setSecondaryCategory(categoriesList[length - 2]);
      setTertiaryCategory(thirdCategory);
    }
  }, [lancamento, categories])

  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    // Define qual é o último nível de subcategoria, apenas ela é adicionada para ser enviada pelo form
    // const plano_conta_id = isLastCategoryLevel ? tertiaryCategory : secondaryCategory;

    let allValuesEdit = { 
      ...values,
      entrada_caixa: typeof values.entrada_caixa == 'string' ? formatMoney(values.entrada_caixa): values.entrada_caixa,
      entrada_51387_3: typeof values.entrada_51387_3  == 'string'  ? formatMoney(values.entrada_51387_3): values.entrada_51387_3,
      entrada_51403_9: typeof values.entrada_51403_9  == 'string'  ? formatMoney(values.entrada_51403_9): values.entrada_51403_9,
      saida_caixa: typeof values.saida_caixa  == 'string'  ? formatMoney(values.saida_caixa): values.saida_caixa,
      saida_51387_3: typeof values.saida_51387_3  == 'string'  ? formatMoney(values.saida_51387_3): values.saida_51387_3,
      saida_51403_9: typeof values.saida_51403_9  == 'string'  ? formatMoney(values.saida_51403_9): values.saida_51403_9,
     };
    delete allValuesEdit.id;
    delete allValuesEdit.createdAt;
    delete allValuesEdit.updatedAt;
    delete allValuesEdit.deletedAt;

    await ApiService.post("/controlecaixa", allValuesEdit)
      .then((response) => {
        toast("success", "Criado com sucesso");
        resetForm();

        if (response.status === 201) {
          updateState();
        }
      })
      .catch((error) => {
        let message = error.response.data.message[0];
        toast("error", message);

        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("error", error.message);
        }
      });
    setSubmitting(false);
  };
  
  return (

      <Accordion>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography>Novo lançamento</Typography>
        </AccordionSummary>
          <AccordionDetails>
            <Formik
              initialValues={lancamento}
              validationSchema={validationSchema}
              enableReinitialize
              validateOnChange={false}
              onSubmit={handleSubmit}
            >
              {({ values, isSubmitting, handleChange, setFieldValue, errors }) => (
                <Form style={{ width: "100%" }} noValidate autoComplete="off">
                  <Grid
                    container
                    spacing={2}
                    padding={2}
                    // columns={10}
                    alignContent={"center"}
                    // justifyContent={"center"}
                  >
                    <Grid item lg={3} md={4} xs={12}>
                      <InputLabel htmlFor="grouped-native-select">Categoria</InputLabel>
                      <TreeSelect categories={categories} setFieldValue={setFieldValue} defaultValue={values.plano_conta_id} />
                    </Grid>
                    {(planoContaIdsDemoSetorProdutivo.includes(values.plano_conta_id)) && (
                      <Grid item lg={2} md={4} xs={12}>
                        <InputLabel>
                          Sub Categoria (Opcional)
                        </InputLabel>
                        <Select
                          id="sub_plano_conta_id"
                          name="sub_plano_conta_id"
                          value={values.sub_plano_conta_id}
                          onChange={handleChange}
                          style={{
                            width: '100%'
                          }}
                        >
                          <MenuItem value={3}>Agrossilvopastoril</MenuItem>
                          <MenuItem value={4}>Mineração</MenuItem>
                          <MenuItem value={5}>Prestadores de serviços</MenuItem>
                        </Select>
                      </Grid>
                    )}
                      
                    <Grid item lg={5} md={8} xs={12} >
                      <JumboTextField
                        fullWidth
                        name="observacao"
                        label="Observação"
                        value={values.observacao}
                        onChange={handleChange}
                        sx={{ background: "#ffffff", marginTop: '20px' }}
                      />
                    </Grid>

                    <Grid item lg={2} md={8} xs={12}>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={pt}
                        localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}
                      >
                          <Stack>
                              <DesktopDatePicker
                                  id="data"
                                  name="data"
                                  format="DD/MM/YYYY"
                                  label="Data de lançamento"
                                  value={values.data ? dayjs(values.data).add(3, 'hours') : null}
                                  sx={{ background: "#ffffff", marginTop: '20px' }}
                                  onChange={(event) => {
                                    const newValue = dayjs(event.$d).format('YYYY-MM-DD').toString()

                                    setFieldValue("data", newValue)
                                  }}
                                  renderInput={(params) => <TextField {...params} />}
                              />
                          </Stack>
                      </LocalizationProvider>
                    </Grid>

                    <Grid container xs={12} md={12} lg={5} spacing={1} mt={1} ml={1} mr={1}>
                      <Grid item xs={12}>
                        <Typography variant="h6" color={"#005D5F"}>Entrada</Typography>
                      </Grid>

                      <Grid item xs={4} md={3} lg={4}>
                        <MoneyInput name="entrada_caixa" label="Caixa" setFieldValue={setFieldValue} defaultValue={values.entrada_caixa}/>
                      </Grid>

                      <Grid item xs={4} md={3} lg={4}>
                        <MoneyInput name="entrada_51387_3" label="51387_3" setFieldValue={setFieldValue} defaultValue={values.entrada_51387_3}/>
                      </Grid>

                      <Grid item xs={4} md={3} lg={4}>
                        <MoneyInput name="entrada_51403_9" label="51403_9" setFieldValue={setFieldValue} defaultValue={values.entrada_51403_9}/>
                      </Grid>
                    </Grid>



                    <Grid container xs={12} md={12} lg={5} spacing={1} mt={1} ml={1}>
                      <Grid item xs={12}>
                        <Typography variant="h6" color={"#E73145"}>Saída</Typography>
                      </Grid>

                      <Grid item xs={4} md={3} lg={4}>
                        <MoneyInput name="saida_caixa" label="Caixa" setFieldValue={setFieldValue} defaultValue={values.saida_caixa}/>
                      </Grid>

                      <Grid item xs={4} md={3} lg={4}>
                        <MoneyInput name="saida_51387_3" label="51387_3" setFieldValue={setFieldValue} defaultValue={values.saida_51387_3}/>
                      </Grid>

                      <Grid item xs={4} md={3} lg={4}>
                        <MoneyInput name="saida_51403_9" label="51403_9" setFieldValue={setFieldValue} defaultValue={values.saida_51403_9}/>
                      </Grid>
                    </Grid>


                    
                    <Box style={{ width: "100%" }} marginTop={3}>
                      <LoadingButton
                        loading={isSubmitting}
                        type="submit"
                        style={{ margin: "auto", display: "block" }}
                        variant="contained"
                        color="primary"
                      >
                        {id ? "Atualizar" : "Criar"}
                      </LoadingButton>
                    </Box>
                  </Grid>
                </Form>
              )}
            </Formik>
        </AccordionDetails>
      </Accordion>  
  );
};

export default AccordionCreateLancamento;
