export const tiposArquivos = {
    pdf: {
      nome: "PDF",
      cor: "#FF0000", 
      tipo: "Documento"
    },
    png: {
      nome: "PNG",
      cor: "#4CAF50",
      tipo: "Imagem" 
    },
    jpg: {
      nome: "JPG",
      cor: "#FF9800", 
      tipo: "Imagem" 
    },
    jpeg: {
      nome: "JPEG",
      cor: "#FF9800", 
      tipo: "Imagem" 
    },
    xls: {
      nome: "XLS",
      cor: "#0B8043", 
      tipo: "Documento"
    },
    docx: {
      nome: "DOCX",
      cor: "#2196F3", 
      tipo: "Documento"
    }
  };
  