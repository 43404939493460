import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {
  Box,
  Divider,
  Fade,
  Modal,
  Button,
  Paper,
  Typography,
  useMediaQuery,
  Stepper,
  Step,
  StepLabel,
  FormControl,
  TextField,
  Stack,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";
import * as yup from "yup";
import ApiService from "app/services/config";
import Div from "@jumbo/shared/Div";
import { LoadingButton } from "@mui/lab";
import DeleteIcon from "@mui/icons-material/Delete";

const steps = ["Etapa 1", "Etapa 2"];

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "transparent" : "white",
    border: state.isFocused
      ? "1px solid #005D5F"
      : "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    padding: "8px",
    boxShadow: "none",
    "&:hover": {
      borderColor: "black",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#005D5F" : "white",
    color: state.isSelected ? "white" : "black",
    "&:hover": {
      backgroundColor: "lightblue",
      color: "white",
    },
  }),
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1200,
  height: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const validationSchema = yup.object().shape({
  unidadeId: yup.number("Campo obrigatório").required("Campo obrigatório"),
  alvaraLicencas: yup.array().of(
    yup.object().shape({
      id: yup.number("Campo obrigatório").required("Campo obrigatório"),
      identificacao: yup.string().nullable(),
    })
  ),
  alvaraLicencasComplementar: yup.array().of(
    yup.object().shape({
      id: yup.number("Campo obrigatório").required("Campo obrigatório"),
      identificacao: yup.string().nullable(),
    })
  ),
  documentosLicenca: yup.array().of(
    yup.object().shape({
      id: yup.mixed().nullable(),
      descricao: yup.string().nullable(),
    })
  ),
});

const ModalAdicionarDocumentosUnidade = ({
  aberto,
  handleClose,
  licencaId,
  unidadeId,
  nomeUnidade,
  atualizarUnidade,
  userId,
}) => {
  const initialValues = {
    userId: Number(userId),
    unidadeId: unidadeId,
    alvaraLicencas: [],
    alvaraLicencasComplementar: [],
    documentosLicenca: [],
  };
  const [modalAberto, setModalAberto] = useState(aberto);
  const { theme } = useJumboTheme();
  const Swal = useSwalWrapper();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const [documentos, setDocumentos] = useState(initialValues);
  const [alvaraLicencas, setAlvaraLicencas] = useState([]);
  const [alvaraLicencasComplementares, setAlvaraLicencasComplementares] =
    useState([]);
  const [documentosLicenca, setDocumentosLicenca] = useState([]);
  const [isDadosCarregados, setIsDadosCarregados] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const formikRef = useRef();

  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const modalAlert = (id, acao) => {
    Swal.fire({
      title: `Tem certeza que deseja ${
        acao === "deletar" ? "excluir" : "arquivar"
      }?`,
      text: `Não será póssível reverter a ação!`,
      icon: `warning`,
      showCancelButton: true,
      confirmButtonText: `Sim!`,
      cancelButtonText: `Não!`,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        return acao === "deletar" ? handleDeleteDocumentoUnidade(id) : null;
      }
    });
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;

    if (formikRef.current.values.unidadeId === "") {
      toast("warning", "Preencha todos os campos obrigatórios!");
      return;
    }

    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const getAlvaraLicencas = useCallback(async () => {
    try {
      await ApiService.get("/alvaralicencas").then((response) => {
        if (response.status === 200) {
          setAlvaraLicencas(response.data);
        }
      });
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  const getAlvaraLicencasComplementares = useCallback(async () => {
    try {
      await ApiService.get("/alvaralicencascomplementar").then((response) => {
        if (response.status === 200) {
          setAlvaraLicencasComplementares(response.data);
        }
      });
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  const getDocumentosLicenca = useCallback(async () => {
    try {
      await ApiService.get("/documentolicenca").then((response) => {
        if (response.status === 200) {
          console.log(response.data);
          setDocumentosLicenca(response.data);
        }
      });
    } catch (error) {
      console.log(error.message);
    }
  }, [unidadeId]);

  const handleDeleteDocumentoUnidade = async (documentoId) => {
    try {
      await ApiService.delete(`/documentocomplementar/${documentoId}`).then(
        (response) => {
          if (response.status === 200) {
            toast("success", "Documento excluído!");
            getDocumentosUnidade();
          }
        }
      );
    } catch (error) {
      toast("error", "Ocorreu um erro!");
      console.log(error);
    }
  };

  const getDocumentosUnidade = async (unidadeId) => {
    try {
      await ApiService.get(`/unidadealvaralicenca/unidade/${unidadeId}`).then(
        (response) => {
          if (response.status === 200) {
            formikRef.current.setFieldValue("alvaraLicencas", response.data);
          }
        }
      );
      await ApiService.get(
        `/unidadealvaralicencacomplementar/unidade/${unidadeId}`
      ).then((response) => {
        if (response.status === 200) {
          formikRef.current.setFieldValue(
            "alvaraLicencasComplementar",
            response.data
          );
        }
      });
      await ApiService.get(`/unidade/${unidadeId}`).then((response) => {
        if (response.status === 200) {
          formikRef.current.setFieldValue(
            "documentosLicenca",
            response.data.documentos_complementares
          );
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    const allValuesEdit = {
      ...values,
    };
    delete allValuesEdit.deletedAt;
    delete allValuesEdit.createdAt;
    delete allValuesEdit.updatedAt;
    delete allValuesEdit.alvaraLicenca;
    delete allValuesEdit.alvaraLicencaComplementar;
    delete allValuesEdit.id;
    delete allValuesEdit.dataVencimento;
    delete allValuesEdit.unidade;
    delete allValuesEdit.status;
    delete allValuesEdit.documentos;
    delete allValuesEdit.licencaId;
    console.log(allValuesEdit);
    await ApiService.put(`/unidadealvaralicenca/documentos/unidade/${unidadeId}`, allValuesEdit)
      .then((response) => {
        resetForm();
        if (response.status === 200) {
          toast("success", "Salvo com sucesso");
          atualizarUnidade();
          handleClose();
        }
      })
      .catch((error) => {
        let message = error.response.data.message;
        toast("error", message);

        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("error", error.message);
        }
      });
  };

  useEffect(async () => {
    setIsDadosCarregados(false);
    const fetchData = async () => {
      await Promise.all([
        getAlvaraLicencasComplementares(),
        getAlvaraLicencas(),
        getDocumentosLicenca(),
        getDocumentosUnidade(unidadeId),
      ]);
    };
    fetchData();
    setIsDadosCarregados(true);
  }, [aberto]);

  useEffect(() => {
    setModalAberto(aberto);
    setActiveStep(0);
  }, [aberto]);

  return (
    <>
      {isDadosCarregados && (
        <Formik
          innerRef={formikRef}
          initialValues={documentos}
          validationSchema={validationSchema}
          enableReinitialize
          validateOnChange={false}
          onSubmit={handleSubmit}
        >
          {({
            values,
            isSubmitting,
            handleChange,
            handleBlur,
            setFieldValue,
            setSubmitting,
            handleSubmit,
            errors,
            isValid,
          }) => (
            <Form style={{ width: "100%" }} noValidate autoComplete="off">
              <Paper>
                <Modal
                  open={modalAberto}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  closeAfterTransition
                >
                  <Fade in={modalAberto}>
                    <Box sx={style}>
                      <Box>
                        <Typography variant={"h2"}>
                          Alvarás, licenças e documentos
                        </Typography>
                        <Typography my={1}>{nomeUnidade ?? ""}</Typography>
                      </Box>
                      <Divider />
                      <Box my={1} height={300} sx={{ overflowY: "scroll" }}>
                        {activeStep === 0 && (
                          <Paper
                            sx={{
                              width: "100%",
                              background: "white",
                              padding: 5,
                            }}
                            elevation={1}
                          >
                            <Box width={"100%"} my={2}>
                              <Typography variant={"h5"} fontWeight={600}>
                                Alvarás e Licenças
                              </Typography>
                              <FieldArray name="alvaraLicencas">
                                {({ push, remove }) => (
                                  <Box width={"100%"} my={2}>
                                    <FormControl
                                      sx={{
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      {alvaraLicencas &&
                                        alvaraLicencas.length > 0 &&
                                        alvaraLicencas.map(
                                          (alvaraLicenca, index) => {
                                            let isChecked;

                                            isChecked =
                                              values.alvaraLicencas.some(
                                                (item) =>
                                                  item.alvaraLicenca
                                                    ? item.alvaraLicenca.id ===
                                                      alvaraLicenca.id
                                                    : item.id ===
                                                      alvaraLicenca.id
                                              );
                                            let itemIndex;

                                            itemIndex =
                                              values.alvaraLicencas.findIndex(
                                                (item) =>
                                                  item.alvaraLicenca
                                                    ? item.alvaraLicenca.id ===
                                                      alvaraLicenca.id
                                                    : item.id ===
                                                      alvaraLicenca.id
                                              );

                                            return (
                                              <Div
                                                key={alvaraLicenca.id}
                                                my={2}
                                                sx={{
                                                  width: "100%",
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent:
                                                    "space-between",
                                                  borderRadius: 1,
                                                  paddingLeft: 2,
                                                  border: "1px solid #808080",
                                                  my: 1,
                                                }}
                                              >
                                                <FormControlLabel
                                                  value="female"
                                                  control={
                                                    <Checkbox
                                                      checked={isChecked}
                                                      onChange={(e) => {
                                                        const isChecked =
                                                          e.target.checked;
                                                        const itemId =
                                                          alvaraLicenca.id;

                                                        if (isChecked) {
                                                          push({
                                                            id: itemId,
                                                            identificacao: "",
                                                          });
                                                        } else {
                                                          let itemToRemove;

                                                          itemToRemove =
                                                            values.alvaraLicencas.some(
                                                              (item) =>
                                                                item.alvaraLicenca
                                                                  ? item
                                                                      .alvaraLicenca
                                                                      .id ===
                                                                    alvaraLicenca.id
                                                                  : item.id ===
                                                                    alvaraLicenca.id
                                                            );

                                                          if (itemToRemove) {
                                                            const itemIndex =
                                                              values.alvaraLicencas.indexOf(
                                                                itemToRemove
                                                              );
                                                            remove(itemIndex);
                                                          }
                                                        }
                                                      }}
                                                    />
                                                  }
                                                  label={
                                                    alvaraLicenca.descricao
                                                  }
                                                />
                                                {isChecked && (
                                                  <TextField
                                                    value={
                                                      values.alvaraLicencas
                                                        .alvaraLicenca
                                                        ? values.alvaraLicencas[
                                                            `${itemIndex}`
                                                          ].alvaraLicenca
                                                            .identificacao
                                                        : values.alvaraLicencas[
                                                            `${itemIndex}`
                                                          ].identificacao
                                                    }
                                                    placeholder="Identificação *"
                                                    onChange={(e) => {
                                                      const newValue =
                                                        e.target.value;
                                                      let itemIndex;

                                                      itemIndex =
                                                        values.alvaraLicencas.findIndex(
                                                          (item) =>
                                                            item.alvaraLicenca
                                                              ? item
                                                                  .alvaraLicenca
                                                                  .id ===
                                                                alvaraLicenca.id
                                                              : item.id ===
                                                                alvaraLicenca.id
                                                        );
                                                      setFieldValue(
                                                        `alvaraLicencas[${itemIndex}].identificacao`,
                                                        newValue
                                                      );
                                                    }}
                                                    sx={{ width: "60%" }}
                                                  />
                                                )}
                                              </Div>
                                            );
                                          }
                                        )}
                                      <ErrorMessage
                                        name={`alvarasLicenca`}
                                        component={"div"}
                                        style={{ color: "red" }}
                                      />
                                    </FormControl>
                                  </Box>
                                )}
                              </FieldArray>
                            </Box>
                            <Box width={"100%"} my={2}>
                              <Typography variant={"h5"} fontWeight={600}>
                                Alvarás e Licenças Complementares
                              </Typography>
                              <FieldArray name="alvaraLicencasComplementar">
                                {({ push, remove }) => (
                                  <Box width={"100%"} my={2}>
                                    <FormControl
                                      sx={{
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      {alvaraLicencasComplementares &&
                                        alvaraLicencasComplementares.length >
                                          0 &&
                                        alvaraLicencasComplementares.map(
                                          (
                                            alvaraLicencaComplementar,
                                            index
                                          ) => {
                                            let isChecked;

                                            isChecked =
                                              values.alvaraLicencasComplementar.some(
                                                (item) =>
                                                  item.alvaraLicencaComplementar
                                                    ? item
                                                        .alvaraLicencaComplementar
                                                        .id ===
                                                      alvaraLicencaComplementar.id
                                                    : item.id ===
                                                      alvaraLicencaComplementar.id
                                              );
                                            let itemIndex;

                                            itemIndex =
                                              values.alvaraLicencasComplementar.findIndex(
                                                (item) =>
                                                  item.alvaraLicencaComplementar
                                                    ? item
                                                        .alvaraLicencaComplementar
                                                        .id ===
                                                      alvaraLicencaComplementar.id
                                                    : item.id ===
                                                      alvaraLicencaComplementar.id
                                              );

                                            return (
                                              <Div
                                                key={
                                                  alvaraLicencaComplementar.id
                                                }
                                                my={2}
                                                sx={{
                                                  width: "100%",
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent:
                                                    "space-between",
                                                  borderRadius: 1,
                                                  paddingLeft: 2,
                                                  border: "1px solid #808080",
                                                  my: 1,
                                                }}
                                              >
                                                <FormControlLabel
                                                  value="female"
                                                  control={
                                                    <Checkbox
                                                      checked={isChecked}
                                                      onChange={(e) => {
                                                        let isChecked =
                                                          e.target.checked;
                                                        let itemId =
                                                          alvaraLicencaComplementar.id;

                                                        if (isChecked) {
                                                          push({
                                                            id: itemId,
                                                            identificacao: "",
                                                          });
                                                        } else {
                                                          let itemToRemove;

                                                          itemToRemove =
                                                            values.alvaraLicencasComplementar.find(
                                                              (item) =>
                                                                item.alvaraLicencaComplementar
                                                                  ? item
                                                                      .alvaraLicencaComplementar
                                                                      .id ===
                                                                    alvaraLicencaComplementar.id
                                                                  : item.id ===
                                                                    alvaraLicencaComplementar.id
                                                            );

                                                          if (itemToRemove) {
                                                            const itemIndex =
                                                              values.alvaraLicencasComplementar.indexOf(
                                                                itemToRemove
                                                              );
                                                            remove(itemIndex);
                                                          }
                                                        }
                                                      }}
                                                    />
                                                  }
                                                  label={
                                                    alvaraLicencaComplementar.descricao
                                                  }
                                                />
                                                {isChecked && (
                                                  <>
                                                    <TextField
                                                      value={
                                                        values
                                                          .alvaraLicencasComplementar[
                                                          itemIndex
                                                        ]?.identificacao || ""
                                                      }
                                                      placeholder="Identificação *"
                                                      onChange={(e) => {
                                                        const newValue =
                                                          e.target.value;
                                                        let itemIndex;

                                                        itemIndex =
                                                          values.alvaraLicencasComplementar.findIndex(
                                                            (item) =>
                                                              item.alvaraLicencaComplementar
                                                                ? item
                                                                    .alvaraLicencaComplementar
                                                                    .id ===
                                                                  alvaraLicencaComplementar.id
                                                                : item.id ===
                                                                  alvaraLicencaComplementar.id
                                                          );

                                                        if (
                                                          values
                                                            .alvaraLicencasComplementar[
                                                            itemIndex
                                                          ]
                                                            .alvaraLicencaComplementar
                                                        ) {
                                                          setFieldValue(
                                                            `alvaraLicencasComplementar[${itemIndex}].alvaraLicencaComplementar.identificacao`,
                                                            newValue
                                                          );
                                                        }

                                                        setFieldValue(
                                                          `alvaraLicencasComplementar[${itemIndex}].identificacao`,
                                                          newValue
                                                        );
                                                      }}
                                                      sx={{ width: "60%" }}
                                                    />
                                                  </>
                                                )}
                                              </Div>
                                            );
                                          }
                                        )}
                                      <ErrorMessage
                                        name={`alvaraLicencasComplementar`}
                                        component={"div"}
                                        style={{ color: "red" }}
                                      />
                                    </FormControl>
                                  </Box>
                                )}
                              </FieldArray>
                            </Box>
                          </Paper>
                        )}
                        {activeStep === 1 && (
                          <>
                            <Paper
                              sx={{
                                width: "100%",
                                background: "white",
                                padding: 5,
                                my: 2,
                              }}
                              elevation={1}
                            >
                              <FieldArray name="documentosLicenca">
                                {({ push, remove }) => (
                                  <Box width={"100%"} my={2}>
                                    <Typography>
                                      Documentos complementares
                                    </Typography>
                                    <FormControl
                                      sx={{
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      {documentosLicenca &&
                                        documentosLicenca.length > 0 &&
                                        documentosLicenca.map(
                                          (documentoLicenca, index) => {
                                            let isChecked =
                                              values.documentosLicenca?.some(
                                                (item) =>
                                                  item.identificacao ===
                                                  documentoLicenca.descricao
                                              );

                                            let itemIndex =
                                              values.documentosLicenca?.findIndex(
                                                (item) =>
                                                  item.identificacao ===
                                                  documentoLicenca.descricao
                                              );

                                            {
                                              console.log(
                                                `INDEX: ${itemIndex}`
                                              );
                                            }
                                            return (
                                              <Div
                                                key={index}
                                                my={2}
                                                sx={{
                                                  width: "100%",
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent:
                                                    "space-between",
                                                  borderRadius: 1,
                                                  paddingLeft: 2,
                                                  border: "1px solid #808080",
                                                  my: 1,
                                                }}
                                              >
                                                <FormControlLabel
                                                  value="female"
                                                  control={
                                                    <Checkbox
                                                      checked={isChecked}
                                                      onChange={(e) => {
                                                        const isChecked =
                                                          e.target.checked;
                                                        const identificacao =
                                                          documentoLicenca.descricao;

                                                        if (isChecked) {
                                                          push({
                                                            id: documentoLicenca.id,
                                                            identificacao:
                                                              identificacao,
                                                          });
                                                        } else {
                                                          remove(itemIndex);
                                                        }
                                                      }}
                                                    />
                                                  }
                                                  label={
                                                    documentoLicenca.descricao
                                                  }
                                                />
                                              </Div>
                                            );
                                          }
                                        )}
                                      {values.documentosLicenca &&
                                        values.documentosLicenca.length > 0 &&
                                        values.documentosLicenca.map(
                                          (documento, index) => {
                                            const documentoJaAdicionado =
                                              documentosLicenca.some(
                                                (doc) =>
                                                  doc.descricao ===
                                                  documento.identificacao
                                              );
                                            if (
                                              !documentoJaAdicionado &&
                                              !documento.id
                                            ) {
                                              return (
                                                <Div
                                                  key={index}
                                                  my={2}
                                                  sx={{
                                                    width: "100%",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent:
                                                      "space-between",
                                                    borderRadius: 1,
                                                    paddingLeft: 2,
                                                    border: "1px solid #808080",
                                                    my: 1,
                                                  }}
                                                >
                                                  <Typography>
                                                    OUTRO (ESPECÍFICAR AO LADO):
                                                  </Typography>
                                                  <TextField
                                                    sx={{ width: "60%" }}
                                                    placeholder="Nome do documento"
                                                    onBlur={handleBlur}
                                                    onChange={(e) => {
                                                      setFieldValue(
                                                        `documentosLicenca[${index}].identificacao`,
                                                        e.target.value
                                                      );
                                                    }}
                                                    name={`documentosLicenca[${index}].identificacao`}
                                                    value={
                                                      documento.identificacao
                                                    }
                                                  />
                                                  <Stack
                                                    sx={{
                                                      paddingRight: 1,
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                      remove(index);
                                                    }}
                                                  >
                                                    <DeleteIcon color="error" />
                                                  </Stack>
                                                </Div>
                                              );
                                            } else if (!documentoJaAdicionado) {
                                              return (
                                                <Div
                                                  key={index}
                                                  my={2}
                                                  sx={{
                                                    width: "100%",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent:
                                                      "space-between",
                                                    borderRadius: 1,
                                                    padding: 2,
                                                    border: "1px solid #808080",
                                                    my: 1,
                                                  }}
                                                >
                                                  <Typography>
                                                    {documento.identificacao}
                                                  </Typography>
                                                  <Stack
                                                    sx={{
                                                      paddingRight: 1,
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    <DeleteIcon
                                                      color="error"
                                                      onClick={() => {
                                                        modalAlert(
                                                          documento.id,
                                                          "deletar"
                                                        );
                                                      }}
                                                    />
                                                  </Stack>
                                                </Div>
                                              );
                                            }
                                          }
                                        )}
                                      {console.log(errors)}
                                      <ErrorMessage
                                        name={`alvaraLicencasComplementar`}
                                        component={"div"}
                                        style={{ color: "red" }}
                                      />
                                    </FormControl>
                                    <Div my={2}>
                                      <Button
                                        variant={"contained"}
                                        color="info"
                                        onClick={() => {
                                          push({
                                            id: null,
                                            identificacao: "",
                                          });
                                        }}
                                      >
                                        Adicionar mais documentos
                                      </Button>
                                    </Div>
                                  </Box>
                                )}
                              </FieldArray>
                            </Paper>
                          </>
                        )}
                      </Box>
                      <input hidden type="number" name="userid" value={Number(userId)} />
                      {console.log(errors)}
                      <Box
                        sx={{
                          width: "80%",
                          display: "block",
                          margin: "auto",
                        }}
                        elevation={1}
                      >
                        <Div sx={{ width: "100%" }}>
                          <Stepper activeStep={activeStep}>
                            {steps.map((label, index) => {
                              const stepProps = {};
                              const labelProps = {};

                              if (isStepSkipped(index)) {
                                stepProps.completed = false;
                              }
                              return (
                                <Step key={label} {...stepProps}>
                                  <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                              );
                            })}
                          </Stepper>
                          {activeStep === steps.length ? (
                            <Div>
                              <Typography sx={{ mt: 2, mb: 1 }}>
                                All steps completed - you&apos;re finished
                              </Typography>
                              <Div
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  pt: 2,
                                }}
                              >
                                <Div sx={{ flex: "1 1 auto" }} />
                                <Button onClick={handleReset}>Resetar</Button>
                              </Div>
                            </Div>
                          ) : (
                            <Div>
                              <Typography sx={{ mt: 2, mb: 1 }}>
                                Etapa {activeStep + 1}
                              </Typography>
                              <Div
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  pt: 2,
                                }}
                              >
                                <Button
                                  disabled={activeStep === 0}
                                  variant="outlined"
                                  onClick={handleBack}
                                  color="primary"
                                  sx={{ mr: 1 }}
                                >
                                  Voltar
                                </Button>
                                <Div sx={{ flex: "1 1 auto" }} />

                                <Button
                                  variant="contained"
                                  color="success"
                                  onClick={() => {
                                    if (activeStep === steps.length - 1) {
                                      handleSubmit();
                                    } else {
                                      handleNext();
                                    }
                                  }}
                                >
                                  {activeStep === steps.length - 1
                                    ? "Finalizar"
                                    : "Avançar"}
                                </Button>
                              </Div>
                            </Div>
                          )}
                        </Div>
                      </Box>
                    </Box>
                  </Fade>
                </Modal>
              </Paper>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};
export default ModalAdicionarDocumentosUnidade;
