import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Fade,
  Modal,
  Paper,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  InputAdornment,
} from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { ErrorMessage, Form, Formik } from "formik";
import * as yup from "yup";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import ApiService from "app/services/config";
import CurrencyInput from "react-currency-input-field";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  currencyInput: {
    width: "100%",
    height: 50,
    padding: "10px 14px",
    fontSize: "1rem",
    borderRadius: 4,
    border: "1px solid #ced4da",
    backgroundColor: "#fff",
    boxSizing: "border-box",
  },
};

const validationSchema = yup.object().shape({
  contratoId: yup.number().required("É preciso fornecer o ID do contrato"),
  valor: yup.number("Insira um número válido").required("Campo obrigatório"),
  numeroParcelas: yup
    .number("Insira um número válido")
    .required("Campo obrigatório"),
  descricao: yup.string().optional(),
});

const NUMERO_TOTAL_PARCELMENTO = 12;

const ModalRenegociarContrato = ({
  aberto,
  handleClose,
  contratoId,
  atualizarValorRenegociacao,
  valorAtualContrato,
}) => {
  const initialValues = {
    contratoId: "",
    valor: 0,
    numeroParcelas: 1,
    descricao: "",
  };
  const [pagamentoContrato, setPagamentoContrato] = useState(initialValues);
  const [valorMoedaFormatada, setValorMoedaFormatada] = useState(
    initialValues.valor
  );

  const [modalAberto, setModalAberto] = useState(aberto);
  const [idContrato, setIdContrato] = useState();
  useEffect(() => {
    setModalAberto(aberto);
  }, [aberto]);

  const Swal = useSwalWrapper();

  useEffect(() => {
    setIdContrato(contratoId);
    setPagamentoContrato((prevPagamentoContrato) => ({
      ...prevPagamentoContrato,
      contratoId: contratoId,
    }));
  }, [contratoId]);

  const toast = (variant, message, type = false) => {
    Swal.fire({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      icon: type ? "success" : "error",
      title: message,
      didOpen: (toast) => {
        toast.style.zIndex = 10000;
      },
      timer: 3000,
    });
  };

  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    console.log(values);
    try {
      await ApiService.post("/renegociacaocontrato", values).then(
        (response) => {
          if (response.status === 201) {
            const novaRenegociacao = response.data;
            atualizarValorRenegociacao(novaRenegociacao, "adicao");
            handleClose();
            toast(null, "Inserido com sucesso!", "success");
          }
        }
      );
    } catch (error) {
      toast(null, "Ocorreu um erro!", "error");
      console.log(error);
    }
  };

  return (
    <Paper>
      <Modal
        open={modalAberto}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
      >
        <Fade in={modalAberto}>
          <Box sx={style}>
            <Typography variant="h2" component="h2" gutterBottom>
              Renegociar
            </Typography>
            <Divider />
            <Box width={1} mt={3}>
              <Formik
                enableReinitialize
                initialValues={pagamentoContrato}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  errors,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Box width={1} mt={3}>
                      Valor atual do contrato:{" "}
                      {Number(valorAtualContrato).toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </Box>
                    <Box width={1} mt={3}>
                      <InputLabel id="valor">Valor</InputLabel>
                      <CurrencyInput
                        style={style.currencyInput}
                        id="valor"
                        name="valor"
                        prefix={"R$ "}
                        decimalsLimit={2}
                        decimalSeparator=","
                        groupSeparator="."
                        step={0.01}
                        defaultValue={values.valor}
                        placeholder="Valor"
                        onValueChange={(value, name, values) => {
                          if (
                            value === undefined ||
                            value === null ||
                            value === ""
                          ) {
                            setFieldValue("valor", 0);
                          } else {
                            const valorFormatado = Number(
                              value.replace(/[^0-9,.]/g, "").replace(",", ".")
                            );
                            setFieldValue("valor", valorFormatado);
                          }
                        }}
                      />

                      <ErrorMessage
                        name="valor"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Box>
                    {console.log(errors)}
                    <Box width={1} mt={3}>
                      <InputLabel id="numeroParcelasSelect">
                        Número de parcelas
                      </InputLabel>

                      <Select
                        labelId="numeroParcelasSelect"
                        name="numeroParcelas"
                        value={values.numeroParcelas}
                        onChange={(event) => {
                          setFieldValue("numeroParcelas", event.target.value);
                        }}
                        fullWidth
                      >
                        {Array.from(
                          { length: NUMERO_TOTAL_PARCELMENTO },
                          (_, numeroParcela) => (
                            <MenuItem
                              key={numeroParcela + 1}
                              value={numeroParcela + 1}
                            >
                              {`${numeroParcela + 1}x`}
                            </MenuItem>
                          )
                        )}
                      </Select>
                      <ErrorMessage
                        name="numeroParcelas"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Box>
                    <Box width={1} mt={3}>
                      <TextField
                        name="descricao"
                        label="Descrição"
                        variant="outlined"
                        onChange={handleChange}
                        multiline
                        rows={4}
                        fullWidth
                      />
                    </Box>
                    <Box mt={3} display="flex" justifyContent="space-evenly">
                      <Button variant="contained" color="success" type="submit">
                        Salvar
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={handleClose}
                      >
                        Fechar
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Paper>
  );
};

export default ModalRenegociarContrato;
