import axios from "axios";

export const ApiService = axios.create({
  baseURL: process.env.REACT_APP_API_KEY,
  headers: {
    "Content-Type": "application/json",
  },
});

ApiService.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (err.response && err.response.data.type === "token-invalid") {
      //todo logout the user
      localStorage.removeItem("tokenMC");
      localStorage.removeItem("avatarUrl");
      localStorage.removeItem("id");
      localStorage.removeItem("username");
      localStorage.removeItem("email");
      localStorage.removeItem("storedPermissions");
      window.location.href = "/login";
    }
    return Promise.reject(err);
  }
);

export const setAuthToken = (token) => {
  if (token) {
    ApiService.defaults.headers.common["Authorization"] = "Bearer " + token;
    sessionStorage.setItem("tokenMC", token);
  } else {
    delete ApiService.defaults.headers.common["Authorization"];
    sessionStorage.removeItem("tokenMC");
  }
};

export const getAuthToken = () => {
  return sessionStorage.getItem("token");
};

//todo: define interceptors and other configuration like baseURL, headers etc. here
export default ApiService;

//todo: define interceptors and other configuration like baseURL, headers etc. here
