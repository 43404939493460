import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Grid,
  Stack,
  Toolbar,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import { PermissionContext } from "app/contexts/PermissionContext";
import ApiService from "app/services/config";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";

const linkStyle = {
  color: "#302D2E",
  fontSize: 20,
  textDecoration: "none",
  "&:visited": {
    color: "#302D2E",
  },
};

const LicencasContratoList = () => {
  const { theme } = useJumboTheme();
  const location = useLocation();
  const { contratoId, clienteId, processoId, procadmin } = useParams();
  const [licencas, setLicencas] = useState([]);
  const [breadCrumbsInfo, setBreadCrumbsInfo] = useState([]);
  const [selectedLicencasIds, setSelectedLicencasIds] = useState([]);
  const [todasLicencasSelecionadas, setTodasLicencasSelecionadas] =
    useState(false);
  const [mostrarBotaoDeletarMuitos, setMostrarBotaoDeletarMuitos] =
    useState(false);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");
  const [count, setCount] = useState("");

  const navigate = useNavigate();
  const { hasPermission } = useContext(PermissionContext);
  const permission = hasPermission("Contratos", "create");
  if (!hasPermission("Contratos", "read")) {
    navigate("/app");
  }

  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const Swal = useSwalWrapper();
  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const handleSearch = async (newSearchTerm) => {
    if (searchTerm === "" || searchTerm === null || searchTerm === undefined) {
      setPage(1);
    }
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  const handleSelecionarTodasLicencas = () => {
    const todosIdsLicencas = licencas.map((licenca) => licenca.id);
    if (!todasLicencasSelecionadas) {
      setSelectedLicencasIds(todosIdsLicencas);
    } else {
      setSelectedLicencasIds([]);
    }
    setTodasLicencasSelecionadas(!todasLicencasSelecionadas);
  };

  const handleDeleteMuitasLicencas = () => {
    Swal.fire({
      title: "Tem certeza que deseja apagar as licenças selecionadas?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteMuitasLicencas();
      }
    });
  };

  const deleteMuitasLicencas = useCallback(async () => {
    try {
      await ApiService.put("/licenca/deletar/many/", selectedLicencasIds).then(
        (response) => {
          if (response.status === 200) {
            toast("success", "Licenças removidas com sucesso!");
            getLicencas();
            setSelectedLicencasIds([]);
          }
        }
      );
    } catch (error) {
      toast("error", "Não foi possível deletar as licenças selecionadas");
      console.log(error);
    }
  }, [selectedLicencasIds]);

  const getLicencas = useCallback(async () => {
    try {
      let apiUrl = `/licenca/contrato/${contratoId}/processo/${processoId}?page=${
        page + 1
      }&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}`;

      if (searchTerm) {
        apiUrl += `&searchTerm=${searchTerm}`;
      }
      ApiService.get(apiUrl)
        .then((response) => {
          const initialData = response.data.licencas.map((licenca) => ({
            ...licenca,
            isSelected: selectedLicencasIds.includes(licenca.id),
          }));
          setLicencas(initialData);
          setCount(response.data.count);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [page, perPage, sortBy, sortOrder, searchTerm, selectedLicencasIds]);

  useEffect(() => {
    getLicencas();
  }, [getLicencas]);

  const handleCheckboxChange = (licencaId) => {
    setLicencas((prevLicencas) =>
      prevLicencas.map((licenca) =>
        licenca.id === licencaId
          ? { ...licenca, isSelected: !licenca.isSelected }
          : licenca
      )
    );

    setSelectedLicencasIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(licencaId)) {
        return prevSelectedIds.filter((id) => id !== licencaId);
      } else {
        return [...prevSelectedIds, licencaId];
      }
    });

    setMostrarBotaoDeletarMuitos(selectedLicencasIds.length > 0);
    setTodasLicencasSelecionadas(false);
  };

  const columns = [
    {
      name: "selecionar",
      label: "Selecionar",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const licencaId = tableMeta.rowData[1];
          const licenca = licencas.find((licenca) => licenca.id === licencaId);
          return (
            <Checkbox
              checked={licenca.isSelected}
              onChange={() => handleCheckboxChange(licencaId)}
            />
          );
        },
      },
    },
    {
      name: "id",
      label: "id",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "processo_administrativo",
      label: "Id Processo",
      options: {
        filter: true,
        sort: true,
        display: false,
        enableNestedAccess: ".",
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value?.id}</>;
        },
      },
    },
    {
      name: "nome_licenca",
      label: "Licença",
      options: {
        filter: true,
        sort: true,
        enableNestedAccess: ".",
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value?.nome}</>;
        },
      },
    },
    {
      name: "numero",
      label: "Número",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "data_emissao",
      label: "Data de Emissão",
      options: {
        filter: true,
        sort: true,
        enableNestedAccess: ".",
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{moment.utc(value).format("DD/MM/YYYY")}</>;
        },
      },
    },
    {
      name: "data_expiracao",
      label: "Data de Expiração",
      options: {
        filter: true,
        sort: true,
        enableNestedAccess: ".",
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{moment.utc(value).format("DD/MM/YYYY")}</>;
        },
      },
    },
    {
      name: "dataVencimento",
      label: "Data de Vencimento",
      options: {
        filter: true,
        sort: true,
        enableNestedAccess: ".",
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{moment.utc(value).format("DD/MM/YYYY")}</>;
        },
      },
    },
    {
      name: "action",
      label: "Ações",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const licencaId = tableMeta.rowData[1];
          const procAdminId = tableMeta.rowData[2]?.id;
          return (
            <Box display={"flex"} gap={"1rem"}>
              <Tooltip title="Editar Licença">
                <EditIcon
                  cursor="pointer"
                  color="info"
                  disabled={!permission}
                  onClick={() => {
                    navigate(
                      `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procAdminId}/licencas/${licencaId}/editar`,
                      {
                        state: {
                          descricao:
                            location?.state?.descricao ??
                            licencas[0]?.contrato?.descricao,
                          processoDescricao: tableMeta.rowData[2]?.descricao,
                        },
                      }
                    );
                  }}
                />
              </Tooltip>
              <Tooltip title="Excluir Licença">
                <DeleteIcon
                  cursor="pointer"
                  color="error"
                  onClick={() => {
                    navigate(
                      `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procAdminId}/licencas/${licencaId}/deletar`
                    );
                  }}
                />
              </Tooltip>
            </Box>
          );
        },
      },
    },
  ];

  const options = {
    changeRowsPerPage: perPage,
    filterType: "dropdown",
    filter: false,
    selectableRows: "none",
    searchAlwaysOpen: true,
    searchable: true,
    serverSide: true,
    page: page,
    count: count,
    selectToolbarPlacement: "above",

    onTableChange: (action, tableState) => {
      console.log(action);
      switch (action) {
        case "changePage":
          setPage(tableState.page);
          break;
        case "sort":
          setSortBy(tableState.sortOrder.name);
          setSortOrder(tableState.sortOrder.direction);
          break;
        case "search":
          handleSearch(tableState.searchText);
          break;
        case "changeRowsPerPage":
          setPerPage(tableState.rowsPerPage);
          break;
        default:
          console.log("action not handled.");
      }
    },
    customToolbar: ({ displayData }) => (
      <>
        {selectedLicencasIds.length > 0 ? (
          <Toolbar>
            <Box
              display={"flex"}
              gap={"1rem"}
              justifyContent={"flex-end"}
              width={"100%"}
            ></Box>
          </Toolbar>
        ) : null}
      </>
    ),
    textLabels: {
      body: {
        noMatch: "Nenhum resultado encontrado",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: "Próxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Itens por Página:",
        displayRows: "of",
      },
    },
  };

  const getBreadCrumbsInfo = async () => {
    try {
      return await ApiService.get(
        `/contratos/breadcrumbs?contratoId=${contratoId}&processoId=${processoId}`
      ).then((response) => {
        if (response.status === 200) {
          setBreadCrumbsInfo(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (contratoId) {
      getBreadCrumbsInfo();
    }
  }, [contratoId]);

  return (
    <JumboContentLayout
      header={<PageHeader title={"Licenças"} />}
      layoutOptions={layoutOptions}
    >
      {lg && (
        <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}></Stack>
      )}
      <Stack width={"100%"}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link to={"/app/listar-contratos"} color="success" style={linkStyle}>
            Contratos
          </Link>
          {breadCrumbsInfo && (
            <Link
              to={`/app/editar-contrato/${contratoId}`}
              color="success"
              style={linkStyle}
            >
              {breadCrumbsInfo?.descricao}
            </Link>
          )}
          <Link
            to={`/app/contrato/${contratoId}/cliente/${clienteId}/processos`}
            color="primary"
            style={linkStyle}
          >
            Processos Administrativos
          </Link>
          {breadCrumbsInfo && (
            <Link
              to={`/app/contrato/${contratoId}/cliente/${clienteId}/processos/editar/${processoId}`}
              color="primary"
              style={linkStyle}
            >
              {
                breadCrumbsInfo?.processos_contratos?.[0]
                  ?.processo_administrativo?.descricao
              }{" "}
            </Link>
          )}
          <Link
            to={`/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/licencas`}
            color="primary"
            style={linkStyle}
          >
            Licenças
          </Link>
        </Breadcrumbs>
      </Stack>

      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <LoadingButton
          color="success"
          type="submit"
          variant="contained"
          size="large"
          sx={{
            maxWidth: { md: "200px", mt: "1rem" },
            marginBottom: "2rem",
          }}
          disabled={!permission}
          onClick={() => {
            navigate(
              `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/licencas/nova`,
              {
                state: {
                  descricao:
                    location?.state?.descricao ??
                    licencas[0]?.contrato?.descricao,
                },
              }
            );
          }}
        >
          Nova Licença
        </LoadingButton>
      </Grid>
      <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
        {selectedLicencasIds.length > 0 && (
          <Grid item>
            <span style={{ margin: 10 }}>
              {selectedLicencasIds.length} licença(s) selecionado(s)
            </span>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<DeleteIcon />}
              onClick={handleDeleteMuitasLicencas}
              disabled={!permission}
            >
              Excluir Selecionados
            </Button>
          </Grid>
        )}
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSelecionarTodasLicencas}
          >
            {todasLicencasSelecionadas ? "Desmarcar Todos" : "Selecionar Todos"}
          </Button>
        </Grid>
      </Grid>

      <React.Fragment>
        <Box mt={3}>
          <MUIDataTable
            title={"Licenças"}
            data={licencas}
            columns={columns}
            options={options}
          />
        </Box>
      </React.Fragment>
    </JumboContentLayout>
  );
};

export default LicencasContratoList;
