import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {
  Box,
  Divider,
  Fade,
  Modal,
  Button,
  Paper,
  Typography,
  useMediaQuery,
  Stepper,
  Step,
  StepLabel,
  FormControl,
  TextField,
  Stack,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";
import * as yup from "yup";
import ApiService from "app/services/config";
import Div from "@jumbo/shared/Div";
import { LoadingButton } from "@mui/lab";
import DeleteIcon from "@mui/icons-material/Delete";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "transparent" : "white",
    border: state.isFocused
      ? "1px solid #005D5F"
      : "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    padding: "8px",
    boxShadow: "none",
    "&:hover": {
      borderColor: "black",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#005D5F" : "white",
    color: state.isSelected ? "white" : "black",
    "&:hover": {
      backgroundColor: "lightblue",
      color: "white",
    },
  }),
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1200,
  height: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const validationSchema = yup.object().shape({
  licencaId: yup.number("Campo obrigatório").required("Campo obrigatório"),
  documentosLicenca: yup.array().of(
    yup.object().shape({
      id: yup.mixed().nullable(),
      descricao: yup.string().nullable(),
    })
  ),
});

const ModalEdicaoDocumentosLicenca = ({
  aberto,
  handleClose,
  licencaId,
  nomeUnidade,
  atualizarUnidade,
  userId,
}) => {
  const initialValues = {
    userId: Number(userId),
    licencaId: licencaId,
    documentosLicenca: [],
  };
  const [modalAberto, setModalAberto] = useState(aberto);
  const { theme } = useJumboTheme();
  const Swal = useSwalWrapper();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const [documentos, setDocumentos] = useState(initialValues);
  const [idLicenca, setIdLicenca] = useState([]);
  const [alvaraLicencas, setAlvaraLicencas] = useState([]);
  const [alvaraLicencasComplementares, setAlvaraLicencasComplementares] =
    useState([]);
  const [documentosLicenca, setDocumentosLicenca] = useState([]);
  const [isDadosCarregados, setIsDadosCarregados] = useState(false);
  const [skipped, setSkipped] = React.useState(new Set());
  const formikRef = useRef();

  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const modalAlert = (id, acao) => {
    Swal.fire({
      title: `Tem certeza que deseja ${
        acao === "deletar" ? "excluir" : "arquivar"
      }?`,
      text: `Não será póssível reverter a ação!`,
      icon: `warning`,
      showCancelButton: true,
      confirmButtonText: `Sim!`,
      cancelButtonText: `Não!`,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        return acao === "deletar" ? handleDeleteDocumentoUnidade(id) : null;
      }
    });
  };

  const getDocumentosLicenca = useCallback(async () => {
    try {
      await ApiService.get("/documentolicenca").then((response) => {
        if (response.status === 200) {
          setDocumentosLicenca(response.data);
        }
      });
    } catch (error) {
      console.log(error.message);
    }
  }, [licencaId]);

  const handleDeleteDocumentoUnidade = async (documentoId) => {
    try {
      await ApiService.delete(`/documentocomplementar/${documentoId}`).then(
        (response) => {
          if (response.status === 200) {
            toast("success", "Documento excluído!");
            getDocumentosUnidade();
          }
        }
      );
    } catch (error) {
      toast("error", "Ocorreu um erro!");
      console.log(error);
    }
  };

  const getDocumentosUnidade = async (licencaId) => {
    try {
      await ApiService.get(`/unidadealvaralicenca/${licencaId}`).then(
        (response) => {
          if (response.status === 200) {
            formikRef.current.setFieldValue(
              "documentosLicenca",
              response.data.documentos_complementares
            );
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    const allValuesEdit = {
      ...values,
    };
    delete allValuesEdit.deletedAt;
    delete allValuesEdit.createdAt;
    delete allValuesEdit.updatedAt;
    delete allValuesEdit.alvaraLicenca;
    delete allValuesEdit.alvaraLicencaComplementar;
    delete allValuesEdit.id;
    delete allValuesEdit.dataVencimento;
    delete allValuesEdit.unidade;
    delete allValuesEdit.status;
    delete allValuesEdit.documentos;
    delete allValuesEdit.licencaId;
    await ApiService.put(
      `/unidadealvaralicenca/documentos/licenca/${licencaId}`,
      allValuesEdit
    )
      .then((response) => {
        resetForm();
        if (response.status === 200) {
          toast("success", "Salvo com sucesso");
          atualizarUnidade();
          handleClose();
        }
      })
      .catch((error) => {
        let message = error.response.data.message;
        toast("error", message);

        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("error", error.message);
        }
      });
  };

  useEffect(async () => {
    setIsDadosCarregados(false);
    const fetchData = async () => {
      await Promise.all([
        getDocumentosUnidade(idLicenca),
        getDocumentosLicenca(),
      ]);
    };
    fetchData();
    setIsDadosCarregados(true);
  }, [aberto, licencaId]);

  useEffect(() => {
    setModalAberto(aberto);
  }, [aberto]);

  useEffect(() => {
    setIdLicenca(licencaId);
  }, [licencaId]);

  return (
    <>
      {isDadosCarregados && (
        <Formik
          innerRef={formikRef}
          initialValues={documentos}
          validationSchema={validationSchema}
          enableReinitialize
          validateOnChange={false}
          onSubmit={handleSubmit}
        >
          {({
            values,
            isSubmitting,
            handleChange,
            handleBlur,
            setFieldValue,
            setSubmitting,
            handleSubmit,
            errors,
            isValid,
          }) => (
            <Form style={{ width: "100%" }} noValidate autoComplete="off">
              <Paper>
                <Modal
                  open={modalAberto}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  closeAfterTransition
                >
                  <Fade in={modalAberto}>
                    <Box sx={style}>
                      <Box>
                        <Typography variant={"h2"}>
                          Documentos complementares
                        </Typography>
                        <Typography my={1}>{nomeUnidade ?? ""}</Typography>
                      </Box>
                      <Divider />
                      <Box my={1} height={300} sx={{ overflowY: "scroll" }}>
                        <>
                          <Paper
                            sx={{
                              width: "100%",
                              background: "white",
                              padding: 5,
                              my: 2,
                            }}
                            elevation={1}
                          >
                            {console.log(values)}
                            <FieldArray name="documentosLicenca">
                              {({ push, remove }) => (
                                <Box width={"100%"} my={2}>
                                  <Typography>
                                    Documentos complementares
                                  </Typography>
                                  <FormControl
                                    sx={{
                                      width: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {documentosLicenca &&
                                      documentosLicenca.length > 0 &&
                                      documentosLicenca.map(
                                        (documentoLicenca, index) => {
                                          let isChecked =
                                            values.documentosLicenca?.some(
                                              (item) =>
                                                item.identificacao ===
                                                documentoLicenca.descricao
                                            );

                                          let itemIndex =
                                            values.documentosLicenca?.findIndex(
                                              (item) =>
                                                item.identificacao ===
                                                documentoLicenca.descricao
                                            );

                                          return (
                                            <Div
                                              key={index}
                                              my={2}
                                              sx={{
                                                width: "100%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                borderRadius: 1,
                                                paddingLeft: 2,
                                                border: "1px solid #808080",
                                                my: 1,
                                              }}
                                            >
                                              <FormControlLabel
                                                value="female"
                                                control={
                                                  <Checkbox
                                                    checked={isChecked}
                                                    onChange={(e) => {
                                                      const isChecked =
                                                        e.target.checked;
                                                      const identificacao =
                                                        documentoLicenca.descricao;

                                                      if (isChecked) {
                                                        push({
                                                          id: documentoLicenca.id,
                                                          identificacao:
                                                            identificacao,
                                                        });
                                                      } else {
                                                        remove(itemIndex);
                                                      }
                                                    }}
                                                  />
                                                }
                                                label={
                                                  documentoLicenca.descricao
                                                }
                                              />
                                            </Div>
                                          );
                                        }
                                      )}
                                    {values.documentosLicenca &&
                                      values.documentosLicenca.length > 0 &&
                                      values.documentosLicenca.map(
                                        (documento, index) => {
                                          const documentoJaAdicionado =
                                            documentosLicenca.some(
                                              (doc) =>
                                                doc.descricao ===
                                                documento.identificacao
                                            );
                                          if (
                                            !documentoJaAdicionado &&
                                            !documento.id
                                          ) {
                                            return (
                                              <Div
                                                key={index}
                                                my={2}
                                                sx={{
                                                  width: "100%",
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent:
                                                    "space-between",
                                                  borderRadius: 1,
                                                  paddingLeft: 2,
                                                  border: "1px solid #808080",
                                                  my: 1,
                                                }}
                                              >
                                                <Typography>
                                                  OUTRO (ESPECÍFICAR AO LADO):
                                                </Typography>
                                                <TextField
                                                  sx={{ width: "60%" }}
                                                  placeholder="Nome do documento"
                                                  onBlur={handleBlur}
                                                  onChange={(e) => {
                                                    setFieldValue(
                                                      `documentosLicenca[${index}].identificacao`,
                                                      e.target.value
                                                    );
                                                  }}
                                                  name={`documentosLicenca[${index}].identificacao`}
                                                  value={
                                                    documento.identificacao
                                                  }
                                                />
                                                <Stack
                                                  sx={{
                                                    paddingRight: 1,
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                    remove(index);
                                                  }}
                                                >
                                                  <DeleteIcon color="error" />
                                                </Stack>
                                              </Div>
                                            );
                                          } else if (!documentoJaAdicionado) {
                                            return (
                                              <Div
                                                key={index}
                                                my={2}
                                                sx={{
                                                  width: "100%",
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent:
                                                    "space-between",
                                                  borderRadius: 1,
                                                  padding: 2,
                                                  border: "1px solid #808080",
                                                  my: 1,
                                                }}
                                              >
                                                <Typography>
                                                  {documento.identificacao}
                                                </Typography>
                                                <Stack
                                                  sx={{
                                                    paddingRight: 1,
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  <DeleteIcon
                                                    color="error"
                                                    onClick={() => {
                                                      modalAlert(
                                                        documento.id,
                                                        "deletar"
                                                      );
                                                    }}
                                                  />
                                                </Stack>
                                              </Div>
                                            );
                                          }
                                        }
                                      )}
                                    {console.log(errors)}
                                    <ErrorMessage
                                      name={`alvaraLicencasComplementar`}
                                      component={"div"}
                                      style={{ color: "red" }}
                                    />
                                  </FormControl>
                                  <Div my={2}>
                                    <Button
                                      variant={"contained"}
                                      color="info"
                                      onClick={() => {
                                        push({
                                          id: null,
                                          identificacao: "",
                                        });
                                      }}
                                    >
                                      Adicionar mais documentos
                                    </Button>
                                  </Div>
                                </Box>
                              )}
                            </FieldArray>
                          </Paper>
                        </>
                      </Box>
                      <input
                        hidden
                        type="number"
                        name="userid"
                        value={Number(userId)}
                      />
                      {console.log(errors)}
                      <Box
                        sx={{
                          width: "80%",
                          display: "block",
                          margin: "auto",
                        }}
                        elevation={1}
                      >
                        <Div sx={{ width: "100%" }}>
                          <Button
                            variant="contained"
                            color="success"
                            onClick={handleSubmit}
                            sx={{ display: "block", margin: "auto" }}
                          >
                            Finalizar
                          </Button>
                        </Div>
                      </Box>
                    </Box>
                  </Fade>
                </Modal>
              </Paper>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};
export default ModalEdicaoDocumentosLicenca;
