import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Paper,
  Stack,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { PermissionContext } from "app/contexts/PermissionContext";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import ApiService from "app/services/config";
import { ErrorMessage, Form, Formik } from "formik";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Typography } from "antd";
import { tiposArquivos } from "app/utils/tiposArquivos";

const validationSchema = yup.object().shape({
  nome: yup
    .string("Insira a descrição do documento")
    .required("Preenchimento obrigatório"),
});

const linkStyle = {
  color: "#302D2E",
  fontSize: 20,
  textDecoration: "none",
  "&:visited": {
    color: "#302D2E",
  },
};

const DocumentosServicosForm = () => {
  const {
    contratoId,
    clienteId,
    processoId,
    servicoId,
    documentoId,
    procadmin,
  } = useParams();
  const initialValues = {
    nome: "",
  };
  const [documento, setDocumento] = useState(initialValues);
  const [isDeleteMode, setIsDeleteMode] = useState(false);
  const [breadCrumbsInfo, setBreadCrumbsInfo] = useState([]);
  const [isDocumentoAnexado, setIsDocumentoAnexado] = useState(false);
  const [documentoSelecionado, setDocumentosSelecionado] = useState(null);

  const Swal = useSwalWrapper();
  const navigate = useNavigate();
  const location = useLocation();

  const { hasPermission } = useContext(PermissionContext);

  if (!hasPermission("Contratos", "read")) {
    navigate("/app");
  }

  const { theme } = useJumboTheme();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const getDocumento = useCallback(async () => {
    try {
      ApiService.get(`/documentos/servicos/${documentoId}`)
        .then((response) => {
          setDocumento({
            nome: response.data?.nome,
          });
          setIsDocumentoAnexado(true);
          setDocumentosSelecionado({
            name: response.data?.path,
          });
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const modalAlertDeleteDocumento = (documentoId) => {
    Swal.fire({
      title: "Tem certeza que deseja apagar o processo?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteDocumento(documentoId);
      }
    });
  };

  const deleteDocumento = useCallback(async (documentoId) => {
    try {
      ApiService.delete(`/documentos/servicos/${documentoId}`)
        .then((response) => {
          if (response.status === 200) {
            toast("success", "Removido com sucesso");
          }
        })
        .catch((error) => {
          const message = error.response.data.message;
          toast("error", message);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleDocumentoAnexado = (event) => {
    const documento = event.target.files[0];
    console.log(documento);
    if (documento) {
      setDocumentosSelecionado(documento);
      setIsDocumentoAnexado(true);
    }
  };

  useEffect(() => {
    if (documentoId) {
      getDocumento();
    }
    if (location && String(location?.pathname).includes("deletar")) {
      setIsDeleteMode(true);
    }
  }, [documentoId]);

  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    console.log("Valores do Formulário:", values);

    const formData = new FormData();

    const allValuesEdit = {
      nome: values.descricao,
      servico_id: Number(servicoId),
    };
    delete allValuesEdit.id;
    delete allValuesEdit.createdAt;
    delete allValuesEdit.updatedAt;
    delete allValuesEdit.deletedAt;

    formData.append("nome", values.nome);
    formData.append("servico_id", Number(servicoId));

    if (documentoSelecionado && documentoSelecionado.type) {
      console.log("Documento Selecionado:", documentoSelecionado);
      formData.append("arquivo", documentoSelecionado);
    }
    if (!documentoId && !documentoSelecionado) {
      toast("warning", "Faça o upload de um arquivo!");
      return;
    }

    for (let pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    try {
      let response;
      if (documentoId) {
        response = await ApiService.put(
          `/documentos/servicos/${documentoId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        toast("success", "Atualizado com sucesso!");
      } else {
        response = await ApiService.post("/documentos/servicos", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        toast("success", "Criado com sucesso");
      }

      if (response.status === (documentoId ? 200 : 201)) {
        resetForm();
        navigate(
          `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/servicos/${servicoId}/documentos`
        );
      }
    } catch (error) {
      let message = error.response?.data?.message || "Ocorreu um erro";
      toast("error", message);
      console.error("Error details:", error.response || error);
    }

    setSubmitting(false);
  };

  const getBreadCrumbsInfo = async () => {
    try {
      return await ApiService.get(
        `/contratos/breadcrumbs?contratoId=${contratoId}&processoId=${processoId}&servicoId=${servicoId}`
      ).then((response) => {
        if (response.status === 200) {
          setBreadCrumbsInfo(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (contratoId) {
      getBreadCrumbsInfo();
    }
  }, [contratoId]);

  return (
    <JumboContentLayout layoutOptions={layoutOptions}>
      {lg && (
        <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}></Stack>
      )}
      <Stack width={"100%"}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link to={"/app/listar-contratos"} color="success" style={linkStyle}>
            Contratos
          </Link>
          {breadCrumbsInfo && (
            <Link
              to={`/app/editar-contrato/${contratoId}`}
              color="success"
              style={linkStyle}
            >
              {breadCrumbsInfo?.descricao}
            </Link>
          )}
          <Link
            to={`/app/contrato/${contratoId}/cliente/${clienteId}/processos`}
            color="primary"
            style={linkStyle}
          >
            Processos Administrativos
          </Link>
          {breadCrumbsInfo && (
            <Link
              to={`/app/contrato/${contratoId}/cliente/${clienteId}/processos/editar/${processoId}`}
              color="primary"
              style={linkStyle}
            >
              {
                breadCrumbsInfo?.processos_contratos?.[0]
                  ?.processo_administrativo?.descricao
              }{" "}
            </Link>
          )}
          <Link
            to={`/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/servicos`}
            color="primary"
            style={linkStyle}
          >
            Serviços
          </Link>
          {servicoId && (
            <Link
              to={`/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/servicos/editar/${servicoId}`}
              color="success"
              style={linkStyle}
            >
              {`Serviço ID - ${servicoId}`}
            </Link>
          )}
          <Link
            to={`/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/servicos/${servicoId}/documentos`}
            color="primary"
            style={linkStyle}
          >
            Documentos
          </Link>
        </Breadcrumbs>
      </Stack>
      {isDeleteMode && (
        <Stack width={"100%"} p={1}>
          <Alert severity="error">
            Você está prestes a excluir o documento abaixo:
          </Alert>
        </Stack>
      )}
      <Paper sx={{ p: "15px" }}>
        <Formik
          initialValues={documento}
          validationSchema={validationSchema}
          enableReinitialize
          validateOnChange={false}
          onSubmit={handleSubmit}
        >
          {({ values, isSubmitting, handleChange, setFieldValue, errors }) => (
            <Form style={{ width: "100%" }} noValidate autoComplete="off">
              <Grid
                container
                alignContent={"center"}
                justifyContent={"center"}
                spacing={2}
              >
                <Grid
                  item
                  lg={7}
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Stack direction="column" gap={1} width={"50%"}>
                    <TextField
                      name="nome"
                      label="Descrição"
                      required
                      disabled={isDeleteMode}
                      value={values.nome}
                      onChange={handleChange}
                      sx={{ background: "#ffffff", width: "100%" }}
                    />
                    <ErrorMessage
                      component={"div"}
                      name="nome"
                      style={{ color: "red" }}
                    />
                  </Stack>
                </Grid>
                <Grid
                  item
                  lg={7}
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <label
                    htmlFor="upload-button-file"
                    style={{
                      width: "50%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <input
                      id="upload-button-file"
                      name="documento"
                      hidden
                      accept="image/*, .pdf, .docx, .xls"
                      type="file"
                      onChange={(event) => {
                        handleDocumentoAnexado(event);
                      }}
                    />
                    <Button
                      variant="contained"
                      color="info"
                      name="descricao"
                      label="Descrição"
                      required
                      disabled={isDeleteMode}
                      sx={{ width: "100%" }}
                      component="span"
                    >
                      Fazer upload
                    </Button>
                    <ErrorMessage
                      component={"div"}
                      name="arquivo"
                      style={{ color: "red" }}
                    />
                  </label>
                </Grid>
                {isDocumentoAnexado && (
                  <Grid
                    item
                    lg={7}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Stack
                      direction="row"
                      gap={2}
                      alignItems={"center"}
                      borderRadius={1}
                      style={{ padding: 4, border: "1px solid #0e0e0e" }}
                    >
                      <Box
                        style={{
                          background:
                            tiposArquivos[
                              String(documentoSelecionado?.name).split(".")[1]
                            ]?.cor,
                          color: "#FFF",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "50px",
                          height: "50px",
                          padding: 1,
                        }}
                        borderRadius={1}
                      >
                        {
                          tiposArquivos[
                            String(documentoSelecionado?.name).split(".")[1]
                          ]?.nome
                        }
                      </Box>
                      <Box>
                        <Typography style={{ fontWeight: 500, fontSize: 15 }}>
                          {documentoSelecionado?.name}
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                )}

                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  marginTop={3}
                >
                  {isDeleteMode ? (
                    <Stack direction="row" gap={2} display={"flex"}>
                      <Box>
                        <Button
                          color="error"
                          variant="contained"
                          onClick={() => {
                            modalAlertDeleteDocumento(documentoId);
                          }}
                        >
                          Excluir documento
                        </Button>
                      </Box>
                      <Box>
                        <Button
                          color="primary"
                          variant="outlined"
                          onClick={() => {
                            navigate(
                              `/app/contrato/${contratoId}/cliente/${clienteId}/processos`
                            );
                          }}
                        >
                          Cancelar
                        </Button>
                      </Box>
                    </Stack>
                  ) : (
                    <LoadingButton
                      loading={isSubmitting}
                      type="submit"
                      style={{ margin: "auto", display: "block" }}
                      variant="contained"
                      color="primary"
                    >
                      {documentoId ? "Atualizar" : "Criar"}
                    </LoadingButton>
                  )}
                </Box>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </JumboContentLayout>
  );
};

export default DocumentosServicosForm;
