import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {
  Box,
  Divider,
  Fade,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import ApiService from "app/services/config";
import { Field, Form, Formik } from "formik";
import React from "react";
import ReactInputMask from "react-input-mask";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ESTADOS = {
  AC: "Acre",
  AL: "Alagoas",
  AP: "Amapá",
  AM: "Amazonas",
  BA: "Bahia",
  CE: "Ceará",
  DF: "Distrito Federal",
  ES: "Espírito Santo",
  GO: "Goiás",
  MA: "Maranhão",
  MT: "Mato Grosso",
  MS: "Mato Grosso do Sul",
  MG: "Minas Gerais",
  PA: "Pará",
  PB: "Paraíba",
  PR: "Paraná",
  PE: "Pernambuco",
  PI: "Piauí",
  RJ: "Rio de Janeiro",
  RN: "Rio Grande do Norte",
  RS: "Rio Grande do Sul",
  RO: "Rondônia",
  RR: "Roraima",
  SC: "Santa Catarina",
  SP: "São Paulo",
  SE: "Sergipe",
  TO: "Tocantins",
};

const ModalCadastroImovelCliente = ({
  aberto,
  handleClose,
  clienteSelecionado,
}) => {
  const initialValues = {
    logradouro: "",
    cidade: "",
    cep: "",
    bairro: "",
    numero: "",
    complemento: "",
    uf: "",
    descricao: "",
  };
  const Swal = useSwalWrapper();
  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const handleSubmit = async (values, resetForm) => {
    try {
      await ApiService.put(`/clientes/adicionar/imovel/${Number(clienteSelecionado?.id)}`, values)
      .then((response) => {
        if(response.status === 200) {
          toast("success", "Imóvel adicionado ao cliente!");
          handleClose();
          resetForm();
        }
      })
    } catch (error) {
      toast("error", "Erro ao cadastrar um imóvel para o cliente!");
      console.log(error);
    }
  }

  return (
    <Paper>
      <Modal open={aberto} onClose={handleClose}>
        <Fade in={aberto}>
          <Box sx={style}>
            <Typography variant="h2" component="h2" gutterBottom>
              Administrar imóveis
            </Typography>
            <Divider />
            <Formik
              initialValues={initialValues}
              enableReinitialize
              validateOnBlur
              validateOnChange
              onSubmit={handleSubmit}
            >
              {({
                values,
                isSubmitting,
                handleChange,
                updateInputValue,
                setFieldValue,
                errors,
                resetForm
              }) => (
                <Form>
                  <Grid container my={1} justifyContent={"center"} gap={2}>
                    <Grid item lg={5} xs={12}>
                      <Field
                        required
                        as={TextField}
                        label={"Descrição"}
                        name={`descricao`}
                        value={values.descricao}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={5} xs={12}>
                      <FormControl fullWidth>
                        <ReactInputMask
                          mask="99999-999"
                          name={`cep`}
                          value={values.cep}
                          onChange={handleChange}
                        >
                          {(inputProps) => (
                            <TextField
                              fullWidth
                              name="cep"
                              label="CEP"
                              sx={{
                                background: "#ffffff",
                              }}
                              {...inputProps}
                            />
                          )}
                        </ReactInputMask>
                      </FormControl>
                    </Grid>
                    <Grid item lg={5} xs={12}>
                      <Field
                        required
                        name={`numero`}
                        as={TextField}
                        label={`Número`}
                        value={values.numero}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={5} xs={12}>
                      <Field
                        required
                        name={`complemento`}
                        as={TextField}
                        label={`Complemento`}
                        value={values.complemento}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={5} xs={12}>
                      <Field
                        required
                        name={`bairro`}
                        as={TextField}
                        label={`Bairro`}
                        value={values.bairro}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={5} xs={12}>
                      <Field
                        required
                        name={`cidade`}
                        as={TextField}
                        label={`Cidade`}
                        value={values.cidade}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={5} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="select-estado-label">UF</InputLabel>
                        <Select
                          labelId="select-estado-label"
                          id="select-estado"
                          name={`uf`}
                          value={values.uf}
                          label={"UF"}
                          onChange={handleChange}
                          fullWidth
                        >
                          {Object.entries(ESTADOS).map(([sigla, estado]) => (
                            <MenuItem value={sigla}>{estado}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Stack
                    width={"100%"}
                    gap={2}
                    justifyContent={"center"}
                    direction={"row"}
                  >
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => {
                        handleSubmit(values, resetForm);
                      }}
                    >
                      Salvar
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleClose}
                    >
                      Cancelar
                    </Button>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Box>
        </Fade>
      </Modal>
    </Paper>
  );
};

export default ModalCadastroImovelCliente;
