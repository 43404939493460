import { Divider, Fade, FormControl, InputLabel, MenuItem, Modal, Select } from "@mui/material";

import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ApiService from "app/services/config";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import ReactInputMask from "react-input-mask";
import * as yup from "yup";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CreatableSelect from "react-select/creatable";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "transparent" : "white",
    border: state.isFocused
      ? "1px solid #005D5F"
      : "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    padding: "8px",
    boxShadow: "none",
    "&:hover": {
      borderColor: "black",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#005D5F" : "white",
    color: state.isSelected ? "white" : "black",
    "&:hover": {
      backgroundColor: "lightblue",
      color: "white",
    },
  }),
};

const validationSchema = yup.object().shape({
  nome: yup
    .string("Insira o nome do cliente")
    .required("Preenchimento obrigatório"),
  email: yup
    .string("Insira um e-mail inválido")
    .email("E-mail inválido")
    .required("Preenchimento obrigatório"),
  telefone: yup
    .string()
    .matches(/^\(\d{2}\)\s\d{5}-\d{4}$/, "Telefone inválido")
    .required("Preenchimento obrigatório"),
  cpf: yup.string(),
  cnpj: yup
    .string()
    .matches(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/, {
      message: "CNPJ Inválido",
      excludeEmptyString: true,
    })
    .nullable(),

  cep: yup
    .string()
    .matches(/^[0-9]{5}-[0-9]{3}$/, "CEP Inválido")
    .nullable(),
  segmento_cliente: yup
    .number("ID Inválido")
    .required("Selecione um Segmento de Cliente"),
  imoveis: yup
    .array()
    .of(
      yup.object({
        descricao: yup.string().required("Adicione a descrição do imóvel"),
        cidade: yup.string(),
        uf: yup.string(),
        logradouro: yup.string(),
      })
    )
    .min(1, "Adicione pelo menos um imóvel")
    .required("Adicione pelo menos um imóvel"),
});

const ESTADOS = {
  AC: "Acre",
  AL: "Alagoas",
  AP: "Amapá",
  AM: "Amazonas",
  BA: "Bahia",
  CE: "Ceará",
  DF: "Distrito Federal",
  ES: "Espírito Santo",
  GO: "Goiás",
  MA: "Maranhão",
  MT: "Mato Grosso",
  MS: "Mato Grosso do Sul",
  MG: "Minas Gerais",
  PA: "Pará",
  PB: "Paraíba",
  PR: "Paraná",
  PE: "Pernambuco",
  PI: "Piauí",
  RJ: "Rio de Janeiro",
  RN: "Rio Grande do Norte",
  RS: "Rio Grande do Sul",
  RO: "Rondônia",
  RR: "Roraima",
  SC: "Santa Catarina",
  SP: "São Paulo",
  SE: "Sergipe",
  TO: "Tocantins",
};

const ModalCadastroCliente = ({
  nomeCliente,
  isOpen,
  onClose,
  getClientes,
  setNomeCliente,
  atualizarImoveis,
}) => {
  const initialValues = {
    nome: nomeCliente,
    email: "",
    telefone: "",
    cep: "",
    cpf: "",
    cnpj: "",
    bairro: "",
    segmento_cliente: "",
    imoveis: [
      {
        id: "",
        descricao: "",
      },
    ],
  };
  const [cliente, setCliente] = useState(initialValues);
  const [segmentosCliente, setSegmentosCliente] = useState([]);
  const [segmentoSelected, setSegmentoSelected] = useState(null);
  const [imoveisCliente, setImoveisCliente] = useState([]);
  const [formattedCPF, setFormattedCPF] = useState("");
  const [formattedCNPJ, setFormattedCNPJ] = useState("");
  const [formattedCEP, setFormatedCEP] = useState("");
  const [formattedTelefone, setFormatedTelefone] = useState("");
  const [nomeClienteInicial, setNomeClienteInicial] = useState("");
  const Swal = useSwalWrapper();

  const toast = (variant, message, type = false) => {
    Swal.fire({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      icon: type ? "success" : "error",
      title: message,
      didOpen: (toast) => {
        toast.style.zIndex = 10000;
      },
      timer: 3000,
    });
  };

  const getSegmentosCliente = useCallback(async () => {
    try {
      const response = await ApiService.get("/segmentos/all");
      setSegmentosCliente(response.data);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleCreateSegmentoCliente = useCallback(
    async (descricao) => {
      try {
        const response = await ApiService.post("/segmentos/", { descricao });
        if (response.status === 201) {
          const { id, descricao } = response.data;
          getSegmentosCliente();
          setSegmentoSelected({ id, descricao });

          toast(null, "Segmento criado com sucesso!", "success");
          return { id, descricao };
        }
        return response;
      } catch (error) {
        console.log(error);
        toast(null, "Ocorreu um erro ao criar o segmento!", "error");
      }
    },
    [segmentosCliente]
  );
  useEffect(() => {
    getSegmentosCliente();
  }, []);

  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    const imoveisDoCliente = values.imoveis.concat(imoveisCliente);
    const allValuesEdit = {
      ...values,
      imoveis: imoveisDoCliente,
      segmento_cliente: segmentoSelected?.id,
    };
    delete allValuesEdit.segmento_cliente_id;
    delete allValuesEdit.id;
    for (const imovel of imoveisDoCliente) {
      if (
        imovel.descricao === ""
      ) {
        toast("warning", "Preencha todos os campos de imóvel!", "warning");
        return;
      }
    }
    try {
      console.log(allValuesEdit);
      await ApiService.post("/clientes", allValuesEdit)
        .then((response) => {
          resetForm();
          if (response.status === 201) {
            console.log(response);
            toast(null, "Cliente cadastrado com sucesso!", "success");
            onClose(response.data.id);
            getClientes();
            atualizarImoveis();
            setNomeCliente("");
            resetForm();
          }
        })
        .catch((error) => {
          let message = error.response.data.message;

          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
      setSubmitting(false);
    } catch (error) {
      toast(null, "Ocorreu um erro!", "error");
      console.log(error);
    }
  };

  const optionsSegmentos = segmentosCliente?.map((segmento) => {
    return {
      id: segmento.id,
      descricao: segmento.descricao,
    };
  });

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
    >
      <Fade in={isOpen}>
        <Paper
          sx={{ p: "15px", width: "80%", maxWidth: 800 }}
          style={{
            display: "block",
            margin: "auto",
            overflowY: "scroll",
            maxHeight: "80vh",
            marginTop: 80,
          }}
        >
          <Formik
            initialValues={cliente}
            validationSchema={validationSchema}
            enableReinitialize
            validateOnChange={false}
            onSubmit={handleSubmit}
          >
            {({
              values,
              isSubmitting,
              handleChange,
              updateInputValue,
              setFieldValue,
              errors,
            }) => (
              <Form style={{ width: "100%" }} noValidate autoComplete="off">
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Typography variant="h2">Cadastro de cliente</Typography>
                  </Grid>
                  <Grid item>
                    <JumboTextField
                      fullWidth
                      name="nome"
                      label="Nome"
                      required
                      value={cliente.nome}
                      onChange={handleChange}
                      sx={{ background: "#ffffff" }}
                    />
                  </Grid>
                  <Grid item>
                    <JumboTextField
                      fullWidth
                      name="email"
                      label="Email"
                      required
                      value={values.email}
                      onChange={handleChange}
                      sx={{ background: "#ffffff" }}
                    />
                  </Grid>
                  <Grid item>
                    <ReactInputMask
                      mask="(99) 99999-9999"
                      maskChar=""
                      value={formattedTelefone}
                      onChange={(e) => {
                        setFormatedTelefone(e.target.value);
                        setFieldValue("telefone", e.target.value);
                      }}
                    >
                      {(inputProps) => (
                        <TextField
                          fullWidth
                          name="telefone"
                          label="Telefone"
                          sx={{ background: "#ffffff" }}
                          {...inputProps}
                        />
                      )}
                    </ReactInputMask>
                    {errors.telefone && (
                      <div style={{ color: "red" }}>{errors.telefone}</div>
                    )}
                  </Grid>
                  <Grid item lg={2} xs={12}>
                  <ReactInputMask
                    mask="99.999.999/9999-99"
                    maskChar=""
                    value={formattedCNPJ}
                    onChange={(e) => {
                      setFormattedCNPJ(e.target.value);
                      setFieldValue("cnpj", formattedCNPJ);
                    }}
                  >
                    {(inputProps) => (
                      <TextField
                        fullWidth
                        name="cnpj"
                        label="CNPJ"
                        sx={{ background: "#ffffff" }}
                        {...inputProps}
                      />
                    )}
                  </ReactInputMask>
                  {errors.cnpj && (
                    <div style={{ color: "red" }}>{errors.cnpj}</div>
                  )}
                </Grid>
                <Grid item lg={2} xs={12}>
                  <ReactInputMask
                    mask="999.999.999-99"
                    maskChar=""
                    value={formattedCPF}
                    onChange={(e) => {
                      setFormattedCPF(e.target.value);
                      setFieldValue("cpf", formattedCPF);
                    }}
                  >
                    {(inputProps) => (
                      <TextField
                        fullWidth
                        name="cpf"
                        label="CPF"
                        sx={{ background: "#ffffff" }}
                        {...inputProps}
                      />
                    )}
                  </ReactInputMask>
                  <ErrorMessage
                    component={"div"}
                    name="cpf"
                    style={{ color: "red" }}
                  />
                </Grid>
                  <Grid item lg={2} xs={12}>
                  <ReactInputMask
                    mask="99999-999"
                    value={formattedCEP}
                    onChange={(e) => {
                      const value = e.target.value;
                      setFormatedCEP(value);
                      setFieldValue("cep", value);
                    }}
                  >
                    {(inputProps) => (
                      <TextField
                        fullWidth
                        name="cep"
                        label="CEP"
                        sx={{ background: "#ffffff" }}
                        {...inputProps}
                      />
                    )}
                  </ReactInputMask>
                  {errors.cep && (
                    <div style={{ color: "red" }}>{errors.cep}</div>
                  )}
                </Grid>
                <Grid item lg={3} xs={12}>
                  <JumboTextField
                    fullWidth
                    name="logradouro"
                    label="Logradouro"
                    value={values.logradouro}
                    onChange={handleChange}
                    sx={{ background: "#ffffff" }}
                  />
                </Grid>
                <Grid item lg={3} xs={12}>
                  <JumboTextField
                    fullWidth
                    name="numero"
                    label="Número"
                    value={values.numero}
                    onChange={handleChange}
                    sx={{ background: "#ffffff" }}
                  />
                </Grid>
                <Grid item lg={3} xs={12}>
                  <JumboTextField
                    fullWidth
                    name="complemento"
                    label="Complemento"
                    value={values.complemento}
                    onChange={handleChange}
                    sx={{ background: "#ffffff" }}
                  />
                </Grid>
                <Grid item lg={3} xs={12}>
                  <JumboTextField
                    fullWidth
                    name="bairro"
                    label="Bairro"
                    value={values.complemento}
                    onChange={handleChange}
                    sx={{ background: "#ffffff" }}
                  />
                </Grid>
                <Grid item lg={3} xs={12}>
                  <JumboTextField
                    fullWidth
                    name="cidade"
                    label="Cidade"
                    value={values.cidade}
                    onChange={handleChange}
                    sx={{ background: "#ffffff" }}
                  />
                </Grid>
                <Grid item lg={3}>
                  <FormControl fullWidth>
                    <InputLabel id="select-estado-label">UF</InputLabel>
                    <Select
                      labelId="select-estado-label"
                      id="select-estado"
                      value={values.uf}
                      label={"UF"}
                      name={"uf"}
                      onChange={handleChange}
                      fullWidth
                    >
                      {Object.entries(ESTADOS).map(([sigla, estado]) => (
                        <MenuItem value={sigla}>{estado}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                  {console.log(errors)}
                  <Grid item>
                    <div style={{ position: "relative" }}>
                      <CreatableSelect
                        isClearable
                        styles={customStyles}
                        name={`segmento_cliente`}
                        onChange={(option) => {
                          if (option) {
                            setSegmentoSelected({
                              id: option.id,
                              descricao: option.descricao,
                            });
                            setFieldValue(
                              `segmento_cliente`,
                              option ? option.id : ""
                            );
                          }
                        }}
                        onCreateOption={async (value) => {
                          const { id, descricao } =
                            await handleCreateSegmentoCliente(value);
                          setSegmentoSelected({ id, descricao });
                          setFieldValue(`segmento_cliente`, id);
                        }}
                        options={optionsSegmentos}
                        getOptionLabel={(option) =>
                          option.__isNew__ ? option.label : option.descricao
                        }
                        value={segmentoSelected}
                        placeholder={"Selecione um Tipo de Segmento de Cliente"}
                        menuPosition="top"
                        menuPlacement="auto"
                        maxMenuHeight={115}
                      />
                      {errors.segmento_cliente && (
                        <div style={{ color: "red" }}>
                          {errors.segmento_cliente}
                        </div>
                      )}
                    </div>
                  </Grid>
                  <Grid item my={3}>
                    <Box margin={1}>
                      <Typography variant="h2">Imóveis</Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                  <FieldArray name="imoveis">
                    {({ push, remove }) => (
                      <>
                      <Box width={"100%"} sx={{ marginX: 5 }}>
                        {values.imoveis.length > 0 &&
                          values.imoveis.map((imovel, index) => (
                            <>
                            <Grid
                              container
                              key={index}
                              spacing={1}
                              sx={{ marginBottom: 2 }}
                              alignItems="center"
                            >
                              <Grid item lg={3}>
                                <Field
                                  required
                                  name={`imoveis.${index}.descricao`}
                                  as={TextField}
                                  label={`Imóvel #${index} Descrição - Obrigatório`}
                                  onChange={handleChange}
                                  fullWidth
                                />
                                <ErrorMessage
                                  name={`imoveis.${index}.descricao`}
                                  component={"div"}
                                  style={{ color: "red" }}
                                />
                              </Grid>
                              <Grid item lg={2}>
                                <FormControl fullWidth>
                                <ReactInputMask
                                mask="99999-999"
                                name={`imoveis.${index}.cep`}
                                label={`Imóvel #${index} CEP`}
                                value={``.cep}
                                onChange={handleChange}
                              >
                                  {(inputProps) => (
                                    <TextField
                                      fullWidth
                                      name="cep"
                                      label="CEP"
                                      sx={{ background: "#ffffff" }}
                                      {...inputProps}
                                    />
                                  )}
                                </ReactInputMask>
                                  <ErrorMessage
                                    name={`imoveis.${index}.cep`}
                                    component={"div"}
                                    style={{ color: "red" }}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item lg={3}>
                                <Field
                                  required
                                  name={`imoveis.${index}.logradouro`}
                                  as={TextField}
                                  label={`Imóvel #${index} Logradouro`}
                                  onChange={handleChange}
                                  fullWidth
                                />
                                <ErrorMessage
                                  name={`imoveis.${index}.logradouro`}
                                  component={"div"}
                                  style={{ color: "red" }}
                                />
                              </Grid>
                              <Grid item lg={2}>
                                <Field
                                  required
                                  name={`imoveis.${index}.numero`}
                                  as={TextField}
                                  label={`Imóvel #${index} Número`}
                                  onChange={handleChange}
                                  fullWidth
                                />
                                <ErrorMessage
                                  name={`imoveis.${index}.numero`}
                                  component={"div"}
                                  style={{ color: "red" }}
                                />
                              </Grid>
                              <Grid item lg={3}>
                                <Field
                                  required
                                  name={`imoveis.${index}.complemento`}
                                  as={TextField}
                                  label={`Imóvel #${index} Complemento`}
                                  onChange={handleChange}
                                  fullWidth
                                />
                                <ErrorMessage
                                  name={`imoveis.${index}.complemento`}
                                  component={"div"}
                                  style={{ color: "red" }}
                                />
                              </Grid>
                              <Grid item lg={3}>
                                <Field
                                  required
                                  name={`imoveis.${index}.bairro`}
                                  as={TextField}
                                  label={`Imóvel #${index} Bairro`}
                                  onChange={handleChange}
                                  fullWidth
                                />
                                <ErrorMessage
                                  name={`imoveis.${index}.bairro`}
                                  component={"div"}
                                  style={{ color: "red" }}
                                />
                              </Grid>
                              <Grid item lg={2}>
                                <Field
                                  required
                                  name={`imoveis.${index}.cidade`}
                                  as={TextField}
                                  label={`Imóvel #${index} Cidade`}
                                  onChange={handleChange}
                                  fullWidth
                                />
                                <ErrorMessage
                                  name={`imoveis.${index}.cidade`}
                                  component={"div"}
                                  style={{ color: "red" }}
                                />
                              </Grid>
                              <Grid item lg={2} my={-3}>
                                <FormControl fullWidth>
                                  <InputLabel id="select-estado-label">
                                    UF
                                  </InputLabel>
                                  <Select
                                    labelId="select-estado-label"
                                    id="select-estado"
                                    name={`imoveis.${index}.uf`}
                                    value={imovel.uf}
                                    label={"UF"}
                                    onChange={handleChange}
                                    fullWidth
                                  >
                                    {Object.entries(ESTADOS).map(
                                      ([sigla, estado]) => (
                                        <MenuItem value={sigla}>
                                          {estado}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                  <ErrorMessage
                                    name={`imoveis.${index}.uf`}
                                    component={"div"}
                                    style={{ color: "red" }}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item lg={2}>
                                <Grid container>
                                  <Grid item>
                                    {index === values.imoveis.length - 1 && (
                                      <Button
                                        type="button"
                                        onClick={() =>
                                          push({
                                            descricao: "",
                                            cidade: "",
                                            logradouro: "",
                                            uf: "",
                                            numero: "",
                                            complemento: "",
                                            bairro: "",
                                            cep: ""
                                          })
                                        }
                                      >
                                        <AddIcon />
                                      </Button>
                                    )}
                                  </Grid>
                                  <Grid item>
                                    {values.imoveis.length > 0 && (
                                      <Button
                                        type="button"
                                        color="error"
                                        onClick={() => remove(index)}
                                        sx={{ marginLeft: 1 }}
                                      >
                                        <DeleteIcon />
                                      </Button>
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                              <Divider sx={{maxWidth: "90%", my: 2}} />
                            </> 
                                                   
                          ))}
                      </Box>
                      </>

                    )}
                  </FieldArray>
                    <Box width={100}>
                      <Grid item my={4}>
                        <Box width={100} justifyContent={"center"}>
                          <LoadingButton
                            loading={isSubmitting}
                            type="submit"
                            style={{ margin: "auto", display: "block" }}
                            variant="contained"
                            color="primary"
                          >
                            Criar
                          </LoadingButton>
                        </Box>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
      </Fade>
    </Modal>
  );
};
export default ModalCadastroCliente;
