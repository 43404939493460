import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import { useJumboTheme } from "@jumbo/hooks";
import {
  Box,
  Button,
  Divider,
  FormLabel,
  Grid,
  IconButton,
  Input,
  MenuItem,
  Paper,
  Stack,
  TextField,
  TextareaAutosize,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Select from "react-select";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import ApiService from "app/services/config";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import React, { useCallback, useContext, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";
import { PermissionContext } from "app/contexts/PermissionContext";
import moment from "moment";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "transparent" : "white",
    border: state.isFocused
      ? "1px solid #005D5F"
      : "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    padding: "8px",
    boxShadow: "none",
    "&:hover": {
      borderColor: "black",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#005D5F" : "white",
    color: state.isSelected ? "white" : "black",
    "&:hover": {
      backgroundColor: "lightblue",
      color: "white",
    },
  }),
};

const validationSchema = yup.object().shape({
  valor: yup.number(),
  quantidade: yup.number(),
  observacao: yup
    .array()
    .of(
      yup.object().shape({
        descricao: yup
          .string("Campo obrigatório")
          .required("Campo obrigatório"),
      })
    )
    .optional(),
  responsavel: yup.string().required("Campo obrigatório"),
});

export const ServicosForm = () => {
  const initialValues = {
    valor: "",
    quantidade: "",
    observacao: "",
    centrosDeCustoId: "",
    tipoServicoId: "",
    processoAdministrativoId: "",
    tarefaId: "",
    statusOrcamentoId: "",
    colaboradores: [],
    responsavel: [],
  };
  const { id } = useParams();
  const { theme } = useJumboTheme();
  const [servico, setServico] = useState(initialValues);
  const [centroDeCustos, setCentroDeCustos] = useState([]);
  const [tiposServicos, setTiposServicos] = useState([]);
  const [processosAdministrativos, setProcessosAdministrativos] = useState([]);
  const [tarefas, setTarefas] = useState([]);
  const [statusOrcamentos, setStatusOrcamentos] = useState([]);
  const [colaboradores, setColaboradores] = useState([]);
  const [quantidade, setQuantidade] = useState(0);
  const [valor, setValor] = useState(0);
  const [valorFinal, setValorFinal] = useState(0);
  const userId = localStorage.getItem("id");

  const navigate = useNavigate();

  const { hasPermission } = useContext(PermissionContext);
  if (!hasPermission("Serviços", "read")) {
    navigate("/app");
  }

  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const getCentroDeCustos = useCallback(async () => {
    try {
      await ApiService.get("/centrosdecustos/all").then((response) => {
        setCentroDeCustos(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getTiposServicos = useCallback(async () => {
    try {
      await ApiService.get("/tiposservico").then((response) => {
        setTiposServicos(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getProcessosAdministrativos = useCallback(async () => {
    try {
      await ApiService.get("/processosadmin/all").then((response) => {
        setProcessosAdministrativos(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getTarefas = useCallback(async () => {
    try {
      await ApiService.get("/tarefas/all").then((response) => {
        setTarefas(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getStatusOrcamento = useCallback(async () => {
    try {
      await ApiService.get("/statusorcamento").then((response) => {
        setStatusOrcamentos(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getColaboradores = useCallback(async () => {
    try {
      await ApiService.get("/users/all").then((response) => {
        setColaboradores(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const handleCalculateValue = useCallback(() => {
    const valorFormatado = Number((valor * quantidade).toFixed(2));
    setValorFinal(valorFormatado);
  });

  useEffect(() => {
    handleCalculateValue();
  }, [valorFinal, quantidade, valor]);

  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    await ApiService.post("servicos", values)
      .then((response) => {
        toast("success", "Criado com sucesso!");
        if (response.status === 201) {
          navigate("/app/listar-servicos");
        }
      })
      .catch((error) => {
        let message = error.response.data.message;

        toast("error", message);
        if (error.response.data) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("error", error.message);
        }
      });
    resetForm();
  };


  useEffect(() => {
    getCentroDeCustos();
    getTiposServicos();
    getProcessosAdministrativos();
    getTarefas();
    getStatusOrcamento();
    getColaboradores();
  }, []);

  return (
    <JumboContentLayout
      header={
        <HeaderBreadcrumbs
          id={id}
          title={"Serviços"}
          subtitle={id ? "Editar" : "Cadastro de Serviços"}
          titleUrl={"/app/listar-servicos"}
        />
      }
      layoutOptions={layoutOptions}
    >
      {lg && (
        <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}></Stack>
      )}
      <Paper sx={{ p: "15px" }}>
        <Formik
          initialValues={servico}
          validationSchema={validationSchema}
          enableReinitialize
          validateOnChange={false}
          onSubmit={handleSubmit}
        >
          {({
            values,
            isSubmitting,
            handleChange,
            updateInputValue,
            setFieldValue,
            errors,
          }) => (
            <Form style={{ width: "100%" }} noValidate autoComplete="off">
              <Grid container alignContent={"center"} spacing={2}>
                <Grid item lg={6} xs={12}>
                  <Typography my={2}>Centro de Custos</Typography>
                  <div style={{ position: "relative" }}>
                    <Select
                      styles={customStyles}
                      options={centroDeCustos}
                      name="centrosDeCustoId"
                      required
                      getOptionLabel={(option) => option.descricao}
                      onChange={(option) => {
                        setFieldValue(
                          "centrosDeCustoId",
                          option ? option.id : ""
                        );
                      }}
                      placeholder="Selecione o Centro de Custos"
                      value={centroDeCustos.find(
                        (option) => option.id === values.centrosDeCustoId
                      )}
                    />
                    {errors.centrosDeCustoId && (
                      <div style={{ color: "red" }}>
                        {errors.centrosDeCustoId}
                      </div>
                    )}
                  </div>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <Typography my={2}>Tipo de Serviço</Typography>
                  <div style={{ position: "relative" }}>
                    <Select
                      styles={customStyles}
                      options={tiposServicos}
                      name="tipoServicoId"
                      required
                      getOptionLabel={(option) => option.descricao}
                      onChange={(option) => {
                        setFieldValue("tipoServicoId", option ? option.id : "");
                      }}
                      placeholder="Selecione o Tipo de Serviço"
                      value={tiposServicos.find(
                        (option) => option.id === values.tipoServicoId
                      )}
                    />
                    {errors.tipoServicoId && (
                      <div style={{ color: "red" }}>{errors.tipoServicoId}</div>
                    )}
                  </div>
                </Grid>
              </Grid>
              <Grid container alignContent={"center"} spacing={2}>
                <Grid item lg={6} xs={12}>
                  <Typography my={2}>Processo Administrativo</Typography>
                  <div style={{ position: "relative" }}>
                    <Select
                      styles={customStyles}
                      options={processosAdministrativos}
                      name="processoAdministrativoId"
                      required
                      getOptionLabel={(option) => option.descricao}
                      onChange={(option) => {
                        setFieldValue(
                          "processoAdministrativoId",
                          option ? option.id : ""
                        );
                      }}
                      placeholder="Selecione o Processo Administrativo"
                      value={processosAdministrativos.find(
                        (option) =>
                          option.id === values.processoAdministrativoId
                      )}
                    />
                    {errors.processoAdministrativoId && (
                      <div style={{ color: "red" }}>
                        {errors.processoAdministrativoId}
                      </div>
                    )}
                  </div>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <Typography my={2}>Tarefa</Typography>
                  <div style={{ position: "relative" }}>
                    <Select
                      styles={customStyles}
                      options={tarefas}
                      name="tarefaId"
                      required
                      getOptionLabel={(option) => option.descricao}
                      onChange={(option) => {
                        setFieldValue("tarefaId", option ? option.id : "");
                      }}
                      placeholder="Selecione a Tarefa"
                      value={tarefas.find(
                        (option) => option.id === values.tarefaId
                      )}
                    />
                    {errors.tarefaId && (
                      <div style={{ color: "red" }}>{errors.tarefaId}</div>
                    )}
                  </div>
                </Grid>
              </Grid>
              <Grid container alignContent={"center"} spacing={2}>
                <Grid item lg={6} xs={12}>
                  <Typography my={2}>Status orçamentário</Typography>
                  <div style={{ position: "relative" }}>
                    <Select
                      styles={customStyles}
                      options={statusOrcamentos}
                      name="statusOrcamentoId"
                      required
                      getOptionLabel={(option) => option.descricao}
                      onChange={(option) => {
                        setFieldValue(
                          "statusOrcamentoId",
                          option ? option.id : ""
                        );
                      }}
                      placeholder="Selecione o Status Orçamentário"
                      value={statusOrcamentos.find(
                        (option) => option.id === values.statusOrcamentoId
                      )}
                    />
                    {errors.tarefaId && (
                      <div style={{ color: "red" }}>{errors.tarefaId}</div>
                    )}
                  </div>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <Typography my={2}>Quantidade e Valor</Typography>
                  <Input
                    type="number"
                    style={{ borderRight: "1px solid gray", width: "60px" }}
                    name="quantidade"
                    placeholder="0"
                    onChange={(event) => {
                      setQuantidade(event.target.value);
                      setFieldValue("quantidade", Number(event.target.value));
                    }}
                  />
                  <Input
                    type="number"
                    name="valor"
                    placeholder="0"
                    style={{ width: "60%" }}
                    onChange={(event) => {
                      setValor(event.target.value);
                      setFieldValue("valor", Number(event.target.value));
                    }}
                  />
                  <Input
                    type="readonly"
                    style={{
                      width: "20%",
                      background: "#cecece",
                      textAlign: "center",
                      paddingLeft: "15px",
                    }}
                    value={
                      valorFinal !== 0 ? `R$ ${valorFinal}` : "Valor Final"
                    }
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Grid container alignContent={"center"} spacing={2}>
                <Grid item lg={6} xs={12}>
                  <Typography my={2}>Colaboradores</Typography>
                  <FieldArray name="colaboradores">
                    {({ push, remove }) => (
                      <Box>
                        <Grid container>
                          <Grid item lg={12}>
                            <Grid item lg={8}>
                              <div style={{ position: "relative" }}>
                                <Select
                                  styles={customStyles}
                                  options={colaboradores}
                                  required
                                  getOptionLabel={(option) => option.name}
                                  onChange={(option) => {
                                    push({
                                      id: option.id,
                                      name: option.name,
                                    });
                                  }}
                                  placeholder="Selecione um colaborador"
                                  value={null}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                        {values.colaboradores.map(
                          (colaborador, index) =>
                            colaborador.name && (
                              <Grid
                                my={2}
                                container
                                key={index}
                                sx={{ marginBottom: 2 }}
                              >
                                <Grid item lg={8}>
                                  <Field
                                    required
                                    name={`colaboradores.${index}.name`}
                                    as={TextField}
                                    label={`Colaborador #${index}`}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid container lg={3} spacing={1}>
                                  {values.colaboradores.length >= 1 && (
                                    <Grid item>
                                      <Button
                                        type="button"
                                        color="error"
                                        onClick={() => remove(index)}
                                        sx={{ marginLeft: 1 }}
                                      >
                                        <DeleteIcon />
                                      </Button>
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>
                            )
                        )}
                      </Box>
                    )}
                  </FieldArray>
                </Grid>
                <Grid container my={2}>
                  <Typography variant="h4">Observações</Typography>
                  <FieldArray name="observacao">
                    {({ push, remove }) => (
                      <Box my={1} width={"100%"}>
                        {values.observacao && values.observacao.length === 0 ? (
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            height={150}
                          >
                            <Typography my={1}>
                              Nenhuma observação registrada
                            </Typography>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => push({ descricao: "" })}
                            >
                              Adicionar nova observação
                            </Button>
                          </Box>
                        ) : (
                          <>
                            {values.observacao &&
                              values.observacao.map((observacao, index) => (
                                <>
                                  <Box
                                    key={index}
                                    my={2}
                                    display="flex"
                                    alignItems="center"
                                  >
                                    <Field
                                      name={`observacao.${index}.descricao`}
                                      as={TextField}
                                      label={`Observação ${index + 1}`}
                                      variant="outlined"
                                      fullWidth
                                      multiline
                                    />
                                    <IconButton
                                      variant="outlined"
                                      color="error"
                                      onClick={() =>
                                        observacao.id
                                          ? console.log(observacao.id)
                                          : remove(index)
                                      }
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Box>
                                  {observacao.id && (
                                    <>
                                      <Stack
                                        px={1}
                                        direction={"row"}
                                        justifyContent={"space-between"}
                                      >
                                        <Typography color="#909FAE">
                                          Usuário:{" "}
                                          {observacao.usuario?.name ?? "N/I"}
                                        </Typography>
                                        <Typography color="#909FAE">
                                          Criado em:{" "}
                                          {moment(observacao.createdAt).format(
                                            "DD/MM/YY HH:MM"
                                          )}
                                        </Typography>
                                      </Stack>
                                      <Divider my={2} />
                                    </>
                                  )}
                                </>
                              ))}
                            <Button
                              sx={{ backgroundColor: "info" }}
                              onClick={() =>
                                push({ descricao: "", userId: Number(userId) })
                              }
                            >
                              Adicionar nova observação
                            </Button>
                          </>
                        )}
                      </Box>
                    )}
                  </FieldArray>
                </Grid>
                <Box style={{ width: "100%" }} marginTop={3}>
                  <LoadingButton
                    loading={isSubmitting}
                    type="submit"
                    style={{ margin: "auto", display: "block" }}
                    variant="contained"
                    color="primary"
                  >
                    {id ? "Atualizar" : "Criar"}
                  </LoadingButton>
                </Box>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </JumboContentLayout>
  );
};
