import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { LoadingButton } from "@mui/lab";
import {
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import MoneyInput from "app/components/MoneyInput/MoneyInput";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import ApiService from "app/services/config";
import { useMediaQuery } from "beautiful-react-hooks";
import { ErrorMessage, Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from 'yup';
import CreatableSelect from "react-select/creatable";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "transparent" : "white",
    border: state.isFocused
      ? "1px solid #005D5F"
      : "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    padding: "8px",
    boxShadow: "none",
    "&:hover": {
      borderColor: "black",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#005D5F" : "white",
    color: state.isSelected ? "white" : "black",
    "&:hover": {
      backgroundColor: "lightblue",
      color: "white",
    },
  }),
};

const validationSchema = yup.object().shape({
  descricao: yup.string().required("Campo obrigatório"),
  valor: yup.string().required("Campo obrigatório").typeError("Valor inválido"),
  status_pagamento: yup.string().required("Campo obrigatório"),
  categoria: yup
    .number("Campo obrigatório")
    .required("Campo obrigatório")
    .typeError("Campo obrigatório"),
  data_vencimento: yup
    .date("Campo obrigatório")
    .required("Campo obrigatório")
    .typeError("Data inválida"),
});

const ContasAPagarForm = () => {
  const initialValues = {
    descricao: "",
    valor: 0,
    status_pagamento: "",
    categoria: "",
    beneficiario: "",
    data_vencimento: "",
  };
  const [contaAPagar, setContaAPagar] = useState(initialValues);
  const [categorias, setCategorias] = useState([]);
  const [fornecedores, setFornecedores] = useState([]);
  const { id } = useParams();
  const { theme } = useJumboTheme();
  const [isDeleteMode, setIsDeleteMode] = useState(false);

  const navigate = useNavigate();

  const Swal = useSwalWrapper();

  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const handleCreateCategoria = useCallback(async (nome) => {
    console.log('handleCreateCategoria', nome)
    const response = await ApiService.post("/categoria-contas", { nome })
      .then((response) => {
        if (response.status === 201) {
          toast("success", "Criado com sucesso!");
          getCategorias();
          return response.data;
        }
      })
      .catch((error) => {
        toast("error", error.message);
      });
    return response;
  });

  const getCategorias = async () => {
    try {
      await ApiService.get("/categoria-contas/all").then((response) => {
        if (response.status === 200) {
          setCategorias(response.data);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getFornecedores = async () => {
    try {
      await ApiService.get("/fornecedores/all").then((response) => {
        if (response.status === 200) {
          setFornecedores(response.data);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (values, {resetForm}) => {
    try {
      const valuesEdited = {
        ...values,
        valor: parseFloat(
          typeof values.valor === "string" 
            ? values.valor.replace(",", ".")
            : String(values.valor).replace(",", ".")),
        fornecedor_id: values.fornecedor_id !== "selecionar" ? values.fornecedor_id : null
      }
      await ApiService.put(`/contas-a-pagar/${id}`, valuesEdited).then((response) => {
        if (response.status === 200) {
          toast("success", "Conta atualizada com sucesso!", "success");
          navigate('/app/contas-a-pagar');
          resetForm();
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getContaAPagar = async () => {
    try {
      await ApiService.get(`/contas-a-pagar/${id}`)
      .then((response) => {
        if(response.status === 200) {
          const conta = {
            categoria: response.data?.contas_categorias?.[0]?.categoria?.id || '',
            data_vencimento: moment.utc(response.data.data_vencimento).format('YYYY-MM-DD'),
            descricao: response.data.descricao,
            beneficiario: response.data.beneficiario,
            status_pagamento: response.data.status_pagamento,
            valor: response.data.valor
          }
          setContaAPagar(conta);
        }
      })
    } catch (error) {
      console.error(error);
    }
  }

  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  useEffect(() => {
    getCategorias();
    getFornecedores();
    if(id) {
      getContaAPagar();
    }
  }, []);

  return (
    <JumboContentLayout
      header={
        <HeaderBreadcrumbs
          id={id}
          title={"Contas a pagar"}
          subtitle={id ? "Editar" : "Nova conta a pagar"}
          titleUrl={"/app/contas-a-pagar"}
        />
      }
      layoutOptions={layoutOptions}
    >
      {lg && (
        <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}></Stack>
      )}
      <Paper width="100%" sx={{ padding: 2, my: 1 }}>
        <Formik
          initialValues={contaAPagar}
          validationSchema={validationSchema}
          validateOnBlur
          validateOnChange
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            values,
            isSubmitting,
            handleChange,
            updateInputValue,
            setFieldValue,
            errors,
          }) => (
            <Form width="100%">
              <Grid container width="100%" spacing={2} p={2}>
                <Grid item xs={4} lg={4}>
                  <FormGroup>
                    <InputLabel>Descrição</InputLabel>
                    <TextField
                      name="descricao"
                      type="text"
                      value={values.descricao}
                      onChange={handleChange}
                      fullWidth
                      placeholder="Descrição"
                    />
                    <ErrorMessage
                      component={"div"}
                      name="descricao"
                      style={{ color: "red" }}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={4} lg={4}>
                  <FormGroup>
                    <MoneyInput
                      defaultValue={values.valor}
                      label={"Valor"}
                      name="valor"
                      setFieldValue={setFieldValue}
                    />
                    <ErrorMessage
                      component={"div"}
                      name="valor"
                      style={{ color: "red" }}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={4} lg={4}>
                  <FormGroup>
                    <InputLabel>Status do Pagamento</InputLabel>
                    <Select
                      value={values.status_pagamento}
                      onChange={handleChange}
                      name="status_pagamento"
                    >
                      <MenuItem value="pendente">Pendente</MenuItem>
                      <MenuItem value="atrasado">Atrasado</MenuItem>
                      <MenuItem value="pago">Pago</MenuItem>
                    </Select>
                    <ErrorMessage
                      component={"div"}
                      name="status_pagamento"
                      style={{ color: "red" }}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={4} lg={4}>
                  <FormGroup>
                    <InputLabel>Categoria</InputLabel>
                    {/* <Select
                      value={values.categoria}
                      onChange={handleChange}
                      name="categoria"
                    >
                      <MenuItem value={"selecionar"}>Selecionar</MenuItem>
                      {categorias &&
                        categorias.map((categoria) => (
                          <MenuItem value={categoria.id}>
                            {categoria.nome}
                          </MenuItem>
                        ))}
                    </Select> */}
                    <CreatableSelect
                      isClearable
                      styles={customStyles}
                      isDisabled={isDeleteMode}
                      name={`categoria`}
                      onChange={(option) => {
                        setFieldValue(
                          `categoria`,
                          option ? option.id : ""
                        );
                      }}
                      onCreateOption={async (value) => {
                        const { id } = await handleCreateCategoria(
                          value
                        );
                        setFieldValue(`categoria`, id);
                      }}
                      options={categorias}
                      getOptionLabel={(option) =>
                        option.__isNew__ ? option.label : option.nome
                      }
                      value={categorias.find(
                        (categoria) =>
                          categoria.id ===
                          (values.categoria
                            ? values.categoria
                            : "")
                      )}
                      placeholder={"Selecione a categoria"}
                    />
                    <ErrorMessage
                      component={"div"}
                      name="categoria"
                      style={{ color: "red" }}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={4} lg={4}>
                  <FormGroup>
                    <InputLabel>Data</InputLabel>
                    <TextField
                      type="date"
                      name="data_vencimento"
                      value={values.data_vencimento}
                      onChange={handleChange}
                    />
                    <ErrorMessage
                      component={"div"}
                      name="data_vencimento"
                      style={{ color: "red" }}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={4} lg={4}>
                  <FormGroup>
                    <InputLabel>Beneficiário</InputLabel>
                    <TextField
                      name="beneficiario"
                      type="text"
                      value={values.beneficiario}
                      onChange={handleChange}
                      fullWidth
                      placeholder="Beneficiário"
                    />
                    <ErrorMessage
                      component={"div"}
                      name="beneficiario"
                      style={{ color: "red" }}
                    />
                  </FormGroup>
                </Grid>
              </Grid>
              <Grid
                container
                width="100%"
                display="flex"
                justifyContent={"center"}
                style={{ my: 1 }}
              >
                <LoadingButton
                  loading={isSubmitting}
                  type="submit"
                  style={{ margin: "auto", display: "block" }}
                  variant="contained"
                  color="primary"
                >
                  {"Atualizar"}
                </LoadingButton>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </JumboContentLayout>
  );
};

export default ContasAPagarForm;
