import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Pagination,
  PaginationItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import { useMediaQuery } from "beautiful-react-hooks";
import React, { useCallback, useEffect, useState } from "react";
import ArchiveIcon from "@mui/icons-material/Archive";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AddIcon from "@mui/icons-material/Add";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ApiService from "app/services/config";
import EditIcon from "@mui/icons-material/Edit";
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import DeleteIcon from "@mui/icons-material/Delete";
import { Visibility } from "@mui/icons-material";
import axios from "axios";
import { v4 } from "uuid";
import CustomChipLicencasUnidade from "app/components/CustomChipLicencasUnidade/CustomChipLicencasUnidade";
import ScoreUnidades from "app/components/ScoreUnidades/ScoreUnidades";

const Unidades = () => {
  const [page, setPage] = useState(0);
  const location = useLocation();
  const [unidades, setUnidades] = useState([]);
  const [tipoUnidade, setTipoUnidade] = useState([]);
  const [grupoRestricao, setGrupoRestricao] = useState([]);
  const [estados, setEstados] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [responsaveis, setResponsaveis] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [todosItensSelecionados, setTodosItensSelecionados] = useState(false);
  const [unidadesRegulares, setUnidadesRegulares] = useState([]);
  const [unidadesIrregulares, setUnidadesIrregulares] = useState([]);
  const [unidadesArquivadas, setUnidadesArquivadas] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");
  const [count, setCount] = useState("");
  const [estadoSelecionado, setEstadoSelecionado] = useState("");
  const [unidadesCadastradas, setUnidadesCadastradas] = useState(0);
  const [unidadesNovas, setUnidadesNovas] = useState(0);
  const [isOcultarScores, setIsOcultarScores] = useState(false);
  const { theme } = useJumboTheme();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const [filtrosPesquisa, setFiltrosPesquisa] = useState({
    cidade: [],
    estado: "",
    statusUnidade: [],
    is_arquivada: false,
    termoBusca: "",
    page: 0,
    perPage: 10,
    sortOrder: "desc",
    sortBy: "createdAt",
  });
  const navigate = useNavigate();
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const Swal = useSwalWrapper();

  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const modalAlert = (id, acao) => {
    Swal.fire({
      title: `Tem certeza que deseja ${
        acao === "deletar" ? "apagar" : "arquivar"
      }?`,
      text: `${
        acao === "deletar"
          ? "Não será possível reverter a ação!"
          : "Você pode desarquivar a unidade posteriormente!"
      }`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        switch (acao) {
          case "deletar":
            deleteUnidade(id);
            break;
          case "arquivar":
            arquivarUnidade(id);
            break;
        }
      }
    });
  };
  const modalAlertVarios = (acao) => {
    Swal.fire({
      title: `Tem certeza que deseja ${
        acao === "deletar"
          ? "apagar as licenças"
          : `${
              acao === "arquivar" ? "arquivar " : "desarquivar "
            } as licenças`
      }?`,
      text: `${
        acao === "deletar"
          ? "Não será possível reverter a ação!"
          : `Você pode ${
              acao === "arquivar" ? "desarquivar " : "arquivar "
            } as licenças posteriormente!`
      }`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        switch (acao) {
          case "deletar":
            handleDeleteMany();
            break;
          case "arquivar":
            handleArquivarMany();
            break;
          case "desarquivar":
            handleDesarquivarMany();
            break;
        }
      }
    });
  };


  const handleDeleteMany = async () => {
    try {
      await ApiService.put("/unidade/many", selectedIds).then(
        (response) => {
          if (response.status === 200) {
            toast("success", "Unidades excluídas com sucesso!");
            getUnidades();
          }
          setSelectedIds([]);
          setTodosItensSelecionados(false);
        }
      );
    } catch (error) {
      toast("error", "Ocorreu um erro!");
      console.error(error);
    }
  };

  const handleArquivarMany = async () => {
    try {
      await ApiService.put("/unidade/arquivar/varias/unidades", selectedIds).then(
        (response) => {
          if (response.status === 200) {
            toast("success", "Unidades arquivadas!");
            getUnidades();
          }
          setSelectedIds([]);
          setTodosItensSelecionados(false);
        }
      );
    } catch (error) {
      toast("error", "Ocorreu um erro!");
      console.error(error);
    }
  };

  const handleDesarquivarMany = async () => {
    try {
      await ApiService.put("/unidade/desarquivar/varias/unidades", selectedIds).then(
        (response) => {
          if (response.status === 200) {
            toast("success", "Unidades arquivadas!");
            getUnidades();
          }
          setSelectedIds([]);
          setTodosItensSelecionados(false);
        }
      );
    } catch (error) {
      toast("error", "Ocorreu um erro!");
      console.error(error);
    }
  };

  const modalAlertDesarquivar = (id) => {
    Swal.fire({
      title: `Tem certeza que quer desarquivar a unidade?`,
      text: `Você poderá arquivá-la novamente posteriormente!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        desarquivarUnidade(id);
      }
    });
  };

  const handleOcultarScores = () => {
    setIsOcultarScores(!isOcultarScores);
  };

  const deleteUnidade = async (id) => {
    try {
      await ApiService.delete(`/unidade/${id}`).then((response) => {
        if (response.status === 200) {
          toast("success", "Unidade excluída!");
          getUnidades();
        }
      });
    } catch (error) {
      toast("error", "Ocorreu um erro!");
      console.log(error);
    }
  };

  const getUnidades = async () => {
    try {
      let filtros = {};
      if (filtrosPesquisa.grupoRestricaoId) {
        filtros.grupoRestricaoId = filtrosPesquisa.grupoRestricaoId;
      }
      if (filtrosPesquisa.cidade) {
        filtros.cidade = filtrosPesquisa.cidade;
      }
      if (filtrosPesquisa.estado) {
        filtros.estado = filtrosPesquisa.estado;
      }
      if (filtrosPesquisa.responsavelId) {
        filtros.responsavelId = filtrosPesquisa.responsavelId;
      }
      if (filtrosPesquisa.statusUnidade) {
        filtros.statusUnidade = filtrosPesquisa.statusUnidade;
      }
      if (filtrosPesquisa.termoBusca) {
        filtros.termoBusca = filtrosPesquisa.termoBusca;
      }
      if (filtrosPesquisa.tipoUnidadeId) {
        filtros.tipoUnidadeId = filtrosPesquisa.tipoUnidadeId;
      }
      filtros.page = filtrosPesquisa.page;
      filtros.perPage = filtrosPesquisa.perPage;
      filtros.sortBy = filtrosPesquisa.sortBy;
      filtros.sortOrder = filtrosPesquisa.sortOrder;
      filtros.is_arquivada = filtrosPesquisa.is_arquivada;
      await ApiService.post("/unidade", filtros)
        .then((response) => {
          if (response.status === 201) {
            setUnidades(response.data.unidades);
            setUnidadesCadastradas(response.data.countUnidadesCadastradas);
            setUnidadesNovas(response.data.countUnidadesNovas);
            setUnidadesRegulares(response.data.countUnidadesRegulares);
            setUnidadesIrregulares(response.data.countUnidadesIrregulares);
            setUnidadesArquivadas(response.data.countUnidadesArquivadas);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error(error, error.message);
    }
  };

  const arquivarUnidade = async (id) => {
    try {
      await ApiService.put(`/unidade/arquivar/${id}`).then((response) => {
        if (response.status === 200) {
          toast("success", "Unidade arquivada!");
          getUnidades();
        }
      });
    } catch (error) {
      toast("error", "Ocorreu um erro!");
      console.log(error);
    }
  };

  const desarquivarUnidade = async (id) => {
    try {
      await ApiService.put(`/unidade/desarquivar/${id}`).then((response) => {
        if (response.status === 200) {
          toast("success", "Unidade desarquivada!");
          getUnidades();
        }
      });
    } catch (error) {
      toast("error", "Ocorreu um erro!");
      console.log(error);
    }
  };


  const getEstados = useCallback(async () => {
    try {
      await axios
        .get("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
        .then((response) => {
          if (response.status === 200) {
            setEstados(response.data);
          }
        });
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  const getMunicipios = useCallback(
    async (sigla) => {
      await axios
        .get(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${sigla}/municipios
  `
        )
        .then((response) => {
          if (response.status === 200) {
            setCidades(response.data);
          }
        });
    },
    [estados]
  );

  const handleSelecionarTodosItens = () => {
    setSelectedIds(
      todosItensSelecionados ? [] : unidades.map((unidade) => unidade.id)
    );
    setTodosItensSelecionados(!todosItensSelecionados);
  };

  const handleLimparFiltros = () => {
    setFiltrosPesquisa({
      cidade: [],
      is_arquivada: false,
      estado: "",
      statusUnidade: [],
      termoBusca: "",
      page: 0,
      perPage: 10,
      sortOrder: "desc",
      sortBy: "createdAt",
    });
  };

  const handleCheckboxChange = (itemId) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(itemId)) {
        return prevSelectedIds.filter((id) => id !== itemId);
      } else {
        return [...prevSelectedIds, itemId];
      }
    });
    setTodosItensSelecionados(false);
  };

  useEffect(() => {
    getUnidades();
  }, [filtrosPesquisa]);

  useEffect(() => {
    getEstados();
  }, []);

  return (
    <JumboContentLayout
      header={<PageHeader title={"Unidades"} />}
      layoutOptions={layoutOptions}
    >
      {lg && (
        <Stack
          spacing={2}
          direction={"row"}
          sx={{ mb: 1, mt: -2, justifyContent: "space-between" }}
        ></Stack>
      )}
      <Stack
        width={"100%"}
        display={"flex"}
        direction={"row"}
        gap={1}
        justifyContent={"flex-end"}
      >
        <Box my={1}>
          <Link to={"/app/nova-unidade"}>
            <Button variant="contained" color="success" sx={{ gap: 1 }}>
              <AddIcon /> Nova Unidade
            </Button>
          </Link>
        </Box>
        <Box my={1}>
          <Button
            variant="contained"
            color="info"
            sx={{ gap: 1 }}
            onClick={handleOcultarScores}
          >
            {isOcultarScores ? <VisibilityIcon /> : <VisibilityOffIcon />}{" "}
            {isOcultarScores ? "Visualizar" : "Ocultar"} scores
          </Button>
        </Box>
      </Stack>
      <ScoreUnidades
        isOcultarScores={isOcultarScores}
        unidadesArquivadas={unidadesArquivadas}
        unidadesCadastradas={unidadesCadastradas}
        unidadesNovas={unidadesNovas}
        unidadesIrregulares={unidadesIrregulares}
        unidadesRegulares={unidadesRegulares}
      />

      <React.Fragment>
        <Grid
          container
          spacing={2}
          justifyContent="flex-end"
          alignItems="center"
          my={1}
        >
          <Grid item>
            <Button variant={"contained"} onClick={handleSelecionarTodosItens}>
              {todosItensSelecionados ? "Desmarcar tudo" : "Selecionar todas"}
            </Button>
          </Grid>
          {selectedIds.length > 0 && (
            <Grid item display={"flex"} gap={1}>
              <span style={{ margin: 10 }}>
                {selectedIds.length} unidade (s) selecionada(s)
              </span>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={() => {
                  modalAlertVarios("deletar");
                }}
              >
                Excluir Selecionados
              </Button>
              <Button
                variant="contained"
                color="info"
                startIcon={
                  filtrosPesquisa.is_arquivada ? (
                    <UnarchiveIcon />
                  ) : (
                    <ArchiveIcon />
                  )
                }
                onClick={() => {
                  {
                    filtrosPesquisa.is_arquivada
                      ? modalAlertVarios("desarquivar")
                      : modalAlertVarios("arquivar");
                  }
                }}
              >
                {filtrosPesquisa.is_arquivada
                  ? "Desarquivar selecionados"
                  : "Arquivar selecionados"}
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid
          container
          p={3}
          mt={3}
          gap={3}
          style={{ background: "white", minHeight: "120px" }}
        >
          <Grid item xs={2}>
            <Box mt={2} mb={2}>
              <TextField
                label="Buscar"
                variant="outlined"
                value={filtrosPesquisa.termoBusca}
                onChange={(e) => {
                  setFiltrosPesquisa((prevFiltros) => ({
                    ...prevFiltros,
                    termoBusca: e.target.value,
                  }));
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => console.log(searchTerm)}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={1.5}>
            <Box mt={2} mb={2} my={-0.6}>
              <FormLabel id="status-unidade">Status da Unidade</FormLabel>
              <Select
                id="status-unidade"
                label="Status da Unidade"
                onChange={(e) => {
                  const status = e.target.value;
                  if (status.length === 1 && status[0] == "") {
                    setFiltrosPesquisa((prevFiltros) => ({
                      ...prevFiltros,
                      statusUnidade: [],
                    }));
                  } else if (status.some((value) => value === "")) {
                    setFiltrosPesquisa((prevFiltros) => ({
                      ...prevFiltros,
                      statusUnidade: [],
                    }));
                  } else {
                    setFiltrosPesquisa((prevFiltros) => ({
                      ...prevFiltros,
                      statusUnidade: status,
                    }));
                  }
                }}
                value={filtrosPesquisa.statusUnidade ?? ""}
                fullWidth
                multiple
              >
                <MenuItem value="">Selecionar</MenuItem>
                <MenuItem value="nova">Nova</MenuItem>
                <MenuItem value="irregular">Irregular</MenuItem>
                <MenuItem value="parcialmente_regular">
                  Parcialmente Regular
                </MenuItem>
                <MenuItem value="regular">Regular</MenuItem>
              </Select>
            </Box>
          </Grid>
          <Grid item xs={1}>
            <Box mt={2} mb={2} my={-0.6}>
              <FormLabel id="estado-unidade">Estado</FormLabel>
              <Select
                id="estado-unidade"
                label="Estado"
                value={filtrosPesquisa.estado ?? ""}
                onChange={(event) => {
                  setEstadoSelecionado(event.target.value);
                  getMunicipios(event.target.value);
                  setFiltrosPesquisa((prevFiltros) => ({
                    ...prevFiltros,
                    estado: event.target.value,
                  }));
                }}
                fullWidth
              >
                <MenuItem value="">Selecionar</MenuItem>
                {estados &&
                  estados.length > 0 &&
                  estados.map((estado) => (
                    <MenuItem value={estado.sigla}>{estado.nome}</MenuItem>
                  ))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={1}>
            <Box mt={2} mb={2} my={-0.6}>
              <FormLabel id="cidade-unidade">Cidade</FormLabel>
              <Select
                id="cidade-unidade"
                multiple
                label="Cidade"
                fullWidth
                onChange={(e) => {
                  setFiltrosPesquisa((prevFiltros) => ({
                    ...prevFiltros,
                    cidade: e.target.value,
                  }));
                }}
                value={filtrosPesquisa.cidade ?? null}
              >
                <MenuItem value="">Selecionar</MenuItem>
                {cidades &&
                  cidades.length > 0 &&
                  cidades.map((cidade) => (
                    <MenuItem value={cidade.nome}>{cidade.nome}</MenuItem>
                  ))}
              </Select>
            </Box>
          </Grid>

          {unidades.map((unidade) => (
            <Card sx={{ mb: 1, p: 2 }} style={{ width: "100%" }}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item lg={1}>
                  <Checkbox
                    key={unidade.id}
                    checked={selectedIds.includes(unidade.id)}
                    onChange={() => handleCheckboxChange(unidade.id)}
                  />
                </Grid>
                <Grid item lg={1.6}>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: 500,
                        marginBottom: 5,
                        fontSize: "18px",
                      }}
                    >
                      Nome
                    </Typography>
                    <Typography style={{ color: "#808080", fontSize: "16px" }}>
                      {unidade.nomeUnidade}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={3}>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: 500,
                        marginBottom: 5,
                        fontSize: "18px",
                      }}
                    >
                      Razão Social
                    </Typography>
                    <Typography style={{ color: "#808080", fontSize: "18px" }}>
                      {unidade.razaoSocial}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={1.8}>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: 500,
                        marginBottom: 5,
                        fontSize: "18px",
                      }}
                    >
                      CNPJ
                    </Typography>
                    <Typography style={{ color: "#808080", fontSize: "18px" }}>
                      {unidade.cnpj}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={2}>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: 500,
                        marginBottom: 5,
                        fontSize: "18px",
                      }}
                    >
                      Próx. Vencimento
                    </Typography>
                    <Typography style={{ color: "#808080", fontSize: "18px" }}>
                      Em ajuste
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={1}>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: 500,
                        marginBottom: 5,
                        fontSize: "18px",
                      }}
                    >
                      Status
                    </Typography>
                    <CustomChipLicencasUnidade situacao={unidade.status} />
                  </Box>
                </Grid>
                <Grid item gap={2} display={"flex"} direction={"row"}>
                  <Box>
                    <Visibility
                      color="success"
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(`/app/unidade/${unidade.id}`);
                      }}
                    />
                  </Box>
                  <Box>
                    <EditIcon
                      color="info"
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(`/app/editar-unidade/${unidade.id}`);
                      }}
                    />
                  </Box>
                  <Box>
                    {!unidade.is_arquivada ? (
                      <ArchiveIcon
                        sx={{ cursor: "pointer" }}
                        color="info"
                        onClick={() => {
                          modalAlert(unidade.id, "arquivar");
                        }}
                      />
                    ) : (
                      <UnarchiveIcon
                        sx={{ cursor: "pointer" }}
                        color="warning"
                        onClick={() => {
                          modalAlertDesarquivar(unidade.id);
                        }}
                      />
                    )}
                  </Box>
                  <Box>
                    <DeleteIcon
                      color="error"
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        modalAlert(unidade.id, "deletar");
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Card>
          ))}

          <Paper
            style={{
              background: "white",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <FormControl>
              <Grid
                container
                spacing={2}
                alignItems={"center"}
                display={"flex"}
                justifyContent={"space-evenly"}
              >
                <Grid item textAlign={"center"}>
                  <Box my={2}>
                    <Typography>Página</Typography>
                    <Pagination
                      count={Math.ceil(count / filtrosPesquisa.perPage)}
                      page={filtrosPesquisa.page + 1}
                      onChange={(event, value) =>
                        setFiltrosPesquisa((prevFiltros) => ({
                          ...prevFiltros,
                          page: value - 1,
                        }))
                      }
                      variant="outlined"
                      shape="rounded"
                      size="large"
                      renderItem={(item) => (
                        <PaginationItem
                          slots={{
                            previous: ArrowBackIcon,
                            next: ArrowForwardIcon,
                          }}
                          {...item}
                        />
                      )}
                      style={{ marginTop: 10 }}
                    />
                  </Box>
                </Grid>
                <Grid item textAlign={"center"}>
                  <Box>
                    <Typography>Itens por página</Typography>
                    <TextField
                      select
                      label={"Itens por página"}
                      value={filtrosPesquisa.perPage}
                      onChange={(e) =>
                        setFiltrosPesquisa((prevFiltros) => ({
                          ...prevFiltros,
                          perPage: e.target.value,
                        }))
                      }
                      style={{ marginTop: 10 }}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={30}>30</MenuItem>
                    </TextField>
                  </Box>
                </Grid>
                <Grid item textAlign={"center"}>
                  <Box>
                    <Typography>Ordenar por</Typography>
                    <TextField
                      select
                      label={"Ordenação"}
                      value={filtrosPesquisa.sortBy}
                      onChange={(e) =>
                        setFiltrosPesquisa((prevFiltros) => ({
                          ...prevFiltros,
                          sortBy: e.target.value,
                        }))
                      }
                      style={{ marginTop: 10 }}
                    >
                      <MenuItem value={"createdAt"}>Data de Criação</MenuItem>
                      <MenuItem value={"nomeUnidade"}>Nome da Unidade</MenuItem>
                    </TextField>
                  </Box>
                </Grid>
                <Grid item textAlign={"center"}>
                  <Box>
                    <Typography>Ordem</Typography>
                    <TextField
                      select
                      label={"Ordem"}
                      value={filtrosPesquisa.sortOrder}
                      onChange={(e) =>
                        setFiltrosPesquisa((prevFiltros) => ({
                          ...prevFiltros,
                          sortOrder: e.target.value,
                        }))
                      }
                      style={{ marginTop: 10 }}
                    >
                      <MenuItem value={"asc"}>Ascendente</MenuItem>
                      <MenuItem value={"desc"}>Descendente</MenuItem>
                    </TextField>
                  </Box>
                </Grid>
                <Grid item textAlign={"center"}>
                  <Box>
                    <Typography>Listar unidades</Typography>
                    <TextField
                      select
                      label={"Listar unidades"}
                      value={filtrosPesquisa.is_arquivada}
                      onChange={(e) =>
                        setFiltrosPesquisa((prevFiltros) => ({
                          ...prevFiltros,
                          is_arquivada: e.target.value,
                        }))
                      }
                      style={{ marginTop: 10 }}
                    >
                      <MenuItem value={true}>Arquivadas</MenuItem>
                      <MenuItem value={false}>Não arquivadas</MenuItem>
                    </TextField>
                  </Box>
                </Grid>
                <Grid item textAlign={"center"}>
                  <Box>
                    <Typography>Filtros</Typography>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        handleLimparFiltros();
                      }}
                    >
                      Resetar
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </FormControl>
          </Paper>
        </Grid>
      </React.Fragment>
    </JumboContentLayout>
  );
};

export default Unidades;
