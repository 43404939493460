import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Divider,
  Fade,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Slider,
  Stack,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import * as yup from "yup";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import ApiService from "app/services/config";
import DeleteIcon from "@mui/icons-material/Delete";
import { v4 } from "uuid";
import CreatableSelect from "react-select/creatable";
import moment from "moment";

const ATIVIDADES_DEPENDE = {
  ambiental: {
    atividade: "Ambiental",
    cor: "#EC5F6F",
  },
  cliente: {
    atividade: "Cliente",
    cor: "#F5AB3E",
  },
  orgao: {
    atividade: "Órgão",
    cor: "#5FDBB3",
  },
  protocolado: {
    atividade: "Protocolado",
    cor: "#82A2B9",
  },
  nao_informado: {
    atividade: "Não informado",
    cor: "#F0F0F0",
  },
};

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "transparent" : "white",
    border: state.isFocused
      ? "1px solid #005D5F"
      : "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    padding: "8px",
    boxShadow: "none",
    "&:hover": {
      borderColor: "black",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#005D5F" : "white",
    color: state.isSelected ? "white" : "black",
    "&:hover": {
      backgroundColor: "lightblue",
      color: "white",
    },
  }),
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const validationSchema = yup.object().shape({
  descricao: yup.string().optional(),
  tarefaId: yup
    .number("ID da Tarefa Obrigatório")
    .required("ID da Tarefa Obrigatório"),
  servicoId: yup
    .number("ID do Serviço Obrigatório")
    .required("ID do Serviço Obrigatório"),
  prazo: yup.date().nullable().typeError("Data inválida"),
  municipio: yup.string().optional().nullable(),
  documentacao_necessaria: yup.string().optional().nullable(),
  observacoes: yup
    .array()
    .of(
      yup.object().shape({
        descricao: yup
          .string("Campo obrigatório")
          .required("Campo obrigatório"),
      })
    )
    .optional(),
  status_servico_id: yup
    .number("Campo obrigatório")
    .required("Campo obrigatório")
    .typeError("Campo obrigatório"),
  prioridade: yup.number().required("Campo obrigatório"),
  responsavel: yup
    .string()
    .required("Campo obrigatório")
    .typeError("Campo obrigatório"),
});

const ModalEditarTarefa = ({
  aberto,
  handleClose,
  servicoTarefaId,
  setServicoTarefaId,
  atualizarListaContratos,
}) => {
  const userId = localStorage.getItem("id");
  const initialValues = {
    descricao: "",
    tarefaId: "",
    servicoId: "",
    realizacao: 0,
    municipio: "",
    prazo: "",
    documentacao_necessaria: "",
    observacoes: [],
    status_servico_id: "",
    prioridade: 0,
    responsavel: "",
  };
  const [servicoTarefa, setServicoTarefa] = useState(initialValues);
  const [servicoTarefaSelecionadaId, setServicoTarefaSelecionadaId] =
    useState();
  const [isDadosCarregados, setIsDadosCarregados] = useState(false);
  const [statusServico, setStatusServico] = useState([]);
  const [statusServicoSelecionado, setStatusServicoSelecionado] =
    useState(null);
  const [modalAberto, setModalAberto] = useState(aberto);

  const Swal = useSwalWrapper();
  const toast = (variant, message, type = false) => {
    Swal.fire({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      icon: type ? "success" : "error",
      title: message,
      didOpen: (toast) => {
        toast.style.zIndex = 10000;
      },
      timer: 3000,
    });
  };

  useEffect(() => {
    setModalAberto(aberto);
  }, [aberto]);

  useEffect(() => {
    setServicoTarefaSelecionadaId(servicoTarefaId);
  }, [servicoTarefaSelecionadaId, modalAberto]);

  const getServicoTarefa = useCallback(async () => {
    setServicoTarefa([]);
    try {
      await ApiService.get(`/servicotarefa/${servicoTarefaId}`)
        .then((response) => {
          setStatusServicoSelecionado(response.data.status_servico_id);
          setServicoTarefa({
            ...response.data,
            prazo: moment(response.data.prazo).format("YYYY-MM-DD"),
          });
          if (response.data.status_servico_id) {
            setStatusServicoSelecionado({
              id: response.data.status_servico_id,
              descricao: response.data.status_servico.descricao,
            });
          } else {
            setStatusServicoSelecionado(null);
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.log(error);
    }
  }, [modalAberto]);

  const getStatusServicos = async () => {
    try {
      await ApiService.get("/statusservico").then((response) => {
        setStatusServico(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateStatusServico = async (descricao) => {
    try {
      const response = await ApiService.post("/statusservico", {
        descricao,
      });

      if (response.status === 201) {
        toast(null, "Criado com sucesso", "success");
        const novoId = response.data.id;
        setStatusServicoSelecionado(novoId);
        getStatusServicos();
        return novoId;
      }
    } catch (error) {
      toast(null, "Ocorreu um erro", "error");
      console.log(error);
    }
  };

  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    try {
      const valoresFormatados = {
        ...values,
        prazo: moment(values.prazo).format("YYYY-MM-DD"),
      };
      delete valoresFormatados.deletedAt;
      delete valoresFormatados.updatedAt;
      delete valoresFormatados.createdAt;
      delete valoresFormatados.status_servico;
      delete valoresFormatados.status_orcamento_id;

      await ApiService.put(
        `/servicotarefa/atualizar/${servicoTarefaId}`,
        valoresFormatados
      )
        .then((response) => {
          if (response.status === 200) {
            toast(null, "Atualizado com sucesso!", "success");
            atualizarListaContratos();
            setStatusServicoSelecionado("");
            resetForm();
            handleClose();
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
      toast(null, "Ocorreu um erro", "error");
      console.log(error);
    }
  };

  const modalAlert = (idObservacao) => {
    Swal.fire({
      title: "Tem certeza que deseja apagar?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
      didOpen: () => {
        Swal.getContainer().style.zIndex = "9999";
      },
    }).then((result) => {
      if (result.value) {
        handleDeleteObservacao(idObservacao);
      }
    });
  };

  const handleDeleteObservacao = async (observacaoId) => {
    try {
      await ApiService.delete(`/observacaotarefa/${observacaoId}`).then(
        (response) => {
          if (response.status === 200) {
            toast(null, "Removido com sucesso!", "success");
            getServicoTarefa();
          }
        }
      );
    } catch (error) {
      toast(null, "Ocorreu um erro", "error");
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getServicoTarefa();
      await getStatusServicos();
    };
    fetchData();
    setIsDadosCarregados(true);
  }, [modalAberto, servicoTarefaId]);

  useEffect(() => {
    setModalAberto(aberto);
    if (!aberto) {
      setServicoTarefa(initialValues);
    }
  }, [aberto]);

  const optionsStatusServicos = statusServico?.map((status) => {
    return {
      id: status.id,
      descricao: status.descricao,
    };
  });

  return (
    <>
      {isDadosCarregados && (
        <Paper>
          <Modal
            open={modalAberto}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            closeAfterTransition
          >
            <Fade in={modalAberto}>
              <Box sx={style}>
                <Box>
                  <Typography variant={"h2"}>Editar Tarefa</Typography>
                </Box>
                <Divider />
                <Box my={1}>
                  <Formik
                    enableReinitialize
                    initialValues={servicoTarefa}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({
                      values,
                      handleChange,
                      handleSubmit,
                      errors,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <Form
                        onSubmit={handleSubmit}
                        style={{
                          width: "100%",
                          maxHeight: 500,
                          marginTop: 20,
                          overflowY: "auto",
                        }}
                      >
                        <Box my={1} width={"100%"}>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Typography marginY={1} variant="h5">
                                Município:
                              </Typography>
                              <TextField
                                name="municipio"
                                onChange={handleChange}
                                value={values.municipio}
                                variant="outlined"
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Typography marginY={1} variant="h5">
                                Realização:
                              </Typography>
                              <Box
                                display="flex"
                                gap={1}
                                alignItems="center"
                                justifyContent="space-around"
                              >
                                <Slider
                                  value={values.realizacao}
                                  onChange={(event, novoValor) => {
                                    setFieldValue("realizacao", novoValor);
                                  }}
                                  key={v4()}
                                  defaultValue={values.realizacao}
                                  color={"success"}
                                  min={0}
                                  max={100}
                                  sx={{ flex: 4 }}
                                />
                                <TextField
                                  name="realizacao"
                                  type="number"
                                  onChange={(event) => {
                                    setFieldValue(
                                      "realizacao",
                                      event.target.value
                                    );
                                  }}
                                  value={values.realizacao}
                                  variant="outlined"
                                  size="small"
                                  InputProps={{
                                    endAdornment: "%",
                                  }}
                                  sx={{ flex: 3 }}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                        <Box my={1} width={"100%"}>
                          <Typography marginY={1} variant="h5">
                            Documentação necessária:
                          </Typography>
                          <TextareaAutosize
                            minRows={5}
                            style={{
                              width: "100%",
                              fontFamily: "sans-serif",
                              fontSize: "16px",
                            }}
                            name="documentacao_necessaria"
                            onChange={handleChange}
                            value={values.documentacao_necessaria}
                          />

                          <ErrorMessage
                            component={"div"}
                            name="documentacao_necessaria"
                            style={{ color: "red" }}
                          />
                        </Box>
                        <Grid container spacing={2}>
                          <Grid item lg={6}>
                            <Box my={1} width={"100%"}>
                              <Typography marginY={1} variant="h5">
                                Prazo:
                              </Typography>
                              <TextField
                                name="prazo"
                                type="date"
                                onChange={(event) => {
                                  setFieldValue("prazo", event.target.value);
                                }}
                                value={values.prazo}
                                variant="outlined"
                                fullWidth
                              />

                              <ErrorMessage
                                component={"div"}
                                name="prazo"
                                style={{ color: "red" }}
                              />
                            </Box>
                          </Grid>
                          <Grid item lg={6}>
                            <Box my={1} width={"100%"}>
                              <Typography marginY={1} variant="h5">
                                Status Serviço:
                              </Typography>
                              <CreatableSelect
                                key={v4()}
                                isClearable
                                styles={customStyles}
                                name={`status_servico_id`}
                                onChange={(option) => {
                                  if (option) {
                                    setStatusServicoSelecionado({
                                      id: option.id,
                                      descricao: option.descricao,
                                    });
                                    setFieldValue(
                                      `status_servico_id`,
                                      option ? option.id : ""
                                    );
                                  }
                                }}
                                onCreateOption={async (value) => {
                                  const novoId =
                                    await handleCreateStatusServico(value);
                                  setStatusServicoSelecionado({
                                    novoId,
                                    descricao: value,
                                  });
                                  setFieldValue(`status_servico_id`, novoId);
                                }}
                                options={optionsStatusServicos}
                                getOptionLabel={(option) =>
                                  option.__isNew__
                                    ? option.label
                                    : option.descricao
                                }
                                value={statusServicoSelecionado}
                                placeholder={"Selecione um Status de Serviço"}
                              />
                              <ErrorMessage
                                name="status_servico_id"
                                component={"div"}
                                style={{ color: "red" }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item lg={6}>
                            <FormLabel id="prioridade-radio-group">
                              Prioridade
                            </FormLabel>
                            <RadioGroup
                              row
                              id="prioridade-radio-group"
                              name="prioridade"
                              value={values.prioridade ?? 0}
                              onChange={(event) => {
                                setFieldValue(
                                  "prioridade",
                                  Number(event.target.value)
                                );
                              }}
                            >
                              <FormControlLabel
                                value={0}
                                label={0}
                                control={<Radio />}
                              />
                              <FormControlLabel
                                value={1}
                                label={1}
                                control={<Radio />}
                              />
                              <FormControlLabel
                                value={2}
                                label={2}
                                control={<Radio />}
                              />
                              <FormControlLabel
                                value={3}
                                label={3}
                                control={<Radio />}
                              />
                            </RadioGroup>
                            <ErrorMessage component={"div"} name="prioridade" />
                          </Grid>
                          <Grid item lg={6}>
                            <FormLabel id="select-responsavel">
                              Atividade depende:
                            </FormLabel>
                            <Select
                              name="responsavel"
                              id="select-responsavel"
                              fullWidth
                              onChange={handleChange}
                              renderValue={(value) => {
                                const atividade = ATIVIDADES_DEPENDE[value];
                                return (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        width: "10px",
                                        height: "10px",
                                        borderRadius: "50%",
                                        backgroundColor: atividade?.cor,
                                        marginRight: "8px",
                                      }}
                                    />
                                    <Typography>
                                      {atividade?.atividade}
                                    </Typography>
                                  </Box>
                                );
                              }}
                              value={values.responsavel ?? "nao_informado"}
                              sx={{
                                "& .MuiSelect-selectMenu": {
                                  display: "flex",
                                  alignItems: "center",
                                },
                              }}
                            >
                              {Object.keys(ATIVIDADES_DEPENDE).map((key) => {
                                const atividade = ATIVIDADES_DEPENDE[key];
                                return (
                                  <MenuItem
                                    key={key}
                                    value={key}
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        width: "10px",
                                        height: "10px",
                                        borderRadius: "50%",
                                        backgroundColor: atividade.cor,
                                        marginRight: "8px",
                                      }}
                                    />
                                    <Typography>
                                      {atividade.atividade}
                                    </Typography>
                                  </MenuItem>
                                );
                              })}
                            </Select>
                            <ErrorMessage
                              name="responsavel"
                              component={"div"}
                              style={{ color: "red" }}
                            />
                          </Grid>
                        </Grid>
                        <Divider />
                        <Grid container my={2}>
                          <Typography variant="h4">Observações</Typography>
                          <FieldArray name="observacao">
                            {({ push, remove }) => (
                              <Box my={1} width={"100%"}>
                                {values.observacoes &&
                                values.observacoes.length === 0 ? (
                                  <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    height={150}
                                  >
                                    <Typography my={1}>
                                      Nenhuma observação registrada
                                    </Typography>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={() => push({ descricao: "" })}
                                    >
                                      Adicionar nova observação
                                    </Button>
                                  </Box>
                                ) : (
                                  <>
                                    {values.observacoes &&
                                      values.observacoes.map(
                                        (observacao, index) => (
                                          <>
                                            <Box
                                              key={index}
                                              my={2}
                                              display="flex"
                                              alignItems="center"
                                            >
                                              <Field
                                                name={`observacao.${index}.descricao`}
                                                as={TextField}
                                                label={`Observação ${
                                                  index + 1
                                                }`}
                                                variant="outlined"
                                                fullWidth
                                                multiline
                                              />
                                              <IconButton
                                                variant="outlined"
                                                color="error"
                                                onClick={() =>
                                                  observacao.id
                                                    ? modalAlert(observacao.id)
                                                    : remove(index)
                                                }
                                              >
                                                <DeleteIcon />
                                              </IconButton>
                                            </Box>
                                            {observacao.id && (
                                              <>
                                                <Stack
                                                  px={1}
                                                  direction={"row"}
                                                  justifyContent={
                                                    "space-between"
                                                  }
                                                >
                                                  <Typography color="#909FAE">
                                                    Usuário:{" "}
                                                    {observacao.usuario?.name ??
                                                      "N/I"}
                                                  </Typography>
                                                  <Typography color="#909FAE">
                                                    Criado em:{" "}
                                                    {moment(
                                                      observacao.createdAt
                                                    ).format("DD/MM/YY HH:MM")}
                                                  </Typography>
                                                </Stack>
                                                <Divider my={2} />
                                              </>
                                            )}
                                          </>
                                        )
                                      )}
                                    <Button
                                      sx={{ backgroundColor: "info" }}
                                      onClick={() => push({ descricao: "", userId: Number(userId) })}
                                    >
                                      Adicionar nova observação
                                    </Button>
                                  </>
                                )}
                              </Box>
                            )}
                          </FieldArray>
                        </Grid>
                        <Box
                          mt={3}
                          display="flex"
                          justifyContent="space-evenly"
                        >
                          <Button
                            variant="contained"
                            color="success"
                            type="submit"
                          >
                            Salvar
                          </Button>
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                              setServicoTarefa(initialValues);
                              handleClose();
                            }}
                          >
                            Fechar
                          </Button>
                        </Box>
                        <input
                          type="hidden"
                          name="servicoId"
                          value={servicoTarefa.servicoId}
                        />
                        <input
                          type="hidden"
                          name="tarefaId"
                          value={servicoTarefa.tarefaId}
                        />
                      </Form>
                    )}
                  </Formik>
                </Box>
              </Box>
            </Fade>
          </Modal>
        </Paper>
      )}
    </>
  );
};

export default ModalEditarTarefa;
