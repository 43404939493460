import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { PermissionContext } from "app/contexts/PermissionContext";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import ApiService from "app/services/config";
import { ESTADOS_BRASIL } from "app/utils/estados";
import { Form, Formik } from "formik";
import React, { useCallback, useContext, useEffect, useState } from "react";
import ReactInputMask from "react-input-mask";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  nome: yup
    .string("Insira a descrição do Processo Administrativo")
    .required("Preenchimento obrigatório"),
  cnpj: yup
    .string()
    .matches(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/, {
      message: "CNPJ Inválido",
      excludeEmptyString: true,
    })
    .required("Campo obrigatório"),
  cep: yup
    .string()
    .matches(/^[0-9]{5}-[0-9]{3}$/, "CEP Inválido")
    .nullable(),
  telefone: yup
    .string()
    .matches(/^\(\d{2}\)\s\d{5}-\d{4}$/, "Telefone inválido")
    .required("Preenchimento obrigatório")
    .typeError("Telefone inválido"),
});

const FornecedorForm = () => {
  const initialValues = {
    nome: "",
    cnpj: "",
    telefone: "",
    endereco: {
      cep: "",
      vizinhanca: "",
      rua: "",
      cidade: "",
      numero: "",
      complemento: "",
      uf: "",
    },
  };
  const { id } = useParams();
  const [fornecedor, setFornecedor] = useState(initialValues);
  const [formattedCNPJ, setFormattedCNPJ] = useState("");
  const [formattedCEP, setFormatedCEP] = useState("");
  const [formattedTelefone, setFormatedTelefone] = useState("");

  const Swal = useSwalWrapper();
  const navigate = useNavigate();

  const { hasPermission } = useContext(PermissionContext);

  if (!hasPermission("Administrativo", "read")) {
    navigate("/app");
  }

  const { theme } = useJumboTheme();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const getFornecedor = useCallback(async () => {
    try {
      ApiService.get(`/fornecedores/${id}`)
        .then((response) => {
          const { cep } = response.data?.endereco;
          const { telefone, cnpj } = response.data;

          setFornecedor({
            ...response.data,
            telefone: response.data?.telefone,
            endereco: {
              vizinhanca: response.data?.endereco?.vizinhanca,
              rua: response.data?.endereco?.rua,
              cidade: response.data?.endereco?.cidade,
              numero: response.data?.endereco?.numero,
              complemento: response.data?.endereco?.complemento,
              uf: response.data?.endereco?.uf,
            },
          });

          setFormattedCNPJ(cnpj);
          setFormatedCEP(cep);
          setFormatedTelefone(telefone ?? "");
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    if (id) {
      getFornecedor();
    }
  }, []);

  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    console.log(values);
    const allValuesEdit = { ...values };
    delete allValuesEdit.id;
    delete allValuesEdit.createdAt;
    delete allValuesEdit.updatedAt;
    delete allValuesEdit.deletedAt;
    if (id) {
      ApiService.put(`/fornecedores/${id}`, allValuesEdit)
        .then((response) => {
          toast("success", "Atualizado com sucesso!");
          if (response.status === 200) {
            navigate("/app/fornecedores");
          }
        })
        .catch((error) => {
          let message = error.response.data.message;
          toast("error", message);

          if (error.response.data) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } else {
      await ApiService.post("/fornecedores", allValuesEdit)
        .then((response) => {
          toast("success", "Criado com sucesso");
          resetForm();
          if (response.status === 201) {
            navigate("/app/fornecedores");
          }
        })
        .catch((error) => {
          let message = error.response.data.message;
          toast("error", message);

          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    }
    setSubmitting(false);
  };

  return (
    <JumboContentLayout
      header={
        <HeaderBreadcrumbs
          id={id}
          title={"Fornecedores"}
          subtitle={id ? "Editar" : "Cadastro de Fornecedor"}
          titleUrl={"/app/fornecedores"}
        />
      }
      layoutOptions={layoutOptions}
    >
      {lg && (
        <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}></Stack>
      )}
      <Paper sx={{ p: "15px" }}>
        <Formik
          initialValues={fornecedor}
          validationSchema={validationSchema}
          enableReinitialize
          validateOnChange={true}
          onSubmit={handleSubmit}
        >
          {({ values, isSubmitting, handleChange, setFieldValue, errors }) => (
            <Form style={{ width: "100%" }} noValidate autoComplete="off">
              <Grid container alignContent={"center"} spacing={2}>
                <Grid item lg={5} xs={12}>
                  <JumboTextField
                    fullWidth
                    name="nome"
                    label="Nome / Descrição"
                    required
                    value={values.nome}
                    onChange={handleChange}
                    sx={{ background: "#ffffff" }}
                  />
                </Grid>
                <Grid item lg={4} xs={4}>
                  <ReactInputMask
                    mask="99.999.999/9999-99"
                    maskChar=""
                    value={formattedCNPJ}
                    onChange={(e) => {
                      setFormattedCNPJ(e.target.value);
                      setFieldValue("cnpj", formattedCNPJ);
                    }}
                  >
                    {(inputProps) => (
                      <TextField
                        fullWidth
                        name="cnpj"
                        label="CNPJ"
                        sx={{ background: "#ffffff" }}
                        {...inputProps}
                      />
                    )}
                  </ReactInputMask>
                  {errors.cnpj && (
                    <div style={{ color: "red" }}>{errors.cnpj}</div>
                  )}
                </Grid>
                <Grid item lg={3} xs={3}>
                  <ReactInputMask
                    mask="(99) 99999-9999"
                    maskChar=""
                    value={formattedTelefone}
                    onChange={(e) => {
                      setFormatedTelefone(e.target.value);
                      setFieldValue("telefone", e.target.value);
                    }}
                  >
                    {(inputProps) => (
                      <TextField
                        fullWidth
                        name="telefone"
                        label="Telefone"
                        sx={{ background: "#ffffff" }}
                        {...inputProps}
                      />
                    )}
                  </ReactInputMask>
                  {errors.telefone && (
                    <div style={{ color: "red" }}>{errors.telefone}</div>
                  )}
                </Grid>
                <Grid item lg={5} xs={5}>
                  <ReactInputMask
                    mask="99999-999"
                    value={formattedCEP}
                    onChange={(e) => {
                      const value = e.target.value;
                      setFormatedCEP(value);
                      setFieldValue("endereco.cep", value);
                    }}
                  >
                    {(inputProps) => (
                      <TextField
                        fullWidth
                        name="endereco.cep"
                        label="CEP"
                        sx={{ background: "#ffffff" }}
                        {...inputProps}
                      />
                    )}
                  </ReactInputMask>
                  {errors.cep && (
                    <div style={{ color: "red" }}>{errors.cep}</div>
                  )}
                </Grid>
                <Grid item lg={5} xs={12}>
                  <JumboTextField
                    fullWidth
                    name="endereco.rua"
                    label="Rua"
                    value={values.rua}
                    onChange={handleChange}
                    sx={{ background: "#ffffff" }}
                  />
                </Grid>
                <Grid item lg={2} xs={12}>
                  <JumboTextField
                    fullWidth
                    name="endereco.numero"
                    label="Número"
                    value={values.endereco.numero}
                    onChange={handleChange}
                    sx={{ background: "#ffffff" }}
                  />
                </Grid>
                <Grid item lg={3} xs={12}>
                  <JumboTextField
                    fullWidth
                    name="endereco.complemento"
                    label="Complemento"
                    value={values.endereco.complemento}
                    onChange={handleChange}
                    sx={{ background: "#ffffff" }}
                  />
                </Grid>
                <Grid item lg={3} xs={12}>
                  <JumboTextField
                    fullWidth
                    name="endereco.vizinhanca"
                    label="Bairro"
                    value={values.endereco.vizinhanca}
                    onChange={handleChange}
                    sx={{ background: "#ffffff" }}
                  />
                </Grid>
                <Grid item lg={4} xs={12}>
                  <JumboTextField
                    fullWidth
                    name="endereco.cidade"
                    label="Cidade"
                    value={values.endereco.cidade}
                    onChange={handleChange}
                    sx={{ background: "#ffffff" }}
                  />
                </Grid>
                <Grid item lg={2} xs={2}>
                  <FormControl fullWidth>
                    <InputLabel id="select-estado-label">UF</InputLabel>
                    <Select
                      labelId="select-estado-label"
                      id="select-estado"
                      value={values.endereco.uf}
                      label={"UF"}
                      name={"endereco.uf"}
                      onChange={handleChange}
                      fullWidth
                    >
                      {Object.entries(ESTADOS_BRASIL).map(([sigla, estado]) => (
                        <MenuItem value={sigla}>{estado}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Box style={{ width: "100%" }} marginTop={3}>
                  <LoadingButton
                    loading={isSubmitting}
                    type="submit"
                    style={{ margin: "auto", display: "block" }}
                    variant="contained"
                    color="primary"
                  >
                    {id ? "Atualizar" : "Criar"}
                  </LoadingButton>
                </Box>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </JumboContentLayout>
  );
};

export default FornecedorForm;
