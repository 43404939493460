import { useState, useEffect, useContext, createContext } from "react";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";

export const PermissionContext = createContext();

export const PermissionProvider = ({ children }) => {
  const { authToken, permissoes } = useJumboAuth();

  /* const hasPermission = (permission, action) => {
        const permissionObj = userPermissions?.find((p) => p.tela.nome === permission);
        return permissionObj && permissionObj[action];
    }; */

  const hasPermission = (permission, action) => {
    // console.log(permission, action)
    let hasPermission = false;
    permissoes?.forEach((permissionObj) => {
      if (permissionObj.Page.name === permission && permissionObj[action]) {
        hasPermission = true;
      }
    });
    return hasPermission;
  };

  return (
    <PermissionContext.Provider value={{ hasPermission }}>
      {children}
    </PermissionContext.Provider>
  );
};
