import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {
  Box,
  Button,
  Divider,
  Fade,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import ApiService from "app/services/config";
import { STATUS_CONDICIONANTES } from "app/utils/statusCondicionantes";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const validationSchema = yup.object().shape({
  observacao: yup.string().nullable(),
  status: yup.string().nullable(),
  userId: yup.number().required("Campo obrigatório"),
  condicionanteId: yup.number().required("Campo obrigatório"),
});

const ModalEdicaoCondicionante = ({
  modalAberto,
  handleClose,
  atualizarCondicionante,
  observacao,
  statusCondicionante,
  nomeCondicionante,
  userId,
  condicionanteId,
  acao,
}) => {
  const initialValues = {
    observacao,
    userId: Number(userId),
    condicionanteId: Number(condicionanteId),
    status: statusCondicionante,
  };
  const [condicionante, setCondicionante] = useState(initialValues);
  const [aberto, setAberto] = useState(modalAberto);
  const [acaoEdicao, setAcaoEdicao] = useState("");
  const Swal = useSwalWrapper();
  const toast = (variant, message, type = false) => {
    Swal.fire({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      icon: type ? "success" : "error",
      title: message,
      didOpen: (toast) => {
        toast.style.zIndex = 10000;
      },
      timer: 3000,
    });
  };
  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    try {
      if (acao === "observacao") {
        delete values.status;
        await ApiService.put(
          `/condicionante/observacao/${Number(condicionanteId)}`,
          values
        ).then((response) => {
          if (response.status === 200) {
            toast("success", "Observação atualizada!", "success");
            atualizarCondicionante();
            handleClose();
          }
        });
      } else if(acao === "status") {
        delete values.observacao;
        await ApiService.put(
          `/condicionante/status/${Number(condicionanteId)}`,
          values
        ).then((response) => {
          if (response.status === 200) {
            toast("success", "Status atualizado!", "success");
            atualizarCondicionante();
            handleClose();
          }
        });
      }
    } catch (error) {
      toast("error", "Ocorreu um erro!");
      console.log(error);
    }
  };

  useEffect(() => {
    setAberto(modalAberto);
  }, [modalAberto]);

  useEffect(() => {
    setAcaoEdicao(acao);
  }, [acao]);


  return (
    <Paper>
      <Modal
        open={aberto}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
      >
        <Fade in={modalAberto}>
          <Box
            sx={{
              ...style,
            }}
          >
            <Box>
              <Typography variant={"h2"}>
                Editar {acao === "observacao" ? "observação" : "status"}
              </Typography>
              <Typography variant={"h5"} color={"#A5A5A5"}>
                {nomeCondicionante}
              </Typography>
              <Divider />
              <Formik
                enableReinitialize
                initialValues={condicionante}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  handleChange,
                  handleSubmit,
                  handleBlur,
                  errors,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <Form
                    onSubmit={handleSubmit}
                    style={{
                      width: "100%",
                      maxHeight: 500,
                      marginTop: 20,
                      overflowY: "auto",
                    }}
                  >
                    {console.log(errors)}
                    <Box sx={{ width: "100%" }}>
                      {acaoEdicao === "observacao" ? (
                        <>
                          <Typography my={1}>
                            Status: {STATUS_CONDICIONANTES[statusCondicionante]}
                          </Typography>
                          <TextField
                            name={"observacao"}
                            value={values.observacao}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            multiline
                            sx={{ width: "100%" }}
                            rows={4}
                            type="textarea"
                          />
                        </>
                      ) : (
                        <>
                          <Select
                            name="status"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.status}
                            fullWidth
                          >
                            <MenuItem value="nao_iniciada">
                              Não iniciada
                            </MenuItem>
                            <MenuItem value="em_andamento">
                              Em andamento
                            </MenuItem>
                            <MenuItem value="cumprida">Cumprida</MenuItem>
                            <MenuItem value="atrasada">Atrasada</MenuItem>
                          </Select>
                        </>
                      )}
                    </Box>
                    <input
                      name="userId"
                      value={Number(userId)}
                      type="number"
                      hidden
                    />
                    <input
                      name="condicionanteId"
                      value={Number(condicionanteId)}
                      type="number"
                      hidden
                    />
                    <Box my={2}>
                      <Box mt={3} display="flex" justifyContent="space-evenly">
                        <Button
                          loading={isSubmitting}
                          type="submit"
                          variant="contained"
                          color="success"
                          onClick={handleSubmit}
                        >
                          Salvar
                        </Button>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => {
                            handleClose();
                          }}
                        >
                          Fechar
                        </Button>
                      </Box>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Paper>
  );
};

export default ModalEdicaoCondicionante;
