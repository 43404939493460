import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Chip,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ContratoDetalhesServicos = ({ contrato }) => {
  const servicosAgrupados = contrato?.servicos?.reduce((acc, servico) => {
    const processoId = servico?.processo_administrativo?.id;

    if (!acc[processoId]) {
      acc[processoId] = {
        descricao: servico?.processo_administrativo?.descricao,
        status: servico?.status_andamento_servico?.descricao,
        tarefas: [],
      };
    }

    acc[processoId].tarefas.push(servico?.tarefas_servico?.[0]);
    return acc;
  }, {});

  return (
    <>
      <Grid item xs={4}>
        <Typography variant={"h4"} fontWeight={600}>
          Processos Administrativos e Serviços:
        </Typography>{" "}
      </Grid>
      <Grid item xs={12}>
        {(servicosAgrupados && Object.entries(servicosAgrupados).length > 0) ?
          Object.entries(servicosAgrupados).map(([processoId, processo]) => (
            <Accordion key={processoId} variant="elevation" sx={{ my: 1 }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h4" fontWeight={600}>
                  {processo?.descricao}
                </Typography>
              </AccordionSummary>

              {processo?.tarefas.length > 0 ? (
                <AccordionDetails>
                  <Grid container>
                    <Grid item xs={4}>
                      <Typography variant={"h5"} fontWeight={600}>
                        Serviços:
                      </Typography>{" "}
                    </Grid>
                    <Grid item xs={12}>
                      {processo.tarefas.map((tarefa, index) => (
                        <>
                          <Stack direction="row" gap={2} p={2} key={index}>
                            <Typography fontWeight={600} fontSize={16}>
                              Descrição:
                            </Typography>{" "}
                            <Typography fontSize={16}>
                              {tarefa?.descricao ?? "Descrição indisponível"}
                            </Typography>
                            <Typography fontWeight={600} fontSize={16}>
                              Status:
                            </Typography>{" "}
                            <Typography fontSize={16}>
                              {processo?.status ?? "Status não disponível"}
                            </Typography>
                          </Stack>
                          <Divider my={2} />
                        </>
                      ))}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              ) : (
                <AccordionDetails>
                  Nenhum serviço cadastrado para este processo.
                </AccordionDetails>
              )}
            </Accordion>
          ) ) : <>Nenhum serviço cadastrado para este contrato.</>}
      </Grid>
    </>
  );
};

export default ContratoDetalhesServicos;
