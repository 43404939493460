import { LoadingButton } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Fade,
  Modal,
  Paper,
  Box,
  FormHelperText,
  Chip,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Button,
} from "@mui/material";
import ApiService from "app/services/config";
import { ErrorMessage, Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";

import React, { useEffect, useState, useCallback, useRef } from "react";
import * as yup from "yup";
import MoneyInput from "../MoneyInput/MoneyInput";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import CreatableSelect from "react-select/creatable";
import moment from "moment";

const validationSchema = yup.object().shape({
  descricao: yup.string().required("Campo obrigatório"),
  valor: yup.string().required("Campo obrigatório").typeError("Valor inválido"),
  status_pagamento: yup.string().required("Campo obrigatório"),
  categoria: yup
    .number("Campo obrigatório")
    .required("Campo obrigatório")
    .typeError("Campo obrigatório"),
  data_vencimento: yup
    .date("Campo obrigatório")
    .required("Campo obrigatório")
    .typeError("Data inválida"),
  horario: yup.string().typeError("Valor inválido"),
  status_agendamento: yup.string().nullable(),
  recorrencia: yup
    .string("Campo obrigatório")
    .required("Campo obrigatório")
    .typeError("Campo obrigatório"),
  inicio_recorrencia: yup
    .date("Campo obrigatório")
    .typeError("Data inválida")
    .when("recorrencia", {
      is: (value) => value !== "nenhuma",
      then: yup.date().required("Campo obrigatório"),
      otherwise: yup.date().nullable(),
    }),
  fim_recorrencia: yup
    .date("Campo obrigatório")
    .typeError("Data inválida")
    .when("recorrencia", {
      is: (value) => value !== "nenhuma",
      then: yup.date().required("Campo obrigatório"),
      otherwise: yup.date().nullable(),
    }),
  dias: yup
    .array()
    .of(
      yup.object().shape({
        dia: yup.string().when("recorrencia", {
          is: "semanal",
          then: yup.string().required("Escolha pelo menos um dia"),
        }),
        id: yup.number(),
      })
    )
    .when("recorrencia", {
      is: "semanal",
      then: yup
        .array()
        .min(1, "Escolha pelo menos um dia")
        .required("Campo obrigatório"),
      otherwise: yup.array().nullable(),
    }),
  usuario: yup.number("Campo obrigatório").required("Campo obrigatório"),
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "transparent" : "white",
    border: state.isFocused
      ? "1px solid #005D5F"
      : "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    padding: "8px",
    boxShadow: "none",
    "&:hover": {
      borderColor: "black",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#005D5F" : "white",
    color: state.isSelected ? "white" : "black",
    "&:hover": {
      backgroundColor: "lightblue",
      color: "white",
    },
  }),
};

const DIAS_SEMANA = {
  0: "Domingo",
  1: "Segunda-feira",
  2: "Terça-feira",
  3: "Quarta-feira",
  4: "Quinta-feira",
  5: "Sexta-feira",
  6: "Sábado",
};

const ModalNovaConta = ({
  aberto,
  handleClose,
  data,
  getContasAPagar,
  getContasAPagarAgenda,
}) => {
  // console.log('data', data)
  const id = data?.id;
  const [modalAberto, setModalAberto] = useState(aberto);
  const [categorias, setCategorias] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [isDeleteMode, setIsDeleteMode] = useState(false);
  const initialValues = {
    descricao: data?.title ?? "",
    valor: data?.valor ?? 0,
    status_pagamento: data?.status ?? "",
    categoria: data?.categoria ?? "",
    data_vencimento: data?.data_vencimento ?? "",
    beneficiario: data?.beneficiario ?? "",
    horario: "",
    status_agendamento: "ativo",
    recorrencia: "nenhuma",
    inicio_recorrencia: "",
    fim_recorrencia: "",
    usuario: "",
    dias: [],
    status_pagamento_conta: "",
    descricao_conta: "",
    valor_conta: 0,
    categoria_conta: "",
    beneficiario_conta: "",
  };
  const [contaAPagar, setContaAPagar] = useState(initialValues);
  const [agendamentoInicial, setAgendamentoInicial] = useState({});
  const [idConta, setIdConta] = useState(null);
  const [selectedDays, setSelectedDays] = useState([]);
  const formikRef = useRef();
  // console.log('ModalNovaConta')
  // console.log('data', data)
  // console.log('categorias', categorias)

  const Swal = useSwalWrapper();

  const navigate = useNavigate();

  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const getCategorias = async () => {
    try {
      await ApiService.get("/categoria-contas/all").then((response) => {
        if (response.status === 200) {
          setCategorias(response.data);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleCreateCategoria = useCallback(async (nome) => {
    // console.log('handleCreateCategoria', nome)
    const response = await ApiService.post("/categoria-contas", { nome })
      .then((response) => {
        if (response.status === 201) {
          toast("success", "Criado com sucesso!");
          getCategorias();
          return response.data;
        }
      })
      .catch((error) => {
        toast("error", error.message);
      });
    return response;
  });

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const contaValues = {
        descricao: id ? values.descricao_conta : values.descricao,
        status_pagamento: id ? values.status_pagamento_conta : values.status_pagamento,
        categoria: id ? values.categoria_conta : values.categoria,
        data_vencimento: values.data_vencimento,
        beneficiario: id? values.beneficiario_conta : values.beneficiario,
        valor: id ? parseFloat(String(values.valor_conta).replace(",", ".")) : parseFloat(String(values.valor).replace(",", ".")),
      };

      let agendaValues = {
        descricao: values.descricao,
        data: values.data_vencimento,
        horario: values.horario,
        status_agendamento: values.status_agendamento,
        recorrencia: values.recorrencia,
        inicio_recorrencia: values.inicio_recorrencia,
        fim_recorrencia: values.fim_recorrencia,
        usuario: values.usuario,
        dias: values.dias,
        status_pagamento_conta: id ? values.status_pagamento_conta : values.status_pagamento,
        descricao_conta: id ? values.descricao_conta : values.descricao,
        categoria_conta: id ? values.categoria_conta : values.categoria,
        beneficiario_conta: id ? values.beneficiario_conta : values.beneficiario,
        valor_conta: id ? parseFloat(String(values.valor_conta).replace(",", ".")) : parseFloat(String(values.valor).replace(",", ".")),
      };

      const valoresAgendaDiferentes = Object.keys(agendamentoInicial).some(
        (key) => {
          console.log(key);
          return agendamentoInicial[key] !== agendaValues[key];
        }
      );

      if (id) {
        await ApiService.put(`/contas-a-pagar/${id}`, contaValues).then(
          async (response) => {
            if (response.status === 200) {
              if (valoresAgendaDiferentes) {
                const responseAgenda = await ApiService.put(
                  `/agenda-contas/${response.data?.agenda_conta?.id}`,
                  {
                    ...agendaValues,
                    conta_id: id,
                  }
                );
                if (responseAgenda.status !== 200) {
                  throw new Error("Erro ao atualizar agenda");
                }
              }
              toast("success", "Atualizado com sucesso!", "success");
              getContasAPagar();
              getContasAPagarAgenda();
              handleClose();
              resetForm();
            } else {
              throw new Error("Erro ao atualizar conta");
            }
          }
        );
      } else {
        const responseConta = await ApiService.post(
          "/contas-a-pagar",
          contaValues
        );

        if (responseConta.status === 201) {
          const contaId = responseConta.data.id;

          const responseAgenda = await ApiService.post(`/agenda-contas`, {
            ...agendaValues,
            conta_id: contaId,
          });

          if (responseAgenda?.status === 201) {
            toast("success", "Criado com sucesso!", "success");
            getContasAPagar();
            getContasAPagarAgenda();
            handleClose();
            resetForm();
          } else {
            throw new Error("Erro ao criar agenda");
          }
        } else {
          throw new Error("Erro ao criar conta");
        }
      }
    } catch (error) {
      console.error(error);
      toast("error", error.message);
    }
  };

  const getUsuarios = async () => {
    try {
      await ApiService.get("/users/all").then((response) => {
        if (response.status === 200) {
          setUsuarios(response.data);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleDayChange = (value) => {
    if (selectedDays.some((dia) => dia.dia === value[value.length - 1])) {
      const index = selectedDays.findIndex(
        (dia) => dia.dia === value[value.length - 1]
      );
      const newSelectedDays = [
        ...selectedDays.slice(0, index),
        ...selectedDays.slice(index + 1),
      ];
      setSelectedDays(newSelectedDays);
      formikRef.current.setFieldValue("dias", newSelectedDays);
      if (selectedDays[index].id) {
        //handleDeleteDiaAgenda(selectedDays[index].id);
      }
    } else {
      const newSelectedDays =
        typeof value === "string"
          ? value.split(",")
          : value.map((dia) => (typeof dia === "object" ? dia : { dia }));

      setSelectedDays(newSelectedDays);
      formikRef.current.setFieldValue("dias", newSelectedDays);
    }
  };

  const getContaAPagar = async () => {
    try {
      console.log(`ID CONTA: ${id}`);
      await ApiService.get(`/contas-a-pagar/${data?.id}`).then((response) => {
        if (response.status === 200) {
          const { agenda_conta } = response.data;
          let dias = agenda_conta.dias_agenda_contas_pivot
            .filter((dia) => dia.dias_agenda !== null)
            .map((dia) => {
              if (
                dia?.dias_agenda?.id &&
                dia?.dias_agenda.dia &&
                dia?.dias_agenda !== undefined &&
                dia?.dias_agenda !== null
              ) {
                return {
                  dia: dia?.dias_agenda?.dia,
                  id: dia?.dias_agenda?.id,
                };
              }
            });

          const conta = {
            categoria:
              response.data?.contas_categorias?.[0]?.categoria?.id || "",
            data_vencimento: moment
              .utc(response.data.data_vencimento)
              .format("YYYY-MM-DD"),
            descricao: response.data.descricao,
            beneficiario: response.data.beneficiario,
            status_pagamento: response.data.status_pagamento,
            valor: response.data.valor,
            inicio_recorrencia: moment
              .utc(agenda_conta.inicio_recorrencia)
              .format("YYYY-MM-DD"),
            fim_recorrencia: moment
              .utc(agenda_conta.fim_recorrencia)
              .format("YYYY-MM-DD"),
            horario: agenda_conta.horario,
            recorrencia: agenda_conta.recorrencia,
            status_agendamento: agenda_conta.status_agendamento,
            usuario: agenda_conta.usuario_id,
            dias,
            status_pagamento_conta: response.data?.status_pagamento,
            descricao_conta: response.data?.descricao,
            valor_conta: response.data?.valor,
            categoria_conta:
              response.data?.contas_categorias?.[0]?.categoria?.id || "",
            beneficiario_conta: response.data?.beneficiario,
          };
          setContaAPagar(conta);
          setAgendamentoInicial({
            horario: agenda_conta.horario,
            recorrencia: agenda_conta.recorrencia,
            status_agendamento: agenda_conta.status_agendamento,
            usuario: agenda_conta.usuario_id,
            dias,
            inicio_recorrencia: moment
              .utc(agenda_conta.inicio_recorrencia)
              .format("YYYY-MM-DD"),
            fim_recorrencia: moment
              .utc(agenda_conta.fim_recorrencia)
              .format("YYYY-MM-DD"),
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const modalAlert = () => {
    Swal.fire({
      title: "Tem certeza que deseja apagar a conta selecionada?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        handleDeleteConta();
      }
    });
  };

  const handleDeleteConta = async () => {
    try {
      await ApiService.delete(`/contas-a-pagar/${id}`).then((response) => {
        if (response.status === 200) {
          toast("success", "Deletado com sucesso!", "success");
          getContasAPagar();
          getContasAPagarAgenda();
          handleClose();
        }
      });
    } catch (error) {
      toast("error", "Erro ao apagar conta!");
      console.log(error);
    }
  };

  useEffect(async () => {
    setModalAberto(aberto);
    setIdConta(data?.id);
    if (aberto) {
      await getContaAPagar();
    }
  }, [aberto, data]);

  useEffect(() => {
    getCategorias();
  }, []);

  useEffect(async () => {
    if (idConta) {
    }
  }, [data]);

  useEffect(() => {
    getUsuarios();
    return () => {
      setContaAPagar(initialValues);
    };
  }, [aberto]);

  return (
    <Paper>
      <Modal
        open={modalAberto}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
      >
        <Fade in={modalAberto}>
          <Box sx={style}>
            <Formik
              innerRef={formikRef}
              enableReinitialize
              initialValues={contaAPagar}
              validationSchema={validationSchema}
              validateOnBlur
              validateOnChange
              onSubmit={handleSubmit}
            >
              {({
                values,
                isSubmitting,
                handleChange,
                updateInputValue,
                setFieldValue,
                errors,
                handleBlur,
              }) => (
                <Form width="100%">
                  <Grid container width="100%" spacing={2} p={2}>
                    <Grid item xs={4} lg={4}>
                      <FormGroup>
                        <InputLabel>Descrição</InputLabel>
                        <TextField
                          name="descricao"
                          type="text"
                          value={id ? values.descricao_conta : values.descricao}
                          onChange={(event) => {
                            const descricao = event.target.value;
                            id
                              ? setFieldValue("descricao_conta", descricao)
                              : setFieldValue("descricao", descricao);
                          }}
                          fullWidth
                          placeholder="Descrição"
                        />
                        <ErrorMessage
                          component={"div"}
                          name="descricao"
                          style={{ color: "red" }}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={4} lg={4}>
                      <FormGroup>
                        <MoneyInput
                          defaultValue={id ? values.valor_conta : values.valor}
                          label={"Valor"}
                          name={id ? "valor_conta" : "valor"}
                          setFieldValue={setFieldValue}
                        />
                        <ErrorMessage
                          component={"div"}
                          name="valor"
                          style={{ color: "red" }}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={4} lg={4}>
                      <FormGroup>
                        <InputLabel>Status do Pagamento</InputLabel>
                        <Select
                          value={
                            id
                              ? values.status_pagamento_conta
                              : values.status_pagamento
                          }
                          onChange={(event) => {
                            const status = event.target.value;
                            id
                              ? setFieldValue("status_pagamento_conta", status)
                              : setFieldValue("status_pagamento", status);
                          }}
                          name="status_pagamento"
                        >
                          <MenuItem value="pendente">Pendente</MenuItem>
                          <MenuItem value="atrasado">Atrasado</MenuItem>
                          <MenuItem value="pago">Pago</MenuItem>
                        </Select>
                        <ErrorMessage
                          component={"div"}
                          name="status_pagamento"
                          style={{ color: "red" }}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={4} lg={4} my={2.5}>
                      <CreatableSelect
                        isClearable
                        styles={customStyles}
                        isDisabled={isDeleteMode}
                        name={`categoria`}
                        onChange={(option) => {
                          id
                            ? setFieldValue(
                                `categoria_conta`,
                                option ? option.id : ""
                              )
                            : setFieldValue(
                                `categoria`,
                                option ? option.id : ""
                              );
                        }}
                        onCreateOption={async (value) => {
                          const { id } = await handleCreateCategoria(value);
                          id
                            ? setFieldValue("categoria_conta", id)
                            : setFieldValue(`categoria`, id);
                        }}
                        options={categorias}
                        getOptionLabel={(option) =>
                          option.__isNew__ ? option.label : option.nome
                        }
                        value={categorias.find(
                          (categoria) =>
                            categoria.id ===
                            (id ? values.categoria_conta : values.categoria)
                        )}
                        placeholder={"Selecione a categoria"}
                      />
                    </Grid>
                    <Grid item xs={4} lg={4}>
                      <FormGroup>
                        <InputLabel>Data</InputLabel>
                        <TextField
                          type="date"
                          name="data_vencimento"
                          value={
                            values.data_vencimento
                              ? moment
                                  .utc(values?.data_vencimento)
                                  .format("YYYY-MM-DD")
                              : null
                          }
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          component={"div"}
                          name="data_vencimento"
                          style={{ color: "red" }}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={4} lg={4}>
                      <FormGroup>
                        <InputLabel>Beneficiário</InputLabel>
                        <TextField
                          name="beneficiario"
                          type="text"
                          value={
                            id ? values.beneficiario_conta : values.beneficiario
                          }
                          onChange={(event) => {
                            const beneficiario = event.target.value;
                            console.log(`BENEFICIÁRIO: ${beneficiario}`);
                            console.log(values);
                            id
                              ? setFieldValue(
                                  "beneficiario_conta",
                                  beneficiario
                                )
                              : setFieldValue("beneficiario", beneficiario);
                          }}
                          fullWidth
                          placeholder="Beneficiário"
                        />
                        <ErrorMessage
                          component={"div"}
                          name="beneficiario"
                          style={{ color: "red" }}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={4} lg={4}>
                      <FormGroup>
                        <InputLabel>Horário</InputLabel>
                        <TextField
                          name="horario"
                          type="time"
                          value={values.horario}
                          onChange={handleChange}
                          fullWidth
                          placeholder="Horário"
                        />
                        <FormHelperText>
                          Caso tenha algum horário específico, informar neste
                          campo (OPCIONAL).
                        </FormHelperText>
                        <ErrorMessage
                          component={"div"}
                          name="horario"
                          style={{ color: "red" }}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={4} lg={4}>
                      <FormGroup>
                        <InputLabel>Status do Agendamento</InputLabel>
                        <Select
                          value={values.status_agendamento}
                          onChange={handleChange}
                          name="status_agendamento"
                        >
                          <MenuItem value="ativo">Ativo</MenuItem>
                          <MenuItem value="cancelado">Cancelado</MenuItem>
                          <MenuItem value="concluido">Concluído</MenuItem>
                        </Select>
                        <ErrorMessage
                          component={"div"}
                          name="status_agendamento"
                          style={{ color: "red" }}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={4} lg={4}>
                      <FormGroup>
                        <InputLabel>Colaborador responsável</InputLabel>
                        <Select
                          value={values.usuario}
                          onChange={handleChange}
                          name="usuario"
                          defaultValue={"selecionar"}
                        >
                          <MenuItem value="selecionar">Selecionar</MenuItem>
                          {usuarios &&
                            usuarios.length > 0 &&
                            usuarios.map((usuario) => (
                              <MenuItem value={usuario.id}>
                                {usuario.name}
                              </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>
                          Este colaborador receberá notificações / atualizações
                          sobre o estado da agenda.
                        </FormHelperText>
                        <ErrorMessage
                          component={"div"}
                          name="usuario"
                          style={{ color: "red" }}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={4} lg={4}>
                      <FormGroup>
                        <InputLabel>Recorrência</InputLabel>
                        <Select
                          value={values.recorrencia}
                          onChange={handleChange}
                          name="recorrencia"
                        >
                          <MenuItem value="nenhuma">Nenhuma</MenuItem>
                          <MenuItem value="diaria">Diária</MenuItem>
                          <MenuItem value="semanal">Semanal</MenuItem>
                          <MenuItem value="mensal">Mensal</MenuItem>
                          <MenuItem value="anual">Anual</MenuItem>
                        </Select>
                        <ErrorMessage
                          component={"div"}
                          name="recorrencia"
                          style={{ color: "red" }}
                        />
                      </FormGroup>
                    </Grid>
                    {values.recorrencia !== "nenhuma" && (
                      <>
                        <Grid item xs={4} lg={4}>
                          <FormGroup>
                            <InputLabel>Início da Recorrência</InputLabel>
                            <TextField
                              type="date"
                              name="inicio_recorrencia"
                              value={values.inicio_recorrencia}
                              onChange={handleChange}
                            />
                            <ErrorMessage
                              component={"div"}
                              name="inicio_recorrencia"
                              style={{ color: "red" }}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={4} lg={4}>
                          <FormGroup>
                            <InputLabel>Fim da Recorrência</InputLabel>
                            <TextField
                              type="date"
                              name="fim_recorrencia"
                              value={values.fim_recorrencia}
                              onChange={handleChange}
                            />
                            <ErrorMessage
                              component={"div"}
                              name="fim_recorrencia"
                              style={{ color: "red" }}
                            />
                          </FormGroup>
                        </Grid>
                      </>
                    )}
                    {values.recorrencia === "semanal" && (
                      <Grid item xs={4} lg={4}>
                        <FormGroup>
                          <InputLabel>Dias da Semana</InputLabel>
                          <Select
                            multiple
                            value={values.dias ?? []}
                            name={"dias"}
                            onChange={(event) => {
                              handleDayChange(event.target.value);
                            }}
                            onBlur={handleBlur}
                            input={
                              <OutlinedInput
                                id="select-multiple-chip-checkbox"
                                label="Selecionar Dias"
                              />
                            }
                            renderValue={() => (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                                {values.dias.length === 1 ? (
                                  <Chip
                                    label={DIAS_SEMANA[values.dias[0]?.dia]}
                                  />
                                ) : (
                                  <Chip
                                    label={`${values.dias.length} dias selecionados`}
                                  />
                                )}
                              </Box>
                            )}
                          >
                            {Object.entries(DIAS_SEMANA).map(
                              ([chave, valor], index) => (
                                <MenuItem key={index} value={chave}>
                                  <Checkbox
                                    checked={values?.dias?.some(
                                      (dia) =>
                                        String(dia?.dia) === String(chave)
                                    )}
                                  />
                                  <ListItemText primary={valor} />
                                </MenuItem>
                              )
                            )}
                          </Select>
                          <ErrorMessage
                            component={"div"}
                            name="dias"
                            style={{ color: "red" }}
                          />
                        </FormGroup>
                      </Grid>
                    )}
                  </Grid>
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    style={{ my: 1 }}
                  >
                    <Box display="flex" gap={2}>
                      {" "}
                      <LoadingButton
                        loading={isSubmitting}
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        {"Salvar"}
                      </LoadingButton>
                      {id && (
                        <Button
                          color="error"
                          variant="outlined"
                          onClick={modalAlert}
                        >
                          Deletar conta
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Fade>
      </Modal>
    </Paper>
  );
};

export default ModalNovaConta;
