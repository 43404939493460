import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { useJumboTheme } from "@jumbo/hooks";
import Div from "@jumbo/shared/Div";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { CheckBox } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Paper,
  Select,
  Typography,
  Step,
  StepLabel,
  Stepper,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Grid,
  FormLabel,
  TextareaAutosize,
  Checkbox,
  TextField,
  MenuItem,
} from "@mui/material";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import ApiService from "app/services/config";
import { consultarCEP } from "app/utils/consultarCEP";
import axios from "axios";
import { Select as ReactSelect } from "react-select";
import { useMediaQuery } from "beautiful-react-hooks";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactInputMask from "react-input-mask";
import { useNavigate, useParams } from "react-router-dom";
import { v4 } from "uuid";
import * as yup from "yup";

const steps = ["Etapa 1", "Etapa 2"];

const CAMPOS_OBRIGATORIOS = {
  nomeUnidade: "Nome da Unidade",
  cnpj: "CNPJ",
};

const validationSchema = yup.object().shape({
  userId: yup.number().optional(),
  nomeUnidade: yup.string("Campo obrigatório").required("Campo obrigatório"),
  cnpj: yup
    .string()
    .matches(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/, {
      message: "CNPJ Inválido",
      excludeEmptyString: true,
    })
    .required("Campo obrigatório"),
});

const UnidadeForm = () => {
  const initialValues = {
    userId: "",
    nomeUnidade: "",
    razaoSocial: "",
    cnpj: "",
    inscricaoEstadual: "",
    inscricaoMunicipal: "",
    cep: "",
    logradouro: "",
    numero: "",
    complemento: "",
    bairro: "",
    setorQuadraLote: "",
    estado: "",
    cidade: "",
  };
  const { id } = useParams();
  const userId = localStorage.getItem("id");
  const { theme } = useJumboTheme();
  const Swal = useSwalWrapper();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const [unidade, setUnidade] = useState(initialValues);
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [cnpjFormatado, setCnpjFormatado] = useState();
  const [cepFormatado, setCepFormatado] = useState();
  const [isDadosCarregados, setIsDadosCarregados] = useState(false);

  const [estados, setEstados] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const formikRef = useRef();
  const navigate = useNavigate();

  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const getEstados = useCallback(async () => {
    try {
      await axios
        .get("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
        .then((response) => {
          if (response.status === 200) {
            setEstados(response.data);
          }
        });
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  const getMunicipios = useCallback(
    async (sigla) => {
      await axios
        .get(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${sigla}/municipios
  `
        )
        .then((response) => {
          if (response.status === 200) {
            setMunicipios(response.data);
          }
        });
    },
    [estados]
  );

  const handleChangeCEP = async (cep) => {
    formikRef.current.setFieldValue("cep", cep);
    if (cep.length === 9) {
      try {
        const cepConsultado = await consultarCEP(cep);
        if (cepConsultado) {
          formikRef.current.setFieldValue(
            "estado",
            cepConsultado.dados.stateShortname
          );
          await getMunicipios(cepConsultado.dados.stateShortname);
          formikRef.current.setFieldValue("cidade", cepConsultado.dados.city);
          formikRef.current.setFieldValue(
            "logradouro",
            cepConsultado.dados.street
          );
          formikRef.current.setFieldValue(
            "bairro",
            cepConsultado.dados.district
          );
        }
        setCepFormatado(
          String(cep)
            .slice(0, 5)
            .concat(`-${String(cep).slice(5, 9)}`)
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getUnidade = async () => {
    try {
      await ApiService.get(`/unidade/${id}`).then(async (response) => {
        if (response.status === 200) {
          const { cnpj, cep, estado } = response.data;
          await getMunicipios(estado);
          setUnidade({
            ...response.data,
            userId: Number(userId),
          });
          setCepFormatado(cep);
          setCnpjFormatado(cnpj);
        }
      });
    } catch (error) {}
  };

  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    const allValuesEdit = {
      ...values,
    };
    delete allValuesEdit.deletedAt;
    delete allValuesEdit.createdAt;
    delete allValuesEdit.updatedAt;
    delete allValuesEdit.alvaraLicenca;
    delete allValuesEdit.alvaraLicencaComplementar;
    delete allValuesEdit.id;
    delete allValuesEdit.tipoUnidade;
    delete allValuesEdit.responsavel;
    delete allValuesEdit.is_arquivada;
    delete allValuesEdit.documentos_complementares;
    delete allValuesEdit.licenca;
    delete allValuesEdit.condicionantes;
    delete allValuesEdit.contrato_id;
    if (Object.keys(formikRef.current.errors).length > 0) {
      toast("warning", "Preencha todos os campos obrigatórios!");
      alert("erro teste");
      Object.keys(formikRef.current.errors).map((campo) => {
        toast("warning", `Campo ${CAMPOS_OBRIGATORIOS[campo]} obrigatório!`);
      });
      return;
    } else {
      if (id) {
        ApiService.put(`/unidade/${id}`, allValuesEdit)
          .then((response) => {
            toast("success", "Atualizado com sucesso!");
            if (response.status === 200) {
              navigate("/app/unidades");
            }
          })
          .catch((error) => {
            let message = error.response.data.message;
            toast("error", message);

            if (error.response.data) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log("error", error.message);
            }
          });
      } else {
        await ApiService.post("/unidade/criar", allValuesEdit)
          .then((response) => {
            toast("success", "Criado com sucesso");
            resetForm();
            if (response.status === 201) {
              navigate("/app/unidades");
            }
          })
          .catch((error) => {
            let message = error.response.data.message;
            toast("error", message);

            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log("error", error.message);
            }
          });
      }
    }

    setSubmitting(false);
  };

  useEffect(async () => {
    setIsDadosCarregados(false);
    const fetchData = async () => {
      await Promise.all([getEstados()]);
    };
    fetchData();
    if (id) {
      getUnidade();
    }
    setUnidade((prevDados) => ({
      ...prevDados,
      userId: Number(userId),
    }));
    setIsDadosCarregados(true);
  }, []);

  return (
    <JumboContentLayout
      header={
        <HeaderBreadcrumbs
          id={id}
          title={"Unidades"}
          subtitle={id ? "Editar Unidade" : "Nova Unidade"}
          titleUrl={"/app/unidades"}
        />
      }
      layoutOptions={layoutOptions}
    >
      {isDadosCarregados && (
        <Formik
          innerRef={formikRef}
          initialValues={unidade}
          validationSchema={validationSchema}
          enableReinitialize
          validateOnChange={true}
          onSubmit={handleSubmit}
        >
          {({
            values,
            isSubmitting,
            handleChange,
            handleBlur,
            setFieldValue,
            setSubmitting,
            errors,
            isValid,
          }) => (
            <Form style={{ width: "100%" }} noValidate autoComplete="off">
              <>
                {console.log(errors)}
                <Paper
                  sx={{
                    width: "100%",
                    background: "white",
                    padding: 5,
                    my: 2,
                  }}
                  elevation={1}
                >
                  <Box width={"100%"} my={2}>
                    <Typography variant={"h3"} fontWeight={600}>
                      Dados da Unidade
                    </Typography>
                    <Typography>
                      Os campos marcados com * são obrigatórios
                    </Typography>
                    <Divider sx={{ my: 3 }} />
                  </Box>
                  <Grid
                    container
                    spacing={2}
                    alignContent={"center"}
                    style={{ padding: 10 }}
                  >
                    <Grid item xs={6}>
                      <JumboTextField
                        name="nomeUnidade"
                        onChange={handleChange}
                        fullWidth
                        label={"Nome ou apelido da unidade *"}
                        value={values.nomeUnidade}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <JumboTextField
                        name="razaoSocial"
                        onChange={handleChange}
                        fullWidth
                        label={"Razão Social"}
                        value={values.razaoSocial}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    alignContent={"center"}
                    my={1}
                    style={{ padding: 10 }}
                  >
                    <Grid item xs={4}>
                      <ReactInputMask
                        mask="99.999.999/9999-99"
                        maskChar=""
                        value={cnpjFormatado}
                        onChange={(e) => {
                          setCnpjFormatado(e.target.value);
                          setFieldValue("cnpj", e.target.value);
                        }}
                        onBlur={handleBlur}
                      >
                        {(inputProps) => (
                          <TextField
                            fullWidth
                            name="cnpj"
                            label="CNPJ *"
                            onBlur={handleBlur}
                            sx={{ background: "#ffffff" }}
                            {...inputProps}
                          />
                        )}
                      </ReactInputMask>
                      <ErrorMessage
                        component={"div"}
                        name="cnpj"
                        style={{ color: "red" }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <JumboTextField
                        name="inscricaoEstadual"
                        onChange={handleChange}
                        fullWidth
                        label={"Inscrição estadual"}
                        values={values.inscricaoEstadual}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <JumboTextField
                        name="inscricaoMunicipal"
                        onChange={handleChange}
                        fullWidth
                        label={"Inscrição municipal"}
                        values={values.inscricaoMunicipal}
                      />
                    </Grid>
                  </Grid>
                </Paper>
                <Paper
                  sx={{
                    width: "100%",
                    background: "white",
                    padding: 5,
                    my: 2,
                  }}
                  elevation={1}
                >
                  <Box width={"100%"} my={2}>
                    <Typography variant={"h3"} fontWeight={600}>
                      Endereço da Unidade
                    </Typography>
                    <Divider sx={{ my: 3 }} />
                  </Box>
                  <Grid
                    container
                    spacing={2}
                    alignContent={"center"}
                    style={{ padding: 10 }}
                  >
                    <Grid item xs={3} my={-2.6}>
                      <ReactInputMask
                        mask="99999-999"
                        maskChar=""
                        value={cepFormatado}
                        onChange={(e) => {
                          handleChangeCEP(e.target.value);
                          setCepFormatado(e.target.value);
                          setFieldValue("cep", e.target.value);
                        }}
                      >
                        {(inputProps) => (
                          <TextField
                            fullWidth
                            name="cep"
                            label="CEP"
                            sx={{ background: "#ffffff" }}
                            {...inputProps}
                          />
                        )}
                      </ReactInputMask>
                    </Grid>
                    <Grid item xs={9} my={-2.6}>
                      <JumboTextField
                        name="logradouro"
                        onChange={handleChange}
                        fullWidth
                        label={"Logradouro"}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    my={1}
                    alignContent={"center"}
                    style={{ padding: 10 }}
                  >
                    <Grid item xs={3}>
                      <JumboTextField
                        name="numero"
                        onChange={handleChange}
                        fullWidth
                        label={"Número"}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <JumboTextField
                        name="complemento"
                        onChange={handleChange}
                        fullWidth
                        label={"Complemento"}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <JumboTextField
                        name="bairro"
                        onChange={handleChange}
                        fullWidth
                        label={"Bairro"}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <JumboTextField
                        name="setorQuadraLote"
                        onChange={handleChange}
                        fullWidth
                        label={"Setor/Quadra/Lote"}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    my={1}
                    alignContent={"center"}
                    style={{ padding: 10 }}
                  >
                    <Grid item xs={6}>
                      <FormLabel id="estado-select">Estado</FormLabel>
                      <Select
                        id="estado-select"
                        name="estado"
                        label={"Estado"}
                        onChange={(event) => {
                          getMunicipios(event.target.value);
                          setFieldValue("estado", event.target.value);
                        }}
                        value={values.estado}
                        fullWidth
                      >
                        {estados.map((estado) => (
                          <MenuItem key={v4()} value={estado.sigla}>
                            {estado.nome}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item xs={6}>
                      <FormLabel id="cidade-select">Cidade</FormLabel>
                      <Select
                        id="cidade-select"
                        name="cidade"
                        label={"Cidade"}
                        onChange={handleChange}
                        value={values.cidade}
                        fullWidth
                      >
                        {municipios.map((municipio) => (
                          <MenuItem key={v4()} value={municipio.nome}>
                            {municipio.nome}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  </Grid>
                </Paper>
              </>
              <Paper
                sx={{ width: "100%", background: "white", padding: 5, my: 2 }}
                elevation={1}
              >
                <Div sx={{ width: "100%" }}>
                  <Div sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <input
                      hidden
                      type="number"
                      name="userId"
                      value={Number(userId)}
                    />

                    <LoadingButton
                      loading={isSubmitting}
                      type="submit"
                      style={{ margin: "auto", display: "block" }}
                      variant="contained"
                      color="primary"
                    >
                      {id ? "Atualizar" : "Criar"}
                    </LoadingButton>
                  </Div>
                </Div>
              </Paper>
            </Form>
          )}
        </Formik>
      )}
    </JumboContentLayout>
  );
};

export default UnidadeForm;
