import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Stack,
  Toolbar,
  useMediaQuery,
  Chip,
} from "@mui/material";
import ApiService from "app/services/config";
import React, { useState, useEffect, useCallback, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import { LoadingButton } from "@mui/lab";
import MUIDataTable from "mui-datatables";
import { PermissionContext } from "app/contexts/PermissionContext";

const PlanoContasList = () => {
  const { theme } = useJumboTheme();
  const location = useLocation();
  const [planoContas, setPlanoContas] = useState([]);
  const [selectedTiposServicoIds, setSelectedTiposServicoIds] = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");
  const [count, setCount] = useState("");

  const navigate = useNavigate();

  const { hasPermission } = useContext(PermissionContext);

  if(!hasPermission('Financeiro', 'read')) {
    navigate('/app');
  }

  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const Swal = useSwalWrapper();
  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: "Fechar",
    });
  };

  const modalAlert = (id) => {
    Swal.fire({
      title: "Tem certeza que deseja apagar?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deletePlanoConta(id);
      }
    });
  };

  const handleSearch = async (newSearchTerm) => {
    if (searchTerm === "" || searchTerm === null || searchTerm === undefined) {
      setPage(1);
    }
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  const deletePlanoConta = useCallback(async (id) => {
    try {
      ApiService.delete(`/planocontas/${id}`)
        .then((response) => {
          toast("success", "Removido com sucesso");
          getPlanoContas();
        })
        .catch((error) => {
          const message = error.response.data.message;
          toast("error", message);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getPlanoContas = useCallback(async () => {
    try {
      let apiUrl = `planocontas/?page=${
        page + 1
      }&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}`;

      if (searchTerm) {
        apiUrl += `&searchTerm=${searchTerm}`;
      }

      ApiService.get(apiUrl)
        .then((response) => {
          const initialData = response.data.planoContas.map((data) => ({
            ...data,
            isSelected: selectedTiposServicoIds.includes(data.id),
            plano_mae_desc: data.plano_conta_id ? response.data.categories.find(category => category.id === data.plano_conta_id).plano_mae_desc : null,
            plano_mae_id: data.plano_conta_id ? response.data.categories.find(category => category.id === data.plano_conta_id).plano_mae_id : null
          }));
          setPlanoContas(initialData);
          setCount(response.data.total);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [page, perPage, sortBy, sortOrder, searchTerm, selectedTiposServicoIds]);

  useEffect(() => {
    getPlanoContas();
  }, [getPlanoContas]);

  // const handleCheckboxChange = (planoContaId) => {
  //   setPlanoContas((prevTipoServico) =>
  //     prevTipoServico.map((tipoServico) =>
  //       tipoServico.id === planoContaId
  //         ? { ...tipoServico, isSelected: !tipoServico.isSelected }
  //         : tipoServico
  //     )
  //   );

  //   setSelectedTiposServicoIds((prevSelectedIds) => {
  //     if (prevSelectedIds.includes(planoContaId)) {
  //       return prevSelectedIds.filter((id) => id !== planoContaId);
  //     } else {
  //       return [...prevSelectedIds, planoContaId];
  //     }
  //   });
  // };

  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "codigo",
      label: "Código",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "descricao",
      label: "Descrição",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "plano_mae_desc",
      label: "Categoria",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const categories = tableMeta.rowData[2];
          const explodedCategories = typeof categories == 'string' ? categories.split(',') : [];
          const colors = ['#005D5F', '#007d80', '#00afb3', '#00c8cc']

          return (
            explodedCategories.map((element, index) => {
              return <Chip color='primary' style={{ backgroundColor: colors[index], margin: 3 }} label={element} />
            })
          );
        },
      },
    },
    {
      name: "action",
      label: "Ações",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const planoContaId = tableMeta.rowData[0];
          return (
            <Box display={"flex"} gap={"1rem"}>
              <Link
                to={`/app/editar-plano-conta/${planoContaId}`}
                style={{ textDecoration: "none" }}
              >
                <Button
                  color="info"
                  variant="contained"
                  size="small"
                  disableElevation
                >
                  <VisibilityIcon />
                </Button>
              </Link>
              <Button
                color="error"
                variant="contained"
                size="small"
                onClick={() => modalAlert(planoContaId)}
              >
                <DeleteIcon />
              </Button>
            </Box>
          );
        },
      },
    },
  ];

  const options = {
    changeRowsPerPage: perPage,
    filterType: "dropdown",
    filter: false,
    selectableRows: "none",
    // searchAlwaysOpen: true,
    searchable: true,
    serverSide: true,
    page: page,
    count: count,
    selectToolbarPlacement: "above",

    onTableChange: (action, tableState) => {
      console.log(action);
      switch (action) {
        case "changePage":
          setPage(tableState.page);
          break;
        case "sort":
          setSortBy(tableState.sortOrder.name);
          setSortOrder(tableState.sortOrder.direction);
          break;
        case "search":
          handleSearch(tableState.searchText);
          break;
        case "changeRowsPerPage":
          setPerPage(tableState.rowsPerPage);
          break;
        default:
          console.log("action not handled.");
      }
    },
    customToolbar: ({ displayData }) => (
      <>
        {selectedTiposServicoIds.length > 0 ? (
          <Toolbar>
            <Box
              display={"flex"}
              gap={"1rem"}
              justifyContent={"flex-end"}
              width={"100%"}
            ></Box>
          </Toolbar>
        ) : null}
      </>
    ),
    textLabels: {
      body: {
        noMatch: "Nenhum resultado encontrado",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: "Próxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Itens por Página:",
        displayRows: "of",
      },
    },
  };

  return (
    <JumboContentLayout
      header={<PageHeader title={"Plano de Contas"} />}
      layoutOptions={layoutOptions}
    >
      {lg && (
        <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}></Stack>
      )}

      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <Link
          to="/app/novo-plano-conta"
          state={{ backUrl: location.pathname }}
          style={{ textDecoration: "none" }}
        >
          <LoadingButton
            color="success"
            type="submit"
            variant="contained"
            size="large"
            sx={{
              maxWidth: { md: "450px", mt: "1rem" },
              marginBottom: "2rem",
            }}
          >
            Novo Plano de Contas
          </LoadingButton>
        </Link>
      </Grid>

      <React.Fragment>
        <MUIDataTable
          title={""}
          data={planoContas}
          columns={columns}
          options={options}
        />
      </React.Fragment>
    </JumboContentLayout>
  );
};

export default PlanoContasList;
