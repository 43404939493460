import { Avatar, Box, Chip, Grid, Stack, Typography } from "@mui/material";
import moment from "moment";
import React from "react";

const DetalhesContrato = ({contrato}) => {
  return (
    <>
      <Grid item xs={4}>
        <Typography variant={"h4"} fontWeight={600}>
          Dados do Contrato:
        </Typography>{" "}
      </Grid>
      <Grid item xs={4}>
        <Typography fontWeight={600} fontSize={16}>
          Código:
        </Typography>{" "}
        <Typography fontSize={16}>{contrato?.descricao}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" gap={2}>
          <Typography fontWeight={600} fontSize={16}>
            Data de Criação:
          </Typography>{" "}
          <Typography fontSize={16}>
            {moment.utc(contrato?.createdAt).format("DD/MM/YYYY")}
          </Typography>
          <Typography fontWeight={600} fontSize={16}>
            Última Modificação:
          </Typography>{" "}
          <Typography fontSize={16}>
            {moment.utc(contrato?.updated).format("DD/MM/YYYY")}
          </Typography>
          <Typography fontWeight={600} fontSize={16}>
            Colaborador que criou:
          </Typography>{" "}
          <Typography fontSize={16}>
            {contrato?.colaborador_que_criou?.name}
          </Typography>
        </Stack>
      </Grid>
      {contrato?.dataVencimento && contrato?.prazoRenovacao && (
        <Grid item xs={8}>
          <Stack direction="row" gap={2}>
            <Typography fontWeight={600} fontSize={16}>
              Data de Vencimento:
            </Typography>{" "}
            <Typography fontSize={16}>
              {moment.utc(contrato?.dataVencimento).format("DD/MM/YYYY")}
            </Typography>
            <Typography fontWeight={600} fontSize={16}>
              Prazo de Renovação:
            </Typography>{" "}
            <Typography fontSize={16}>
              {moment.utc(contrato?.prazoRenovacao).format("DD/MM/YYYY")}
            </Typography>
          </Stack>
        </Grid>
      )}
      <Grid item xs={4}>
        <Typography fontWeight={600} fontSize={16}>
          Tipo de Contrato:
        </Typography>{" "}
        {contrato.tipocontratocontrato?.map((tipo) => (
          <Box>
            <Typography>{tipo?.tipos_contratos?.descricao}</Typography>
          </Box>
        ))}
      </Grid>
      <Grid item xs={4}>
        <Typography fontWeight={600} fontSize={16}>
          Unidades:
        </Typography>{" "}
        {contrato.unidades?.map((unidade) => (
          <Box>
            <Typography>{unidade?.nomeUnidade}</Typography>
          </Box>
        ))}
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" gap={2} width="100%">
          <Stack width="50%" direction="row" gap={2} alignItems={"center"}>
            <Typography fontWeight={600} fontSize={16}>
              Elaboradores do orçamento:
            </Typography>{" "}
            {contrato?.elaboradores_orcamento?.map((elaborador) => (
              <Avatar
                title={elaborador?.usuario?.name}
                sx={{ width: "24px", height: "24px" }}
              >
                {String(elaborador?.usuario?.name).charAt(0).toUpperCase()}
              </Avatar>
            ))}
          </Stack>
          <Stack width="50%" direction="row" gap={2}>
            <Typography fontWeight={600} fontSize={16}>
              Orientadores:
            </Typography>{" "}
            {contrato?.orientadores?.map((orientador) => (
              <Avatar
                sizes="small"
                title={orientador?.usuario?.name}
                sx={{ width: "24px", height: "24px" }}
              >
                {String(orientador?.usuario?.name).charAt(0).toUpperCase()}
              </Avatar>
            ))}
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={4}>
        <Typography fontWeight={600} fontSize={16}>
          Status Orçamentário:
        </Typography>{" "}
        <Chip label={contrato?.status_orcamentario?.descricao} />
      </Grid>
    </>
  );
};

export default DetalhesContrato;
