import ApiService from "app/services/config";
import moment from "moment";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const gerarPDF = async (tipoContrato) => {
  let widths = [];
  let bodyTable = [
    {
      text: "Contrato",
      fillColor: "#4D5525",
      color: "#FFFFFF",
      alignment: "center",
      margin: [0, 5],
    },
    {
      text: "Cliente",
      fillColor: "#4D5525",
      color: "#FFFFFF",
      alignment: "center",
      margin: [0, 5],
    },
    {
      text: "Processos",
      fillColor: "#4D5525",
      color: "#FFFFFF",
      alignment: "center",
      margin: [0, 5],
    },
    {
      text: "Colaboradores",
      fillColor: "#4D5525",
      color: "#FFFFFF",
      alignment: "center",
      margin: [0, 5],
    },
  ];

  const tituloDocumento = () => {
    switch (tipoContrato) {
      case "fixo":
        widths = ["*", "*", "*", "*"];
        return "Contratos fixos";
      case "padrao":
        widths = ["*", "*", "*", "*"];
        return "Contratos padrão";
      case "renovacao":
        widths = ["*", "*", "*", "*", "*", "*", "*"];
        bodyTable.push(
          {
            text: "Data de Vencimento",
            fillColor: "#4D5525",
            color: "#FFFFFF",
            alignment: "center",
            margin: [0, 5],
          },
          {
            text: "Prazo de Renovação",
            fillColor: "#4D5525",
            color: "#FFFFFF",
            alignment: "center",
            margin: [0, 5],
          },
          {
            text: "Status",
            fillColor: "#4D5525",
            color: "#FFFFFF",
            alignment: "center",
            margin: [0, 5],
          }
        );
        return "Contratos de renovação";
      case "todos":
        widths = ["*", "*", "*", "*"];
        return "Contratos";
    }
  };

  const obterUrlImagem = async (path) => {
    const blogImage = await fetch(process.env.REACT_APP_ROOT_URL + path).then(
      (data) => data.blob()
    );
    let dataUrl = await new Promise((resolve) => {
      let reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(blogImage);
    });
    return dataUrl;
  };

  let urlImagemEsquerda = await obterUrlImagem("/icone_ambiental.png");
  let urlImagemDireita = await obterUrlImagem("/nome_ambiental.png");
  let urlImagemHeaderPortrait = await obterUrlImagem(
    "/header_ambiental_portrait.png"
  );
  let urlImagemHeaderLandscape = await obterUrlImagem(
    "/header_ambiental_landscape.png"
  );

  await Promise.all([
    (async () => {
      urlImagemEsquerda = await obterUrlImagem("/icone_ambiental.png");
    })(),
    (async () => {
      urlImagemDireita = await obterUrlImagem("/nome_ambiental.png");
    })(),
  ]);

  try {
    let apiUrl = `/contratos/all/?tipoContrato=${tipoContrato}`;
    const response = await ApiService.get(apiUrl);
    if (response.status === 200) {
      let bodyData = response.data.map((contrato) => {
        let rowData = [
          { text: contrato.descricao, alignment: "center", margin: [0, 5] },
          {
            text: contrato.Cliente.user[0]?.user?.name,
            alignment: "center",
            margin: [0, 5],
          },
          {
            stack: contrato.servicos
              ? contrato.processos_contratos?.map((processo) => {
                  return {
                    text: processo.processo_administrativo.descricao,
                    margin: [0, 2],
                  };
                })
              : [{ text: "Nenhuma", margin: [0, 2] }],
            alignment: "center",
            margin: [0, 5],
          },
          {
            stack: contrato.servicos
              ? contrato.servicos.flatMap((servico) =>
                  servico.servicocolaborador
                    ? servico.servicocolaborador.map((colab) => ({
                        text: colab.users.name,
                        margin: [0, 2],
                      }))
                    : [{ text: "Nenhum", margin: [0, 2] }]
                )
              : [{ text: "Nenhum", margin: [0, 2] }],
            alignment: "center",
            margin: [0, 5],
          },
        ];

        if (tipoContrato === "renovacao") {
          rowData.push(
            {
              text: contrato.dataVencimento
                ? moment(contrato.dataVencimento).format("DD/MM/YYYY")
                : "N/I",
              alignment: "center",
              margin: [0, 5],
            },
            {
              text: contrato.prazoRenovacao
                ? moment(contrato.prazoRenovacao).format("DD/MM/YYYY")
                : "N/I",
              alignment: "center",
              margin: [0, 5],
            },
            {
              text: "FINALIZADO",
              alignment: "center",
              margin: [0, 5],
            }
          );
        }

        return rowData;
      });

      const DOCUMENTO_PDF_DEFINICOES = {
        footer: function (currentPage, pageCount) {
          return {
            alignment: "center",
            fontSize: 10,
            bold: true,
            columns: [
              {
                text: currentPage.toString() + " de " + pageCount,
                alignment: "center",
              },
              { text: "", width: "*" },
              {
                text: `Documento gerado em ${new Date().toLocaleDateString()} às ${new Date().toLocaleTimeString()}`,
                alignment: "center",
                width: "*",
              },
              { text: "", width: "*" },
            ],
          };
        },
        header: {
          image:
            tipoContrato === "renovacao"
              ? urlImagemHeaderLandscape
              : urlImagemHeaderPortrait,
          width: tipoContrato === "renovacao" ? 750 : 500,
          // columns: [
          //   {
          //     image: urlImagemEsquerda,
          //     width: 40,
          //     alignment: 'center',
          //     margin: [0, 0, 0, 0],
          //   },
          //   {
          //     text: ' ',
          //     width: '*',
          //   },
          //   {
          //     image: urlImagemDireita,
          //     width: 78,
          //     alignment: 'center',
          //     margin: [0, 0, 0, 0],
          //   },
          // ],
          // columnGap: 10,
          margin: [40, 20, 40, 20],
        },
        pageOrientation:
          tipoContrato === "renovacao" ? "landscape" : "portrait",
        content: [
          {
            text: tituloDocumento(),
            fontSize: 16,
            alignment: "left",
            margin: [0, 80, 0, 10],
          },
          {
            table: {
              headerRows: 1,
              widths,
              margin: 3,
              body: [bodyTable, ...bodyData],
            },
          },
        ],
      };

      pdfMake.createPdf(DOCUMENTO_PDF_DEFINICOES).open();
    }
  } catch (error) {
    console.log(error);
  }
};
