import React, { useCallback, useContext, useEffect } from "react";
import JumboNavIdentifier from "@jumbo/components/JumboVerticalNavbar/JumboNavIdentifier";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import { SIDEBAR_VIEWS } from "@jumbo/utils/constants/layout";
import List from "@mui/material/List";
import PropTypes from "prop-types";
import { PermissionContext } from "app/contexts/PermissionContext";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";

const JumboVerticalNavbar = ({ items }) => {
  const { sidebarOptions } = useJumboLayoutSidebar();
  const { permissoes } = useJumboAuth();

  const isMiniAndClosed = React.useMemo(() => {
    return sidebarOptions?.view === SIDEBAR_VIEWS.MINI && !sidebarOptions?.open;
  }, [sidebarOptions.view, sidebarOptions.open]);

  return (
    <List
      disablePadding
      sx={{
        mr: isMiniAndClosed ? 0 : 2,
        pb: 2,
        my: 5
      }}
    >
      <>
        {items.map((item, index) => (
          <React.Fragment key={index}>
            <>
              {permissoes?.some(
                (permissao) => (permissao.Page.name === item.label && permissao.read)
              ) && (
                <JumboNavIdentifier
                  translate
                  item={item}
                  key={index}
                />
              )}
            </>
          </React.Fragment>
        ))}
      </>
    </List>
  );
};

JumboVerticalNavbar.defaultProps = {
  items: PropTypes.array,
  translate: false,
};

export default JumboVerticalNavbar;
