import React, { useContext, useEffect, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Chip,
  Grid,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import ApiService from "app/services/config";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { PermissionContext } from "app/contexts/PermissionContext";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import DateRangeIcon from '@mui/icons-material/DateRange';
import ModalNovaConta from "../ModalNovaConta/ModalNovaConta";


const AccordionListaContasAPagar = ({
  page,
  setPage,
  perPage,
  setPerPage,
  sortBy,
  setSortBy,
  sortOrder,
  setSortOrder,
  searchTerm,
  setSearchTerm,
  count,
  setCount,
  contasAPagar,
  setContasAPagar,
  selectedContasIds,
  setSelectedContasIds,
  handleDeleteContas,
}) => {
  const [todasAsContasSelecionadas, setTodasAsContasSelecionadas] =
    useState(false);
  const [mostrarBotaoDeletarMuitos, setMostrarBotaoDeletarMuitos] =
    useState(false);
  const [isModalNovaContaAberto, setIsModalNovaContaAberto] = useState(false);
  const [selectedConta, setSelectedConta] = useState(null);
  
  const { hasPermission } = useContext(PermissionContext);
  const permission = hasPermission("Financeiro", "create");

  if (!hasPermission("Financeiro", "read")) {
    navigate("/app");
  }

  const navigate = useNavigate();

  const handleSearch = async (newSearchTerm) => {
    if (searchTerm === "" || searchTerm === null || searchTerm === undefined) {
      setPage(1);
    }
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  const handleSelecionarTodasAsContas = () => {
    const todosIdsContas = contasAPagar.map((conta) => conta.id);
    if (!todasAsContasSelecionadas) {
      setSelectedContasIds(todosIdsContas);
    } else {
      setSelectedContasIds([]);
    }
    setTodasAsContasSelecionadas(!todasAsContasSelecionadas);
  };

  const handleOpenModalNovaConta = (value) => {
    const conta = {
      id: value?.rowData?.[1],
      descricao: value?.rowData?.[2],
      valor: value?.rowData?.[3],
      status_pagamento: value?.rowData?.[4],
      data_vencimento: value?.rowData?.[5],
      categoria: value?.rowData?.[7]?.[0]?.categoria?.id,
      beneficiario: value?.rowData?.[6],
    }

    setSelectedConta(conta)
    setIsModalNovaContaAberto(!isModalNovaContaAberto);
  };

  const handleCheckboxChange = (idConta) => {
    setContasAPagar((contasPrevias) =>
      contasPrevias.map((conta) =>
        conta.id === idConta
          ? { ...conta, isSelected: !conta.isSelected }
          : conta
      )
    );

    setSelectedContasIds((idsSelecionadosPrevios) => {
      const estaSelecionada = idsSelecionadosPrevios.includes(idConta);
      const novosIdsSelecionados = estaSelecionada
        ? idsSelecionadosPrevios.filter((id) => id !== idConta)
        : [...idsSelecionadosPrevios, idConta];

      setMostrarBotaoDeletarMuitos(novosIdsSelecionados.length > 0);

      return novosIdsSelecionados;
    });

    setTodasAsContasSelecionadas(false);
  };

  const columns = [
    {
      name: "selecionar",
      label: "Selecionar",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const idConta = tableMeta.rowData[1];
          return (
            <Checkbox
              checked={selectedContasIds.includes(idConta)}
              onChange={() => handleCheckboxChange(idConta)}
            />
          );
        },
      },
    },
    {
      name: "id",
      label: "ID",
      options: {
        display: false,
        sort: false,
      },
    },
    {
      name: "descricao",
      label: "Descrição",
      options: {
        display: true,
        sort: true,
      },
    },
    {
      name: "valor",
      label: "Valor",
      options: {
        display: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <>
              {Number(value).toLocaleString("pt-BR", {
                currency: "BRL",
                style: "currency",
              })}
            </>
          );
        },
      },
    },
    {
      name: "status_pagamento",
      label: "Status de Pagamento",
      options: {
        display: true,
        sort: true,
        customBodyRender: (value) => {
          const MAPEAMENTO_STATUS = {
            pago: {
              nome: "Pago",
              background: "#4CAF50",
              color: "#FFF",
            },
            pendente: {
              nome: "Pendente",
              background: "#FFC107",
              color: "#0a0a0a",
            },
            atrasado: {
              nome: "Atrasado",
              background: "#F44336",
              color: "#fff",
            },
          };
          return (
            <Chip
              sx={{
                background: MAPEAMENTO_STATUS[value].background,
                color: MAPEAMENTO_STATUS[value].color,
              }}
              label={MAPEAMENTO_STATUS[value].nome}
            />
          );
        },
      },
    },
    {
      name: "data_vencimento",
      label: "Data",
      options: {
        display: true,
        sort: true,
        enableNestedAccess: ".",
        customBodyRender: (value) => {
          return (
            <Typography>{moment.utc(value).format("DD/MM/YYYY")}</Typography>
          );
        },
      },
    },
    {
      name: "beneficiario",
      label: "Beneficiário",
      options: {
        display: true,
        sort: true,
        enableNestedAccess: ".",
        customBodyRender: (value) => {
          return <Typography>{value ?? "N/I"}</Typography>;
        },
      },
    },
    {
      name: "contas_categorias",
      label: "Categoria",
      options: {
        display: true,
        sort: true,
        enableNestedAccess: ".",
        customBodyRender: (value) => {
          return <Typography>{value?.[0]?.categoria.nome ?? "N/I"}</Typography>;
        },
      },
    },
    {
      name: "action",
      label: "Ações",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const contaId = tableMeta.rowData[1];
          return (
            <Box display={"flex"} gap={"1rem"}>
              {/* <Tooltip title="Editar Conta a Pagar">
                <EditIcon
                  cursor="pointer"
                  color="info"
                  disabled={!permission}
                  onClick={() => handleOpenModalNovaConta(tableMeta)}
                />
              </Tooltip>
              <ModalNovaConta
                aberto={isModalNovaContaAberto}
                handleClose={handleOpenModalNovaConta}
                data={selectedConta}
              />
              <Tooltip title="Gerenciar Agenda da Conta">
                <DateRangeIcon
                  cursor="pointer"
                  color="success"
                  disabled={!permission}
                  onClick={() => {
                    navigate(`/app/conta-a-pagar/${contaId}/agenda`);
                  }}
                />
              </Tooltip> */}
              <Tooltip title="Excluir Conta a Pagar">
                <DeleteIcon
                  cursor="pointer"
                  color="error"
                  onClick={() => {
                    handleDeleteContas("unica", contaId);
                  }}
                />
              </Tooltip>
            </Box>
          );
        },
      },
    },
  ];

  const options = {
    changeRowsPerPage: perPage,
    filterType: "dropdown",
    filter: false,
    selectableRows: "none",
    searchAlwaysOpen: true,
    searchable: true,
    serverSide: true,
    page: page,
    count: count,
    selectToolbarPlacement: "above",

    onTableChange: (action, tableState) => {
      // console.log(action);
      switch (action) {
        case "changePage":
          setPage(tableState.page);
          break;
        case "sort":
          setSortBy(tableState.sortOrder.name);
          setSortOrder(tableState.sortOrder.direction);
          break;
        case "search":
          handleSearch(tableState.searchText);
          break;
        case "changeRowsPerPage":
          setPerPage(tableState.rowsPerPage);
          break;
        default:
          // console.log("action not handled.");
      }
    },
    customToolbar: ({ displayData }) => (
      <>
        {selectedContasIds.length > 0 ? (
          <Toolbar>
            <Box
              display={"flex"}
              gap={"1rem"}
              justifyContent={"flex-end"}
              width={"100%"}
            ></Box>
          </Toolbar>
        ) : null}
      </>
    ),
    textLabels: {
      body: {
        noMatch: "Nenhum resultado encontrado",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: "Próxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Itens por Página:",
        displayRows: "of",
      },
    },
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
        <Typography>Lista de Contas a Pagar</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid
          container
          spacing={2}
          justifyContent="flex-end"
          alignItems="center"
          my={1}
        >
          {selectedContasIds.length > 0 && (
            <Grid item>
              <span style={{ margin: 10 }}>
                {selectedContasIds.length} conta(s) selecionado(s)
              </span>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={() => {
                  handleDeleteContas("multiplas");
                  setTodasAsContasSelecionadas(false);
                }}
                disabled={!permission}
              >
                Excluir Selecionados
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSelecionarTodasAsContas}
            >
              {todasAsContasSelecionadas
                ? "Desmarcar Todos"
                : "Selecionar Todos"}
            </Button>
          </Grid>
        </Grid>
        <MUIDataTable columns={columns} data={contasAPagar} options={options} />
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionListaContasAPagar;
