import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { LoadingButton } from "@mui/lab";
import { Box, Grid, Paper, Stack, useMediaQuery } from "@mui/material";
import { PermissionContext } from "app/contexts/PermissionContext";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import ApiService from "app/services/config";
import { Form, Formik } from "formik";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  descricao: yup.string("Insira uma descrição").required("Campo obrigatório"),
});

const SegmentosForm = () => {
  const initialValues = {
    descricao: "",
  };
  const { id } = useParams();
  const [segmento, setSegmento] = useState(initialValues);
  const [clientes, setClientes] = useState([]);

  const Swal = useSwalWrapper();
  const navigate = useNavigate();

  const { hasPermission } = useContext(PermissionContext);

  if(!hasPermission('Administrativo', 'read')) {
    navigate('/app');
  }
  const { theme } = useJumboTheme();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const getSegmento = useCallback(async () => {
    try {
      ApiService.get(`/segmentos/${id}`)
        .then((response) => {
          setSegmento(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getClientes = async () => {
    try {
      await ApiService.get(`/clientes/segmento/${id}`).then((response) => {
        setClientes(response.data);
      });
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    if (id) {
      getSegmento();
      getClientes();
    }
  }, []);

  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    delete values[0];
    delete values.id;
    console.log(values);
    if (id) {
      ApiService.put(`/segmentos/${id}`, values)
        .then((response) => {
          toast("success", "Atualizado com sucesso!");
          if (response.status === 200) {
            navigate("/app/listar-segmentos");
          }
        })
        .catch((error) => {
          let message = error.response.data.message;
          toast("error", message);

          if (error.response.data) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } else {
      await ApiService.post("/segmentos", values)
        .then((response) => {
          toast("success", "Criado com sucesso");
          resetForm();
          if (response.status === 201) {
            navigate("/app/listar-segmentos");
          }
        })
        .catch((error) => {
          let message = error.response.data.message;
          toast("error", message);

          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    }
    setSubmitting(false);
  };

  return (
    <JumboContentLayout
      header={
        <HeaderBreadcrumbs
          id={id}
          title={"Segmentos"}
          subtitle={id ? "Editar" : "Cadastro de Segmentos"}
          titleUrl={"/app/listar-segmentos"}
        />
      }
      layoutOptions={layoutOptions}
    >
      {lg && (
        <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}></Stack>
      )}
      <Paper sx={{ p: "15px" }}>
        <Formik
          initialValues={segmento}
          validationSchema={validationSchema}
          enableReinitialize
          validateOnChange={false}
          onSubmit={handleSubmit}
        >
          {({ values, isSubmitting, handleChange, setFieldValue, errors }) => (
            <Form style={{ width: "100%" }} noValidate autoComplete="off">
              <Grid container alignContent={"center"} spacing={2}>
                <Grid item lg={5} xs={12}>
                  <JumboTextField
                    fullWidth
                    name="descricao"
                    label="Descrição"
                    required
                    value={values.descricao}
                    onChange={handleChange}
                    sx={{ background: "#ffffff" }}
                  />
                </Grid>
                <Grid item lg={2} xs={12}>
                  <LoadingButton
                    loading={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    {id ? "Atualizar" : "Criar"}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
        {id && (
          <>
            <Box mt={5} width={"100%"}>
              <h2>Clientes</h2>
              {clientes ? (
                <>
                  {clientes.map((cliente) => {
                    return <Box>{cliente.nome}</Box>;
                  })}
                </>
              ) : (
                <>
                  <p>Nenhum cliente para este segmento cadastrado</p>
                </>
              )}
            </Box>
          </>
        )}
      </Paper>
    </JumboContentLayout>
  );
};

export default SegmentosForm;
