import React from "react";
import {
  Card,
  CardContent,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Div from "@jumbo/shared/Div";
import * as yup from "yup";
import { ErrorMessage, Form, Formik } from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import LoadingButton from "@mui/lab/LoadingButton";
import authServices from "../../services/auth-services";
import { useNavigate } from "react-router-dom";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { useState } from "react";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";

const validationSchema = yup.object({
  email: yup
    .string("Insira um email válido")
    .email("Email inválido")
    .required("Preenchimento obrigatório"),
});

const EsqueceuSenha = ({ disableSmLogin }) => {
  const { setAuthToken } = useJumboAuth();

  const navigate = useNavigate();

  const Swal = useSwalWrapper();

  const [showPassword, setShowPassword] = useState(false);

  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: "Fechar",
      timer: 3000
    });
  };

  const handleForgotPassword = async (email, setSubmitting) => {
    try {
      const response = await authServices.forgotPassword({ email });
       
      if(response.status === 201) {
        toast("success", "Instruções enviadas por e-mail!")
      }

      setSubmitting(false);
    } catch (err) {
      if (
        err.response &&
        err.response.data &&
        err.response.data.error &&
        err.response.data.message
      ) {
        toast("error", err.response.data.message);
      } else if (err.response && err.response.data) {
        alert(err.response.data);
      } else {
        // Aqui você pode tratar outros erros ou exibir uma mensagem padrão
        console.log(err);
        toast("error", "Ocorreu um erro ao fazer login.");
      }

      setSubmitting(false);
    }
  };

  return (
    <Div
      sx={{
        width: 750,
        maxWidth: "100%",
        margin: "auto",
        p: 4,
      }}
    >
      <Card
        sx={{
          display: "flex",
          minWidth: 0,
          flexDirection: { xs: "column", md: "row" },
          backgroundColor: "#005D5F",
        }}
      >
        <CardContent
          sx={{
            flex: "0 1 300px",
            position: "relative",
            /* background: `#0267a0 url(/images/logos/logo.png) no-repeat center`, */
            backgroundImage: "url(/images/login_logo_ambiental.png)",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",

            "&::after": {
              display: "inline-block",
              position: "absolute",
              content: `''`,
              inset: 0,
              /* backgroundColor: alpha('#0267a0', .65) */
            },
          }}
        ></CardContent>
        <CardContent sx={{ flex: 1, p: 4 }}>
          <Formik
            validateOnChange={true}
            initialValues={{
              email: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting }) => {
              setSubmitting(true);
              handleForgotPassword(data.email, setSubmitting);
            }}
          >
            {({
              isSubmitting,
              handleChange,
              values,
              handleSubmit,
              handleBlur,
              errors,
              setFieldTouched,
              setFieldValue,
            }) => (
              <Form style={{ textAlign: "left" }} noValidate autoComplete="off">
                <Div sx={{ mt: 1, mb: 3 }}>
                  <Typography sx={{ color: "#fff" }}>
                    Informe o e-mail do seu usuário para a mudança de senha.
                  </Typography>
                </Div>
                <Div sx={{ mt: 1, mb: 3 }}>
                  <JumboTextField
                    fullWidth
                    name="email"
                    label="Email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="filled"
                    sx={{ background: "#ffffff" }}
                  />
                </Div>
                <LoadingButton
                  color="info"
                  fullWidth
                  type="submit"
                  onClick={handleSubmit}
                  variant="contained"
                  size="large"
                  sx={{ mb: 3 }}
                  loading={isSubmitting}
                >
                  Conferir
                </LoadingButton>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Div>
  );
};

export default EsqueceuSenha;
