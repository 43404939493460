import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Stack,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import ApiService from "app/services/config";
import React, { useState, useEffect, useCallback, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import { LoadingButton } from "@mui/lab";
import MUIDataTable from "mui-datatables";
import { PermissionContext } from "app/contexts/PermissionContext";

const FornecedoresList = () => {
  const { theme } = useJumboTheme();
  const location = useLocation();
  const [forceUpdate, setForceUpdate] = useState(false);
  const [fornecedores, setFornecedores] = useState([]);
  const [
    selectedFornecedoresIds,
    setSelectedFornecedoresIds,
  ] = useState([]);
  const [idsSelecionados, setIdsSelecionados] = useState([]);
  const [todosItensSelecionados, setTodosItensSelecionados] = useState(false);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");
  const [count, setCount] = useState("");

  const navigate = useNavigate();
  const { hasPermission } = useContext(PermissionContext);

  if (!hasPermission("Administrativo", "read")) {
    navigate("/app");
  }

  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const Swal = useSwalWrapper();
  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: "Fechar",
      timer: 3000
    });
  };

  const modalAlert = (id) => {
    Swal.fire({
      title: "Tem certeza que deseja apagar?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteOrgao(id);
      }
    });
  };

  const handleSearch = async (newSearchTerm) => {
    if (searchTerm === "" || searchTerm === null || searchTerm === undefined) {
      setPage(1);
    }
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  const deleteOrgao = useCallback(async (id) => {
    try {
      ApiService.delete(`/fornecedores/${id}`)
        .then((response) => {
          if (response.status === 200) {
            toast("success", "Removido com sucesso");
            setPerPage(10);
            setForceUpdate((prev) => !prev);
            getFornecedores();
          }
        })
        .catch((error) => {
          const message = error.response.data.message;
          toast("error", message);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getFornecedores = useCallback(async () => {
    try {
      let apiUrl = `fornecedores?page=${
        page + 1
      }&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}`;

      if (searchTerm) {
        apiUrl += `&searchTerm=${searchTerm}`;
      }
      ApiService.get(apiUrl)
        .then((response) => {
          console.log(response);
          const initialData = response.data.fornecedores.map(
            (orgao) => ({
              ...orgao,
              isSelected: selectedFornecedoresIds.includes(
                orgao.id
              ),
            })
          );
          setFornecedores(initialData);
          setCount(response.data.count);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [
    page,
    perPage,
    sortBy,
    sortOrder,
    searchTerm,
    selectedFornecedoresIds,
  ]);

  useEffect(() => {
    getFornecedores();
  }, [getFornecedores]);

  const handleDeleteMuitosItens = () => {
    Swal.fire({
      title: "Tem certeza que deseja apagar os itens selecionados?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteManyItens();
      }
    });
  };

  const deleteManyItens = async () => {
    try {
      await ApiService.put('/fornecedores/deletar/varios', idsSelecionados)
      .then((response) => {
        if(response.status === 200) {
          toast("success", "Itens deletados com sucesso!", "success");
          getFornecedores();
          setIdsSelecionados([]);
        }
      })
    } catch (error) {
      toast("error", "Ocorreu um erro", "error");
      console.log(error);
    }
  };


  const handleSelecionarTodosItens = () => {
    const novosIdsSelecionados = todosItensSelecionados
      ? []
      : fornecedores.map((fornecedor) => fornecedor.id);
    setIdsSelecionados(novosIdsSelecionados);
    setTodosItensSelecionados(!todosItensSelecionados);
  
    setFornecedores((prevItems) =>
      prevItems.map((item) => ({
        ...item,
        isSelected: !todosItensSelecionados,
      }))
    );
  };


  const handleCheckboxChange = (itemId) => {
    setFornecedores((prevItems) =>
      prevItems.map((item) =>
        item.id === itemId ? { ...item, isSelected: !item.isSelected } : item
      )
    );

    setIdsSelecionados((prevSelectedIds) => {
      if (prevSelectedIds.includes(itemId)) {
        return prevSelectedIds.filter((id) => id !== itemId);
      } else {
        return [...prevSelectedIds, itemId];
      }
    });
  };

  const columns = [
    {
      name: "selecionar",
      label: "Selecionar",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const id = tableMeta.rowData[1];
          const item = fornecedores.find((item) => item.id === id);
          const isSelected = item ? item.isSelected : false;
          return (
            <Checkbox
              checked={isSelected}
              onChange={() => handleCheckboxChange(id)}
            />
          );
        },
      },
    },
    {
      name: "id",
      label: "Id",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "nome",
      label: "Nome / Descrição",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "action",
      label: "Ações",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const fornecedorId = tableMeta.rowData[1];
          return (
            <Box display={"flex"} gap={"1rem"}>
              <Link
                to={`/app/editar-fornecedor/${fornecedorId}`}
                style={{ textDecoration: "none" }}
              >
                <Button
                  color="info"
                  variant="contained"
                  size="small"
                  disableElevation
                >
                  <VisibilityIcon />
                </Button>
              </Link>
              <Button
                color="error"
                variant="contained"
                size="small"
                onClick={() => modalAlert(fornecedorId)}
              >
                <DeleteIcon />
              </Button>
            </Box>
          );
        },
      },
    },
  ];

  const options = {
    changeRowsPerPage: perPage,
    filterType: "dropdown",
    filter: false,
    selectableRows: "none",
    searchAlwaysOpen: true,
    searchable: true,
    serverSide: true,
    page: page,
    count: count,
    selectToolbarPlacement: "above",

    onTableChange: (action, tableState) => {
      console.log(action);
      switch (action) {
        case "changePage":
          setPage(tableState.page);
          break;
        case "sort":
          setSortBy(tableState.sortOrder.name);
          setSortOrder(tableState.sortOrder.direction);
          break;
        case "search":
          handleSearch(tableState.searchText);
          break;
        case "changeRowsPerPage":
          setPerPage(tableState.rowsPerPage);
          break;
        default:
          console.log("action not handled.");
      }
    },
    customToolbar: ({ displayData }) => (
      <>
        {selectedFornecedoresIds.length > 0 ? (
          <Toolbar>
            <Box
              display={"flex"}
              gap={"1rem"}
              justifyContent={"flex-end"}
              width={"100%"}
            ></Box>
          </Toolbar>
        ) : null}
      </>
    ),
    textLabels: {
      body: {
        noMatch: "Nenhum resultado encontrado",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: "Próxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Itens por Página:",
        displayRows: "of",
      },
    },
  };

  return (
    <JumboContentLayout
      header={<PageHeader title={"Fornecedores"} />}
      layoutOptions={layoutOptions}
    >
      {lg && (
        <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}></Stack>
      )}

      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <Link
          to="/app/novo-fornecedor"
          state={{ backUrl: location.pathname }}
          style={{ textDecoration: "none" }}
        >
          <LoadingButton
            color="success"
            type="submit"
            variant="contained"
            size="large"
            sx={{
              maxWidth: { md: "450px", mt: "1rem" },
              marginBottom: "2rem",
            }}
          >
            Novo Fornecedor
          </LoadingButton>
        </Link>
      </Grid>
      <Stack my={2} direction={"row"} gap={2}>
        {idsSelecionados.length > 0 && (
          <Grid item>
            <span style={{ margin: 10 }}>
              {idsSelecionados.length} Fornecedor (es) selecionado(s)
            </span>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<DeleteIcon />}
              onClick={handleDeleteMuitosItens}
            >
              Excluir Selecionados
            </Button>
          </Grid>
        )}
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSelecionarTodosItens}
          >
            {todosItensSelecionados ? "Desmarcar Todos" : "Selecionar Todos"}
          </Button>
        </Grid>
      </Stack>
      <React.Fragment>
        <MUIDataTable
          key={forceUpdate ? "force-update" : "normal"}
          title={"Fornecedores"}
          data={fornecedores}
          columns={columns}
          options={options}
        />
      </React.Fragment>
    </JumboContentLayout>
  );
};

export default FornecedoresList;
