import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import ApiService from "app/services/config";
import { useMediaQuery } from "beautiful-react-hooks";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import ArquivoWrapper from "app/components/ArquivoWrapper/ArquivoWrapper";
import AddIcon from "@mui/icons-material/Add";
import { v4 } from "uuid";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";

const validationSchema = yup.object().shape({
  licencaId: yup.number().required("ID da Unidade obrigatório"),
  userId: yup.number().required("ID do usuário obrigatório"),
  nome_condicionante: yup.string().required("Campo obrigatório"),
  responsaveis: yup
    .array()
    .of(yup.number())
    .required("Campo obrigatório")
    .min(1, "Campo obrigatório"),
  nao_possui_prazo: yup.boolean().optional(),
  is_prazo_data_fixa: yup.boolean().optional(),
  is_prazo_periodo: yup.boolean().optional(),
  notificar_no_prazo: yup.boolean().optional(),
  notificar_antes_prazo: yup.boolean().optional(),
  data_prazo: yup.string().optional().typeError("Data inválida"),
  tarefas: yup.array().of(
    yup.object().shape({
      id: yup.number().optional(),
      descricao: yup.string().optional(),
      data_prazo: yup.date().optional(),
    })
  ),
  notificacoes: yup.array().of(
    yup.object().shape({
      id: yup.number().optional(),
      dias: yup.number().required(),
    })
  ),
  notificacoes_tarefa: yup.array().of(
    yup.object().shape({
      id: yup.number().optional(),
      dias: yup.number().required(),
    })
  ),
  notificar_tarefas_no_prazo: yup.boolean().optional(),
  notificar_tarefas_antes_prazo: yup.boolean().optional(),
});

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "transparent" : "white",
    border: state.isFocused
      ? "1px solid #005D5F"
      : "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    padding: "8px",
    boxShadow: "none",
    "&:hover": {
      borderColor: "black",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#005D5F" : "white",
    color: state.isSelected ? "white" : "black",
    "&:hover": {
      backgroundColor: "lightblue",
      color: "white",
    },
  }),
};

const CondicionanteLicencaForm = () => {
  const { licencaId, condicionanteId } = useParams();
  const userId = localStorage.getItem("id");
  const initialValues = {
    licencaId: Number(licencaId),
    userId: Number(userId),
    nome_condicionante: "",
    responsaveis: [],
    nao_possui_prazo: true,
    is_prazo_data_fixa: false,
    is_prazo_periodo: false,
    notificar_no_prazo: false,
    notificar_antes_prazo: false,
    data_prazo: "",
    opcao_data: "",
    tarefas: [],
    notificacoes: [],
    notificacoes_tarefa: [],
    notificar_tarefas_no_prazo: false,
    notificar_tarefas_antes_prazo: false,
    anexos: [],
  };
  const [condicionante, setCondicionante] = useState(initialValues);
  const [isDadosCarregados, setIsDadosCarregados] = useState([]);
  const [arquivos, setArquivos] = useState([]);
  const [arquivosUpload, setArquivosUpload] = useState([]);
  const [responsaveis, setResponsaveis] = useState([]);
  const { theme } = useJumboTheme();
  const Swal = useSwalWrapper();
  const formikRef = useRef();

  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const getResponsaveis = async () => {
    try {
      await ApiService.get("/users/all").then((response) => {
        if (response.status === 200) {
          setResponsaveis(response.data);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const navigate = useNavigate();

  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const modalAlert = (id, acao, item) => {
    Swal.fire({
      title:
        acao !== "descartarAlteracoes"
          ? "Tem certeza que deseja apagar?"
          : "Tem certeza que quer descartar as alterações?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
      didOpen: () => {
        Swal.getContainer().style.zIndex = "9999";
      },
    }).then((result) => {
      if (result.value) {
        if (id && !acao) {
          handleDeleteDocumento(id);
        }
        if (id && acao === "descartarAlteracoes") {
          navigate(`/app/licenca/${id}`);
        }
        if (id && acao === "deletar" && item === "anexo") {
          handleDeleteDocumento(id);
        }
        if (id && acao === "deletar" && item === "tarefa") {
          handleDeleteTarefa(id);
        }
        if (id && acao === "deletar" && item === "notificacaoCondicionante") {
          handleDeleteNotificacaoCondicionante(id);
        }
        if (id && acao === "deletar" && item === "notificacaoTarefa") {
          handleDeleteNotificacaoTarefa(id);
        }
      }
    });
  };

  const handleRemoverArquivo = (arquivoRemovido) => {
    const novosArquivos = arquivos.filter(
      (arquivo) => arquivo !== arquivoRemovido
    );
    setArquivos(novosArquivos);
  };

  const getCondicionante = async (id) => {
    try {
      await ApiService.get(`/condicionante/${id}`).then((response) => {
        if (response.status === 200) {
          const tarefasComDataPrazoFormatadas = response.data.tarefas.map(
            (task) => ({
              ...task,
              data_prazo: moment
                .parseZone(task.data_prazo)
                .format("YYYY-MM-DD"),
            })
          );

          const { nao_possui_prazo, is_prazo_data_fixa, is_prazo_periodo } =
            response.data;
          let opcao_data,
            notificacoes_tarefa,
            notificar_tarefas_no_prazo,
            notificar_tarefas_antes_prazo;

          if (tarefasComDataPrazoFormatadas?.length > 0) {
            notificar_tarefas_antes_prazo =
              tarefasComDataPrazoFormatadas[0]?.notificar_antes_prazo;
            notificar_tarefas_no_prazo =
              tarefasComDataPrazoFormatadas[0]?.notificar_no_prazo;
            notificacoes_tarefa = tarefasComDataPrazoFormatadas[0]
              ?.notificacoes_tarefa ?? [{ dias: 1 }];
          }
          if (nao_possui_prazo) {
            opcao_data = "nao_possui_prazo";
          }

          if (is_prazo_data_fixa) {
            opcao_data = "prazo_por_data_fixa";
          }

          if (is_prazo_periodo) {
            opcao_data = "prazo_por_periodo";
          }

          setCondicionante({
            ...response.data,
            responsaveis: response.data.responsaveis?.map(
              (responsavel) => responsavel.user_id
            ),
            tarefas: tarefasComDataPrazoFormatadas,
            opcao_data,
            data_prazo: response.data.data_prazo ? moment
              .parseZone(response.data.data_prazo)
              .format("YYYY-MM-DD") : '',
            licencaId: Number(licencaId),
            userId: Number(userId),
            notificar_tarefas_antes_prazo,
            notificar_tarefas_no_prazo,
            notificacoes_tarefa,
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteDocumento = async (id) => {
    try {
      await ApiService.put(`/condicionante/documento/${id}`, {
        userId: Number(userId),
      }).then((response) => {
        if (response.status === 200) {
          toast("success", "Anexo excluído!", "success");
          setCondicionante((prevAnexos) => ({
            ...prevAnexos,
            anexos: prevAnexos.tarefas.filter((anexo) => anexo.id !== id),
          }));
        }
      });
    } catch (error) {
      toast("error", "Ocorreu um erro!", "error");
      console.error(error);
    }
  };

  const handleDeleteTarefa = async (id) => {
    try {
      await ApiService.put(`/condicionante/tarefa/deletar/${id}`, {
        userId: Number(userId),
      }).then((response) => {
        if (response.status === 200) {
          toast("success", "Tarefa excluída!", "success");
          setCondicionante((prevCondicionante) => ({
            ...prevCondicionante,
            tarefas: prevCondicionante.tarefas.filter(
              (tarefa) => tarefa.id !== id
            ),
          }));
        }
      });
    } catch (error) {
      toast("error", "Ocorreu um erro!", "error");
      console.error(error);
    }
  };

  const handleDeleteNotificacaoCondicionante = async (id) => {
    try {
      await ApiService.delete(
        `/condicionante/notificacao/condicionante/deletar/${id}`
      ).then((response) => {
        if (response.status === 200) {
          toast("success", "Notificação excluída!", "success");
          setCondicionante((prevNotificacoes) => ({
            ...prevNotificacoes,
            notificacoes: prevNotificacoes.notificacoes.filter(
              (notificacao) => notificacao.id !== id
            ),
          }));
        }
      });
    } catch (error) {
      toast("error", "Ocorreu um erro!", "error");
      console.error(error);
    }
  };

  const handleDeleteNotificacaoTarefa = async (id) => {
    try {
      await ApiService.delete(
        `/condicionante/notificacao/tarefa/deletar/${id}`
      ).then((response) => {
        if (response.status === 200) {
          toast("success", "Notificação excluída!", "success");
          setCondicionante((prevCondicionante) => ({
            ...prevCondicionante,
            notificacoes_tarefa: prevCondicionante.notificacoes_tarefa.filter(
              (notificacao) => notificacao.id !== id
            ),
          }));
        }
      });
    } catch (error) {
      toast("error", "Ocorreu um erro!", "error");
      console.error(error);
    }
  };
  


  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    try {
      const formData = new FormData();
      formData.append("userId", Number(userId));

      for (let i = 0; i < arquivosUpload.length; i++) {
        formData.append(`files`, arquivosUpload[i]);
      }

      delete values.opcao_data;
      delete values.deletedAt;
      delete values.createdAt;
      delete values.updatedAt;
      delete values.unidade_id;
      delete values.id;
      delete values.licenca_id;
      delete values.is_arquivada;

      if (Object.keys(formikRef.current.errors).length > 0) {
        toast("warning", "Preencha todos os campos obrigatórios!");
        console.log(Object.keys(formikRef.current.errors))
        return;
      };

      if (condicionanteId && condicionanteId !== undefined) {
        await ApiService.put(`/unidadealvaralicenca/condicionante/${condicionanteId}`, values).then(
          async (response) => {
            if (response.status === 200) {
              console.log(response.data);
              const condicionanteId = response.data.id;
              toast("success", "Condicionante atualizada com sucesso!");

              if (formData.has("files")) {
                try {
                  await ApiService.put(
                    `/condicionante/documentos/${condicionanteId}`,
                    formData,
                    {
                      headers: {
                        "Content-Type": "multipart/form-data",
                      },
                    }
                  ).then((response) => {
                    if (response.status === 200) {
                      console.log("Documentos criados");
                    }
                  });
                } catch (error) {
                  toast("error", "Erro ao realizar upload dos arquivos!");
                  console.log(error);
                }
              }

              navigate(`/app/licenca/${licencaId}`, {
                state: {
                  aba: "condicionantes",
                },
              });
            }
          }
        );
      } else {
        await ApiService.post("/unidadealvaralicenca/condicionante", values).then(
          async (response) => {
            if (response.status === 201) {
              console.log(response.data);
              const condicionanteId = response.data.id;
              toast("success", "Condicionante criada com sucesso!");

              if (formData.has("files")) {
                try {
                  await ApiService.put(
                    `/condicionante/documentos/${condicionanteId}`,
                    formData,
                    {
                      headers: {
                        "Content-Type": "multipart/form-data",
                      },
                    }
                  ).then((response) => {
                    if (response.status === 200) {
                      console.log("Documentos criados");
                    }
                  });
                } catch (error) {
                  toast("error", "Erro ao realizar upload dos arquivos!");
                  console.log(error);
                }
              }

              navigate(`/app/licenca/${licencaId}`, {
                state: {
                  aba: "condicionantes",
                },
              });
            }
          }
        );
      }
    } catch (error) {
      toast("error", "Ocorreu um erro!");
      console.error(error);
    }
  };

  useEffect(() => {
    getResponsaveis();
    setIsDadosCarregados(true);
  }, []);

  useEffect(async () => {
    setIsDadosCarregados(false);
    if (condicionanteId && condicionanteId !== undefined) {
      await getCondicionante(condicionanteId);
    }
    setIsDadosCarregados(true);
  }, [condicionanteId]);

  return (
    isDadosCarregados && (
      <JumboContentLayout
        header={
          <HeaderBreadcrumbs
            id={licencaId}
            title={"Condicionantes"}
            subtitle={
              condicionanteId === undefined
                ? "Nova condicionante"
                : "Editar condicionante"
            }
            titleUrl={"/app/condicionantes"}
          />
        }
        layoutOptions={layoutOptions}
      >
        {lg && (
          <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}></Stack>
        )}
        <Formik
          innerRef={formikRef}
          initialValues={condicionante}
          validationSchema={validationSchema}
          enableReinitialize
          validateOnChange={false}
          onSubmit={handleSubmit}
        >
          {({
            values,
            handleBlur,
            isSubmitting,
            handleChange,
            setFieldValue,
            errors,
          }) => (
            <div>
              <Form style={{ width: "100%" }} noValidate autoComplete="off">
                <Grid container sx={{ width: "100%" }} spacing={2}>
                  <Grid item xs={8}>
                    <Paper
                      sx={{ width: "100%", p: 2, background: "white" }}
                      elevation={2}
                    >
                      <Box sx={{ width: "100%" }} my={2}>
                        <TextField
                          name="nome_condicionante"
                          label={"Nome da condicionante *"}
                          value={values.nome_condicionante}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                        />
                        <ErrorMessage
                          name="nome_condicionante"
                          component={"div"}
                          style={{ color: "red" }}
                        />
                      </Box>
                      {console.log(values, errors)}
                      <Box sx={{ width: "100%" }} my={2}>
                        <Typography my={2}>Responsáveis</Typography>
                        <FieldArray>
                          {({ push, remove }) => (
                            <Box>
                              <Select
                                fullWidth
                                isMulti
                                styles={customStyles}
                                options={responsaveis}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                onBlur={handleBlur}
                                onChange={(selectedOptions) => {
                                  const selectedIds = selectedOptions
                                    .filter((option) => !!option.id)
                                    .map((option) => option.id);

                                  setFieldValue("responsaveis", selectedIds);
                                }}
                                value={values.responsaveis.map((id) =>
                                  responsaveis.find(
                                    (responsavel) => responsavel.id === id
                                  )
                                )}
                                placeholder="Selecione um responsável"
                              />
                              <ErrorMessage
                                name="responsaveis"
                                component={"div"}
                                style={{ color: "red" }}
                              />

                              <Divider my={1} />
                            </Box>
                          )}
                        </FieldArray>
                      </Box>
                      <Box sx={{ width: "100%" }} my={1}>
                        <FormControl>
                          <RadioGroup
                            row
                            name="opcoes_data"
                            value={values.opcao_data}
                            onChange={(event) => {
                              setFieldValue("opcao_data", event.target.value);
                            }}
                          >
                            <FormControlLabel
                              value="nao_possui_prazo"
                              control={
                                <Radio
                                  checked={
                                    values.opcao_data === "nao_possui_prazo" ||
                                    values.nao_possui_prazo
                                  }
                                  onChange={() => {
                                    setFieldValue(
                                      "opcao_data",
                                      "nao_possui_prazo"
                                    );
                                    setFieldValue("nao_possui_prazo", true);
                                    setFieldValue("is_prazo_data_fixa", false);
                                    setFieldValue("is_prazo_periodo", false);
                                  }}
                                />
                              }
                              label="Não possui prazo"
                            />
                            <FormControlLabel
                              value="prazo_por_data_fixa"
                              control={
                                <Radio
                                  checked={
                                    values.opcao_data === "prazo_por_data_fixa"
                                  }
                                  onChange={() => {
                                    setFieldValue(
                                      "opcao_data",
                                      "prazo_por_data_fixa"
                                    );
                                    setFieldValue("nao_possui_prazo", false);
                                    setFieldValue("is_prazo_data_fixa", true);
                                    setFieldValue("is_prazo_periodo", false);
                                  }}
                                />
                              }
                              label="Prazo por data fixa"
                            />
                            <FormControlLabel
                              value="prazo_por_periodo"
                              control={
                                <Radio
                                  checked={
                                    values.opcao_data === "prazo_por_periodo"
                                  }
                                  onChange={() => {
                                    setFieldValue(
                                      "opcao_data",
                                      "prazo_por_periodo"
                                    );
                                    setFieldValue("nao_possui_prazo", false);
                                    setFieldValue("is_prazo_data_fixa", false);
                                    setFieldValue("is_prazo_periodo", true);
                                  }}
                                />
                              }
                              label="Prazo por período"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      <Box my={1} sx={{ width: "100%" }}>
                        <Typography fontWeight={500}>Data *</Typography>
                        <FormControl>
                          <TextField
                            name="data_prazo"
                            type="date"
                            onBlur={handleBlur}
                            onChange={(event) => {
                              setFieldValue("data_prazo", event.target.value);
                            }}
                            value={values.data_prazo}
                            disabled={values.nao_possui_prazo}
                            variant="outlined"
                            fullWidth
                          />
                        </FormControl>
                      </Box>
                      <Box my={1} sx={{ width: "100%" }}>
                        <Typography fontWeight={500}>
                          Lembretes de vencimento
                        </Typography>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={
                                  values.nao_possui_prazo ||
                                  !values.data_prazo ||
                                  values.is_prazo_periodo
                                }
                                checked={values.notificar_no_prazo}
                              />
                            }
                            name={"notificar_no_prazo"}
                            onBlur={handleBlur}
                            onChange={(event) => {
                              setFieldValue(
                                "notificar_no_prazo",
                                event.target.checked
                              );
                            }}
                            label="Notificar responsáveis no prazo via e-mail e sistema"
                            color="success"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={
                                  values.nao_possui_prazo ||
                                  !values.data_prazo ||
                                  values.is_prazo_periodo
                                }
                                checked={values.notificar_antes_prazo}
                                name={"notificar_antes_prazo"}
                                onBlur={handleBlur}
                                onChange={(event) => {
                                  setFieldValue(
                                    "notificar_antes_prazo",
                                    event.target.checked
                                  );
                                  setFieldValue("notificacoes[0]", {
                                    dias: 1,
                                  });
                                }}
                              />
                            }
                            label="Notificar responsáveis dias antes do prazo (Personalizado)"
                            color="success"
                          />
                          {values.notificar_antes_prazo &&
                            !values.nao_possui_prazo && (
                              <Box width="100%">
                                <FieldArray name="notificacoes">
                                  {({ push, remove }) => (
                                    <>
                                      {values.notificacoes.length > 0 && (
                                        <>
                                          {values.notificacoes.map(
                                            (notificacao, index) => (
                                              <Box
                                                width={"100%"}
                                                display={"flex"}
                                                gap={2}
                                              >
                                                <TextField
                                                  type="number"
                                                  sx={{ my: 1 }}
                                                  name={`notificacoes[${index}].dias`}
                                                  onChange={(event) => {
                                                    setFieldValue(
                                                      `notificacoes[${index}].dias`,
                                                      Math.abs(
                                                        event.target.value
                                                      )
                                                    );
                                                  }}
                                                  onBlur={handleBlur}
                                                  value={
                                                    values.notificacoes[index]
                                                      ?.dias || 0
                                                  }
                                                  style={{
                                                    width: "10%",
                                                  }}
                                                  required
                                                  placeholder="Dias *"
                                                />
                                                <Stack
                                                  direction="row"
                                                  my={-2.5}
                                                  sx={{ width: "auto" }}
                                                  display={"flex"}
                                                  alignItems={"center"}
                                                >
                                                  <Typography>
                                                    dias antes do prazo da atual
                                                    condicionante, via e-mail e
                                                    no sistema
                                                  </Typography>
                                                  {index > 0 && (
                                                    <Button
                                                      type="button"
                                                      color="error"
                                                      onClick={() => {
                                                        if (notificacao.id) {
                                                          modalAlert(
                                                            notificacao.id,
                                                            "deletar",
                                                            "notificacaoCondicionante"
                                                          );
                                                        } else {
                                                          remove(this);
                                                        }
                                                      }}
                                                      sx={{ marginLeft: 1 }}
                                                    >
                                                      <DeleteIcon />
                                                    </Button>
                                                  )}
                                                </Stack>
                                              </Box>
                                            )
                                          )}
                                        </>
                                      )}
                                      <label htmlFor="add-tarefa">
                                        <Button
                                          variant="contained"
                                          component="span"
                                          sx={{
                                            width: "100%",
                                            background: "transparent",
                                            p: 1,
                                            color: "#A7A7A7",
                                            border: "1px dashed #A7A7A7",
                                            "&:hover": {
                                              background: "#2CB3C7",
                                              color: "#FFF",
                                              border: "none",
                                            },
                                          }}
                                          onClick={() => {
                                            push({
                                              dias: 0,
                                            });
                                          }}
                                          startIcon={<NotificationsNoneIcon />}
                                        >
                                          Adicionar notificação
                                        </Button>
                                      </label>
                                    </>
                                  )}
                                </FieldArray>
                              </Box>
                            )}
                        </FormGroup>
                      </Box>
                    </Paper>
                  </Grid>
                  <Grid item xs={4}>
                    <Paper width={"100%"} sx={{ p: 2, background: "#D2E6FD" }}>
                      <Typography
                        sx={{
                          color: "#324180",
                          gap: 1,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <InfoOutlinedIcon /> Atualize o status, edite a
                        observação e os anexos nos próprios detalhes da
                        condicionante.
                      </Typography>
                    </Paper>
                  </Grid>
                </Grid>
                <Grid container my={2} sx={{ width: "100%" }} spacing={2}>
                  <Grid item xs={8}>
                    <Paper
                      sx={{ width: "100%", p: 2, background: "white" }}
                      elevation={2}
                    >
                      <Box sx={{ width: "100%" }} my={2}>
                        <Typography variant={"h3"}>Anexos</Typography>
                        <Divider my={1} />
                        <Typography variant="h6" my={1}>
                          Formatos de arquivos permitidos: .jpg, .pdf e .png |
                          Tamanho máximo: 10 MB
                        </Typography>
                        <input
                          accept=".pdf, .png, .jpg, .jpeg"
                          style={{ display: "none" }}
                          id="file-upload"
                          multiple
                          type="file"
                          onChange={(event) => {
                            const files = event.target.files;
                            setArquivosUpload((prevArquivos) => [
                              ...prevArquivos,
                              ...Array.from(files),
                            ]);
                            const novosArquivos = Array.from(files).map(
                              (file) => ({
                                nome: file.name,
                                tamanho_documento: file.size,
                                extensao: file.name
                                  .split(".")
                                  .pop()
                                  .toUpperCase(),
                                dataUpload: moment().format("DD/MM/YYYY"),
                              })
                            );
                            setArquivos((arquivos) => [
                              ...arquivos,
                              ...novosArquivos,
                            ]);
                          }}
                        />

                        <label htmlFor="file-upload">
                          <Button
                            variant="contained"
                            component="span"
                            sx={{
                              width: "100%",
                              background: "transparent",
                              p: 1,
                              color: "#A7A7A7",
                              border: "1px dashed #A7A7A7",
                              "&:hover": {
                                background: "#2CB3C7",
                                color: "#FFF",
                                border: "none",
                              },
                            }}
                            startIcon={<CloudUploadIcon />}
                          >
                            Upload
                          </Button>
                        </label>
                        <Stack
                          style={{
                            maxHeight: "150px",
                            overflowY: "auto",
                          }}
                        >
                          {values.anexos.map((anexo) => (
                            <ArquivoWrapper
                              key={v4()}
                              arquivo={anexo}
                              tipoLicenca={"Documentos condicionantes"}
                              onRemoverArquivo={() => {
                                modalAlert(anexo.id, "deletar", "anexo");
                              }}
                              tipoArquivo={"anexoCondicionante"}
                            />
                          ))}
                          {arquivos.length > 0 && (
                            <>
                              {arquivos.map((arquivo, index) => (
                                <ArquivoWrapper
                                  key={v4()}
                                  arquivo={arquivo}
                                  tipoLicenca={"Documento condicionante"}
                                  onRemoverArquivo={handleRemoverArquivo}
                                />
                              ))}
                            </>
                          )}
                        </Stack>
                      </Box>
                    </Paper>
                  </Grid>
                </Grid>
                {console.log(values)}
                <Grid container my={2} sx={{ width: "100%" }} spacing={2}>
                  <Grid item xs={8}>
                    <Paper
                      sx={{ width: "100%", p: 2, background: "white" }}
                      elevation={2}
                    >
                      <Box sx={{ width: "100%" }} my={2}>
                        <Stack sx={{ width: "100%" }}>
                          <Typography variant={"h3"}>Tarefas</Typography>
                        </Stack>
                      </Box>
                      <Box width="100%">
                        <FieldArray name="tarefas">
                          {({ push, remove }) => (
                            <>
                              {values.tarefas.length > 0 && (
                                <>
                                  <Typography my={1}>Tarefa:</Typography>
                                  {values.tarefas.map((tarefa, index) => (
                                    <Box
                                      width={"100%"}
                                      display={"flex"}
                                      gap={2}
                                    >
                                      <TextField
                                        sx={{ my: 2 }}
                                        name={`tarefas[${index}].descricao`}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={tarefa.descricao}
                                        style={{
                                          width: "65%",
                                        }}
                                        required
                                        placeholder="Descrição *"
                                      />
                                      <Stack
                                        direction="column"
                                        my={-2.5}
                                        sx={{ width: "30%" }}
                                      >
                                        <InputLabel id="prazo-tarefa">
                                          Prazo:
                                        </InputLabel>
                                        <TextField
                                          id="prazo-tarefa"
                                          sx={{ my: 2, width: "100%" }}
                                          type="date"
                                          name={`tarefas[${index}].data_prazo`}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={tarefa.data_prazo}
                                          placeholder="Prazo *"
                                          required
                                        />
                                      </Stack>
                                      <Button
                                        type="button"
                                        color="error"
                                        onClick={() => {
                                          if (tarefa.id) {
                                            modalAlert(
                                              tarefa.id,
                                              "deletar",
                                              "tarefa"
                                            );
                                          } else {
                                            remove(this);
                                          }
                                        }}
                                        sx={{ marginLeft: 1 }}
                                      >
                                        <DeleteIcon />
                                      </Button>
                                    </Box>
                                  ))}
                                </>
                              )}
                              <label htmlFor="add-tarefa">
                                <Button
                                  variant="contained"
                                  component="span"
                                  sx={{
                                    width: "100%",
                                    background: "transparent",
                                    p: 1,
                                    color: "#A7A7A7",
                                    border: "1px dashed #A7A7A7",
                                    "&:hover": {
                                      background: "#2CB3C7",
                                      color: "#FFF",
                                      border: "none",
                                    },
                                  }}
                                  onClick={() => {
                                    push({
                                      descricao: "",
                                      data_prazo: "",
                                    });
                                  }}
                                  startIcon={<AddIcon />}
                                >
                                  Adicionar tarefa
                                </Button>
                              </label>
                            </>
                          )}
                        </FieldArray>
                      </Box>
                      <Box my={1} sx={{ width: "100%" }}>
                        <Typography fontWeight={500}>
                          Lembretes de vencimento
                        </Typography>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.notificar_tarefas_no_prazo}
                                name={"notificar_tarefas_no_prazo"}
                                onChange={(event) => {
                                  setFieldValue(
                                    "notificar_tarefas_no_prazo",
                                    event.target.checked
                                  );
                                }}
                              />
                            }
                            label="Notificar responsáveis no prazo via e-mail e sistema"
                            color="success"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.notificar_tarefas_antes_prazo}
                                name={"notificar_tarefas_antes_prazo"}
                                onChange={(event) => {
                                  setFieldValue(
                                    "notificar_tarefas_antes_prazo",
                                    event.target.checked
                                  );
                                  setFieldValue("notificacoes_tarefa[0]", {
                                    dias: 1,
                                  });
                                }}
                              />
                            }
                            label="Notificar responsáveis dias antes do prazo (Personalizado)"
                            color="success"
                          />
                          {values.notificar_tarefas_antes_prazo && (
                            <Box width="100%">
                              <FieldArray name="notificacoes_tarefa">
                                {({ push, remove }) => (
                                  <>
                                    {values.notificacoes_tarefa?.length > 0 && (
                                      <>
                                        {values.notificacoes_tarefa?.map(
                                          (notificacao, index) => (
                                            <Box
                                              width={"100%"}
                                              display={"flex"}
                                              gap={2}
                                            >
                                              <TextField
                                                type="number"
                                                sx={{ my: 1 }}
                                                name={`notificacoes_tarefa[${index}].dias`}
                                                onChange={(event) => {
                                                  setFieldValue(
                                                    `notificacoes_tarefa[${index}].dias`,
                                                    Math.abs(event.target.value)
                                                  );
                                                }}
                                                onBlur={handleBlur}
                                                value={
                                                  values.notificacoes_tarefa
                                                    ? values
                                                        .notificacoes_tarefa[
                                                        index
                                                      ]?.dias
                                                    : 0
                                                }
                                                style={{
                                                  width: "10%",
                                                }}
                                                required
                                                placeholder="Dias *"
                                              />
                                              <Stack
                                                direction="row"
                                                my={-2.5}
                                                sx={{ width: "auto" }}
                                                display={"flex"}
                                                alignItems={"center"}
                                              >
                                                <Typography>
                                                  dias antes do prazo da tarefa,
                                                  via e-mail e no sistema
                                                </Typography>
                                                {index > 0 && (
                                                  <Button
                                                    type="button"
                                                    color="error"
                                                    onClick={() => {
                                                      if (notificacao.id) {
                                                        modalAlert(
                                                          notificacao.id,
                                                          "deletar",
                                                          "notificacaoTarefa"
                                                        );
                                                      } else {
                                                        remove(this);
                                                      }
                                                    }}
                                                    sx={{ marginLeft: 1 }}
                                                  >
                                                    <DeleteIcon />
                                                  </Button>
                                                )}
                                              </Stack>
                                            </Box>
                                          )
                                        )}
                                      </>
                                    )}
                                    <label htmlFor="add-tarefa">
                                      <Button
                                        variant="contained"
                                        component="span"
                                        sx={{
                                          width: "100%",
                                          background: "transparent",
                                          p: 1,
                                          color: "#A7A7A7",
                                          border: "1px dashed #A7A7A7",
                                          "&:hover": {
                                            background: "#2CB3C7",
                                            color: "#FFF",
                                            border: "none",
                                          },
                                        }}
                                        onClick={() => {
                                          push({
                                            dias: 0,
                                          });
                                        }}
                                        startIcon={<NotificationsNoneIcon />}
                                      >
                                        Adicionar notificação
                                      </Button>
                                    </label>
                                  </>
                                )}
                              </FieldArray>
                            </Box>
                          )}
                        </FormGroup>
                      </Box>
                    </Paper>
                  </Grid>
                </Grid>
                <input
                  hidden
                  type="number"
                  name="licencaId"
                  value={Number(licencaId)}
                />
                <input
                  hidden
                  type="number"
                  name="userId"
                  value={Number(userId)}
                />
                <Grid container my={1} mx={-1.7}>
                  <Grid item xs={8}>
                    <Stack direction="row" gap={2} justifyContent={"flex-end"}>
                      <Button
                        variant="outlined"
                        color="info"
                        onClick={() => {
                          modalAlert(licencaId, "descartarAlteracoes");
                        }}
                      >
                        Descartar alterações
                      </Button>
                      <Box>
                        <LoadingButton
                          loading={isSubmitting}
                          type="submit"
                          style={{ margin: "auto", display: "block" }}
                          variant="contained"
                          color="info"
                        >
                          Salvar alterações
                        </LoadingButton>
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
              </Form>
            </div>
          )}
        </Formik>
      </JumboContentLayout>
    )
  );
};

export default CondicionanteLicencaForm;
