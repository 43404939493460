import React, { createContext, useContext, useState } from 'react';

const NotificacoesContext = createContext();

export const NotificacoesProvider = ({ children }) => {
  const [notificacoesNaoLidas, setNotificacoesNaoLidas] = useState(0);

  return (
    <NotificacoesContext.Provider value={{ notificacoesNaoLidas, setNotificacoesNaoLidas }}>
      {children}
    </NotificacoesContext.Provider>
  );
};

export const useNotificacoes = () => useContext(NotificacoesContext);
