import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Grid,
  Paper,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { PermissionContext } from "app/contexts/PermissionContext";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import ApiService from "app/services/config";
import { Form, Formik } from "formik";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import makeAnimated from "react-select/animated";
import * as yup from "yup";


const validationSchema = yup.object().shape({
  descricao: yup
    .string("Insira a descrição do Tipo de Serviço")
    .required("Preenchimento obrigatório"),
});

const TiposServicoForm = () => {
  const initialValues = {
    descricao: "",
  };
  const { id } = useParams();
  const [tipoServico, setTipoServico] = useState(initialValues);

  const Swal = useSwalWrapper();
  const navigate = useNavigate();

  const { theme } = useJumboTheme();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const { hasPermission } = useContext(PermissionContext);

  if(!hasPermission('Administrativo', 'read')) {
    navigate('/app');
  }

  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const getTipoServico = useCallback(async () => {
    try {
      ApiService.get(`/tiposservico/${id}`)
        .then((response) => {
          setTipoServico({
            ...response.data,
          });
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);


  useEffect(() => {
    if (id) {
      getTipoServico();
    }
  }, []);


  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    console.log(values)
    const allValuesEdit = { ...values };
    delete allValuesEdit.id;
    delete allValuesEdit.createdAt;
    delete allValuesEdit.updatedAt;
    delete allValuesEdit.deletedAt;
    if (id) {
      ApiService.put(`/tiposservico/${id}`, allValuesEdit)
        .then((response) => {
          toast("success", "Atualizado com sucesso!");
          if (response.status === 200) {
            navigate("/app/listar-tipos-servico");
          }
        })
        .catch((error) => {
          let message = error.response.data.message;
          toast("error", message);

          if (error.response.data) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } else {
      await ApiService.post("/tiposservico", allValuesEdit)
        .then((response) => {
          toast("success", "Criado com sucesso");
          resetForm();
          if (response.status === 201) {
            navigate("/app/listar-tipos-servico");
          }
        })
        .catch((error) => {
          let message = error.response.data.message;
          toast("error", message);

          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    }
    setSubmitting(false);
  };



  return (
    <JumboContentLayout
      header={
        <HeaderBreadcrumbs
          id={id}
          title={"Tipos de Serviço"}
          subtitle={id ? "Editar" : "Cadastro de Tipos de Serviço"}
          titleUrl={"/app/listar-tipos-servico"}
        />
      }
      layoutOptions={layoutOptions}
    >
      {lg && (
        <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}></Stack>
      )}
      <Paper sx={{ p: "15px" }}>
        <Formik
          initialValues={tipoServico}
          validationSchema={validationSchema}
          enableReinitialize
          validateOnChange={false}
          onSubmit={handleSubmit}
        >
          {({ values, isSubmitting, handleChange, setFieldValue, errors }) => (
            <Form style={{ width: "100%" }} noValidate autoComplete="off">
              <Grid container alignContent={"center"} spacing={2}>
                <Grid item lg={5} xs={12}>
                  <JumboTextField
                    fullWidth
                    name="descricao"
                    label="Descrição"
                    required
                    value={values.descricao}
                    onChange={handleChange}
                    sx={{ background: "#ffffff" }}
                  />
                </Grid>
                
                <Box style={{ width: "100%" }} marginTop={3}>
                  <LoadingButton
                    loading={isSubmitting}
                    type="submit"
                    style={{ margin: "auto", display: "block" }}
                    variant="contained"
                    color="primary"
                  >
                    {id ? "Atualizar" : "Criar"}
                  </LoadingButton>
                </Box>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </JumboContentLayout>
  );
};

export default TiposServicoForm;
