import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {
  Box,
  Button,
  Divider,
  Fade,
  Grid,
  Modal,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import ApiService from "app/services/config";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const validationSchema = yup.object().shape({
  id: yup.number().required("Campo obrigatório"),
  userId: yup.number().required("Campo obrigatório"),
  identificacao: yup.string().optional(),
  observacao: yup.string().optional(),
});

export const ModalEditarIdentificacaoObservacaoLicenca = ({
  modalAberto,
  handleClose,
  licencaId,
  userId,
  acao,
  atualizarLicenca,
}) => {
  const initialValues = {
    id: Number(licencaId),
    userId: Number(userId),
    identificacao: "",
    observacao: "",
  };

  const [isDadosCarregados, setIsDadosCarregados] = useState(false);
  const [aberto, setAberto] = useState(modalAberto);
  const [licenca, setLicenca] = useState(initialValues);
  const [idLicenca, setIdLicenca] = useState(licencaId);
  const [idUser, setIdUser] = useState(userId);
  const [acaoAtualizacao, setAcaoAtualizacao] = useState(acao);

  const Swal = useSwalWrapper();
  const toast = (variant, message, type = false) => {
    Swal.fire({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      icon: type ? "success" : "error",
      title: message,
      didOpen: (toast) => {
        toast.style.zIndex = 10000;
      },
      timer: 3000,
    });
  };

  const getDados = async () => {
    try {
      await ApiService.get(`/unidadealvaralicenca/${idLicenca}`).then(
        (response) => {
          if (response.status === 200) {
            setLicenca((prevLicenca) => ({
              ...prevLicenca,
              identificacao: response.data.identificacao,
              observacao: response.data.observacao,
            }));
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    console.log(values);
    try {
      if (acaoAtualizacao === "observacao") {
        await ApiService.put(`/unidadealvaralicenca/observacao`, values).then(
          (response) => {
            console.log(response)
            if (response.status === 200) {
              toast(null, "Observação atualizada", "success");
              atualizarLicenca();
              handleClose();
            }
          }
        );
      }
      if (acaoAtualizacao === "identificacao") {
        await ApiService.put(
          `/unidadealvaralicenca/identificacao`,
          values
        ).then((response) => {
          if (response.status === 200) {
            toast(null, "Identificação atualizada", "success");
            atualizarLicenca();
            handleClose();
          }
        });
      }
    } catch (error) {
      toast( "error", "Ocorreu um erro!");
      console.log(error);
    }
  };

  useEffect(() => {
    setAberto(modalAberto);
  }, [modalAberto]);

  useEffect(() => {
    setIdLicenca(licencaId);
    setIdUser(userId);
    setAcaoAtualizacao(acao);
  }, [licencaId, userId, acao]);

  useEffect(async () => {
    setIsDadosCarregados(false);
    const fetchDados = async () => {
      await getDados();
    };
    await fetchDados();
    setIsDadosCarregados(true);
  }, [modalAberto]);

  return (
    <>
      <Paper>
        <Modal
          open={aberto}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          closeAfterTransition
        >
          <Fade in={modalAberto}>
            <Box
              sx={{
                ...style,
              }}
            >
              {isDadosCarregados && (
                <Box>
                  <Typography variant={"h2"}>
                    {acaoAtualizacao === "observacao"
                      ? "Atualizar observação"
                      : "Atualizar identificação"}
                  </Typography>
                  <Typography variant={"h5"} color={"#A5A5A5"}>
                    {licenca?.alvaraLicenca?.descricao}
                  </Typography>
                  <Divider />
                  <Formik
                    enableReinitialize
                    initialValues={licenca}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({
                      values,
                      handleChange,
                      handleSubmit,
                      handleBlur,
                      errors,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <Form
                        onSubmit={handleSubmit}
                        style={{
                          width: "100%",
                          maxHeight: 500,
                          marginTop: 20,
                          overflowY: "auto",
                        }}
                      >
                        <Box sx={{ width: "100%" }}>
                          <Typography my={1}>
                            {acaoAtualizacao === "identificacao"
                              ? "Identificação"
                              : "Observação"}
                          </Typography>
                          <TextField
                            name={
                              acaoAtualizacao === "identificacao"
                                ? "identificacao"
                                : "observacao"
                            }
                            value={
                              acaoAtualizacao === "identificacao"
                                ? values.identificacao
                                : values.observacao
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            multiline
                            sx={{ width: "100%" }}
                            rows={4}
                            type="textarea"
                          />
                        </Box>
                        {console.log(errors)}
                        <input name="id" value={Number(licencaId)} type="number" hidden />
                        <input name="userId" value={Number(userId)} type="number" hidden />
                        <Box my={2}>
                          <Box
                            mt={3}
                            display="flex"
                            justifyContent="space-evenly"
                          >
                            <Button
                              loading={isSubmitting}
                              type="submit"
                              variant="contained"
                              color="success"
                              onClick={handleSubmit}
                            >
                              Salvar
                            </Button>
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() => {
                                handleClose();
                              }}
                            >
                              Fechar
                            </Button>
                          </Box>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </Box>
              )}
            </Box>
          </Fade>
        </Modal>
      </Paper>
    </>
  );
};
