import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { LoadingButton } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import ApiService from "app/services/config";
import { ErrorMessage, Form, Formik } from "formik";

import React, { useEffect, useState } from "react";
import * as yup from "yup";
import MoneyInput from "../MoneyInput/MoneyInput";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";

const validationSchema = yup.object().shape({
  descricao: yup.string().required("Campo obrigatório"),
  valor: yup.string().required("Campo obrigatório").typeError("Valor inválido"),
  status_pagamento: yup.string().required("Campo obrigatório"),
  categoria: yup
    .number("Campo obrigatório")
    .required("Campo obrigatório")
    .typeError("Campo obrigatório"),
  data_vencimento: yup
    .date("Campo obrigatório")
    .required("Campo obrigatório")
    .typeError("Data inválida"),
});

const AccordionCreateContasAPagar = ({ getContasAPagar, getContasAPagarAgenda }) => {
  const [categorias, setCategorias] = useState([]);
  const [fornecedores, setFornecedores] = useState([]);
  const initialValues = {
    descricao: "",
    valor: 0,
    status_pagamento: "",
    categoria: "",
    data_vencimento: "",
  };

  const Swal = useSwalWrapper();

  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const getCategorias = async () => {
    try {
      await ApiService.get("/categoria-contas/all").then((response) => {
        if (response.status === 200) {
          setCategorias(response.data);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getFornecedores = async () => {
    try {
      await ApiService.get("/fornecedores/all").then((response) => {
        if (response.status === 200) {
          setFornecedores(response.data);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (values, {resetForm}) => {
    try {
      const valuesEdited = {
        ...values,
        valor: parseFloat((values.valor).replace(",", "."))
      }
      await ApiService.post("/contas-a-pagar", valuesEdited).then((response) => {
        if (response.status === 201) {
          toast("success", "Conta criada com sucesso!", "success");
          getContasAPagar();
          getContasAPagarAgenda();
          resetForm();
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCategorias();
    getFornecedores();
  }, []);
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
        <Typography>Registrar nova Conta a Pagar</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnBlur
          validateOnChange
          onSubmit={handleSubmit}
        >
          {({
            values,
            isSubmitting,
            handleChange,
            updateInputValue,
            setFieldValue,
            errors,
          }) => (
            <Form width="100%">
              <Grid container width="100%" spacing={2} p={2}>
                <Grid item xs={4} lg={4}>
                  <FormGroup>
                    <InputLabel>Descrição</InputLabel>
                    <TextField
                      name="descricao"
                      type="text"
                      value={values.descricao}
                      onChange={handleChange}
                      fullWidth
                      placeholder="Descrição"
                    />
                    <ErrorMessage
                      component={"div"}
                      name="descricao"
                      style={{ color: "red" }}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={4} lg={4}>
                  <FormGroup>
                    <MoneyInput
                      defaultValue={values.valor}
                      label={"Valor"}
                      name="valor"
                      setFieldValue={setFieldValue}
                    />
                    <ErrorMessage
                      component={"div"}
                      name="valor"
                      style={{ color: "red" }}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={4} lg={4}>
                  <FormGroup>
                    <InputLabel>Status do Pagamento</InputLabel>
                    <Select
                      value={values.status_pagamento}
                      onChange={handleChange}
                      name="status_pagamento"
                    >
                      <MenuItem value="pendente">Pendente</MenuItem>
                      <MenuItem value="atrasado">Atrasado</MenuItem>
                      <MenuItem value="pago">Pago</MenuItem>
                    </Select>
                    <ErrorMessage
                      component={"div"}
                      name="status_pagamento"
                      style={{ color: "red" }}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={4} lg={4}>
                  <FormGroup>
                    <InputLabel>Categoria</InputLabel>
                    <Select
                      value={values.categoria}
                      onChange={handleChange}
                      name="categoria"
                    >
                      <MenuItem value={"selecionar"}>Selecionar</MenuItem>
                      {categorias &&
                        categorias.map((categoria) => (
                          <MenuItem value={categoria.id}>
                            {categoria.nome}
                          </MenuItem>
                        ))}
                    </Select>
                    <ErrorMessage
                      component={"div"}
                      name="categoria"
                      style={{ color: "red" }}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={4} lg={4}>
                  <FormGroup>
                    <InputLabel>Data</InputLabel>
                    <TextField
                      type="date"
                      name="data_vencimento"
                      value={values.data_vencimento}
                      onChange={handleChange}
                    />
                    <ErrorMessage
                      component={"div"}
                      name="data_vencimento"
                      style={{ color: "red" }}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={4} lg={4}>
                  <FormGroup>
                    <InputLabel>Beneficiário</InputLabel>
                    <TextField
                      name="beneficiario"
                      type="text"
                      value={values.beneficiario}
                      onChange={handleChange}
                      fullWidth
                      placeholder="Beneficiário"
                    />
                    <ErrorMessage
                      component={"div"}
                      name="beneficiario"
                      style={{ color: "red" }}
                    />
                  </FormGroup>
                </Grid>
              </Grid>
              <Grid
                container
                width="100%"
                display="flex"
                justifyContent={"center"}
                style={{ my: 1 }}
              >
                <LoadingButton
                  loading={isSubmitting}
                  type="submit"
                  style={{ margin: "auto", display: "block" }}
                  variant="contained"
                  color="primary"
                >
                  {"Criar"}
                </LoadingButton>
              </Grid>
            </Form>
          )}
        </Formik>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionCreateContasAPagar;
