import { Avatar, Box, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";

const ArquivoWrapper = ({
  arquivo,
  tipoLicenca,
  onRemoverArquivo,
  tipoArquivo,
}) => {
  const [corArquivo, setCorArquivo] = useState([]);

  const handleCorArquivo = () => {
    let extensao;
    if (
      tipoArquivo === "documentoLicenca" ||
      tipoArquivo === "anexoCondicionante"
    ) {
      extensao = arquivo.id
        ? String(arquivo.path).split(".")[1]?.toUpperCase()
        : arquivo.extensao;
    } else {
      extensao = arquivo.id
        ? String(arquivo.path_documento).split(".")[1].toUpperCase()
        : arquivo.extensao;
    }
    switch (extensao) {
      case "JPG":
        return "#006BAF";
      case "PNG":
        return "#6CBE1E";
      case "PDF":
        return "#F93332";
      case "DOCX":
        return "#006BAF";
      default:
        return "#006BAF";
    }
  };

  useEffect(() => {
    setCorArquivo(handleCorArquivo());
  }, [arquivo]);

  return (
    <Box
      maxWidth={"100%"}
      my={1}
      sx={{
        border: "1px solid #A7A7A7",
        display: "flex",
        justifyContent: "space-between",
        cursor: "pointer",
        "&:hover": {
          background: "#f0f0f0",
        },
      }}
    >
      <Stack
        direction="row"
        gap={1}
        p={1}
        width={"50%"}
        onClick={() => {
          if (arquivo.id) {
            window.open(
              `${process.env.REACT_APP_API_KEY}/public/uploads/documentos/${
                tipoArquivo === "documentoLicenca" ||
                tipoArquivo === "anexoCondicionante"
                  ? arquivo.path
                  : arquivo.path_documento
              }`,
              "_blank"
            );
          }
        }}
      >
        <Avatar
          variant="square"
          sx={{
            background: corArquivo,
            padding: 2,
            borderRadius: 1,
          }}
        >
          {tipoArquivo === "documentoLicenca" ||
          tipoArquivo === "anexoCondicionante" ? (
            <>
              {arquivo.id
                ? String(arquivo.path).split(".")[1]?.toUpperCase()
                : arquivo.extensao}
            </>
          ) : (
            <>
              {arquivo.id
                ? String(arquivo.path_documento).split(".")[1].toUpperCase()
                : arquivo.extensao}
            </>
          )}
        </Avatar>
        <Stack direction="column" maxWidth="70%">
          <Typography sx={{ fontWeight: 600 }}>{tipoLicenca}</Typography>
          <Typography
            color={"#A5A5A5"}
            noWrap
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {tipoArquivo === "documentoLicenca" ||
            tipoArquivo === "anexoCondicionante" ? (
              <>{arquivo.id ? String(arquivo.path) : arquivo.nome}</>
            ) : (
              <>
                {arquivo.id
                  ? String(arquivo.path_documento).split(".")[1].toUpperCase()
                  : arquivo.nome}
              </>
            )}
            {arquivo.id ? arquivo.path_documento : arquivo.nome}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" p={1} textAlign={"right"}>
        <Stack direction="column" p={1} textAlign={"right"}>
          <Typography>
            Upload:{" "}
            {arquivo.id
              ? moment(arquivo.createdAt).format("DD/MM/YYYY")
              : arquivo.dataUpload}
          </Typography>
          <Typography>
            {tipoArquivo === "anexoCondicionante"
              ? `${(arquivo.tamanho_arquivo / 1024).toFixed(2)} KB`
              : `${(arquivo.tamanho_documento / 1024).toFixed(2)} KB`}
          </Typography>
        </Stack>
        <Stack>
          <IconButton
            onClick={() =>
              arquivo.id
                ? onRemoverArquivo(arquivo.id)
                : onRemoverArquivo(arquivo)
            }
          >
            <DeleteIcon color={"error"} />
          </IconButton>
        </Stack>
      </Stack>
    </Box>
  );
};

export default ArquivoWrapper;
