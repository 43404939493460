import { Avatar, Box, Chip, Grid, Stack, Typography } from "@mui/material";
import moment from "moment";
import React from "react";

const DetalhesClienteContrato = ({ contrato }) => {
  return (
    <>
      <Grid item xs={4}>
        <Typography variant={"h4"} fontWeight={600}>
          Dados do Cliente:
        </Typography>{" "}
      </Grid>
      <Grid item xs={4}>
        <Typography fontWeight={600} fontSize={16}>
          Cliente:
        </Typography>{" "}
        <Typography fontSize={16}>{contrato?.Cliente?.nome}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" gap={2}>
          <Typography fontWeight={600} fontSize={16}>
            E-mail:
          </Typography>{" "}
          <Typography fontSize={16}>{contrato?.Cliente?.email}</Typography>
          <Typography fontWeight={600} fontSize={16}>
            Telefone:
          </Typography>{" "}
          <Typography fontSize={16}>
            {contrato?.Cliente?.telefone ?? "N/I"}
          </Typography>
          <Typography fontWeight={600} fontSize={16}>
            {contrato?.Cliente?.tipo_pessoa === "fisica" ? "CPF" : "CNPJ"}:
          </Typography>{" "}
          <Typography fontSize={16}>
            {contrato?.Cliente?.tipo_pessoa === "fisica"
              ? contrato?.Cliente?.cpf
              : contrato?.Cliente?.cnpj}
            :
          </Typography>
          <Typography fontWeight={600} fontSize={16}>
            Segmento do Cliente:
          </Typography>{" "}
          <Typography fontSize={16}>
            {contrato?.Cliente?.segmento_cliente?.descricao ?? "N/I"}
          </Typography>
        </Stack>
      </Grid>
    </>
  );
};

export default DetalhesClienteContrato;
