import { Chip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

const CustomChipLicencasUnidade = ({ situacao }) => {
  const [status, setStatus] = useState("Não informado");
  const [corDeFundo, setCorDeFundo] = useState("#585858");

  useEffect(() => {
    if (situacao) {
      switch (situacao) {
        case "nao_configurada":
          setStatus("Não configurada");
          setCorDeFundo("#82A2B9");
          break;
        case "nova":
          setStatus("NOVA");
          setCorDeFundo("#82A2B9");
          break;
        case "nao_iniciada":
          setStatus("Não iniciada");
          setCorDeFundo("#82A2B9");
          break;
        case "nao_iniciado":
          setStatus("Não iniciado");
          setCorDeFundo("#82A2B9");
          break;
        case "em_andamento":
          setStatus("Em andamento");
          setCorDeFundo("#82A2B9");
          break;
        case "EM ANDAMENTO":
          setStatus("Em andamento");
          setCorDeFundo("#82A2B9");
          break;
        case "Em acompanhamento":
          setStatus("Em acompanhamento");
          setCorDeFundo("#82A2B9");
          break;
        case "PROTOCOLADO":
          setStatus("Protocolado");
          setCorDeFundo("#82A2B9");
          break;
        case "MODIFICAÇÕES PÓS-FINALIZAÇÃO":
          setStatus("Modificações Pós-finalização");
          setCorDeFundo("#82A2B9");
          break;
        case "N/A":
          setStatus("N/A");
          setCorDeFundo("#82A2B9");
          break;
        case "vigente":
          setStatus("Vigente");
          setCorDeFundo("#3BD2A2");
          break;
        case "FINALIZADO":
          setStatus("Finalizado");
          setCorDeFundo("#3BD2A2");
          break;
        case "concluida":
          setStatus("Concluído");
          setCorDeFundo("#3BD2A2");
          break;
        case "regular":
          setStatus("Regular");
          setCorDeFundo("#3BD2A2");
          break;
        case "cumprida":
          setStatus("Cumprida");
          setCorDeFundo("#3BD2A2");
          break;
        case "permanente":
          setStatus("Permanente");
          setCorDeFundo("#3BD2A2");
          break;
        case "em_obtencao":
          setStatus("Em obtenção");
          setCorDeFundo("#F5AB3E");
          break;
        case "protocolo_vencido":
          setStatus("Protocolo vencido");
          setCorDeFundo("#F5AB3E");
          break;
        case "parcialmente_irregular":
          setStatus("Parc. irregular");
          setCorDeFundo("#F5AB3E");
          break;
        case "NECESSITA DE APROVAÇÃO":
          setStatus("Necess. de aprovação");
          setCorDeFundo("#F5AB3E");
          break;
        case "À SER DISCUTIDO EM REUNIÃO":
          setStatus("À ser discutido em reunião");
          setCorDeFundo("#F5AB3E");
          break;
        case "vencida":
          setStatus("Vencida");
          setCorDeFundo("#EC5F6F");
          break;
        case "PARALISADO":
          setStatus("Paralisado");
          setCorDeFundo("#EC5F6F");
          break;
        case "dispensada":
          setStatus("Dispensada");
          setCorDeFundo("#EC5F6F");
          break;
        case "atrasada":
          setStatus("Atrasada");
          setCorDeFundo("#EC5F6F");
          break;
        case "irregular":
          setStatus("Irregular");
          setCorDeFundo("#EC5F6F");
          break;
        case "Não":
          setStatus("Não");
          setCorDeFundo("#82A2B9");
          break;
        case true:
          setStatus("Sim");
          setCorDeFundo("#F5AB3E");
          break;
        default:
          setStatus(situacao);
          setCorDeFundo("#2EB5C9");
      }
    }
  }, [situacao]);

  return (
    situacao &&
    corDeFundo && (
      <Chip
        label={<Typography>{status ?? "Não informado"}</Typography>}
        sx={{
          width: "100%",
          color: "white",
          background: corDeFundo,
        }}
      />
    )
  );
};

export default CustomChipLicencasUnidade;
