import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { LoadingButton } from "@mui/lab";
import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
  Typography,
  IconButton,
  useMediaQuery,
  InputAdornment,
  AccordionDetails,
  Divider,
  Card,
  Paper,
  PaginationItem,
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import ApiService from "app/services/config";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DescriptionIcon from "@mui/icons-material/Description";
import PaidIcon from "@mui/icons-material/Paid";
import { CircularProgressWithLabel } from "app/components/CircularProgressWithLabel/CircularProgressWithLabel";
import ModeIcon from "@mui/icons-material/Mode";
import ModalEditarTarefa from "app/components/ModalEditarTarefa/ModalEditarTarefa";
import ReplayIcon from "@mui/icons-material/Replay";
import StarIcon from "@mui/icons-material/Star";
import EditIcon from "@mui/icons-material/Edit";
import { v4 } from "uuid";
import { PermissionContext } from "app/contexts/PermissionContext";
import CustomChipLicencasUnidade from "app/components/CustomChipLicencasUnidade/CustomChipLicencasUnidade";
import CheckIcon from "@mui/icons-material/Check";
import ServicosTarefasList from "app/components/ServicosTarefasList/ServicosTarefasList";

const ID_STATUS_SERVICO_PROTOCOLADO = 7;

const ServicosList = () => {
  const roleId = Number(localStorage.getItem("role_id"));
  const userId = Number(localStorage.getItem("id"));
  const [contratos, setContratos] = useState([]);
  const [statusServico, setStatusServico] = useState([]);
  const [selectedServicosIds, setSelectedServicosIds] = useState([]);
  const [statusServicoSelecionado, setStatusServicoSelecionado] = useState("");
  const [idsTarefasSelecionadas, setIdsTarefasSelecionadas] = useState([]);
  const [idTarefaSelecionadaParaEdicao, setIdTarefaSelecionadaParaEdicao] =
    useState(null);
  const [isModalEdicaoTarefaAberto, setIsModalEdicaoTarefaAberto] =
    useState(false);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");
  const [count, setCount] = useState("");
  const [todosServicosSelecionados, setTodosServicosSelecionados] =
    useState(false);
  const [mostrarBotaoDeletarMuitos, setMostrarBotaoDeletarMuitos] =
    useState(false);
  const [filtroSelecionarContratos, setFiltroSelecionarContratos] =
    useState("todos");
  const [filtroSituacaoContratos, setFiltroSituacaoContratos] =
    useState("padrao");

  const { theme } = useJumboTheme();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );
  const navigate = useNavigate();
  const { hasPermission } = useContext(PermissionContext);

  if (!hasPermission("Serviços", "read")) {
    navigate("/app/");
  }
  const Swal = useSwalWrapper();
  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const modalAlert = (id) => {
    Swal.fire({
      title: "Tem certeza que deseja apagar?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteServico(id);
      }
    });
  };

  const handleAtualizarStatusServicos = async () => {
    try {
      await ApiService.put(`/servicotarefa/atualizar/status/servico/varios`, {
        tarefasIds: idsTarefasSelecionadas,
        statusId: statusServicoSelecionado,
      }).then((response) => {
        if (response.status === 200) {
          toast("success", "Status de serviços atualizados!");
          setIdsTarefasSelecionadas([]);
          setStatusServicoSelecionado("");
          getContratos();
        }
      });
    } catch (error) {
      toast("error", "Ocorreu um erro!");
      console.log(error);
    }
  };

  const handleSearch = async (newSearchTerm) => {
    if (searchTerm === "" || searchTerm === null || searchTerm === undefined) {
      setPage(1);
    }
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  const getContratos = async () => {
    setContratos([]);
    try {
      let apiUrl = `/contratos?page=${
        page + 1
      }&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}&tipoContrato=${filtroSelecionarContratos}&situacao=${filtroSituacaoContratos}&roleId=${roleId}&userId=${userId}`;

      if (searchTerm) {
        apiUrl += `&searchTerm=${searchTerm}`;
      }

      const response = await ApiService.get(apiUrl);
      const initialData = response.data.contratos.map((contrato) => ({
        ...contrato,
        isChecked: false,
        servicosAgrupados: agruparServicosPorProcessoAdministrativo(
          contrato.servicos
        ),
      }));
      setContratos(initialData);
      setCount(response.data.total);
    } catch (error) {
      console.log(error);
    }
  };

  const agruparServicosPorProcessoAdministrativo = (servicos) => {
    return servicos.reduce((agrupados, servico) => {
      if (servico.processoAdministrativoId !== null) {
        const processoAdministrativo =
          servico.processo_administrativo?.descricao || "Não informado";
        if (!agrupados[processoAdministrativo]) {
          agrupados[processoAdministrativo] = [];
        }
        agrupados[processoAdministrativo].push(servico);
      }
      return agrupados;
    }, {});
  };

  // TODO
  // const handleSelecionarTodosIdsTarefas = () => {
  //   const idsTarefas = contratos.servicos
  //     ?.flatMap((servico) => servico.tarefas?.map((tarefa) => tarefa.id))
  //     .filter((id) => id !== null && id !== undefined);
  //   setIdsTarefasSelecionadas(idsTarefas);
  // };

  useEffect(() => {
    getContratos();
    getStatusServicos();
  }, [
    page,
    perPage,
    sortBy,
    sortOrder,
    searchTerm,
    filtroSelecionarContratos,
    filtroSituacaoContratos,
  ]);

  const deleteServico = useCallback(async (id) => {
    try {
      ApiService.delete(`/servicos/${id}`).then(() => {
        toast("sucess", "Removido com sucesso!");
        // getServicos();
      });
    } catch (error) {
      const message = error.response.data.message;
      toast("error", message);
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("error", error.message);
      }
    }
  });

  const handleCheckboxChange = (contratoId) => {
    setContratos((prevContratos) =>
      prevContratos.map((contrato) =>
        contrato.id === contratoId
          ? { ...contrato, isSelected: !contrato.isSelected }
          : contrato
      )
    );

    setSelectedServicosIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(contratoId)) {
        return prevSelectedIds.filter((id) => id !== contratoId);
      } else {
        return [...prevSelectedIds, contratoId];
      }
    });
  };

  const handleModalEdicaoTarefa = () => {
    setIsModalEdicaoTarefaAberto(!isModalEdicaoTarefaAberto);
  };

  const getStatusServicos = async () => {
    try {
      await ApiService.get("/statusservico").then((response) => {
        setStatusServico(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const calcularPorcentagemConclusao = (contrato) => {
    if (contrato.servicos.length === 0) {
      return 0;
    }
    const somaDasRealizacoes = contrato.servicos.reduce(
      (totalServico, servico) =>
        totalServico +
        servico.tarefas.reduce(
          (totalTarefa, tarefa) =>
            totalTarefa + (tarefa.realizacao ? tarefa.realizacao : 0),
          0
        ),
      0
    );

    const numeroTotalDeTarefas = contrato.servicos.reduce(
      (totalServico, servico) => totalServico + servico.tarefas.length,
      0
    );

    const porcentagemConclusao =
      (somaDasRealizacoes / (numeroTotalDeTarefas * 100)) * 100;

    return porcentagemConclusao > 100
      ? 100
      : porcentagemConclusao
      ? porcentagemConclusao
      : 0;
  };

  return (
    <JumboContentLayout
      header={<PageHeader title={"Serviços"} />}
      layoutOptions={layoutOptions}
    >
      {lg && (
        <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}></Stack>
      )}
      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <React.Fragment>
          <Box my={1} width={"100%"} textAlign={"start"}>
            {idsTarefasSelecionadas.length > 0 && (
              <Stack direction={"row"} gap={2} alignItems={"center"}>
                <Typography>
                  {idsTarefasSelecionadas.length} tarefa (s) selecionada (s)
                </Typography>
                {/* <Button
                  variant={"contained"}
                  onClick={handleSelecionarTodosIdsTarefas}
                >
                  Selecionar todas
                </Button> */}
              </Stack>
            )}
          </Box>
          <Grid
            container
            p={3}
            my={3}
            style={{ background: "white", minHeight: "120px" }}
          >
            <Box mt={2} mb={2} gap={5}>
              <TextField
                label="Buscar"
                variant="outlined"
                value={searchTerm}
                onChange={(e) => handleSearch(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => handleSearch(searchTerm)}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Grid item my={-0.6} mx={1}>
              <InputLabel>Selecionar:</InputLabel>
              <Select
                id="selecionar-contratos-select"
                value={filtroSelecionarContratos}
                onChange={(event) => {
                  const value = event.target.value;
                  setFiltroSelecionarContratos(value);
                }}
              >
                <MenuItem value={"todos"}>Todos os contratos</MenuItem>
                <MenuItem value={"padrao"}>Contratos Padrão</MenuItem>
                <MenuItem value={"fixo"}>Contratos Fixos</MenuItem>
                <MenuItem value={"renovacao"}>Contratos de Renovação</MenuItem>
              </Select>
            </Grid>
            <Grid item my={-0.6}>
              <InputLabel>Situação:</InputLabel>
              <Select
                id="situacao-contratos-select"
                value={filtroSituacaoContratos}
                onChange={(event) => {
                  const value = event.target.value;
                  setFiltroSituacaoContratos(value);
                }}
              >
                <MenuItem value={"padrao"}>Contratos Padrão</MenuItem>
                <MenuItem value={"aguardando_analise"}>
                  Contratos Aguardando Análise
                </MenuItem>
              </Select>
            </Grid>
            {idsTarefasSelecionadas.length > 0 && (
              <>
                <Grid item my={-0.6} mx={1}>
                  <InputLabel>Atualizar status do (s) serviço (s):</InputLabel>
                  <Select
                    id="situacao-contratos-select"
                    value={statusServicoSelecionado}
                    onChange={(event) => {
                      const value = event.target.value;
                      setStatusServicoSelecionado(value);
                    }}
                    displayEmpty
                    fullWidth
                  >
                    <MenuItem value="" disabled>
                      Selecione um status
                    </MenuItem>
                    {statusServico.map((status) => (
                      <MenuItem value={status.id}>{status.descricao}</MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item my={2.5} mx={1}>
                  <Button
                    color="info"
                    variant="contained"
                    disabled={statusServicoSelecionado === ""}
                    onClick={handleAtualizarStatusServicos}
                  >
                    Atualizar
                  </Button>
                </Grid>
              </>
            )}
            {contratos.length > 0 && (
              <>
                {contratos.map((contrato) => (
                  <Card sx={{ mb: 1, p: 2 }} style={{ width: "100%" }}>
                    <Accordion
                      key={contrato.id}
                      p={2}
                      style={{ width: "100%" }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Grid
                          container
                          justifyContent="space-around"
                          alignItems="center"
                        >
                          <Grid item lg={4}>
                            <Box
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Typography fontSize={"16px"}>
                                Contrato
                              </Typography>
                              <Typography
                                fontSize={"18px"}
                                style={{ fontWeight: "500" }}
                              >
                                <Chip
                                  color="success"
                                  label={
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {contrato?.descricao}
                                      {contrato?.tipocontratocontrato.some(
                                        (tipoContrato) =>
                                          String(
                                            tipoContrato.tipos_contratos
                                              .descricao
                                          ).toLowerCase() ===
                                          "Renovação".toLowerCase()
                                      ) && (
                                        <ReplayIcon
                                          fontSize={"small"}
                                          gap={10}
                                        />
                                      )}
                                      {contrato.tipocontratocontrato.some(
                                        (tipoContrato) =>
                                          String(
                                            tipoContrato.tipos_contratos
                                              .descricao
                                          ).toLowerCase() === "contrato fixo"
                                      ) && (
                                        <StarIcon fontSize={"small"} gap={10} />
                                      )}
                                    </Box>
                                  }
                                />
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item lg={4}>
                            <Box
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Typography fontSize={"16px"}>Cliente</Typography>
                              <Typography
                                fontSize={"18px"}
                                style={{ fontWeight: "500" }}
                              >
                                {contrato.Cliente.nome}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid item lg={4}>
                          <Box
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Typography fontSize={"16px"}>Conclusão</Typography>
                            <Typography
                              fontSize={"18px"}
                              style={{ fontWeight: "500" }}
                            >
                              {calcularPorcentagemConclusao(contrato).toFixed(2)
                                ? `${calcularPorcentagemConclusao(
                                    contrato
                                  ).toFixed(2)}%`
                                : Number(0).toFixed(2)}
                            </Typography>
                          </Box>
                        </Grid>
                        {contrato.servicos.some((servico) =>
                          servico.tarefas.some(
                            (tarefa) =>
                              tarefa.status_servico_id ===
                              ID_STATUS_SERVICO_PROTOCOLADO
                          )
                        ) && (
                          <Grid item lg={3} mx={1}>
                            <CustomChipLicencasUnidade
                              situacao={
                                statusServico.find(
                                  (status) =>
                                    status.id === ID_STATUS_SERVICO_PROTOCOLADO
                                )?.descricao || "Status não encontrado"
                              }
                            />
                          </Grid>
                        )}

                        <Grid item lg={2}>
                          <Box style={{ display: "flex", gap: "10px" }}>
                            <Link
                              to={`/app/contrato/${contrato.id}/financeiro`}
                            >
                              <Button
                                color="success"
                                variant="contained"
                                size="small"
                                disableElevation
                              >
                                <PaidIcon />
                              </Button>
                            </Link>
                            <Link to={`/app/editar-contrato/${contrato.id}`}>
                              <Button
                                color="info"
                                variant="contained"
                                size="small"
                                disableElevation
                              >
                                <EditIcon />
                              </Button>
                            </Link>
                          </Box>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails>
                        {Object.entries(contrato.servicosAgrupados).map(
                          ([processo, servicos]) => (
                            <Accordion
                              key={`${contrato.id}-${processo}`}
                              style={{ marginTop: 6 }}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Typography variant="h4">
                                  Processo Administrativo: {processo}
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                {Object.entries(servicos).map(
                                  ([servicoId, servico]) => (
                                    <>
                                      {(roleId !== 1
                                        ? servico?.servicocolaborador?.some(
                                            (colaborador) =>
                                              colaborador?.users?.id === userId
                                          )
                                        : true) && (
                                        <ServicosTarefasList
                                          servico={servico}
                                          idsTarefasSelecionadas={
                                            idsTarefasSelecionadas
                                          }
                                          setIdsTarefasSelecionadas={
                                            setIdsTarefasSelecionadas
                                          }
                                          statusServico={
                                            servico.status_andamento_servico
                                          }
                                          contratoId={contrato.id}
                                          clienteId={contrato.Cliente.id}
                                          processoId={servico.processo_contrato?.id}
                                          procAdminId={servico.processoAdministrativoId}
                                        />
                                      )}
                                    </>
                                  )
                                )}
                              </AccordionDetails>
                            </Accordion>
                          )
                        )}
                      </AccordionDetails>
                    </Accordion>
                    <ModalEditarTarefa
                      aberto={isModalEdicaoTarefaAberto}
                      handleClose={handleModalEdicaoTarefa}
                      servicoTarefaId={idTarefaSelecionadaParaEdicao}
                      setServicoTarefaId={setIdTarefaSelecionadaParaEdicao}
                      atualizarListaContratos={getContratos}
                    />
                  </Card>
                ))}
              </>
            )}

            <Paper style={{ background: "white", width: "100%" }}>
              <FormControl>
                <Grid
                  container
                  spacing={2}
                  alignItems={"center"}
                  display={"flex"}
                  justifyContent={"space-evenly"}
                >
                  <Grid item>
                    <Box my={2}>
                      <Typography>Página</Typography>
                      <Pagination
                        count={Math.ceil(count / perPage)}
                        page={page + 1}
                        onChange={(event, value) => setPage(value - 1)}
                        variant="outlined"
                        shape="rounded"
                        size="large"
                        renderItem={(item) => (
                          <PaginationItem
                            slots={{
                              previous: ArrowBackIcon,
                              next: ArrowForwardIcon,
                            }}
                            {...item}
                          />
                        )}
                        style={{ marginTop: 10 }}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box>
                      <Typography>Itens por página</Typography>
                      <TextField
                        select
                        label={"Itens por página"}
                        value={perPage}
                        onChange={(e) => setPerPage(parseInt(e.target.value))}
                        style={{ marginTop: 10 }}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                      </TextField>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box>
                      <Typography>Ordenar por</Typography>
                      <TextField
                        select
                        label={"Ordenação"}
                        value={sortBy}
                        onChange={(e) => setSortBy(e.target.value)}
                        style={{ marginTop: 10 }}
                      >
                        <MenuItem value={"id"}>ID</MenuItem>
                        <MenuItem value={"createdAt"}>Data de Criação</MenuItem>
                      </TextField>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box>
                      <Typography>Ordem</Typography>
                      <TextField
                        select
                        label={"Ordem"}
                        value={sortOrder}
                        onChange={(e) => setSortOrder(e.target.value)}
                        style={{ marginTop: 10 }}
                      >
                        <MenuItem value={"asc"}>Ascendente</MenuItem>
                        <MenuItem value={"desc"}>Descendente</MenuItem>
                      </TextField>
                    </Box>
                  </Grid>
                </Grid>
              </FormControl>
            </Paper>
          </Grid>
        </React.Fragment>
      </Grid>
    </JumboContentLayout>
  );
};

export default ServicosList;
