const today = new Date();
const currentYear = today.getFullYear();

function getRandomStatus() {
    const statuses = ['pendente', 'pago', 'atrasado'];
    return statuses[Math.floor(Math.random() * statuses.length)];
}

export const calendarData = {
    events: [
        {
            title: 'Pagamento da fatura do cartão',
            allDay: true,
            start: new Date(currentYear, 3, 0),
            end: new Date(currentYear, 3, 1),
            status: getRandomStatus(),
        },
        {
            title: 'Aluguel do escritório',
            start: new Date(currentYear, 3, 7),
            end: new Date(currentYear, 3, 10),
            status: getRandomStatus(),
        },
        {
            title: 'DTS STARTS',
            start: new Date(2016, 2, 13, 0, 0, 0),
            end: new Date(2016, 2, 20, 0, 0, 0),
            status: getRandomStatus(),
        },
        {
            title: 'DTS ENDS',
            start: new Date(2016, 10, 6, 0, 0, 0),
            end: new Date(2016, 10, 13, 0, 0, 0),
            status: getRandomStatus(),
        },
        {
            title: 'Conta de energia',
            start: new Date(currentYear, 3, 9, 0, 0, 0),
            end: new Date(currentYear, 3, 9, 0, 0, 0),
            status: getRandomStatus(),
        },
        {
            title: 'Conferência de negócios',
            start: new Date(currentYear, 3, 11),
            end: new Date(currentYear, 3, 13),
            desc: 'Conferência importante',
            status: getRandomStatus(),
        },
        {
            title: 'Reunião com o contador',
            start: new Date(currentYear, 3, 12, 10, 30, 0, 0),
            end: new Date(currentYear, 3, 12, 12, 30, 0, 0),
            desc: 'Reunião de preparação financeira',
            status: getRandomStatus(),
        },
        {
            title: 'Almoço com cliente',
            start: new Date(currentYear, 3, 12, 12, 0, 0, 0),
            end: new Date(currentYear, 3, 12, 13, 0, 0, 0),
            desc: 'Almoço de negócios',
            status: getRandomStatus(),
        },
        {
            title: 'Pagamento de fornecedores',
            start: new Date(currentYear, 3, 12, 14, 0, 0, 0),
            end: new Date(currentYear, 3, 12, 15, 0, 0, 0),
            status: getRandomStatus(),
        },
        {
            title: 'Happy Hour com equipe',
            start: new Date(currentYear, 3, 12, 17, 0, 0, 0),
            end: new Date(currentYear, 3, 12, 17, 30, 0, 0),
            desc: 'Momento de descontração',
            status: getRandomStatus(),
        },
        {
            title: 'Jantar com parceiro de negócios',
            start: new Date(currentYear, 3, 12, 20, 0, 0, 0),
            end: new Date(currentYear, 3, 12, 21, 0, 0, 0),
            status: getRandomStatus(),
        },
        {
            title: 'Aniversário do cliente',
            start: new Date(currentYear, 3, 13, 7, 0, 0),
            end: new Date(currentYear, 3, 13, 10, 30, 0),
            status: getRandomStatus(),
        },
        {
            title: 'Aniversário do fornecedor',
            start: new Date(currentYear, 3, 13, 7, 0, 0),
            end: new Date(currentYear, 3, 13, 10, 30, 0),
            status: getRandomStatus(),
        },
        {
            title: 'Reunião com parceiros',
            start: new Date(currentYear, 3, 13, 7, 0, 0),
            end: new Date(currentYear, 3, 13, 10, 30, 0),
            status: getRandomStatus(),
        },
        {
            title: 'Evento noturno com clientes',
            start: new Date(currentYear, 3, 17, 19, 30, 0),
            end: new Date(currentYear, 3, 18, 2, 0, 0),
            status: getRandomStatus(),
        },
        {
            title: 'Evento de múltiplos dias',
            start: new Date(currentYear, 3, 20, 19, 30, 0),
            end: new Date(currentYear, 3, 22, 2, 0, 0),
            status: getRandomStatus(),
        },
    ],
};

export const cultures = [
    {id: 'en', title: 'en'},
    {id: 'en-GB', title: 'en-GB'},
    {id: 'es', title: 'es'},
    {id: 'fr', title: 'fr'},
    {id: 'ar-AE', title: 'ar-AE'},
];
