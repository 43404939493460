import {
  Box,
  Button,
  Divider,
  Fade,
  Modal,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import HistoryIcon from "@mui/icons-material/History";
import ApiService from "app/services/config";
import moment from "moment";
import { Link } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const STATUS_LICENCA = {
  vigente: "Vigente",
  vencida: "Vencida",
  em_obtencao: "Em obtenção",
  permanente: "Permanente",
  dispensada: "Dispensada",
  protocolo_vencido: "Protocolo Vencido",
};

const ModalHistoricoCondicionante = ({ modalAberto, handleClose, condicionanteId }) => {
  const [aberto, setAberto] = useState(modalAberto);
  const [historico, setHistorico] = useState([]);
  const [take, setTake] = useState(5);
  const [id, setId] = useState(condicionanteId);
  const [isDadosCarregados, setIsDadosCarregados] = useState(false);

  const getHistorico = async () => {
    try {
      await ApiService.get(`condicionante/historico/${condicionanteId}?take=${take}`).then(
        (response) => {
          if (response.status === 200) {
            setHistorico(response.data);
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setAberto(modalAberto);
  }, [modalAberto]);

  useEffect(() => {
    setId(condicionanteId);
  }, []);

  useEffect(async () => {
    setIsDadosCarregados(false);
    const fetchData = async () => {
      await getHistorico();
    };
    await fetchData();
    setIsDadosCarregados(true);
  }, [modalAberto]);

  console.log(historico)

  return (
    <>
      {isDadosCarregados && (
        <Paper>
          <Modal
            open={aberto}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            closeAfterTransition
          >
            <Fade in={modalAberto}>
              <Box
                sx={{
                  ...style,
                }}
              >
                <Box my={1}>
                  <Typography
                    variant="h3"
                    gap={1}
                    alignItems={"center"}
                    display={"flex"}
                  >
                    <HistoryIcon /> Histórico da condicionante
                  </Typography>
                  <Divider my={1} />
                </Box>
                <Box style={{ maxHeight: "400px", overflowY: "scroll" }}>
                  {historico.map((historicoLicenca) => (
                    <Stack direction={"column"} width="100%" gap={1} p={1}>
                      <Typography variant={"h5"} sx={{ fontWeight: 600 }}>
                        {moment(historicoLicenca.createdAt).format(
                          "DD/MM/YYYY HH:mm"
                        )}
                      </Typography>
                      <Typography color={"#627387"}>
                        Usuário: {historicoLicenca.user?.name}
                      </Typography>
                      <Typography
                        color={"#627387"}
                        style={{ fontStyle: "italic" }}
                      >
                        {historicoLicenca.atividade}
                      </Typography>
                      {historicoLicenca.atividade === "Documento excluído" && (
                        <Box>
                          <Typography>
                            Arquivo:{" "}
                            <Link
                              style={{
                                color: "#2EB5C9",
                                textDecoration: "none",
                              }}
                              to={`${process.env.REACT_APP_API_KEY}/public/uploads/documentos/${historicoLicenca.descricao_atividade}`}
                              target="_blank"
                            >
                              {historicoLicenca.descricao_atividade}
                            </Link>
                          </Typography>
                        </Box>
                      )}
                      {historicoLicenca.atividade === "Upload de arquivos" && (
                        <Box>
                          <Typography>
                            Total:{" "}
                            {historicoLicenca.total_anexos_cadastrados}
                          </Typography>
                        </Box>
                      )}
                      {historicoLicenca.atividade === "Observação atualizada" && (
                        <Box>
                          <Typography>
                            Observação:{" "}
                            {historicoLicenca.descricao_atividade}
                          </Typography>
                        </Box>
                      )}
                      {historicoLicenca.atividade === "Tarefas cadastradas" && (
                        <Box>
                          <Typography>
                            Total:{" "}
                            {historicoLicenca.total_tarefas_cadastradas}
                          </Typography>
                        </Box>
                      )}
                      <Divider />
                    </Stack>
                  ))}
                </Box>

                <Box my={2}>
                  <Box mt={3} display="flex" justifyContent="space-evenly">
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Fechar
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Fade>
          </Modal>
        </Paper>
      )}
    </>
  );
};

export default ModalHistoricoCondicionante;
