import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Pagination,
  PaginationItem,
  Paper,
  Stack,
  TextField,
  Typography,
  Select,
  Avatar,
  Tooltip,
} from "@mui/material";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import { useMediaQuery } from "beautiful-react-hooks";
import React, { useCallback, useEffect, useState } from "react";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import CardIconText from "@jumbo/shared/CardIconText";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArchiveIcon from "@mui/icons-material/Archive";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AddIcon from "@mui/icons-material/Add";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import { Visibility } from "@mui/icons-material";
import moment from "moment";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ApiService from "app/services/config";
import axios from "axios";
import { v4 } from "uuid";
import { DateRangePicker } from "react-dates";
import CustomChipLicencasUnidade from "app/components/CustomChipLicencasUnidade/CustomChipLicencasUnidade";

const CondicionantesList = () => {
  const [condicionantes, setCondicionantes] = useState([]);
  const [responsaveis, setResponsaveis] = useState([]);
  const [page, setPage] = useState(0);
  const location = useLocation();
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("desc");
  const [searchTerm, setSearchTerm] = useState("");
  const [count, setCount] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const [todosItensSelecionados, setTodosItensSelecionados] = useState(false);
  const [totalCondicionantes, setTotalCondicionantes] = useState([]);
  const [totalCondicionantesArquivadas, setTotalCondicionantesArquivadas] =
    useState([]);
  const [totalCondicionantesNaoIniciadas, setTotalCondicionantesNaoIniciadas] =
    useState([]);
  const [totalCondicionantesCumpridas, setTotalCondicionantesCumpridas] =
    useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [isOcultarScores, setIsOcultarScores] = useState(false);
  const { theme } = useJumboTheme();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const [filtrosPesquisa, setFiltrosPesquisa] = useState({
    status: [],
    responsaveisIds: [],
    termoBusca: "",
    data_inicio: "",
    data_final: "",
    page: 0,
    is_arquivada: false,
    perPage: 10,
    sortOrder: "desc",
    sortBy: "createdAt",
  });
  const navigate = useNavigate();
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const Swal = useSwalWrapper();
  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const modalAlert = (id, acao) => {
    Swal.fire({
      title: `Tem certeza que deseja ${
        acao === "deletar" ? "apagar" : "arquivar"
      }?`,
      text: `${
        acao === "deletar"
          ? "Não será possível reverter a ação!"
          : "Você pode desarquivar a condicionante posteriormente!"
      }`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        switch (acao) {
          case "deletar":
            deleteCondicionante(id);
            break;
          case "arquivar":
            arquivarCondicionante(id);
            break;
        }
      }
    });
  };

  const modalAlertVarios = (acao) => {
    Swal.fire({
      title: `Tem certeza que deseja ${
        acao === "deletar"
          ? "apagar as condicionantes"
          : `${
              acao === "arquivar" ? "arquivar " : "desarquivar "
            } as condicionantes`
      }?`,
      text: `${
        acao === "deletar"
          ? "Não será possível reverter a ação!"
          : `Você pode ${
              acao === "arquivar" ? "desarquivar " : "arquivar "
            } as condicionantes posteriormente!`
      }`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        switch (acao) {
          case "deletar":
            handleDeleteMany();
            break;
          case "arquivar":
            handleArquivarMany();
            break;
          case "desarquivar":
            handleDesarquivarMany();
            break;
        }
      }
    });
  };

  const modalAlertDesarquivar = (id) => {
    Swal.fire({
      title: `Tem certeza que quer desarquivar a condicionante?`,
      text: `Você poderá arquivá-la novamente posteriormente!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        desarquivarLicenca(id);
      }
    });
  };

  const deleteCondicionante = async (id) => {
    try {
      await ApiService.delete(`/condicionante/${id}`).then((response) => {
        if (response.status === 200) {
          toast("success", "Condicionante excluída!");
          getCondicionantes();
        }
      });
    } catch (error) {
      toast("error", "Ocorreu um erro!");
      console.log(error);
    }
  };

  const handleSelecionarTodosItens = () => {
    setSelectedIds(
      todosItensSelecionados
        ? []
        : condicionantes.map((condicionante) => condicionante.id)
    );
    setTodosItensSelecionados(!todosItensSelecionados);
  };

  const handleDeleteMany = async () => {
    try {
      await ApiService.delete("/condicionante/varias", selectedIds).then(
        (response) => {
          if (response.status === 200) {
            toast("success", "Condicionantes excluídas com sucesso!");
            getCondicionantes();
          }
          setSelectedIds([]);
          setTodosItensSelecionados(false);
        }
      );
    } catch (error) {
      toast("error", "Ocorreu um erro!");
      console.error(error);
    }
  };

  const handleArquivarMany = async () => {
    try {
      await ApiService.put("/condicionante/arquivar/varias", selectedIds).then(
        (response) => {
          if (response.status === 200) {
            toast("success", "Condicionantes arquivadas!");
            getCondicionantes();
          }
          setSelectedIds([]);
          setTodosItensSelecionados(false);
        }
      );
    } catch (error) {
      toast("error", "Ocorreu um erro!");
      console.error(error);
    }
  };

  const handleDesarquivarMany = async () => {
    try {
      await ApiService.put(
        "/condicionante/desarquivar/varias",
        selectedIds
      ).then((response) => {
        if (response.status === 200) {
          toast("success", "Condicionantes arquivadas!");
          getCondicionantes();
        }
        setSelectedIds([]);
        setTodosItensSelecionados(false);
      });
    } catch (error) {
      toast("error", "Ocorreu um erro!");
      console.error(error);
    }
  };

  const arquivarCondicionante = async (id) => {
    try {
      await ApiService.put(`/condicionante/arquivar/${id}`).then((response) => {
        if (response.status === 200) {
          toast("success", "Condicionante arquivada!");
          getCondicionantes();
        }
      });
    } catch (error) {
      toast("error", "Ocorreu um erro!");
      console.log(error);
    }
  };

  const desarquivarLicenca = async (id) => {
    try {
      await ApiService.put(`/condicionante/desarquivar/${id}`).then(
        (response) => {
          if (response.status === 200) {
            toast("success", "Condicionante desarquivada!");
            getCondicionantes();
          }
        }
      );
    } catch (error) {
      toast("error", "Ocorreu um erro!");
      console.log(error);
    }
  };

  const handleOcultarScores = () => {
    setIsOcultarScores(!isOcultarScores);
  };

  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
    if (startDate && endDate) {
      setFiltrosPesquisa((prevFiltros) => ({
        ...prevFiltros,
        dataInicioVencimento: moment(startDate._d).format("YYYY-MM-DD"),
        dataFinalVencimento: moment(endDate._d).format("YYYY-MM-DD"),
      }));
    }
  };

  const handleFocusChange = (focusedInput) => {
    setFocusedInput(focusedInput);
  };

  const getResponsaveis = useCallback(async () => {
    try {
      await ApiService.get("/users/all").then((response) => {
        setResponsaveis(response.data);
      });
    } catch (err) {
      console.log(err.message);
    }
  }, []);

  const getCondicionantes = async () => {
    try {
      let filtros = {};

      if (filtrosPesquisa.status.length > 0) {
        filtros.status = filtrosPesquisa.status;
      }

      if (filtrosPesquisa.responsaveisIds.length > 0) {
        filtros.responsaveisIds = filtrosPesquisa.responsaveisIds;
      }

      if (filtrosPesquisa.termoBusca) {
        filtros.termoBusca = filtrosPesquisa.termoBusca;
      }

      if (filtrosPesquisa.data_inicio && filtrosPesquisa.data_final) {
        filtros.data_inicio = filtrosPesquisa.data_inicio;
        filtros.data_final = filtrosPesquisa.data_final;
      }

      filtros.page = page;
      filtros.perPage = perPage;
      filtros.sortBy = sortBy;
      filtros.sortOrder = sortOrder;
      filtros.is_arquivada = filtrosPesquisa.is_arquivada;

      await ApiService.post("/condicionante/all", filtros).then((response) => {
        if (response.status === 201) {
          setCondicionantes(response.data.condicionantes);
          setTotalCondicionantes(response.data.totalCondicionantesCadastradas);
          setTotalCondicionantesArquivadas(
            response.data.totalCondicionantesArquivadas
          );
          setTotalCondicionantesNaoIniciadas(
            response.data.totalCondicionantesNaoIniciadas
          );
          setTotalCondicionantesCumpridas(response.data.totalCondicionantesCumpridas);
          setCount(response.data.count);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckboxChange = (itemId) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(itemId)) {
        return prevSelectedIds.filter((id) => id !== itemId);
      } else {
        return [...prevSelectedIds, itemId];
      }
    });
    setTodosItensSelecionados(false);
  };

  const handleLimparFiltros = () => {
    setStartDate(null);
    setEndDate(null);
    setFiltrosPesquisa({
      status: [],
      responsaveisIds: [],
      termoBusca: "",
      is_arquivada: false,
      data_inicio: "",
      data_final: "",
      page: 0,
      perPage: 10,
      sortOrder: "desc",
      sortBy: "createdAt",
    });
  };

  useEffect(() => {
    getResponsaveis();
    getCondicionantes();
  }, [filtrosPesquisa]);

  return (
    <JumboContentLayout
      header={<PageHeader title={"Condicionantes"} />}
      layoutOptions={layoutOptions}
    >
      {lg && (
        <Stack
          spacing={2}
          direction={"row"}
          sx={{ mb: 1, mt: -2, justifyContent: "space-between" }}
        ></Stack>
      )}
      <>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Box my={1}>
            <Button
              variant="contained"
              color="info"
              sx={{ gap: 1 }}
              onClick={handleOcultarScores}
            >
              {isOcultarScores ? <VisibilityIcon /> : <VisibilityOffIcon />}{" "}
              {isOcultarScores ? "Visualizar" : "Ocultar"} scores
            </Button>
          </Box>
        </Box>
        <Stack
          spacing={2}
          direction={"row"}
          sx={{
            my: 1,
            width: "100%",
            display: "flex",
          }}
        >
          <Box sx={{ display: "flex", gap: 2 }}>
            <React.Fragment>
              <CardIconText
                icon={<WorkspacePremiumIcon fontSize={"large"} />}
                onHoverIcon={<WorkspacePremiumIcon fontSize={"large"} />}
                title={totalCondicionantes}
                subTitle={"Condicionantes cadastradas"}
                color={"#005D5F"}
                sx={{ visibility: isOcultarScores ? "hidden" : "visible" }}
              />
              <CardIconText
                icon={<CheckCircleIcon fontSize={"large"} />}
                onHoverIcon={<CheckCircleIcon fontSize={"large"} />}
                title={totalCondicionantesArquivadas}
                subTitle={"Arquivada"}
                color={"#005D5F"}
                sx={{ visibility: isOcultarScores ? "hidden" : "visible" }}
              />
              <CardIconText
                icon={<ArchiveIcon fontSize={"large"} />}
                onHoverIcon={<ArchiveIcon fontSize={"large"} />}
                title={totalCondicionantesCumpridas}
                subTitle={"Cumprida"}
                color={"#005D5F"}
                sx={{ visibility: isOcultarScores ? "hidden" : "visible" }}
              />
              <CardIconText
                icon={<ArchiveIcon fontSize={"large"} />}
                onHoverIcon={<ArchiveIcon fontSize={"large"} />}
                title={totalCondicionantesNaoIniciadas}
                subTitle={"Não iniciada"}
                color={"#005D5F"}
                sx={{ visibility: isOcultarScores ? "hidden" : "visible" }}
              />
            </React.Fragment>
          </Box>
        </Stack>
      </>

      <React.Fragment>
        <Grid
          container
          spacing={2}
          justifyContent="flex-end"
          alignItems="center"
          my={1}
        >
          <Grid item>
            <Button variant={"contained"} onClick={handleSelecionarTodosItens}>
              {todosItensSelecionados ? "Desmarcar tudo" : "Selecionar todas"}
            </Button>
          </Grid>
          {selectedIds.length > 0 && (
            <Grid item display={"flex"} gap={1}>
              <span style={{ margin: 10 }}>
                {selectedIds.length} condicionante (s) selecionada(s)
              </span>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={() => {
                  modalAlertVarios("deletar");
                }}
              >
                Excluir Selecionados
              </Button>
              <Button
                variant="contained"
                color="info"
                startIcon={
                  filtrosPesquisa.is_arquivada ? (
                    <UnarchiveIcon />
                  ) : (
                    <ArchiveIcon />
                  )
                }
                onClick={() => {
                  {
                    filtrosPesquisa.is_arquivada
                      ? modalAlertVarios("desarquivar")
                      : modalAlertVarios("arquivar");
                  }
                }}
              >
                {filtrosPesquisa.is_arquivada
                  ? "Desarquivar selecionados"
                  : "Arquivar selecionados"}
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid
          container
          p={3}
          mt={3}
          gap={3}
          style={{ background: "white", minHeight: "120px" }}
        >
          <Grid item xs={3}>
            <Box mt={2} mb={2}>
              <TextField
                label="Buscar"
                variant="outlined"
                value={filtrosPesquisa.termoBusca}
                onChange={(e) =>
                  setFiltrosPesquisa((prevFiltros) => ({
                    ...prevFiltros,
                    termoBusca: e.target.value,
                  }))
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => console.log(searchTerm)}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Box>
          </Grid>
          <Grid item xs={1.5}>
            <Box mt={2} mb={2} my={-0.6}>
              <FormLabel id="status-licenca">Status</FormLabel>
              <Select
                id="status-licenca"
                label="Status da Condicionante"
                onChange={(e) => {
                  const status = e.target.value;
                  if (status.length === 1 && status[0] == "") {
                    setFiltrosPesquisa((prevFiltros) => ({
                      ...prevFiltros,
                      status: [],
                    }));
                  } else if (status.some((value) => value === "")) {
                    setFiltrosPesquisa((prevFiltros) => ({
                      ...prevFiltros,
                      status: [],
                    }));
                  } else {
                    setFiltrosPesquisa((prevFiltros) => ({
                      ...prevFiltros,
                      status: status,
                    }));
                  }
                }}
                value={filtrosPesquisa.status ?? ""}
                fullWidth
                multiple
              >
                <MenuItem value="">Selecionar</MenuItem>
                <MenuItem value="nao_iniciada">Não iniciada</MenuItem>
                <MenuItem value="em_andamento">Em andamento</MenuItem>
                <MenuItem value="cumprida">Cumprida</MenuItem>
                <MenuItem value="atrasada">Atrasada</MenuItem>
              </Select>
            </Box>
          </Grid>
          <Grid item xs={2.2}>
            <Box mt={2} mb={2} my={-0.6}>
              <FormLabel id="prazo-condicionante">Prazo</FormLabel>
              <Box>
                <DateRangePicker
                  small={true}
                  startDate={startDate}
                  startDatePlaceholderText="Data inicial"
                  startDateId="data_inicio"
                  endDate={endDate}
                  endDateId="data_final"
                  endDatePlaceholderText="Data final"
                  onDatesChange={handleDatesChange}
                  focusedInput={focusedInput}
                  onFocusChange={handleFocusChange}
                  isOutsideRange={() => false}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={1.8}>
            <Box mt={2} mb={2} my={-0.6}>
              <FormLabel id="responsavel-unidade">Responsável</FormLabel>
              <Select
                id="responsavel-unidade"
                label="Responsável"
                multiple
                fullWidth
                onChange={(e) => {
                  const responsavel = e.target.value;
                  if (responsavel.length === 1 && responsavel[0] === "") {
                    setFiltrosPesquisa((prevFiltros) => ({
                      ...prevFiltros,
                      responsaveisIds: [],
                    }));
                  } else if (responsavel.some((value) => value === "")) {
                    setFiltrosPesquisa((prevFiltros) => ({
                      ...prevFiltros,
                      responsaveisIds: [],
                    }));
                  } else {
                    setFiltrosPesquisa((prevFiltros) => ({
                      ...prevFiltros,
                      responsaveisIds: responsavel,
                    }));
                  }
                }}
                value={filtrosPesquisa.responsaveisIds}
              >
                <MenuItem value="">Selecionar</MenuItem>
                {responsaveis &&
                  responsaveis.length > 0 &&
                  responsaveis.map((responsavel) => (
                    <MenuItem value={responsavel.id}>
                      {responsavel.name}
                    </MenuItem>
                  ))}
              </Select>
            </Box>
          </Grid>
          {condicionantes &&
            condicionantes.length > 0 &&
            condicionantes.map((condicionante) => (
              <Card sx={{ mb: 1, p: 2 }} style={{ width: "100%" }}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item lg={1}>
                    <Checkbox
                      key={condicionante.id}
                      checked={selectedIds.includes(condicionante.id)}
                      onChange={() => handleCheckboxChange(condicionante.id)}
                    />
                  </Grid>
                  <Grid item lg={2}>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: 500,
                          marginBottom: 5,
                          fontSize: "18px",
                        }}
                      >
                        Condicionante
                      </Typography>
                      <Typography
                        style={{ color: "#808080", fontSize: "18px" }}
                      >
                        {condicionante.nome_condicionante}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={2}>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: 500,
                          marginBottom: 5,
                          fontSize: "18px",
                        }}
                      >
                        Unidade
                      </Typography>
                      <Typography
                        style={{ color: "#808080", fontSize: "18px" }}
                      >
                        {condicionante.unidade?.nomeUnidade}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={1.5}>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: 500,
                          marginBottom: 5,
                          fontSize: "18px",
                        }}
                      >
                        Prazo
                      </Typography>
                      <Typography
                        style={{ color: "#808080", fontSize: "18px" }}
                      >
                        {condicionante.data_prazo
                          ? moment
                              .parseZone(condicionante.data_prazo)
                              .utc()
                              .format("DD/MM/YYYY")
                          : "N/I"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={2}>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: 500,
                          marginBottom: 5,
                          fontSize: "18px",
                        }}
                      >
                        Responsáveis
                      </Typography>
                      <Stack direction="row" display="flex" gap={1}>
                        {condicionante.responsaveis.length > 0 &&
                          condicionante.responsaveis?.map((responsavel) => (
                            <Tooltip title={responsavel.user.name ?? ""}>
                              <Avatar
                                sx={{ width: "24px", height: "24px" }}
                                src={
                                  responsavel.user.avatarUrl
                                    ? `${process.env.REACT_APP_API_KEY}/public/uploads/${responsavel.user.avatarUrl}`
                                    : null
                                }
                              >
                                {responsavel.user.name
                                  ? responsavel.user.name
                                      .charAt(0)
                                      .toUpperCase()
                                  : "P"}
                              </Avatar>
                            </Tooltip>
                          ))}
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid item lg={1.5}>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: 500,
                          marginBottom: 5,
                          fontSize: "18px",
                        }}
                      >
                        Status
                      </Typography>
                      <Typography
                        fontSize={"18px"}
                        style={{ fontWeight: "500" }}
                      >
                        <CustomChipLicencasUnidade
                          situacao={condicionante.status}
                        />
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item gap={2} display={"flex"} direction={"row"}>
                    <Box>
                      <Visibility
                        sx={{ cursor: "pointer" }}
                        color="success"
                        onClick={() => {
                          navigate(`/app/unidade/${condicionante.unidade.id}`, {
                            state: {
                              condicionanteId: condicionante.id,
                              aba: "condicionantes",
                            },
                          });
                        }}
                      />
                    </Box>
                    <Box>
                      {!condicionante.is_arquivada ? (
                        <ArchiveIcon
                          sx={{ cursor: "pointer" }}
                          color="info"
                          onClick={() => {
                            modalAlert(condicionante.id, "arquivar");
                          }}
                        />
                      ) : (
                        <UnarchiveIcon
                          sx={{ cursor: "pointer" }}
                          color="warning"
                          onClick={() => {
                            modalAlertDesarquivar(condicionante.id);
                          }}
                        />
                      )}
                    </Box>
                    <Box>
                      {/* <EditIcon
                        color="info"
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate(`/app/editar-licenca/${licenca.id}`);
                        }}
                      /> */}
                    </Box>
                    <Box>
                      <DeleteIcon
                        color="error"
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          modalAlert(condicionante.id, "deletar");
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Card>
            ))}
          <Paper
            style={{
              background: "white",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <FormControl>
              <Grid
                container
                spacing={2}
                alignItems={"center"}
                display={"flex"}
                justifyContent={"space-evenly"}
              >
                <Grid item textAlign={"center"}>
                  <Box my={2}>
                    <Typography>Página</Typography>
                    <Pagination
                      count={Math.ceil(count / filtrosPesquisa.perPage)}
                      page={filtrosPesquisa.page + 1}
                      onChange={(event, value) =>
                        setFiltrosPesquisa((prevFiltros) => ({
                          ...prevFiltros,
                          page: value - 1,
                        }))
                      }
                      variant="outlined"
                      shape="rounded"
                      size="large"
                      renderItem={(item) => (
                        <PaginationItem
                          slots={{
                            previous: ArrowBackIcon,
                            next: ArrowForwardIcon,
                          }}
                          {...item}
                        />
                      )}
                      style={{ marginTop: 10 }}
                    />
                  </Box>
                </Grid>
                <Grid item textAlign={"center"}>
                  <Box>
                    <Typography>Itens por página</Typography>
                    <TextField
                      select
                      label={"Itens por página"}
                      value={filtrosPesquisa.perPage}
                      onChange={(e) =>
                        setFiltrosPesquisa((prevFiltros) => ({
                          ...prevFiltros,
                          perPage: e.target.value,
                        }))
                      }
                      style={{ marginTop: 10 }}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={30}>30</MenuItem>
                    </TextField>
                  </Box>
                </Grid>
                <Grid item textAlign={"center"}>
                  <Box>
                    <Typography>Ordenar por</Typography>
                    <TextField
                      select
                      label={"Ordenação"}
                      value={filtrosPesquisa.sortBy}
                      onChange={(e) =>
                        setFiltrosPesquisa((prevFiltros) => ({
                          ...prevFiltros,
                          sortBy: e.target.value,
                        }))
                      }
                      style={{ marginTop: 10 }}
                    >
                      <MenuItem value={"createdAt"}>Data de Criação</MenuItem>
                    </TextField>
                  </Box>
                </Grid>
                <Grid item textAlign={"center"}>
                  <Box>
                    <Typography>Ordem</Typography>
                    <TextField
                      select
                      label={"Ordem"}
                      value={filtrosPesquisa.sortOrder}
                      onChange={(e) =>
                        setFiltrosPesquisa((prevFiltros) => ({
                          ...prevFiltros,
                          sortOrder: e.target.value,
                        }))
                      }
                      style={{ marginTop: 10 }}
                    >
                      <MenuItem value={"asc"}>Ascendente</MenuItem>
                      <MenuItem value={"desc"}>Descendente</MenuItem>
                    </TextField>
                  </Box>
                </Grid>
                <Grid item textAlign={"center"}>
                  <Box>
                    <Typography>Listar condicionantes</Typography>
                    <TextField
                      select
                      label={"Listar Condicionantes"}
                      value={filtrosPesquisa.is_arquivada}
                      onChange={(e) =>
                        setFiltrosPesquisa((prevFiltros) => ({
                          ...prevFiltros,
                          is_arquivada: e.target.value,
                        }))
                      }
                      style={{ marginTop: 10 }}
                    >
                      <MenuItem value={true}>Arquivadas</MenuItem>
                      <MenuItem value={false}>Não arquivadas</MenuItem>
                    </TextField>
                  </Box>
                </Grid>
                <Grid item textAlign={"center"}>
                  <Box>
                    <Typography>Filtros</Typography>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        handleLimparFiltros();
                      }}
                    >
                      Resetar
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </FormControl>
          </Paper>
        </Grid>
      </React.Fragment>
    </JumboContentLayout>
  );
};

export default CondicionantesList;
