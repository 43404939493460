import axios from "axios";

// O CEP é somente números - sem traços
export const consultarCEP = async (cep) => {
  if (cep.length === 9) {
    const dadosCep = await axios
      .get(`https://api.brasilaberto.com/v1/zipcode/${cep}`)
      .then((response) => {
        if (response.status === 200) {
          return response.data.result;
        }
      });
    return { dados: dadosCep };
  }
};
