import React, { useState } from "react";
import { Card, CardContent, Typography, IconButton, Box } from "@mui/material";
import Div from "@jumbo/shared/Div";
import * as yup from "yup";
import { Form, Formik } from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import LoadingButton from "@mui/lab/LoadingButton";
import authServices from "../../services/auth-services";
import { useNavigate, useParams } from "react-router-dom";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const validationSchema = yup.object({
  password: yup
    .string()
    .required("Preenchimento obrigatório")
    .matches(
      /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
      "Sua senha deve conter: números, caracteres especiais, letras maiúsculas e minúsculas, e ter no mínimo 8 caracteres"
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "As senhas devem ser iguais")
    .required("Confirme sua senha"),
});

export const ResetSenha = ({ disableSmLogin }) => {
  const { token } = useParams();
  const navigate = useNavigate();
  const Swal = useSwalWrapper();
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: "Fechar",
      timer: 3000
    });
  };

  const handleResetPassword = async (data, setSubmitting) => {
    try {
      delete data.confirmPassword;
      const response = await authServices.resetPassword(token, data);
      if (response.status === 200) {
        toast("success", "Senha redefinida com sucesso.");
        navigate("/login");
      }
    } catch (err) {
      if (
        err.response &&
        err.response.data &&
        err.response.data.error &&
        err.response.data.message
      ) {
        toast("error", err.response.data.message);
      } else {
        console.error(err);
        toast("error", "Ocorreu um erro ao redefinir a senha.");
      }
    }

    setSubmitting(false);
  };

  return (
    <Div
      sx={{
        width: 750,
        maxWidth: "100%",
        margin: "auto",
        p: 4,
      }}
    >
      <Card
        sx={{
          display: "flex",
          minWidth: 0,
          flexDirection: { xs: "column", md: "row" },
          backgroundColor: "#005D5F",
        }}
      >
        <CardContent sx={{ flex: 1, p: 4 }}>
          <Formik
            validateOnChange={true}
            initialValues={{
              password: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting }) => {
              setSubmitting(true);
              handleResetPassword(data, setSubmitting);
            }}
          >
            {({
              isSubmitting,
              handleChange,
              values,
              setFieldTouched,
              setFieldValue,
              errors,
              touched,
            }) => (
              <Form style={{ textAlign: "left" }} noValidate autoComplete="off">
                <Div sx={{ mt: 1, mb: 3 }}>
                  <Typography sx={{ color: "#fff" }}>
                    Informe a nova senha para redefinir sua senha. Sua senha
                    deve conter:
                  </Typography>
                  <Box>
                    <Typography
                      sx={{
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {errors.senha && touched.senha ? (
                        <span style={{ color: "red", marginRight: "5px" }}>
                          ✘
                        </span>
                      ) : (
                        <span style={{ color: "green", marginRight: "5px" }}>
                          ✔
                        </span>
                      )}
                      Números
                    </Typography>
                    <Typography
                      sx={{
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {errors.password && touched.password ? (
                        <span style={{ color: "red", marginRight: "5px" }}>
                          ✘
                        </span>
                      ) : (
                        <span style={{ color: "green", marginRight: "5px" }}>
                          ✔
                        </span>
                      )}
                      Caracteres especiais
                    </Typography>
                    <Typography
                      sx={{
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {errors.password && touched.password ? (
                        <span style={{ color: "red", marginRight: "5px" }}>
                          ✘
                        </span>
                      ) : (
                        <span style={{ color: "green", marginRight: "5px" }}>
                          ✔
                        </span>
                      )}
                      Letras maiúsculas e minúsculas
                    </Typography>
                    <Typography
                      sx={{
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {errors.password && touched.password ? (
                        <span style={{ color: "red", marginRight: "5px" }}>
                          ✘
                        </span>
                      ) : (
                        <span style={{ color: "green", marginRight: "5px" }}>
                          ✔
                        </span>
                      )}
                      Mínimo de 8 caracteres
                    </Typography>
                  </Box>
                </Div>
                <Div sx={{ mt: 1, mb: 3 }}>
                  <JumboTextField
                    fullWidth
                    id="password"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={values.password}
                    onChange={(e) => {
                      handleChange(e.target.value);
                      setFieldTouched("password", true, false);
                    }}
                    variant="filled"
                    sx={{ background: "#ffffff" }}
                    label="Nova Senha"
                    InputProps={{
                      endAdornment: (
                        <IconButton onClick={togglePasswordVisibility}>
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      ),
                    }}
                  />
                </Div>
                <Div sx={{ mt: 1, mb: 3 }}>
                  <JumboTextField
                    fullWidth
                    id="confirmarSenha"
                    name="confirmPassword"
                    type={showPassword ? "text" : "password"}
                    value={values.confirmPassword}
                    onChange={(e) => {
                      handleChange(e.target.value);
                      setFieldTouched("confirmPassword", true, false);
                    }}
                    variant="filled"
                    sx={{ background: "#ffffff" }}
                    label="Confirmar Senha"
                  />
                </Div>
                <LoadingButton
                  color="info"
                  fullWidth
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{ mb: 3 }}
                  loading={isSubmitting}
                >
                  Redefinir Senha
                </LoadingButton>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Div>
  );
};

export default ResetSenha;
