import React from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

const AlertDialog = ({ title, description, isOpen, showModal }) => {
    const [open, setOpen] = React.useState(isOpen);

    return (
        <Dialog
            open={open}
            onClose={() => {
                showModal(false)
                setOpen(false)
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {description}
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
};

export default AlertDialog;

