import { Box, Typography } from "@mui/material";
import React from "react";
import DescriptionIcon from "@mui/icons-material/Description";

const ListEmptyWrapper = ({message}) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <DescriptionIcon color="#A7A7A7" />

      <Typography>{message}</Typography>
    </Box>
  );
};

export default ListEmptyWrapper;
