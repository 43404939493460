import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

export default function MetricCard({ title, value, bgColor = '#005D5F', secondaryValue = null }) {

  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  function formatUndefined(value){
    if(!value){
      return 0
    }
    return value
  }

  const percentagePrimaryTitles = [
    'Margem líquida',
    'MC%',
    'Representatividade custo fixo',
    'Ponto de equilíbrio',
    'Ponto de equilíbrio econômico 15%',
    'Ponto de equilíbrio econômico 20%'
  ]

  const percentageTitles = [
    'Resultado total de caixa',
  ]

  value = formatUndefined(value)
  secondaryValue = formatUndefined(secondaryValue)

  const formattedValuePrimary = !percentagePrimaryTitles.includes(title) ? formatter.format(value) :  value?.toFixed(2) + '%';
  const formattedValue = percentageTitles.includes(title) ? formatter.format(secondaryValue) :  secondaryValue?.toFixed(2) + '%';

  const theme = value > 0 ? 'success.main' : 'error.light';
  const themeSecondary = secondaryValue > 0 ? 'success.main' : 'error.light';

  return (
    <Box>
      <Card variant="outlined" sx={{ backgroundColor: bgColor }}>
        <CardContent>
          <Box sx={{ display:'flex',flexDirection: 'row' }}>
            <Typography variant="h3" color={theme} sx={{ marginRight: '5px' }}>
                {formattedValuePrimary}
            </Typography>
            <Typography variant="h3" color={themeSecondary}>
                {secondaryValue ? '| '+formattedValue : ''}
            </Typography>
          </Box>
            
            <Typography variant="h5" color="success.contrastText">
                {title}
            </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}