import React, { useEffect, useState } from "react";
import JumboLayout from "@jumbo/components/JumboLayout";
import useJumboLayout from "@jumbo/hooks/useJumboLayout";
import layoutConfig from "./layoutConfig";

const SoloPage = ({ children }) => {
  const { setJumboLayoutOptions } = useJumboLayout();

  // Esse state é para renderizar o useEffect
  // Pelo menos duas vezes, para a página de login não ter o sidebar
  const [effectCount, setEffectCount] = useState(0);

  useEffect(() => {
    if (effectCount < 2) {
      setJumboLayoutOptions(layoutConfig);
      setEffectCount((count) => count + 1);
    }
  }, [effectCount, setJumboLayoutOptions]);

  return <JumboLayout>{children}</JumboLayout>;
};

export default SoloPage;
