import React from "react";
import { useNavigate } from "react-router-dom";
import {
  routesForAuthenticatedOnly,
  routesForNotAuthenticatedOnly,
  routesForPublic,
} from "../../../app/routes";
import useRoutePathMatch from "@jumbo/hooks/useRoutePathMatch";
import { removeToken, storeToken } from "./authHelpers";
import { config } from "../../../app/config/main";
import { AuthContext } from "@jumbo/components/JumboAuthProvider/JumboAuthContext";

const storedToken = localStorage.getItem("token");
const storedUsername = localStorage.getItem("username");
const storedUserEmail = localStorage.getItem("email");
const storedUserId = localStorage.getItem("id");
const storedAvatarUrl = localStorage.getItem("avatarUrl");
const storedUserRoleId = localStorage.getItem("role_id");
const storedPermissions = JSON.parse(localStorage.getItem("storedPermissions"));
const storedClienteId = localStorage.getItem("cliente_id");
let firstTimePageLoad = true;

const init = () => {
  let authUser = storedUsername ? storedUsername : null;

  if (!config?.authSetting) {
    throw Error(
      `You are using JumboAuthProvider but you haven't setup authSetting inside /src/app/config/main.js's config object`
    );
  }

  if (storedToken) {
    storeToken(
      storedToken,
      storedUsername,
      storedUserEmail,
      storedUserId,
      storedAvatarUrl,
      storedPermissions,
      storedUserRoleId,
      storedClienteId,
    ); // also sets axios header
  }

  return {
    authToken: storedToken ?? null,
    authUser: authUser ?? null,
    authEmail: storedUserEmail ?? null,
    authId: storedUserId ?? null,
    avatarUrl: storedAvatarUrl ?? null,
    permissoes: storedPermissions,
    role_id: storedUserRoleId,
    cliente_id: storedClienteId ?? null,
    isLoading: false,
    fallbackPath: config.authSetting.fallbackPath,
  };
};

const authReducer = (state, action) => {
  switch (action.type) {
    case "set-auth-data":
      return {
        ...state,
        ...action.payload,
      };

    case "start-loading":
      return {
        ...state,
        isLoading: true,
      };

    case "stop-loading":
      return {
        ...state,
        isLoading: false,
      };
  }
};

const JumboAuthProvider = ({ children, ...restProps }) => {
  const [authOptions, setAuthOptions] = React.useReducer(
    authReducer,
    restProps,
    init
  );
  const [logout, setLogout] = React.useState(false);
  const navigate = useNavigate();
  const isAuthenticatedRouteOnly = useRoutePathMatch(
    routesForAuthenticatedOnly
  );
  const isNotAuthenticatedRouteOnly = useRoutePathMatch(
    routesForNotAuthenticatedOnly
  );
  const isRoutesForPublic = useRoutePathMatch(routesForPublic);

  React.useEffect(() => {
    if (logout) {
      removeToken();
      setAuthOptions({
        type: "set-auth-data",
        payload: {
          authToken: null,
          authUser: null,
          authEmail: null,
          authId: null,
          isLoading: false,
          avatarUrl: null,
          permissoes: null,
          cliente_id: null
        },
      });
      setLogout(false);
    }
  }, [logout]);

  const setAuthToken = React.useCallback(
    async (token, username, email, id, avatarUrl, role, role_id, cliente_id) => {
      setAuthOptions({ type: "start-loading" });
      if (!token) {
        setLogout(true);
        return;
      }

      console.log(`Cliente ID: ${cliente_id}`)

      storeToken(token, username, email, id, avatarUrl, role, role_id, cliente_id);
      try {
        /*   const authUser = await config?.authSetting?.getAuthUserService(); */
        /*   console.log(authUser); */
        if (username) {
          setAuthOptions({
            type: "set-auth-data",
            payload: {
              authToken: token,
              authUser: username,
              authEmail: email,
              authId: id,
              isLoading: false,
              avatarUrl,
              permissoes: role,
              role_id: role_id,
              cliente_id: cliente_id
            },
          });
          return;
        }
        setLogout(true);
      } catch (error) {
        setLogout(true);
        console.error(error);
      }
    },
    []
  );

  //todo: maybe in next version
  const setRedirectPath = React.useCallback((redirectPath) => {
    setAuthOptions({ type: "set-redirect-path", payload: { redirectPath } });
  }, []);

  const setAuthData = React.useCallback((data) => {
    setAuthOptions({ type: "set-auth-data", payload: data });
  }, []);

  const contextValue = React.useMemo(() => {
    return {
      ...authOptions,
      setAuthData,
      setRedirectPath,
      setAuthToken,
      setAuthOptions,
    };
  }, [authOptions]);

  React.useEffect(() => {
    if (!authOptions.authToken) {
      if (isAuthenticatedRouteOnly) {
        navigate(authOptions?.fallbackPath);
      }
      if (isRoutesForPublic) {
        navigate();
      }
    } else if (!authOptions.authUser) {
      setAuthToken(authOptions.authToken);
    } else if (isNotAuthenticatedRouteOnly) {
      if (!firstTimePageLoad)
        navigate(config.authSetting.redirectNotAuthenticatedPath ?? "/");
      /*  else
                firstTimePageLoad = false; */
    }
  }, [authOptions.authUser]);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default JumboAuthProvider;
