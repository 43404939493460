import { ApiService } from "./config";
//import jwtAuthAxios from "./auth/jwtAuth";

const authServices = {};

authServices.getCurrentUser = async () => {
  //const {data} = await jwtAuthAxios.get("/auth");
  //return data;
};

//loginCreds must be {email: "abc@example.com", password: "ABC123DEF"}
authServices.signIn = async (loginCreds) => {
  const { data } = await ApiService.post("/login", loginCreds);

  return data;
};

authServices.verifySenha = async (loginCreds) => {
  const { data } = await ApiService.post("/consulta-pessoa", loginCreds);

  return data;
};

authServices.registerUser = async (loginCreds) => {
  const { data } = await ApiService.post("/cadastro-inicial", {
    values: loginCreds,
  });

  return data;
};

authServices.signUp = async (loginCreds) => {
  const { data } = await ApiService.post("/signup", { values: loginCreds });

  return data;
};

authServices.forgotPassword = async (email) => {
  const response = await ApiService.post("/forgot-password", email);
  return response;
};

authServices.resetPassword = async (token, request) => {
  const response = await ApiService.post(`/reset-password/${token}`, request);
  return response;
};

export default authServices;
