import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Grid,
  Stack,
  Toolbar,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import ApiService from "app/services/config";
import React, { useState, useEffect, useCallback, useContext } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import { LoadingButton } from "@mui/lab";
import MUIDataTable from "mui-datatables";
import { PermissionContext } from "app/contexts/PermissionContext";
import { useNavigate } from "react-router-dom";
import ModalCadastroImovelCliente from "app/components/ModalCadastroImovelCliente/ModalCadastroImovelCliente";
import EditIcon from "@mui/icons-material/Edit";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CustomChipLicencasUnidade from "app/components/CustomChipLicencasUnidade/CustomChipLicencasUnidade";
import moment from "moment";

const linkStyle = {
  color: "#302D2E",
  fontSize: 20,
  textDecoration: "none",
  "&:visited": {
    color: "#302D2E",
  },
};

const ServicosContratoList = () => {
  const { theme } = useJumboTheme();
  const location = useLocation();
  const { contratoId, clienteId, processoId, procadmin } = useParams();
  const [processosAdministrativos, setProcessosAdministrativos] = useState([]);
  const [breadCrumbsInfo, setBreadCrumbsInfo] = useState([]);
  const [processoAdministrativo, setProcessoAdministrativo] = useState(null);
  const [selectedProcessosIds, setSelectedProcessosIds] = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");
  const [count, setCount] = useState("");
  const [clienteSelecionado, setClienteSelecionado] = useState({
    id: null,
    nome: null,
  });
  const [todosProcessosSelecionados, setTodosProcessosSelecionados] =
    useState(false);
  const [mostrarBotaoDeletarMuitos, setMostrarBotaoDeletarMuitos] =
    useState(false);
  const [
    isModalAdicionarImovelClienteAberto,
    setIsModalAdicionarImovelClienteAberto,
  ] = useState(false);

  const navigate = useNavigate();
  const { hasPermission } = useContext(PermissionContext);
  const permission = hasPermission("Contratos", "create");
  if (!hasPermission("Contratos", "read")) {
    navigate("/app");
  }

  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const Swal = useSwalWrapper();
  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const handleSearch = async (newSearchTerm) => {
    if (searchTerm === "" || searchTerm === null || searchTerm === undefined) {
      setPage(1);
    }
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  const handleSelecionarTodosProcessos = () => {
    const todosIdsProcessos = processosAdministrativos.map(
      (processo) => processo.id
    );
    if (!todosProcessosSelecionados) {
      setSelectedProcessosIds(todosIdsProcessos);
    } else {
      setSelectedProcessosIds([]);
    }
    setTodosProcessosSelecionados(!todosProcessosSelecionados);
  };

  const handleDeleteMuitosProcessos = () => {
    Swal.fire({
      title: "Tem certeza que deseja apagar os processos selecionados?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteMuitosProcessos();
      }
    });
  };

  const deleteMuitosProcessos = useCallback(async () => {
    try {
      await ApiService.put("/servicos/delete/many", selectedProcessosIds).then(
        (response) => {
          if (response.status === 200) {
            toast("success", "Serviços removidos com sucesso!");
            getProcessos();
            setSelectedProcessosIds([]);
          }
        }
      );
    } catch (error) {
      toast("error", "Não foi possível deletar os serviços selecionados");
      console.log(error);
    }
  }, [selectedProcessosIds]);

  const getProcessos = useCallback(async () => {
    try {
      let apiUrl = `/servicos/contrato/${contratoId}/processo/${processoId}/?page=${
        page + 1
      }&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}`;

      if (searchTerm) {
        apiUrl += `&searchTerm=${searchTerm}`;
      }
      ApiService.get(apiUrl)
        .then((response) => {
          const initialData = response.data.servicos.map((processo) => ({
            ...processo,
            isSelected: selectedProcessosIds.includes(processo.id),
          }));
          setProcessosAdministrativos(initialData);
          setCount(response.data.total);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [page, perPage, sortBy, sortOrder, searchTerm, selectedProcessosIds]);

  useEffect(() => {
    getProcessos();
  }, [getProcessos]);

  const handleCheckboxChange = (processoId) => {
    setProcessosAdministrativos((prevProcessos) =>
      prevProcessos.map((processo) =>
        processo.id === processoId
          ? { ...processo, isSelected: !processo.isSelected }
          : processo
      )
    );

    setSelectedProcessosIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(processoId)) {
        return prevSelectedIds.filter((id) => id !== processoId);
      } else {
        return [...prevSelectedIds, processoId];
      }
    });

    setMostrarBotaoDeletarMuitos(selectedProcessosIds.length > 0);
    setTodosProcessosSelecionados(false);
  };

  const handleOpenModalAdicionarImovelCliente = () => {
    setIsModalAdicionarImovelClienteAberto(
      !isModalAdicionarImovelClienteAberto
    );
  };

  const columns = [
    {
      name: "selecionar",
      label: "Selecionar",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const processoId = tableMeta.rowData[1];
          const processo = processosAdministrativos.find(
            (processo) => processo.id === processoId
          );
          return (
            <Checkbox
              checked={processo.isSelected}
              onChange={() => handleCheckboxChange(processoId)}
            />
          );
        },
      },
    },
    {
      name: "id",
      label: "Id",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "tarefas_servico",
      label: "Tarefa",
      options: {
        filter: true,
        sort: true,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value[0]?.descricao ?? "N/I"}</>;
        },
      },
    },
    {
      name: "quantidade",
      label: "Quantidade",
      options: {
        filter: true,
        sort: true,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value}</>;
        },
      },
    },
    {
      name: "valor",
      label: "Valor",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const quantidade = tableMeta.rowData[3];
          const valor = tableMeta.rowData[4];
          return (
            <>
              {Number(value).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </>
          );
        },
      },
    },
    {
      name: "valor_total",
      label: "Valor Total",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const quantidade = tableMeta.rowData[3];
          const valor = tableMeta.rowData[4];
          return (
            <>
              {Number(quantidade * valor).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </>
          );
        },
      },
    },
    {
      name: "prazo",
      label: "Prazo",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value ? moment.utc(value).format("DD/MM/YYYY") : "N/I"}</>;
        },
      },
    },
    {
      name: "is_paralisado",
      label: "Paralisado",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <CustomChipLicencasUnidade
                situacao={value === false ? "Não" : value}
              />
            </>
          );
        },
      },
    },
    {
      name: "status_andamento_servico",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <CustomChipLicencasUnidade situacao={value ?? "N/I"} />
            </>
          );
        },
      },
    },
    {
      name: "servicocolaborador",
      label: "Colaboradores",
      options: {
        filter: true,
        sort: true,
        display: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {value && value.length > 0 ? (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: "4px" }}>
                  {value.map((colaborador) => (
                    <Tooltip
                      key={colaborador?.id}
                      title={colaborador?.users?.name}
                    >
                      <Avatar
                        sx={{ width: "24px", height: "24px" }}
                        src={`${process.env.REACT_APP_API_KEY}/public/uploads/${colaborador?.users?.avatarUrl}`}
                      >
                        {String(colaborador?.users?.name)
                          .charAt(0)
                          .toUpperCase()}
                      </Avatar>
                    </Tooltip>
                  ))}
                </Box>
              ) : (
                "N/I"
              )}
            </>
          );
        },
      },
    },
    {
      name: "action",
      label: "Ações",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const servicoId = tableMeta.rowData[1];
          return (
            <Box display={"flex"} gap={"1rem"}>
              <Tooltip title="Acompanhar Serviço">
                <VisibilityIcon
                  cursor="pointer"
                  color="info"
                  disabled={!permission}
                  onClick={() => {
                    navigate(
                      `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/servicos/${servicoId}/detalhes`,
                      {
                        state: {
                          descricao:
                            location?.state?.descricao ??
                            processosAdministrativos[0]?.contrato?.descricao,
                          processoDescricao: tableMeta.rowData[2],
                          idProcesso: tableMeta.rowData[1],
                        },
                      }
                    );
                  }}
                />
              </Tooltip>
              <Tooltip title="Editar Serviço">
                <EditIcon
                  cursor="pointer"
                  color="info"
                  disabled={!permission}
                  onClick={() => {
                    navigate(
                      `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/servicos/editar/${servicoId}`,
                      {
                        state: {
                          descricao:
                            location?.state?.descricao ??
                            processosAdministrativos[0]?.contrato?.descricao,
                          processoDescricao: tableMeta.rowData[2],
                          idProcesso: tableMeta.rowData[1],
                        },
                      }
                    );
                  }}
                />
              </Tooltip>
              <Tooltip title="Excluir Serviço">
                <DeleteIcon
                  cursor="pointer"
                  color="error"
                  onClick={() => {
                    navigate(
                      `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/servicos/${servicoId}/deletar`
                    );
                  }}
                />
              </Tooltip>
            </Box>
          );
        },
      },
    },
  ];

  const options = {
    changeRowsPerPage: perPage,
    filterType: "dropdown",
    filter: false,
    selectableRows: "none",
    searchAlwaysOpen: true,
    searchable: true,
    serverSide: true,
    page: page,
    count: count,
    selectToolbarPlacement: "above",

    onTableChange: (action, tableState) => {
      console.log(action);
      switch (action) {
        case "changePage":
          setPage(tableState.page);
          break;
        case "sort":
          setSortBy(tableState.sortOrder.name);
          setSortOrder(tableState.sortOrder.direction);
          break;
        case "search":
          handleSearch(tableState.searchText);
          break;
        case "changeRowsPerPage":
          setPerPage(tableState.rowsPerPage);
          break;
        default:
          console.log("action not handled.");
      }
    },
    customToolbar: ({ displayData }) => (
      <>
        {selectedProcessosIds.length > 0 ? (
          <Toolbar>
            <Box
              display={"flex"}
              gap={"1rem"}
              justifyContent={"flex-end"}
              width={"100%"}
            ></Box>
          </Toolbar>
        ) : null}
      </>
    ),
    textLabels: {
      body: {
        noMatch: "Nenhum resultado encontrado",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: "Próxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Itens por Página:",
        displayRows: "of",
      },
    },
  };

  const getBreadCrumbsInfo = async () => {
    try {
      return await ApiService.get(
        `/contratos/breadcrumbs?contratoId=${contratoId}&processoId=${processoId}`
      ).then((response) => {
        if (response.status === 200) {
          setBreadCrumbsInfo(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (contratoId) {
      getBreadCrumbsInfo();
    }
  }, [contratoId]);

  return (
    <JumboContentLayout layoutOptions={layoutOptions}>
      {lg && (
        <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}></Stack>
      )}
      <Stack width={"100%"}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link to={"/app/listar-contratos"} color="success" style={linkStyle}>
            Contratos
          </Link>
          {breadCrumbsInfo && (
            <Link
              to={`/app/editar-contrato/${contratoId}`}
              color="success"
              style={linkStyle}
            >
              {breadCrumbsInfo?.descricao}
            </Link>
          )}
          <Link
            to={`/app/contrato/${contratoId}/cliente/${clienteId}/processos`}
            color="primary"
            style={linkStyle}
          >
            Processos Administrativos
          </Link>
          {breadCrumbsInfo && (
            <Link
              to={`/app/contrato/${contratoId}/cliente/${clienteId}/processos/editar/${processoId}`}
              color="primary"
              style={linkStyle}
            >
              {breadCrumbsInfo?.processos_contratos?.[0]?.processo_administrativo?.descricao}
            </Link>
          )}
          <Link
            to={`/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/servicos`}
            color="primary"
            style={linkStyle}
          >
            Serviços
          </Link>
        </Breadcrumbs>
      </Stack>

      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <LoadingButton
          color="success"
          type="submit"
          variant="contained"
          size="large"
          sx={{
            maxWidth: { md: "200px", mt: "1rem" },
            marginBottom: "2rem",
          }}
          disabled={!permission}
          onClick={() => {
            navigate(
              `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/servicos/novo`,
              {
                state: {
                  descricao:
                    location?.state?.descricao ??
                    processosAdministrativos[0]?.contrato?.descricao,
                },
              }
            );
          }}
        >
          Novo Serviço
        </LoadingButton>
      </Grid>
      <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
        {selectedProcessosIds.length > 0 && (
          <Grid item>
            <span style={{ margin: 10 }}>
              {selectedProcessosIds.length} processo(s) selecionado(s)
            </span>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<DeleteIcon />}
              onClick={handleDeleteMuitosProcessos}
              disabled={!permission}
            >
              Excluir Selecionados
            </Button>
          </Grid>
        )}
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSelecionarTodosProcessos}
          >
            {todosProcessosSelecionados
              ? "Desmarcar Todos"
              : "Selecionar Todos"}
          </Button>
        </Grid>
      </Grid>

      <React.Fragment>
        <Box mt={3}>
          <MUIDataTable
            title={"Processos Administrativos"}
            data={processosAdministrativos}
            columns={columns}
            options={options}
          />
        </Box>
        <ModalCadastroImovelCliente
          aberto={isModalAdicionarImovelClienteAberto}
          handleClose={handleOpenModalAdicionarImovelCliente}
          clienteSelecionado={clienteSelecionado}
        />
      </React.Fragment>
    </JumboContentLayout>
  );
};

export default ServicosContratoList;
