import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Grid,
  Paper,
  Stack,
  useMediaQuery,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  TextField,
} from "@mui/material";
import { PermissionContext } from "app/contexts/PermissionContext";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import ApiService from "app/services/config";
import { Form, Formik } from "formik";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import makeAnimated from "react-select/animated";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  descricao: yup
    .string("Insira a descrição do Plano de conta")
    .required("Preenchimento obrigatório"),
  codigo: yup
    .number("Insira o código do Plano de conta")
    .required("Preenchimento obrigatório"),
});

const PlanoContasForm = () => {
  const initialValues = {
    descricao: "",
    codigo: "",
    // categoria_id: null,
  };
  const { id } = useParams();
  const [planoConta, setPlanoConta] = useState(initialValues);
  const [categories, setCategories] = useState([]);

  const [primaryCategory, setPrimaryCategory] = useState(null);
  const [secondaryCategory, setSecondaryCategory] = useState(null);
  const [tertiaryCategory, setTertiaryCategory] = useState(null);

  const [isSelected, setIsSelected] = useState(false);

  // const [quaternaryCategory, setQuaternaryCategory] = useState(null);

  const Swal = useSwalWrapper();
  const navigate = useNavigate();

  const { hasPermission } = useContext(PermissionContext);

  if (!hasPermission("Financeiro", "read")) {
    navigate("/app");
  }
  const { theme } = useJumboTheme();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const getPlanoConta = useCallback(async () => {
    try {
      ApiService.get(`/planocontas/${id}`)
        .then((response) => {
          setPlanoConta({
            ...response.data,
          });
          setIsSelected(response.data.in_regime_competencia);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleCheckboxChange = (checked) => {
    setIsSelected(!checked);
  };

  const getCategories = useCallback(async () => {
    try {
      ApiService.get(`/planocontas/categories`)
        .then((response) => {
          console.log("response.data", response.data);
          setCategories(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const clearCategorySelect = useCallback(async (currentStep) => {
    if (currentStep == 1) {
      setSecondaryCategory(null);
      setTertiaryCategory(null);
    } else if (currentStep == 2) {
      setTertiaryCategory(null);
    }
  }, []);

  useEffect(() => {
    if (id) {
      getPlanoConta();
    }

    getCategories();
  }, []);

  useEffect(() => {
    // console.log('useEffect', categories.length)
    if (planoConta.plano_conta_id && categories.length > 0) {
      const planoContaCategory = categories.find(
        (opt) => opt.id == planoConta.plano_conta_id
      );

      console.log(planoContaCategory)

      if (planoContaCategory.lista_plano_mae_id) {
        const categoriesList = planoContaCategory.lista_plano_mae_id.split(",");
        const length = categoriesList.length;

        console.log(categoriesList);

        const thirdCategory =
          length == 3 ? categoriesList[0] : planoConta.plano_conta_id;
        const fourthCategory = length == 3 ? planoConta.plano_conta_id : null;

        console.log("categoriesList", categoriesList);

        setPrimaryCategory(categoriesList[length - 1]);
        setSecondaryCategory(categoriesList[length - 2]);
        setTertiaryCategory(thirdCategory);
      }
    }
  }, [planoConta, categories]);

  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    // Define qual é o último nível de subcategoria, apenas ela é adicionada para ser enviada pelo form
    const plano_conta_id = isLastCategoryLevel
      ? tertiaryCategory
      : secondaryCategory;
    const in_regime_competencia = isSelected ? true : false;

    const allValuesEdit = { ...values, plano_conta_id, in_regime_competencia };
    delete allValuesEdit.id;
    delete allValuesEdit.in_demonstrativo_resultados;
    delete allValuesEdit.in_demonstrativo_setores_produtivos;
    delete allValuesEdit.createdAt;
    delete allValuesEdit.updatedAt;
    delete allValuesEdit.deletedAt;

    if (id) {
      ApiService.put(`/planocontas/${id}`, allValuesEdit)
        .then((response) => {
          if (response.data?.response?.status === 400) {
            toast("error", response.data?.response.error);
            return;
          }
          if (response.status === 200) {
            toast("success", "Atualizado com sucesso!");
            navigate("/app/plano-de-contas");
          }
        })
        .catch((error) => {
          let message = error.response.data.message;
          toast("error", message);

          if (error.response.data) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } else {
      await ApiService.post("/planocontas", allValuesEdit)
        .then((response) => {
          console.log(response);
          if (response.data?.response?.status === 400) {
            toast("error", response.data?.response.error);
            return;
          }
          if (response.status === 201) {
            toast("success", "Criado com sucesso");
            resetForm();
            navigate("/app/plano-de-contas");
          }
        })
        .catch((error) => {
          let message = error.response.data.message[0];
          toast("error", message);

          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    }
    setSubmitting(false);
  };
  const zz = categories.filter(
    (opt) => opt.codigo.length < 3 && opt.plano_mae_id == secondaryCategory
  );
  const isLastCategoryLevel = zz ? zz.length > 0 : false;

  // console.log('aaa', categories.filter(opt => opt.codigo.length < 3 && opt.plano_mae_id == 711).length > 0)

  return (
    <JumboContentLayout
      header={
        <HeaderBreadcrumbs
          id={id}
          title={"Plano de Contas"}
          subtitle={id ? "Editar" : "Cadastro de Plano de Conta"}
          titleUrl={"/app/plano-de-contas"}
        />
      }
      layoutOptions={layoutOptions}
    >
      {lg && (
        <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}></Stack>
      )}
      <Paper sx={{ p: "15px" }}>
        <Formik
          initialValues={planoConta}
          validationSchema={validationSchema}
          enableReinitialize
          validateOnChange={false}
          onSubmit={handleSubmit}
        >
          {({ values, isSubmitting, handleChange, setFieldValue, errors }) => (
            <Form style={{ width: "100%" }} noValidate autoComplete="off">
              <Grid
                container
                spacing={2}
                padding={2}
                columns={10}
                alignContent={"center"}
                // justifyContent={"center"}
              >
                <Grid item lg={2} md={6} xs={12}>
                  <TextField
                    type="number"
                    fullWidth
                    name="codigo"
                    label="Código"
                    required
                    value={values.codigo}
                    onChange={handleChange}
                    sx={{ background: "#ffffff", marginTop: "20px" }}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                  <JumboTextField
                    fullWidth
                    name="descricao"
                    label="Descrição"
                    required
                    value={values.descricao}
                    onChange={handleChange}
                    sx={{ background: "#ffffff", marginTop: "20px" }}
                  />
                </Grid>

                <Grid item lg={3} md={4} xs={12}>
                  <div style={{ position: "relative" }}>
                    <InputLabel id="role-usuario">Categoria</InputLabel>
                    <Select
                      labelId="role-usuario"
                      value={primaryCategory ? primaryCategory : ""}
                      onChange={(event) => {
                        setPrimaryCategory(event.target.value);
                        clearCategorySelect(1);
                      }}
                      fullWidth
                    >
                      {categories
                        .filter((opt) => opt.plano_mae_id == null)
                        .map((opt) => (
                          <MenuItem key={opt.id} value={opt.id}>
                            {opt.descricao}
                          </MenuItem>
                        ))}
                    </Select>
                  </div>
                </Grid>

                {primaryCategory && (
                  <Grid item lg={3} md={5} xs={12}>
                    <div style={{ position: "relative" }}>
                      <InputLabel id="role-usuario">Subcategoria 1</InputLabel>
                      {console.log(`SECONDARY: ${secondaryCategory}`)}
                      <Select
                        labelId="role-usuario"
                        value={secondaryCategory ? secondaryCategory : ""}
                        onChange={(event) => {
                          setSecondaryCategory(event.target.value);
                          clearCategorySelect(2);
                        }}
                        fullWidth
                      >
                        {categories
                          .filter((opt) => opt.plano_mae_id == primaryCategory)
                          .map((opt) => (
                            <MenuItem key={opt.id} value={opt.id}>
                              {opt.descricao}
                            </MenuItem>
                          ))}
                      </Select>
                    </div>
                  </Grid>
                )}

                {secondaryCategory && isLastCategoryLevel && (
                  <Grid item lg={3} md={5} xs={12}>
                    <div style={{ position: "relative" }}>
                      <InputLabel id="role-usuario">Subcategoria 2</InputLabel>
                      <Select
                        labelId="role-usuario"
                        value={tertiaryCategory ? tertiaryCategory : ""}
                        onChange={(event) => {
                          setTertiaryCategory(event.target.value);
                          clearCategorySelect(3);
                        }}
                        fullWidth
                      >
                        {categories
                          .filter(
                            (opt) => opt.plano_mae_id == secondaryCategory
                          )
                          .map((opt) => (
                            <MenuItem key={opt.id} value={opt.id}>
                              {opt.descricao}
                            </MenuItem>
                          ))}
                      </Select>
                    </div>
                  </Grid>
                )}

                <div
                  style={{
                    position: "relative",
                    marginLeft: "16px",
                    marginTop: "16px",
                  }}
                >
                  <InputLabel id="role-usuario">
                    Pertence ao regime de competência
                  </InputLabel>

                  <Checkbox
                    checked={isSelected}
                    onChange={() => handleCheckboxChange(isSelected)}
                  />
                </div>

                <Box style={{ width: "100%" }} marginTop={3}>
                  <LoadingButton
                    loading={isSubmitting}
                    type="submit"
                    style={{ margin: "auto", display: "block" }}
                    variant="contained"
                    color="primary"
                  >
                    {id ? "Atualizar" : "Criar"}
                  </LoadingButton>
                </Box>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </JumboContentLayout>
  );
};

export default PlanoContasForm;
