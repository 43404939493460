import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Pagination,
  PaginationItem,
  Paper,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import ApiService from "app/services/config";
import { useMediaQuery } from "beautiful-react-hooks";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNotificacoes } from "app/contexts/NotificacoesContext";
import data from "../charts/Bar/components/data";

const CaixaEntradaList = () => {
  const { notificacoesNaoLidas, setNotificacoesNaoLidas } = useNotificacoes();
  const userId = Number(localStorage.getItem("id"));
  const [isDadosCarregados, setIsDadosCarregados] = useState(false);
  const [notificacoes, setNotificacoes] = useState([]);
  const [notificacoesSelecionadas, setNotificacoesSelecionadas] = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("desc");
  const [searchTerm, setSearchTerm] = useState("");
  const [selecionarMensagens, setSelecionarMensagens] = useState("todas");
  const [count, setCount] = useState("");
  const { theme } = useJumboTheme();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));

  const navigate = useNavigate();
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const Swal = useSwalWrapper();
  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const getNotificacoes = async () => {
    try {
      await ApiService.post(`/notificacoesmensagens/all/${Number(userId)}`, {
        page,
        perPage,
        sortBy,
        sortOrder,
        selecionar_mensagens: selecionarMensagens,
      }).then((response) => {
        if (response.status === 201) {
          setNotificacoes(response.data.notificacoesMensagens);
          setCount(response.data.count);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleMarcarNotificacaoComoLida = async (id) => {
    try {
      await ApiService.put(`/notificacoesmensagens/marcar/lida/${id}`).then(
        (response) => {
          if (response.status === 200) {
            toast("success", "Notificação marcada como lida!", "success");
            setNotificacoesNaoLidas((prev) => prev - 1);
            getNotificacoes();
          }
        }
      );
    } catch (error) {
      toast("error", "Ocorreu um erro!", "error");
    }
  };

  const handleMarcarVariasNotificacoesComoLida = async (id) => {
    try {
      await ApiService.put(
        `/notificacoesmensagens/marcar/varias/lida/`,
        notificacoesSelecionadas
      ).then((response) => {
        if (response.status === 200) {
          toast("success", "Notificações marcadas como lida!", "success");
          const totalNotificacoesLidas =
            notificacoesNaoLidas.length - notificacoesSelecionadas.length;
          if (totalNotificacoesLidas < 0 || totalNotificacoesLidas === NaN) {
            setNotificacoesNaoLidas(0);
          } else {
            setNotificacoesNaoLidas(
              totalNotificacoesLidas
            );
          }

          getNotificacoes();
          setNotificacoesSelecionadas([]);
        }
      });
    } catch (error) {
      toast("error", "Ocorreu um erro!", "error");
    }
  };

  const handleDeleteNotificacao = async (id) => {
    try {
      await ApiService.delete(`/notificacoesmensagens/${id}`).then(
        (response) => {
          if (response.status === 200) {
            toast("success", "Notificação excluída!", "success");
            getNotificacoes();
          }
        }
      );
    } catch (error) {
      toast("error", "Ocorreu um erro!", "error");
    }
  };

  const handleDeleteManyNotificacoes = async () => {
    try {
      await ApiService.put(`/notificacoesmensagens/deletar/varias`, {
        ids: notificacoesSelecionadas,
      }).then((response) => {
        if (response.status === 200) {
          toast("success", "Notificações excluídas!", "success");
          getNotificacoes();
          setNotificacoesSelecionadas([]);
        }
      });
    } catch (error) {
      toast("error", "Ocorreu um erro!", "error");
    }
  };

  const handleSelecionarTodasNotificacoes = () => {
    const notificacoesNaoLidas = notificacoes
    .map((notificacao) => notificacao.id);

    setNotificacoesSelecionadas(notificacoesNaoLidas);
  };

  const handleDesmarcarNotificacoesSelecionadas = () => {
    setNotificacoesSelecionadas([]);
  };

  const modalAlert = (id, quantidade) => {
    Swal.fire({
      title: `Tem certeza que deseja apagar ${
        quantidade === "uma" ? "a notificação" : "as notificações selecionadas"
      } ?`,
      text: `Você não poderá reverter a ação posteriormente!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        if (quantidade === "uma") {
          handleDeleteNotificacao(id);
        }
        if (id === null && quantidade === "varias") {
          handleDeleteManyNotificacoes();
        }
      }
    });
  };

  useEffect(async () => {
    setIsDadosCarregados(false);
    await getNotificacoes();
    setIsDadosCarregados(true);
  }, [page, perPage, sortOrder, sortBy, selecionarMensagens]);

  console.log(notificacoesSelecionadas);

  return (
    <>
      {isDadosCarregados && (
        <JumboContentLayout
          header={<PageHeader title={"Caixa de Entrada"} />}
          layoutOptions={layoutOptions}
        >
          {lg && (
            <Stack
              spacing={2}
              direction={"row"}
              sx={{ mb: 1, mt: -2, justifyContent: "space-between" }}
            ></Stack>
          )}

          <Card
            width={"100%"}
            sx={{
              background: "white",
              padding: 2,
              marginY: 2,
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
            gap={2}
          >
            <Stack direction="column" gap={1}>
              <FormLabel id="filtro-notificacao">Selecionar</FormLabel>
              <Select
                id="filtro-notificacao"
                value={selecionarMensagens}
                onChange={(event) => {
                  setSelecionarMensagens(event.target.value);
                }}
                fullWidth={false}
              >
                <MenuItem value={"todas"}>Todas as notificações</MenuItem>
                <MenuItem value={"nao_lidas"}>Notificações não lidas</MenuItem>
                <MenuItem value={"lidas"}>Notificações lidas</MenuItem>
              </Select>
            </Stack>
            {notificacoesSelecionadas.length > 0 && (
              <Stack direction={"row"} gap={2}>
                <Button
                  color="primary"
                  sx={{ height: "40px" }}
                  variant="outlined"
                  onClick={() => {
                    if (notificacoesSelecionadas.length > 0) {
                      handleSelecionarTodasNotificacoes();
                    }
                    if (notificacoesSelecionadas.length === 10) {
                      handleDesmarcarNotificacoesSelecionadas();
                    }
                  }}
                >
                  {notificacoesSelecionadas.length === 10
                    ? "Desmarcar"
                    : "Selecionar todas"}
                </Button>
                <Button
                  variant="contained"
                  color="info"
                  onClick={handleMarcarVariasNotificacoesComoLida}
                >
                  <CheckIcon /> Marcar como lida
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => {
                    modalAlert(null, "varias");
                  }}
                >
                  <DeleteIcon /> Excluir selecionados
                </Button>
              </Stack>
            )}
          </Card>

          {notificacoes &&
            notificacoes.length > 0 &&
            notificacoes.map((notificacao) => (
              <Card sx={{ mb: 1, p: 2 }} style={{ width: "100%" }}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item lg={1}>
                    <Checkbox
                      checked={notificacoesSelecionadas.includes(
                        notificacao.id
                      )}
                      onChange={() => {
                        if (notificacoesSelecionadas.includes(notificacao.id)) {
                          setNotificacoesSelecionadas(
                            notificacoesSelecionadas.filter(
                              (id) => id !== notificacao.id
                            )
                          );
                        } else {
                          setNotificacoesSelecionadas([
                            ...notificacoesSelecionadas,
                            notificacao.id,
                          ]);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item lg={7}>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        textAlign: "left",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: 500,
                          marginBottom: 5,
                          fontSize: "18px",
                        }}
                      >
                        {notificacao.nome_destinatario}
                      </Typography>
                      <Typography
                        style={{
                          marginBottom: 5,
                          fontSize: "16px",
                          color: "#808080",
                        }}
                      >
                        {notificacao.descricao}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={2}>
                    <Stack direction={"column"} gap={2}>
                      {notificacao.data_leitura === null ? (
                        <Chip label={"Nova"} sx={{ width: "50%" }} />
                      ) : (
                        <Chip
                          color="info"
                          sx={{ p: 1, width: "50%" }}
                          label={"Lida"}
                        />
                      )}
                      <Typography color={"#808080"}>
                        Enviada em{" "}
                        {moment(notificacao.createdAt).format(
                          "DD/MM/YYYY HH:mm:ss"
                        )}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item lg={1}>
                    <Stack direction={"row"} gap={2} sx={{ width: "100%" }}>
                      <Tooltip title={"Marcar como lida"}>
                        <IconButton
                          disabled={notificacao.data_leitura !== null}
                          onClick={() => {
                            handleMarcarNotificacaoComoLida(notificacao.id);
                          }}
                          sx={{
                            cursor: "pointer",
                            color: notificacao.data_leitura
                              ? "disabled"
                              : "info",
                          }}
                        >
                          <CheckIcon />
                        </IconButton>
                      </Tooltip>
                      <IconButton
                        onClick={() => {
                          modalAlert(notificacao.id, "uma");
                        }}
                      >
                        <DeleteIcon color="error" sx={{ cursor: "pointer" }} />
                      </IconButton>
                    </Stack>
                  </Grid>
                </Grid>
              </Card>
            ))}
          <Paper
            style={{
              background: "white",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <FormControl>
              <Grid
                container
                spacing={2}
                alignItems={"center"}
                display={"flex"}
                justifyContent={"space-evenly"}
              >
                <Grid item textAlign={"center"}>
                  <Box my={2}>
                    <Typography>Página</Typography>
                    <Pagination
                      count={Math.ceil(count / perPage)}
                      page={page + 1}
                      onChange={(event, value) => setPage(value - 1)}
                      variant="outlined"
                      shape="rounded"
                      size="large"
                      renderItem={(item) => (
                        <PaginationItem
                          slots={{
                            previous: ArrowBackIcon,
                            next: ArrowForwardIcon,
                          }}
                          {...item}
                        />
                      )}
                      style={{ marginTop: 10 }}
                    />
                  </Box>
                </Grid>
                <Grid item textAlign={"center"}>
                  <Box>
                    <Typography>Itens por página</Typography>
                    <TextField
                      select
                      label={"Itens por página"}
                      value={perPage}
                      onChange={(e) => setPerPage(e.target.value)}
                      style={{ marginTop: 10 }}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={30}>30</MenuItem>
                    </TextField>
                  </Box>
                </Grid>
                <Grid item textAlign={"center"}>
                  <Box>
                    <Typography>Ordenar por</Typography>
                    <TextField
                      select
                      label={"Ordenação"}
                      value={sortBy}
                      onChange={(e) => setSortBy(e.target.value)}
                      style={{ marginTop: 10 }}
                    >
                      <MenuItem value={"createdAt"}>Data de Criação</MenuItem>
                    </TextField>
                  </Box>
                </Grid>
                <Grid item textAlign={"center"}>
                  <Box>
                    <Typography>Ordem</Typography>
                    <TextField
                      select
                      label={"Ordem"}
                      value={sortOrder}
                      onChange={(e) => setSortOrder(e.target.value)}
                      style={{ marginTop: 10 }}
                    >
                      <MenuItem value={"asc"}>Ascendente</MenuItem>
                      <MenuItem value={"desc"}>Descendente</MenuItem>
                    </TextField>
                  </Box>
                </Grid>
              </Grid>
            </FormControl>
          </Paper>
        </JumboContentLayout>
      )}
    </>
  );
};

export default CaixaEntradaList;
