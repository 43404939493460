import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Fade,
  Modal,
  Paper,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  InputAdornment,
  Grid,
  Stack,
} from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { ErrorMessage, Form, Formik } from "formik";
import * as yup from "yup";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import ApiService from "app/services/config";
import CurrencyInput from "react-currency-input-field";
import { tiposArquivos } from "app/utils/tiposArquivos";
import { LoadingButton } from "@mui/lab";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  currencyInput: {
    width: "100%",
    height: 50,
    padding: "10px 14px",
    fontSize: "1rem",
    borderRadius: 4,
    border: "1px solid #ced4da",
    backgroundColor: "#fff",
    boxSizing: "border-box",
  },
};

const validationSchema = yup.object().shape({
  nome: yup.string().required("Campo obrigatório"),
});

const ModalUploadDocumentoServico = ({
  aberto,
  handleClose,
  servicoId,
  atualizarServico,
}) => {
  const initialValues = {
    nome: "",
  };
  const [isDocumentoAnexado, setIsDocumentoAnexado] = useState(false);
  const [documentoSelecionado, setDocumentosSelecionado] = useState(null);
  const [pagamentoContrato, setPagamentoContrato] = useState(initialValues);

  const [modalAberto, setModalAberto] = useState(aberto);

  useEffect(() => {
    setModalAberto(aberto);
    return () => {
      setDocumentosSelecionado(null);
      setIsDocumentoAnexado(false);
    }
  }, [aberto]);

  const Swal = useSwalWrapper();

  const handleDocumentoAnexado = (event) => {
    const documento = event.target.files[0];
    console.log(documento);
    if (documento) {
      setDocumentosSelecionado(documento);
      setIsDocumentoAnexado(true);
    }
  };

  const toast = (variant, message, type = false) => {
    Swal.fire({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      icon: type ? "success" : "error",
      title: message,
      didOpen: (toast) => {
        toast.style.zIndex = 10000;
      },
      timer: 3000,
    });
  };

  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    console.log(values);
    try {
      const formData = new FormData();
      formData.append("nome", values.nome);
      if (documentoSelecionado && documentoSelecionado.type) {
        console.log("Documento Selecionado:", documentoSelecionado);
        formData.append("arquivo", documentoSelecionado);
      }
      if (!documentoSelecionado) {
        toast("warning", "Faça o upload de um arquivo!");
        return;
      }
      await ApiService.post(`/servicos/documento/${servicoId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        if (response.status === 201) {
          handleClose();
          toast(null, "Documento anexado com sucesso!", "success");
          atualizarServico();
        }
      });
    } catch (error) {
      toast(null, "Ocorreu um erro!", "error");
      console.log(error);
    }
  };

  return (
    <Paper>
      <Modal
        open={modalAberto}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
      >
        <Fade in={modalAberto}>
          <Box sx={style}>
            <Typography variant="h2" component="h2" gutterBottom>
              Upload de documento
            </Typography>
            <Divider />
            <Box width={1} mt={3}>
              <Formik
                enableReinitialize
                initialValues={pagamentoContrato}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  errors,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Grid
                      container
                      alignContent={"center"}
                      justifyContent={"center"}
                      spacing={2}
                    >
                      <Grid
                        item
                        lg={7}
                        xs={12}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Stack direction="column" gap={1} width={"100%"}>
                          <TextField
                            name="nome"
                            label="Descrição / Nome do arquivo"
                            required
                            value={values.nome}
                            onChange={handleChange}
                            sx={{ background: "#ffffff", width: "100%" }}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="nome"
                            style={{ color: "red" }}
                          />
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        lg={7}
                        xs={12}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <label
                          htmlFor="upload-button-file"
                          style={{
                            width: "50%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <input
                            id="upload-button-file"
                            name="arquivo"
                            hidden
                            accept="image/*, .pdf, .docx, .xls"
                            type="file"
                            onChange={(event) => {
                              handleDocumentoAnexado(event);
                            }}
                          />
                          <Button
                            variant="contained"
                            color="info"
                            name="descricao"
                            label="Descrição"
                            required
                            sx={{ width: "100%" }}
                            component="span"
                          >
                            Fazer upload
                          </Button>
                          <ErrorMessage
                            component={"div"}
                            name="arquivo"
                            style={{ color: "red" }}
                          />
                        </label>
                      </Grid>
                      {isDocumentoAnexado && (
                        <Grid
                          item
                          lg={7}
                          xs={12}
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <Stack
                            direction="row"
                            gap={2}
                            alignItems={"center"}
                            borderRadius={1}
                            style={{ padding: 4, border: "1px solid #0e0e0e" }}
                          >
                            <Box
                              style={{
                                background:
                                  tiposArquivos[
                                    String(documentoSelecionado?.name).split(
                                      "."
                                    )[1]
                                  ]?.cor,
                                color: "#FFF",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "50px",
                                height: "50px",
                                padding: 1,
                              }}
                              borderRadius={1}
                            >
                              {
                                tiposArquivos[
                                  String(documentoSelecionado?.name).split(
                                    "."
                                  )[1]
                                ]?.nome
                              }
                            </Box>
                            <Box>
                              <Typography
                                style={{ fontWeight: 500, fontSize: 15 }}
                              >
                                {documentoSelecionado?.name}
                              </Typography>
                            </Box>
                          </Stack>
                        </Grid>
                      )}

                      <Box
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        marginTop={3}
                      >
                        <LoadingButton
                          loading={isSubmitting}
                          type="submit"
                          style={{ margin: "auto", display: "block" }}
                          variant="contained"
                          color="primary"
                        >
                          Enviar
                        </LoadingButton>
                      </Box>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Paper>
  );
};

export default ModalUploadDocumentoServico;
