import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Avatar from "@mui/material/Avatar";
import { authUser } from "./fake-db";
import {
  ListItemIcon,
  ListItemText,
  ThemeProvider,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import Div from "@jumbo/shared/Div";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import ApiService from "app/services/config";

const AuthUserDropdown = () => {
  const navigate = useNavigate();
  const { theme } = useJumboTheme();
  const { setAuthToken } = useJumboAuth();
  const username = localStorage.getItem("username");
  const email = localStorage.getItem("email");
  const id = localStorage.getItem("id");
  const cliente_id = localStorage.getItem("cliente_id");
  const avatarUrlLocalStorage = localStorage.getItem("avatarUrl");
  const [urlAvatar, setUrlAvatar] = useState(null);
  const [userId, setUserId] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const onLogout = () => {
    setAuthToken(null);
    navigate("/login");
  };

  const handleEditClick = () => {
    // Recolhe o dropdown
    setAnchorEl(null);
    const ROLE_USER = localStorage.getItem("role_id");


    if (ROLE_USER == 2) {
      navigate(`/app/editar-cliente/${cliente_id}`);
    } else {
      navigate(`/app/editar-colaborador/${id}`);
    }

    // Redireciona para a página de edição de perfil
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleGetAvatarUrl = async (id) => {
    try {
      const avatar = localStorage.getItem("avatarUrl");
      setUrlAvatar(avatar);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetAvatarUrl();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <JumboDdPopover
        triggerButton={
          <Avatar
            src={`${process.env.REACT_APP_API_KEY}/public/uploads/${avatarUrlLocalStorage}`}
            sizes={"small"}
            sx={{ boxShadow: 25, cursor: "pointer" }}
          >
            {username && username.charAt(0).toUpperCase()}
          </Avatar>
        }
        setAnchorEl={setAnchorEl}
        anchorEl={anchorEl}
      >
        <Div
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            p: (theme) => theme.spacing(2.5),
          }}
        >
          <Avatar
            src={`${process.env.REACT_APP_API_KEY}/public/uploads/${avatarUrlLocalStorage}`}
            alt={username}
            sx={{ width: 60, height: 60, mb: 2 }}
          >
            {username && username.charAt(0).toUpperCase()}
          </Avatar>
          <Typography sx={{ textAlign: "center" }} variant={"h5"}>
            {username}
          </Typography>
          <Typography
            sx={{ textAlign: "center" }}
            variant={"body1"}
            color="text.secondary"
          >
            {email}
          </Typography>
        </Div>
        <Divider />
        <nav>
          <List disablePadding sx={{ pb: 1 }}>
            <ListItemButton onClick={handleEditClick}>
              <ListItemIcon sx={{ minWidth: 36 }}>
                <EditOutlinedIcon />
              </ListItemIcon>
              {/* <Link 
                                to={`app/editar-aluno/${getUserIdFromLocalStorage}`}
                                style={{textDecoration: 'none', color: '#475259'}} 
                            >
                                Editar Perfil
                            </Link> */}
              <ListItemText primary="Editar Usuário" sx={{ my: 0 }} />
            </ListItemButton>
            <ListItemButton onClick={onLogout}>
              <ListItemIcon sx={{ minWidth: 36 }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Sair" sx={{ my: 0 }} />
            </ListItemButton>
          </List>
        </nav>
      </JumboDdPopover>
    </ThemeProvider>
  );
};

export default AuthUserDropdown;
