import {config} from "../../../app/config/main";

export const storeToken = (token, username, email, id, avatarUrl, storedPermissions, role_id, cliente_id) => {
    localStorage.setItem('token', token);
    localStorage.setItem('username', username);
    localStorage.setItem('email', email);
    localStorage.setItem('id', id);
    localStorage.setItem('avatarUrl', avatarUrl);
    localStorage.setItem('role_id', role_id);
    localStorage.setItem('storedPermissions', JSON.stringify(storedPermissions));
    localStorage.setItem('cliente_id', cliente_id);
    if(!config?.authSetting?.axiosObject)
        throw Error("axiosObject need to be set under authSettings inside app/config/main.js");
    else
        config.authSetting.axiosObject.defaults.headers.common['Authorization'] = 'Bearer ' + token;
};

export const removeToken = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('email');
    localStorage.removeItem('id');
    localStorage.removeItem('avatarUrl');
    localStorage.removeItem('role_id');
    localStorage.removeItem('storedPermissions');
    localStorage.removeItem('cliente_id');
    if(!config?.authSetting?.axiosObject)
        throw Error("axiosObject need to be set under authSettings inside app/config/main.js");
    else
        delete config.authSetting.axiosObject.defaults.headers.common['Authorization'];
};