import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Grid,
  Stack,
  Toolbar,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import ApiService from "app/services/config";
import React, { useState, useEffect, useCallback, useContext } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import { LoadingButton } from "@mui/lab";
import MUIDataTable from "mui-datatables";
import { PermissionContext } from "app/contexts/PermissionContext";
import { useNavigate } from "react-router-dom";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import ModalCadastroImovelCliente from "app/components/ModalCadastroImovelCliente/ModalCadastroImovelCliente";
import EditIcon from "@mui/icons-material/Edit";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Description from "@mui/icons-material/Description";
import moment from "moment";
import CustomChipLicencasUnidade from "app/components/CustomChipLicencasUnidade/CustomChipLicencasUnidade";

const linkStyle = {
  color: "#302D2E",
  fontSize: 20,
  textDecoration: "none",
  "&:visited": {
    color: "#302D2E",
  },
};

const ProtocolosList = () => {
  const { theme } = useJumboTheme();
  const location = useLocation();
  const { contratoId, clienteId, processoId, procadmin } = useParams();
  const [protocolos, setProtocolos] = useState([]);
  const [breadCrumbsInfo, setBreadCrumbsInfo] = useState([]);
  const [processoAdministrativo, setProcessoAdministrativo] = useState(null);
  const [selectedProtocolosIds, setSelectedProtocolosIds] = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");
  const [count, setCount] = useState("");
  const [clienteSelecionado, setClienteSelecionado] = useState({
    id: null,
    nome: null,
  });
  const [todosProtocolosSelecionados, setTodosProtocolosSelecionados] =
    useState(false);
  const [mostrarBotaoDeletarMuitos, setMostrarBotaoDeletarMuitos] =
    useState(false);
  const [
    isModalAdicionarImovelClienteAberto,
    setIsModalAdicionarImovelClienteAberto,
  ] = useState(false);

  const navigate = useNavigate();
  const { hasPermission } = useContext(PermissionContext);
  const permission = hasPermission("Contratos", "create");
  if (!hasPermission("Contratos", "read")) {
    navigate("/app");
  }

  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const Swal = useSwalWrapper();
  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const handleSearch = async (newSearchTerm) => {
    if (searchTerm === "" || searchTerm === null || searchTerm === undefined) {
      setPage(1);
    }
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  const handleSelecionarTodosProcessos = () => {
    const todosIdsProtocolos = protocolos.map((protocolo) => protocolo.id);
    if (!todosProtocolosSelecionados) {
      setSelectedProtocolosIds(todosIdsProtocolos);
    } else {
      setSelectedProtocolosIds([]);
    }
    setTodosProtocolosSelecionados(!todosProtocolosSelecionados);
  };

  const handleDeleteMuitosProtocolos = () => {
    Swal.fire({
      title: "Tem certeza que deseja apagar os protocolos selecionados?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteMuitosProtocolos();
      }
    });
  };

  const deleteMuitosProtocolos = useCallback(async () => {
    try {
      await ApiService.put(
        "/protocolos/deletar/varios",
        selectedProtocolosIds
      ).then((response) => {
        if (response.status === 200) {
          toast("success", "Protocolos removidos com sucesso!");
          getProtocolos();
          setSelectedProtocolosIds([]);
        }
      });
    } catch (error) {
      toast("error", "Não foi possível deletar os protocolos selecionados");
      console.log(error);
    }
  }, [selectedProtocolosIds]);

  const getProtocolos = useCallback(async () => {
    try {
      let apiUrl = `/protocolos/contrato/${contratoId}/processo/${processoId}/?page=${
        page + 1
      }&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}`;

      if (searchTerm) {
        apiUrl += `&searchTerm=${searchTerm}`;
      }
      ApiService.get(apiUrl)
        .then((response) => {
          const initialData = response.data.protocolos.map((protocolo) => ({
            ...protocolo,
            isSelected: selectedProtocolosIds.includes(protocolo.id),
          }));
          setProtocolos(initialData);
          setCount(response.data.count);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [page, perPage, sortBy, sortOrder, searchTerm, selectedProtocolosIds]);

  useEffect(() => {
    getProtocolos();
  }, [getProtocolos]);

  const handleCheckboxChange = (protocoloId) => {
    setProtocolos((prevProtocolos) =>
      prevProtocolos.map((protocolo) =>
        protocolo.id === protocoloId
          ? { ...protocolo, isSelected: !protocolo.isSelected }
          : protocolo
      )
    );

    setSelectedProtocolosIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(protocoloId)) {
        return prevSelectedIds.filter((id) => id !== protocoloId);
      } else {
        return [...prevSelectedIds, protocoloId];
      }
    });

    setMostrarBotaoDeletarMuitos(selectedProtocolosIds.length > 0);
    setTodosProtocolosSelecionados(false);
  };

  const columns = [
    {
      name: "selecionar",
      label: "Selecionar",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const protocoloId = tableMeta.rowData[1];
          const protocolo = protocolos.find(
            (protocolo) => protocolo.id === protocoloId
          );
          return (
            <Checkbox
              checked={protocolo.isSelected}
              onChange={() => handleCheckboxChange(protocoloId)}
            />
          );
        },
      },
    },
    {
      name: "id",
      label: "Id",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "data",
      label: "Data",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{moment.utc(value).format("DD/MM/YYYY")}</>;
        },
      },
    },
    {
      name: "prazo",
      label: "Prazo",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{moment.utc(value).format("DD/MM/YYYY")}</>;
        },
      },
    },
    {
      name: "orgao",
      label: "Órgão",
      options: {
        filter: true,
        sort: true,
        enableNestedAccess: ".",
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value?.descricao}</>;
        },
      },
    },
    {
      name: "action",
      label: "Ações",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const protocoloId = tableMeta.rowData[1];
          console.log(tableMeta.rowData);
          return (
            <Box display={"flex"} gap={"1rem"}>
              <Tooltip title="Editar Protocolo">
                <EditIcon
                  cursor="pointer"
                  color="info"
                  disabled={!permission}
                  onClick={() => {
                    navigate(
                      `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/protocolos/editar/${protocoloId}`,
                      {
                        state: {
                          descricao:
                            location?.state?.descricao ??
                            protocolos[0]?.contrato?.descricao,
                          processoDescricao: tableMeta.rowData[2],
                          idProtocolo: tableMeta.rowData[1],
                        },
                      }
                    );
                  }}
                />
              </Tooltip>
              <Tooltip title="Excluir Protocolo">
                <DeleteIcon
                  cursor="pointer"
                  color="error"
                  onClick={() => {
                    navigate(
                      `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/protocolos/deletar/${protocoloId}`
                    );
                  }}
                />
              </Tooltip>
            </Box>
          );
        },
      },
    },
  ];

  const options = {
    changeRowsPerPage: perPage,
    filterType: "dropdown",
    filter: false,
    selectableRows: "none",
    searchAlwaysOpen: true,
    searchable: true,
    serverSide: true,
    page: page,
    count: count,
    selectToolbarPlacement: "above",

    onTableChange: (action, tableState) => {
      console.log(action);
      switch (action) {
        case "changePage":
          setPage(tableState.page);
          break;
        case "sort":
          setSortBy(tableState.sortOrder.name);
          setSortOrder(tableState.sortOrder.direction);
          break;
        case "search":
          handleSearch(tableState.searchText);
          break;
        case "changeRowsPerPage":
          setPerPage(tableState.rowsPerPage);
          break;
        default:
          console.log("action not handled.");
      }
    },
    customToolbar: ({ displayData }) => (
      <>
        {selectedProtocolosIds.length > 0 ? (
          <Toolbar>
            <Box
              display={"flex"}
              gap={"1rem"}
              justifyContent={"flex-end"}
              width={"100%"}
            ></Box>
          </Toolbar>
        ) : null}
      </>
    ),
    textLabels: {
      body: {
        noMatch: "Nenhum resultado encontrado",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: "Próxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Itens por Página:",
        displayRows: "of",
      },
    },
  };

  const getBreadCrumbsInfo = async () => {
    try {
      return await ApiService.get(
        `/contratos/breadcrumbs?contratoId=${contratoId}&processoId=${processoId}`
      ).then((response) => {
        if (response.status === 200) {
          setBreadCrumbsInfo(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (contratoId) {
      getBreadCrumbsInfo();
    }
  }, [contratoId]);

  return (
    <JumboContentLayout layoutOptions={layoutOptions}>
      {lg && (
        <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}></Stack>
      )}
      <Stack width={"100%"}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link to={"/app/listar-contratos"} color="success" style={linkStyle}>
            Contratos
          </Link>
          {breadCrumbsInfo && (
            <Link
              to={`/app/editar-contrato/${contratoId}`}
              color="success"
              style={linkStyle}
            >
              {breadCrumbsInfo?.descricao}
            </Link>
          )}
          <Link
            to={`/app/contrato/${contratoId}/cliente/${clienteId}/processos`}
            color="primary"
            style={linkStyle}
          >
            Processos Administrativos
          </Link>
          {breadCrumbsInfo && (
            <Link
              to={`/app/contrato/${contratoId}/cliente/${clienteId}/processos/editar/${processoId}`}
              color="primary"
              style={linkStyle}
            >
              {
                breadCrumbsInfo?.processos_contratos?.[0]
                  ?.processo_administrativo?.descricao
              }{" "}
            </Link>
          )}
          <Link
            to={`/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/protocolos`}
            color="primary"
            style={linkStyle}
          >
            Protocolos
          </Link>
        </Breadcrumbs>
      </Stack>

      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <LoadingButton
          color="success"
          type="submit"
          variant="contained"
          size="large"
          sx={{
            maxWidth: { md: "200px", mt: "1rem" },
            marginBottom: "2rem",
          }}
          disabled={!permission}
          onClick={() => {
            navigate(
              `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/protocolos/novo`,
              {
                state: {
                  descricao:
                    location?.state?.descricao ??
                    protocolos[0]?.contrato?.descricao,
                },
              }
            );
          }}
        >
          Novo Protocolo
        </LoadingButton>
      </Grid>
      <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
        {selectedProtocolosIds.length > 0 && (
          <Grid item>
            <span style={{ margin: 10 }}>
              {selectedProtocolosIds.length} protocolo(s) selecionado(s)
            </span>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<DeleteIcon />}
              onClick={handleDeleteMuitosProtocolos}
              disabled={!permission}
            >
              Excluir Selecionados
            </Button>
          </Grid>
        )}
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSelecionarTodosProcessos}
          >
            {todosProtocolosSelecionados
              ? "Desmarcar Todos"
              : "Selecionar Todos"}
          </Button>
        </Grid>
      </Grid>

      <React.Fragment>
        <Box mt={3}>
          <MUIDataTable
            title={"Protocolos"}
            data={protocolos}
            columns={columns}
            options={options}
          />
        </Box>
      </React.Fragment>
    </JumboContentLayout>
  );
};

export default ProtocolosList;
