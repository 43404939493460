import {
  Box,
  Button,
  Divider,
  Fade,
  FormLabel,
  Grid,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ApiService from "app/services/config";
import { ErrorMessage, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import moment from "moment";
import ArquivoWrapper from "../ArquivoWrapper/ArquivoWrapper";
import { v4 } from "uuid";
import * as yup from "yup";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const validationSchema = yup.object().shape({
  status_licenca: yup
    .string()
    .required("Campo obrigatório")
    .typeError("Campo obrigatório"),
  userId: yup.number().required("ID do Usuário obrigatório"),
  num_licenca: yup
    .string()
    .required("Campo obrigatório")
    .typeError("Campo obrigatório"),
  emissao: yup
    .date("Data inválida")
    .required("Campo obrigatório")
    .max(new Date(), "Data deve ser presente ou passada.")
    .typeError("Data inválida"),
  vencimento: yup
    .date("Data inválida")
    .required("Campo obrigatório")
    .min(new Date(), "Data deve ser futura.")
    .typeError("Data inválida"),
  observacao: yup
    .string()
    .required("Campo obrigatório")
    .typeError("Campo obrigatório"),
});

const ModalInformacoesLicenca = ({
  aberto,
  handleClose,
  licencaId,
  userId,
  atualizarLicenca,
}) => {
  const initialValues = {
    userId: Number(userId),
    status_licenca: "",
    num_licenca: "",
    emissao: "",
    vencimento: "",
    observacao: "",
  };
  const [modalAberto, setModalAberto] = useState(aberto);
  const [isDadosCarregados, setIsDadosCarregados] = useState(false);
  const [idLicenca, setIdLicenca] = useState(licencaId);
  const [usuarioId, setUsuarioId] = useState(userId);
  const [licenca, setLicenca] = useState(initialValues);
  const [arquivos, setArquivos] = useState([]);
  const [arquivosUpload, setArquivosUpload] = useState([]);
  const Swal = useSwalWrapper();
  const toast = (variant, message, type = false) => {
    Swal.fire({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      icon: type ? "success" : "error",
      title: message,
      didOpen: (toast) => {
        toast.style.zIndex = 10000;
      },
      timer: 3000,
    });
  };

  const getLicenca = async () => {
    try {
      await ApiService.get(`/unidadealvaralicenca/${Number(idLicenca)}`).then(
        (response) => {
          if (response.status === 200) {
            const { emissao, vencimento } = response.data;
            setLicenca({
              ...response.data,
              userId: Number(userId) || Number(usuarioId),
              emissao: moment.parseZone(response.data.emissao).format("YYYY-MM-DD"),
              vencimento: moment.parseZone(response.data.vencimento).format("YYYY-MM-DD"),
            });
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const modalAlert = (idDocumento) => {
    Swal.fire({
      title: "Tem certeza que deseja apagar o arquivo?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
      didOpen: () => {
        Swal.getContainer().style.zIndex = "9999";
      },
    }).then((result) => {
      if (result.value) {
        handleDeleteDocumento(idDocumento);
      }
    });
  };

  const handleDeleteDocumento = async (id) => {
    try {
      await ApiService.post(`/unidadealvaralicenca/documento/${Number(id)}`, {
        userId: Number(userId), licencaId: Number(idLicenca)
      }).then(
        (response) => {
          if (response.status === 201) {
            toast(null, "Documento excluído", "success");
            getLicenca();
            atualizarLicenca();
          }
        }
      );
    } catch (error) {
      toast("error", "Ocorreu um erro!");
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsDadosCarregados(false);
      await getLicenca();
      setIsDadosCarregados(true);
    };
    fetchData();
  }, [licencaId, aberto]);

  useEffect(() => {
    setIdLicenca(licencaId);
  }, [licencaId]);

  useEffect(() => {
    setUsuarioId(userId);
  }, [userId]);

  useEffect(() => {
    setModalAberto(aberto);
  }, [aberto]);

  const handleRemoverArquivo = (arquivoRemovido) => {
    const novosArquivos = arquivos.filter(
      (arquivo) => arquivo !== arquivoRemovido
    );
    setArquivos(novosArquivos);
  };

  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    delete values.unidadeId;
    delete values.alvaraLicenca;
    delete values.alvaraLicencaId;
    delete values.licencaId;
    delete values.identificacao;
    delete values.unidade;
    delete values.createdAt;
    delete values.updatedAt;
    delete values.deletedAt;
    delete values.users;
    delete values.is_arquivada;
    delete values.documento_licencas;
    delete values.documentos_complementares;
    delete values.condicionantes;

    const files = new FormData();

    for (let i = 0; i < arquivosUpload.length; i++) {
      const file = arquivosUpload[i];
      files.append(`files[${i}]`, file);
    }

    try {
      await ApiService.put(
        `/unidadealvaralicenca/status/${licencaId}`,
        values
      ).then((response) => {
        if (response.status === 200) {
          toast(null, "Licença atualizada", "success");
          atualizarLicenca();
          handleClose();
        }
      });
      if (arquivosUpload.length > 0) {
        await ApiService.put(
          `unidadealvaralicenca/documentos/${Number(idLicenca)}`,
          files, 
        ).then((response) => {
          if (response.status === 200) {
            atualizarLicenca();
          }
        });
      }
    } catch (error) {
      if (error.message === "Request failed with status code 422") {
        toast("error", "Formato ou tamanho dos arquivos inválidos!");
      } else {
        toast("error", "Ocorreu um erro");
      }
    }
    setArquivos([]);
    setArquivosUpload([]);
  };
  return (
    <>
      <Paper>
        <Modal
          open={modalAberto}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          closeAfterTransition
        >
          <Fade in={modalAberto}>
            <Box
              sx={{
                ...style,
              }}
            >
              {isDadosCarregados && (
                <Box>
                  <Typography variant={"h2"}>
                    Atualizar status da licença
                  </Typography>
                  <Typography variant={"h5"} color={"#A5A5A5"}>
                    {licenca?.alvaraLicenca?.descricao}
                  </Typography>
                  <Divider />
                  <Formik
                    enableReinitialize
                    initialValues={licenca}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({
                      values,
                      handleChange,
                      handleSubmit,
                      handleBlur,
                      errors,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <Form
                        onSubmit={handleSubmit}
                        style={{
                          width: "100%",
                          maxHeight: 500,
                          marginTop: 20,
                          overflowY: "auto",
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography>Status da licença</Typography>
                            <Select
                              id="status-licenca"
                              label="Status da Licença"
                              onChange={handleChange}
                              name="status_licenca"
                              value={values.status_licenca ?? ""}
                              onBlur={handleBlur}
                              fullWidth
                            >
                              <MenuItem value="em_obtencao">
                                Em obtenção
                              </MenuItem>
                              <MenuItem value="protocolo_vencido">
                                Protocolo Vencido
                              </MenuItem>
                              <MenuItem value="vigente">Vigente</MenuItem>
                              <MenuItem value="vencida">Vencida</MenuItem>
                              <MenuItem value="permanente">Permanente</MenuItem>
                              <MenuItem value="dispensada">Dispensada</MenuItem>
                            </Select>
                            <ErrorMessage
                              name="status_licenca"
                              component={"div"}
                              style={{ color: "red" }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography>Nº da licença:</Typography>

                            <TextField
                              id="num-licenca-input"
                              name="num_licenca"
                              value={values.num_licenca}
                              onChange={handleChange}
                              fullWidth
                            />
                            <ErrorMessage
                              name="num_licenca"
                              component={"div"}
                              style={{ color: "red" }}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography>Emissão</Typography>
                            <TextField
                              name="emissao"
                              type="date"
                              onChange={(event) => {
                                setFieldValue("emissao", event.target.value);
                              }}
                              value={values.emissao}
                              variant="outlined"
                              sx={{ width: "100%" }}
                              fullWidth
                            />
                            <ErrorMessage
                              name="emissao"
                              component={"div"}
                              style={{ color: "red" }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography>Vencimento</Typography>
                            <TextField
                              name="vencimento"
                              type="date"
                              onChange={(event) => {
                                setFieldValue("vencimento", event.target.value);
                              }}
                              onBlur={handleBlur}
                              value={values.vencimento}
                              variant="outlined"
                              fullWidth
                            />
                            <ErrorMessage
                              name="vencimento"
                              component={"div"}
                              style={{ color: "red" }}
                            />
                          </Grid>
                        </Grid>
                        <Box my={1} width="100%">
                          <Typography>Observação</Typography>
                          <TextField
                            type="textarea"
                            multiline
                            rows={4}
                            value={values.observacao}
                            onChange={handleChange}
                            sx={{ width: "100%" }}
                            name="observacao"
                          />
                          <ErrorMessage
                            name="observacao"
                            component={"div"}
                            style={{ color: "red" }}
                          />
                        </Box>
                        <Box mt={2} width="100%">
                          <Typography variant="h6">Anexos</Typography>
                          <Typography variant="h6">
                            Formatos de arquivos permitidos: .jpg, .pdf e .png |
                            Tamanho máximo: 10 MB
                          </Typography>
                          <input
                            accept=".pdf, .png, .jpg, .jpeg"
                            style={{ display: "none" }}
                            id="file-upload"
                            multiple
                            type="file"
                            onChange={(event) => {
                              const files = event.target.files;
                              setArquivosUpload((prevArquivos) => [
                                ...prevArquivos,
                                ...Array.from(files),
                              ]);
                              const novosArquivos = Array.from(files).map(
                                (file) => ({
                                  nome: file.name,
                                  tamanho_documento: file.size,
                                  extensao: file.name
                                    .split(".")
                                    .pop()
                                    .toUpperCase(),
                                  dataUpload: moment().format("DD/MM/YYYY"),
                                })
                              );
                              setArquivos((arquivos) => [
                                ...arquivos,
                                ...novosArquivos,
                              ]);
                            }}
                          />

                          <label htmlFor="file-upload">
                            <Button
                              variant="contained"
                              component="span"
                              sx={{
                                width: "100%",
                                background: "transparent",
                                p: 1,
                                color: "#A7A7A7",
                                border: "1px dashed #A7A7A7",
                                "&:hover": {
                                  background: "#2CB3C7",
                                  color: "#FFF",
                                  border: "none",
                                },
                              }}
                              startIcon={<CloudUploadIcon />}
                            >
                              Upload
                            </Button>
                          </label>
                          {arquivos.length === 0 &&
                          licenca.documento_licencas?.length === 0 ? (
                            <Typography my={1} variant="body2">
                              Nenhum anexo enviado
                            </Typography>
                          ) : (
                            <>
                              <Stack
                                style={{
                                  maxHeight: "150px",
                                  overflowY: "auto",
                                }}
                              >
                                {licenca.documento_licencas?.map(
                                  (arquivo, index) => (
                                    <ArquivoWrapper
                                      key={v4()}
                                      arquivo={arquivo}
                                      tipoLicenca={
                                        licenca.alvaraLicenca?.descricao
                                      }
                                      onRemoverArquivo={modalAlert}
                                    />
                                  )
                                )}
                              </Stack>
                              <Stack
                                style={{
                                  maxHeight: "150px",
                                  overflowY: "auto",
                                }}
                              >
                                {arquivos.map((arquivo, index) => (
                                  <ArquivoWrapper
                                    key={v4()}
                                    arquivo={arquivo}
                                    tipoLicenca={
                                      licenca.alvaraLicenca?.descricao
                                    }
                                    onRemoverArquivo={handleRemoverArquivo}
                                  />
                                ))}
                              </Stack>
                            </>
                          )}
                        </Box>
                        <input
                          type="number"
                          name="userId"
                          value={usuarioId}
                          hidden
                        />
                        <Box my={2}>
                          <Box
                            mt={3}
                            display="flex"
                            justifyContent="space-evenly"
                          >
                            <Button
                              loading={isSubmitting}
                              type="submit"
                              variant="contained"
                              color="success"
                              onClick={handleSubmit}
                            >
                              Salvar
                            </Button>
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() => {
                                setArquivos([]);
                                handleClose();
                              }}
                            >
                              Fechar
                            </Button>
                          </Box>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </Box>
              )}
            </Box>
          </Fade>
        </Modal>
      </Paper>
    </>
  );
};

export default ModalInformacoesLicenca;
