import {
  AccordionDetails,
  AccordionSummary,
  Chip,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { Box, Checkbox, Grid, List } from "@mui/material";
import React from "react";
import CustomChipLicencasUnidade from "../CustomChipLicencasUnidade/CustomChipLicencasUnidade";
import { CircularProgressWithLabel } from "../CircularProgressWithLabel/CircularProgressWithLabel";
import ModeIcon from "@mui/icons-material/Mode";
import DescriptionIcon from "@mui/icons-material/Description";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";

const ServicosTarefasList = ({
  idsTarefasSelecionadas,
  setIdsTarefasSelecionadas,
  servico,
  statusServico,
  contratoId,
  clienteId,
  processoId,
  procAdminId,
}) => {
  const navigate = useNavigate();
  const handleCheckboxChange = (tarefaId) => (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setIdsTarefasSelecionadas([...idsTarefasSelecionadas, tarefaId]);
    } else {
      setIdsTarefasSelecionadas(
        idsTarefasSelecionadas.filter((id) => id !== tarefaId)
      );
    }
  };

  return (
    <>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Chip
          label={
            <>
              <Typography variant={"h5"} color={"white"}>
                Serviço ID - {servico.id ? servico.id : "---"}
              </Typography>
            </>
          }
          sx={{
            width: "100%",
            backgroundColor: "#34B993",
            padding: "8px",
            boxSizing: "border-box",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        />
      </AccordionSummary>
      <AccordionDetails>
        <Box style={{ width: "100%" }}>
          <Grid container alignItems={"center"} display={"flex"}>
            <Grid item xs={1}>
              <Checkbox
                checked={idsTarefasSelecionadas.includes(servico.id)}
                onChange={handleCheckboxChange(servico.id)}
              />
            </Grid>
            <Grid item xs={10}>
              <List>
                {servico.tarefas_servico &&
                  servico.tarefas_servico.length > 0 &&
                  servico.tarefas_servico.map((tarefa) => (
                    <ListItem
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      secondaryAction={
                        <ModeIcon
                          fontSize={"large"}
                          color="info"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            navigate(
                              `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procAdminId}/servicos/editar/${servico.id}`
                            );
                          }}
                        />
                      }
                    >
                      <Grid container spacing={2}>
                        <Grid
                          item
                          lg={6}
                          display={"inline-flex"}
                          flexDirection={"row"}
                        >
                          <ListItemIcon>
                            <DescriptionIcon />
                          </ListItemIcon>
                          <ListItemText>
                            <Typography variant="h4">
                              {tarefa.descricao}
                            </Typography>
                          </ListItemText>
                        </Grid>
                        <Grid item lg={3}>
                          <CustomChipLicencasUnidade
                            situacao={
                              servico.status_andamento_servico
                                ? servico.status_andamento_servico?.descricao || "Status não encontrado"
                                : "Sem status"
                            }
                          />
                        </Grid>

                        <Grid item lg={3}>
                          <CircularProgressWithLabel
                            value={
                              servico.porcentagem ? servico.porcentagem : 0
                            }
                          />
                        </Grid>
                      </Grid>
                    </ListItem>
                  ))}
              </List>
            </Grid>
          </Grid>
          <Divider />
        </Box>
      </AccordionDetails>
    </>
  );
};

export default ServicosTarefasList;
