import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Stack,
  Toolbar,
  useMediaQuery,
  Chip,
  TableCell,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography
} from "@mui/material";
import ApiService from "app/services/config";
import React, { useState, useEffect, useCallback, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import { LoadingButton } from "@mui/lab";
import MUIDataTable from "mui-datatables";
import { PermissionContext } from "app/contexts/PermissionContext";
import MetricCard from "app/components/MetricCard/MetricCard";
import * as dayjs from 'dayjs'
import pt from 'dayjs/locale/pt-br';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import { ptBR } from '@mui/x-date-pickers/locales';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const ConsolidacaoFcList = () => {
  const { theme } = useJumboTheme();
  const location = useLocation();
  const [consolidacao, setConsolidacao] = useState([]);
  const [selectedTiposServicoIds, setSelectedTiposServicoIds] = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");
  const [count, setCount] = useState("");
  const [indicators, setIndicators] = useState([]);
  const [demonstrativoResultados, setDemonstrativoResultados] = useState([]);
  const [indicatorsDemonstrativoResultados, setIndicatorsDemonstrativoResultados] = useState([]);
  const [demonstrativoSetorProdutivo, setDemonstrativoSetorProdutivo] = useState([]);
  const [indicatorsSetorProdutivo, setIndicatorsSetorProdutivo] = useState([]);
  let [date, setDate] = useState(dayjs().startOf('month'));
  const [isLoading, setIsLoading] = useState(false);
  // consolidacao-demonstrativo-setores-produtivos
  const navigate = useNavigate();

  const { hasPermission } = useContext(PermissionContext);

  if(!hasPermission('Financeiro', 'read')) {
    navigate('/app');
  }

  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const Swal = useSwalWrapper();
  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: "Fechar",
    });
  };

  const modalAlert = (id) => {
    Swal.fire({
      title: "Tem certeza que deseja apagar?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // deletePlanoConta(id);
      }
    });
  };

  const handleSearch = async (newSearchTerm) => {
    if (searchTerm === "" || searchTerm === null || searchTerm === undefined) {
      setPage(1);
    }
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  const getConsolidacao = useCallback(async () => {
    setIsLoading(true)
    try {
      let apiUrl = `controlecaixa/consolidacao-fc`;

      if (date) {
        apiUrl += `?year=${date}`;
      }
      ApiService.get(apiUrl)
        .then((response) => {
          setConsolidacao(response.data.consolidacaoFc);
          setIndicators(response.data.indicators);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        })
        .then(() => {
          setIsLoading(false)
        });
    } catch (err) {
      console.log(err);
    }
  }, [date]);

  const getDemonstrativoResultados = useCallback(async () => {
    setIsLoading(true)
    try {
      let apiUrl = `controlecaixa/consolidacao-demonstrativo-anual`;

      if (date) {
        apiUrl += `?year=${date}`;
      }
      ApiService.get(apiUrl)
        .then((response) => {
          setDemonstrativoResultados(response.data.demonstrativoResultadosAnual);
          setIndicatorsDemonstrativoResultados(response.data.indicatorsDemonstrativoResultadosAnual);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        })
        .then(() => {
          setIsLoading(false)
        });
    } catch (err) {
      console.log(err);
    }
  }, [date]);

  const getDemonstrativoSetorProdutivo = useCallback(async () => {
    setIsLoading(true)
    try {
      let apiUrl = `controlecaixa/consolidacao-demonstrativo-setores-produtivos`;

      if (date) {
        apiUrl += `?year=${date}`;
      }
      ApiService.get(apiUrl)
        .then((response) => {
          setDemonstrativoSetorProdutivo(response.data.demonstrativoSetorProdutivo);
          setIndicatorsSetorProdutivo(response.data.indicatorsProdutivo);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        })
        .then(() => {
          setIsLoading(false)
        });
    } catch (err) {
      console.log(err);
    }
  }, [date]);

  useEffect(() => {
    getConsolidacao();
    getDemonstrativoResultados();
    getDemonstrativoSetorProdutivo();
  }, [date]);

  const style = {
    position: "sticky",
    left: 0,
    background: "white",
    zIndex: 101,
    borderBottom: '1px solid #d9d9d9'
  };

  const columnsIndicatorsDemoResultados = [
    {
      name: "mes",
      label: "Mês",
      options: {
        display: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          return value.charAt(0).toUpperCase() + value.slice(1);
        },
      },
    },
    {
      name: "resultadoLiquidoSemVendas",
      label: "Resultado líquido s/ vendas",
      options: {
        display: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          });
    
          return formatter.format(value);
        },
      },
    },
    {
      name: "margemLiquida",
      label: "Margem líquida",
      options: {
        display: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const totalValue = value ?? 0;
          return totalValue.toFixed(2)+'%';
        },
      },
    },
    {
      name: "margemContribuicao",
      label: "Margem de contribuição",
      options: {
        display: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          });
    
          return formatter.format(value);
        },
      },
    },
    {
      name: "margemContribuicaoPorcentagem",
      label: "MC%",
      options: {
        display: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const totalValue = value ?? 0;
          return totalValue.toFixed(2)+'%';
        },
      },
    },
    {
      name: "representatividadeCustoFixo",
      label: "Representatividade custo fixo",
      options: {
        display: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const totalValue = value ?? 0;
          return totalValue.toFixed(2)+'%';
        },
      },
    },
    {
      name: "pontoEquilibrio",
      label: "Ponto de equilíbrio",
      options: {
        display: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const totalValue = value ?? 0;
          return totalValue.toFixed(2)+'%';
        },
      },
    },
    {
      name: "pontoEquilibrio15",
      label: "Ponto de equilíbrio econômico 15%",
      options: {
        display: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const totalValue = value ?? 0;
          return totalValue.toFixed(2)+'%';
        },
      },
    },
    {
      name: "pontoEquilibrio20",
      label: "Ponto de equilíbrio econômico 20%",
      options: {
        display: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const totalValue = value ?? 0;
          return totalValue.toFixed(2)+'%';
        },
      },
    }
  ]

  const columnsIndicators = [
    {
      name: "mes",
      label: "Mês",
      options: {
        display: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          return value.charAt(0).toUpperCase() + value.slice(1);
        },
      },
    },
    {
      name: "saldoInicial",
      label: "Saldo inicial",
      options: {
        display: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          });
    
          return formatter.format(value);
        },
      },
    },
    {
      name: "resultadoTotalCaixa",
      label: "Resultado total de caixa",
      options: {
        display: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          });
    
          return formatter.format(value);
        },
      },
    },
    {
      name: "resultadoTotalCaixaAvg",
      label: "Resultado total de caixa %",
      options: {
        display: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const totalValue = value ?? 0;
          return totalValue.toFixed(2)+'%';
        },
      },
    },
    {
      name: "saldoFinal",
      label: "Saldo final",
      options: {
        display: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          });
    
          return formatter.format(value);
        },
      },
    },
    {
      name: "saldoFinalAvg",
      label: "Saldo final %",
      options: {
        display: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const totalValue = value ?? 0;
          return totalValue.toFixed(2)+'%';
        },
      },
    },
    {
      name: "resultadoOperacionalCaixa",
      label: "Resultado operacional de caixa",
      options: {
        display: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          });
    
          return formatter.format(value);
        },
      },
    },
    {
      name: "resultadoOperacionalCaixaAvg",
      label: "Resultado operacional de caixa %",
      options: {
        display: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const totalValue = value ?? 0;
          return totalValue.toFixed(2)+'%';
        },
      },
    },
    {
      name: "resultadoFluxoInvestimento",
      label: "Resultado fluxo investimento",
      options: {
        display: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          });
    
          return formatter.format(value);
        },
      },
    },
    {
      name: "resultadoFluxoInvestimentoAvg",
      label: "Resultado fluxo investimento %",
      options: {
        display: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const totalValue = value ?? 0;
          return totalValue.toFixed(2)+'%';
        },
      },
    },
    {
      name: "resultadoFluxoFinanciamento",
      label: "Resultado fluxo financiamento",
      options: {
        display: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          });
    
          return formatter.format(value);
        },
      },
    },
    {
      name: "resultadoFluxoFinanciamentoAvg",
      label: "Resultado fluxo financiamento %",
      options: {
        display: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const totalValue = value ?? 0;
          return totalValue.toFixed(2)+'%';
        },
      },
    },
    {
      name: "variacaoLiquidaCaixa",
      label: "Variação líquida de caixa",
      options: {
        display: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          });
    
          return formatter.format(value);
        },
      },
    },
    {
      name: "variacaoLiquidaCaixaAvg",
      label: "Variação líquida de caixa %",
      options: {
        display: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const totalValue = value ?? 0;
          return totalValue.toFixed(2)+'%';
        },
      },
    },
  ]

  const columnsSetorProdutivo = [
    {
      name: "codigo",
      label: "codigo",
      options: {
        display: false,
      },
    },
    {
      name: "descricao",
      label: "Descrição",
      options: {
        filter: true,
        sort: true,
        customHeadRender: () => (
          <TableCell key="name" style={{ ...style, top: 0, zIndex: 102 }}>
            Descrição
          </TableCell>
        ),
        setCellProps: (cellValue) => {
          const codigo = cellValue.split(' - ')[0];

          var bgColor = "#fff";
          const length = codigo.length;

          switch (length) {
            case 1:
              bgColor = "#d1d1d1"
              break;
            case 2:
              bgColor = "#e8e8e8"
              break;
            default:
              bgColor = "#fff"
          }

          return {
            style: { ...style, background: bgColor }
          };},
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const codigo = tableMeta.rowData[0];
          const descricao = tableMeta.rowData[1];
          
          return codigo + ' - ' +descricao;
        },
      },
    },
    {
      name: "tipo",
      label: "tipo",
      options: {
        display: false,
      },
    },
    {
      name: "entrada_3",
      label: "Agrossilvopastoril",
      options: {
        display: false,
      },
    },
    {
      name: "saida_3",
      label: "Agrossilvopastoril",
      options: {
        display: false,
      },
    },
    {
      name: "entrada_4",
      label: "Mineração",
      options: {
        display: false,
      },
    },
    {
      name: "saida_4",
      label: "Mineração",
      options: {
        display: false,
      },
    },
    {
      name: "entrada_5",
      label: "Prestadores de serviços",
      options: {
        display: false,
      },
    },
    {
      name: "saida_5",
      label: "Prestadores de serviços",
      options: {
        display: false,
      },
    },
    {
      name: "agrossilvopastoril",
      label: "Agrossilvopastoril",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const isInput = tableMeta.rowData[2] == 'Entrada';
          
          let monthValue = 0;
          if(isInput){
            monthValue = tableMeta.rowData[3]
          } else {
            monthValue = tableMeta.rowData[4]
          }
          
          const formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          });
    
          return formatter.format(monthValue);
        },
      },
    },
    {
      name: "mineracao",
      label: "Mineração",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const isInput = tableMeta.rowData[2] == 'Entrada';
          
          let monthValue = 0;
          if(isInput){
            monthValue = tableMeta.rowData[5]
          } else {
            monthValue = tableMeta.rowData[6]
          }
          
          const formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          });
    
          return formatter.format(monthValue);
        },
      },
    },
    {
      name: "prestadores",
      label: "Prestadores de serviço",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const isInput = tableMeta.rowData[2] == 'Entrada';
          
          let monthValue = 0;
          if(isInput){
            monthValue = tableMeta.rowData[7]
          } else {
            monthValue = tableMeta.rowData[8]
          }
          
          const formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          });
    
          return formatter.format(monthValue);
        },
      },
    },
    {
      name: "consolidacao",
      label: "Consolidação",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const isInput = tableMeta.rowData[2] == 'Entrada';
          
          let monthValue = 0;
          if(isInput){
            monthValue = tableMeta.rowData[3]+tableMeta.rowData[5]+tableMeta.rowData[7]
          } else {
            monthValue = tableMeta.rowData[4]+tableMeta.rowData[6]+tableMeta.rowData[8]
          }
          
          const formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          });
    
          return formatter.format(monthValue);
        },
      },
    },
  ];

  const columnsIndicatorsSetorProdutivo = [
    {
      name: "resultadoLiquidoSemVendas",
      label: "Resultado líquido s/ vendas",
      options: {
        display: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          });
          console.log('resultadoLiquidoSemVendas', value)
          return formatter.format(value);
        },
      },
    },
    {
      name: "margemLiquida",
      label: "Margem líquida",
      options: {
        display: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const totalValue = value ?? 0;
          return totalValue.toFixed(2)+'%';
        },
      },
    },
    {
      name: "margemContribuicao",
      label: "Margem de contribuição",
      options: {
        display: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          });
    
          return formatter.format(value);
        },
      },
    },
    {
      name: "margemContribuicaoPorcentagem",
      label: "MC%",
      options: {
        display: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const totalValue = value ?? 0;
          return totalValue.toFixed(2)+'%';
        },
      },
    },
    {
      name: "representatividadeCustoFixo",
      label: "Representatividade custo fixo",
      options: {
        display: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const totalValue = value ?? 0;
          return totalValue.toFixed(2)+'%';
        },
      },
    },
    {
      name: "pontoEquilibrio",
      label: "Ponto de equilíbrio",
      options: {
        display: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const totalValue = value ?? 0;
          return totalValue.toFixed(2)+'%';
        },
      },
    },
    {
      name: "pontoEquilibrio15",
      label: "Ponto de equilíbrio econômico 15%",
      options: {
        display: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const totalValue = value ?? 0;
          return totalValue.toFixed(2)+'%';
        },
      },
    },
    {
      name: "pontoEquilibrio20",
      label: "Ponto de equilíbrio econômico 20%",
      options: {
        display: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const totalValue = value ?? 0;
          return totalValue.toFixed(2)+'%';
        },
      },
    }
  ]

  const columns = [
    {
      name: "codigo",
      label: "codigo",
      options: {
        display: false,
      },
    },
    {
      name: "descricao",
      label: "Descrição",
      options: {
        filter: true,
        sort: true,
        customHeadRender: () => (
          <TableCell key="name" style={{ ...style, top: 0, zIndex: 102 }}>
            Descrição
          </TableCell>
        ),
        setCellProps: (cellValue) => {
          const codigo = cellValue.split(' - ')[0];

          var bgColor = "#fff";
          const length = codigo.length;

          switch (length) {
            case 1:
              bgColor = "#d1d1d1"
              break;
            case 2:
              bgColor = "#e8e8e8"
              break;
            default:
              bgColor = "#fff"
          }

          return {
            style: { ...style, background: bgColor }
          };},
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const codigo = tableMeta.rowData[0];
          const descricao = tableMeta.rowData[1];
          
          return codigo + ' - ' +descricao;
        },
      },
    },
    {
      name: "tipo",
      label: "tipo",
      options: {
        display: false,
      },
    },
    {
      name: "entrada_janeiro",
      label: "entrada_janeiro",
      options: {
        display: false,
      },
    },
    {
      name: "saida_janeiro",
      label: "saida_janeiro",
      options: {
        display: false,
      },
    },
    {
      name: "entrada_fevereiro",
      label: "entrada_fevereiro",
      options: {
        display: false,
      },
    },
    {
      name: "saida_fevereiro",
      label: "saida_fevereiro",
      options: {
        display: false,
      },
    },
    {
      name: "entrada_marco",
      label: "entrada_marco",
      options: {
        display: false,
      },
    },
    {
      name: "saida_marco",
      label: "saida_marco",
      options: {
        display: false,
      },
    },
    {
      name: "entrada_abril",
      label: "entrada_abril",
      options: {
        display: false,
      },
    },
    {
      name: "saida_abril",
      label: "saida_abril",
      options: {
        display: false,
      },
    },
    {
      name: "entrada_maio",
      label: "entrada_maio",
      options: {
        display: false,
      },
    },
    {
      name: "saida_maio",
      label: "saida_maio",
      options: {
        display: false,
      },
    },
    {
      name: "entrada_junho",
      label: "entrada_junho",
      options: {
        display: false,
      },
    },
    {
      name: "saida_junho",
      label: "saida_junho",
      options: {
        display: false,
      },
    },
    {
      name: "entrada_julho",
      label: "entrada_julho",
      options: {
        display: false,
      },
    },
    {
      name: "saida_julho",
      label: "saida_julho",
      options: {
        display: false,
      },
    },
    {
      name: "entrada_agosto",
      label: "entrada_agosto",
      options: {
        display: false,
      },
    },
    {
      name: "saida_agosto",
      label: "saida_agosto",
      options: {
        display: false,
      },
    },
    {
      name: "entrada_setembro",
      label: "entrada_setembro",
      options: {
        display: false,
      },
    },
    {
      name: "saida_setembro",
      label: "saida_setembro",
      options: {
        display: false,
      },
    },
    {
      name: "entrada_outubro",
      label: "entrada_outubro",
      options: {
        display: false,
      },
    },
    {
      name: "saida_outubro",
      label: "saida_outubro",
      options: {
        display: false,
      },
    },
    {
      name: "entrada_novembro",
      label: "entrada_novembro",
      options: {
        display: false,
      },
    },
    {
      name: "saida_novembro",
      label: "saida_novembro",
      options: {
        display: false,
      },
    },
    {
      name: "entrada_dezembro",
      label: "entrada_dezembro",
      options: {
        display: false,
      },
    },
    {
      name: "saida_dezembro",
      label: "saida_dezembro",
      options: {
        display: false,
      },
    },
    {
      name: "porcentagem_total_tipo_janeiro",
      label: "porcentagem_total_tipo_janeiro",
      options: {
        display: false,
      },
    },
    {
      name: "porcentagem_total_tipo_fevereiro",
      label: "porcentagem_total_tipo_fevereiro",
      options: {
        display: false,
      },
    },
    {
      name: "porcentagem_total_tipo_marco",
      label: "porcentagem_total_tipo_marco",
      options: {
        display: false,
      },
    },
    {
      name: "porcentagem_total_tipo_abril",
      label: "porcentagem_total_tipo_abril",
      options: {
        display: false,
      },
    },
    {
      name: "porcentagem_total_tipo_maio",
      label: "porcentagem_total_tipo_maio",
      options: {
        display: false,
      },
    },
    {
      name: "porcentagem_total_tipo_junho",
      label: "porcentagem_total_tipo_junho",
      options: {
        display: false,
      },
    },
    {
      name: "porcentagem_total_tipo_julho",
      label: "porcentagem_total_tipo_julho",
      options: {
        display: false,
      },
    },
    {
      name: "porcentagem_total_tipo_agosto",
      label: "porcentagem_total_tipo_agosto",
      options: {
        display: false,
      },
    },
    {
      name: "porcentagem_total_tipo_setembro",
      label: "porcentagem_total_tipo_setembro",
      options: {
        display: false,
      },
    },
    {
      name: "porcentagem_total_tipo_outubro",
      label: "porcentagem_total_tipo_outubro",
      options: {
        display: false,
      },
    },
    {
      name: "porcentagem_total_tipo_novembro",
      label: "porcentagem_total_tipo_novembro",
      options: {
        display: false,
      },
    },
    {
      name: "porcentagem_total_tipo_dezembro",
      label: "porcentagem_total_tipo_dezembro",
      options: {
        display: false,
      },
    },
    {
      name: "codigo",
      label: "codigo",
      options: {
        display: false,
      },
    },
    {
      name: "jan",
      label: "Janeiro",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const isInput = tableMeta.rowData[2] == 'Entrada';
          
          let monthValue = 0;
          if(isInput){
            monthValue = tableMeta.rowData[3]
          } else {
            monthValue = tableMeta.rowData[4]
          }
          
          const formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          });
    
          return formatter.format(monthValue);
        },
      },
    },
    {
      name: "jan-perc",
      label: "AV%",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const porcentagem_total_tipo_jan = tableMeta.rowData[27];
          const totalValue = porcentagem_total_tipo_jan ?? 0;
          return totalValue.toFixed(2)+'%';
        },
      },
    },
    {
      name: "fev",
      label: "Fevereiro",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const isInput = tableMeta.rowData[2] == 'Entrada';
          
          let monthValue = 0;
          if(isInput){
            monthValue = tableMeta.rowData[5]
          } else {
            monthValue = tableMeta.rowData[6]
          }
          
          const formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          });
    
          return formatter.format(monthValue);
        },
      },
    },
    {
      name: "fev-perc",
      label: "AV%",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const porcentagem_total_tipo_fev = tableMeta.rowData[28];
          const totalValue = porcentagem_total_tipo_fev ?? 0;
          return totalValue.toFixed(2)+'%';
        },
      },
    },
    {
      name: "mar",
      label: "Março",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const isInput = tableMeta.rowData[2] == 'Entrada';
          
          let monthValue = 0;
          if(isInput){
            monthValue = tableMeta.rowData[7]
          } else {
            monthValue = tableMeta.rowData[8]
          }
          
          const formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          });
    
          return formatter.format(monthValue);
        },
      },
    },
    {
      name: "mar-perc",
      label: "AV%",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const porcentagem_total_tipo_mar = tableMeta.rowData[29];
          const totalValue = porcentagem_total_tipo_mar ?? 0;
          return totalValue.toFixed(2)+'%';
        },
      },
    },
    {
      name: "abr",
      label: "Abril",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const isInput = tableMeta.rowData[2] == 'Entrada';
          
          let monthValue = 0;
          if(isInput){
            monthValue = tableMeta.rowData[9]
          } else {
            monthValue = tableMeta.rowData[10]
          }
          
          const formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          });
    
          return formatter.format(monthValue);
        },
      },
    },
    {
      name: "abr-perc",
      label: "AV%",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const porcentagem_total_tipo_abr = tableMeta.rowData[30];
          const totalValue = porcentagem_total_tipo_abr ?? 0;
          return totalValue.toFixed(2)+'%';
        },
      },
    },
    {
      name: "mai",
      label: "Maio",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const isInput = tableMeta.rowData[2] == 'Entrada';
          
          let monthValue = 0;
          if(isInput){
            monthValue = tableMeta.rowData[11]
          } else {
            monthValue = tableMeta.rowData[12]
          }
          
          const formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          });
    
          return formatter.format(monthValue);
        },
      },
    },
    {
      name: "mai-perc",
      label: "AV%",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const porcentagem_total_tipo_mai = tableMeta.rowData[31];
          const totalValue = porcentagem_total_tipo_mai ?? 0;
          return totalValue.toFixed(2)+'%';
        },
      },
    },
    {
      name: "jun",
      label: "Junho",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const isInput = tableMeta.rowData[2] == 'Entrada';
          
          let monthValue = 0;
          if(isInput){
            monthValue = tableMeta.rowData[13]
          } else {
            monthValue = tableMeta.rowData[14]
          }
          
          const formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          });
    
          return formatter.format(monthValue);
        },
      },
    },
    {
      name: "jun-perc",
      label: "AV%",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const porcentagem_total_tipo_jun = tableMeta.rowData[32];
          const totalValue = porcentagem_total_tipo_jun ?? 0;
          return totalValue.toFixed(2)+'%';
        },
      },
    },
    {
      name: "jul",
      label: "Julho",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const isInput = tableMeta.rowData[2] == 'Entrada';
          
          let monthValue = 0;
          if(isInput){
            monthValue = tableMeta.rowData[15]
          } else {
            monthValue = tableMeta.rowData[16]
          }
          
          const formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          });
    
          return formatter.format(monthValue);
        },
      },
    },
    {
      name: "jul-perc",
      label: "AV%",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const porcentagem_total_tipo_jul = tableMeta.rowData[33];
          const totalValue = porcentagem_total_tipo_jul ?? 0;
          return totalValue.toFixed(2)+'%';
        },
      },
    },
    {
      name: "ago",
      label: "Agosto",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const isInput = tableMeta.rowData[2] == 'Entrada';
          
          let monthValue = 0;
          if(isInput){
            monthValue = tableMeta.rowData[17]
          } else {
            monthValue = tableMeta.rowData[18]
          }
          
          const formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          });
    
          return formatter.format(monthValue);
        },
      },
    },
    {
      name: "ago-perc",
      label: "AV%",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const porcentagem_total_tipo_ago = tableMeta.rowData[34];
          const totalValue = porcentagem_total_tipo_ago ?? 0;
          return totalValue.toFixed(2)+'%';
        },
      },
    },
    {
      name: "set",
      label: "Setembro",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const isInput = tableMeta.rowData[2] == 'Entrada';
          
          let monthValue = 0;
          if(isInput){
            monthValue = tableMeta.rowData[19]
          } else {
            monthValue = tableMeta.rowData[20]
          }
          
          const formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          });
    
          return formatter.format(monthValue);
        },
      },
    },
    {
      name: "set-perc",
      label: "AV%",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const porcentagem_total_tipo_set = tableMeta.rowData[35];
          const totalValue = porcentagem_total_tipo_set ?? 0;
          return totalValue.toFixed(2)+'%';
        },
      },
    },
    {
      name: "out",
      label: "Outubro",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const isInput = tableMeta.rowData[2] == 'Entrada';
          
          let monthValue = 0;
          if(isInput){
            monthValue = tableMeta.rowData[21]
          } else {
            monthValue = tableMeta.rowData[22]
          }
          
          const formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          });
    
          return formatter.format(monthValue);
        },
      },
    },
    {
      name: "out-perc",
      label: "AV%",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const porcentagem_total_tipo_out = tableMeta.rowData[36];
          const totalValue = porcentagem_total_tipo_out ?? 0;
          return totalValue.toFixed(2)+'%';
        },
      },
    },
    {
      name: "nov",
      label: "Novembro",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const isInput = tableMeta.rowData[2] == 'Entrada';
          
          let monthValue = 0;
          if(isInput){
            monthValue = tableMeta.rowData[23]
          } else {
            monthValue = tableMeta.rowData[24]
          }
          
          const formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          });
    
          return formatter.format(monthValue);
        },
      },
    },
    {
      name: "nov-perc",
      label: "AV%",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const porcentagem_total_tipo_nov = tableMeta.rowData[37];
          const totalValue = porcentagem_total_tipo_nov ?? 0;
          return totalValue.toFixed(2)+'%';
        },
      },
    },
    {
      name: "dez",
      label: "Dezembro",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const isInput = tableMeta.rowData[2] == 'Entrada';
          
          let monthValue = 0;
          if(isInput){
            monthValue = tableMeta.rowData[25]
          } else {
            monthValue = tableMeta.rowData[26]
          }
          
          const formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          });
    
          return formatter.format(monthValue);
        },
      },
    },
    {
      name: "dez-perc",
      label: "AV%",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const porcentagem_total_tipo_dez = tableMeta.rowData[38];
          const totalValue = porcentagem_total_tipo_dez ?? 0;
          return totalValue.toFixed(2)+'%';
        },
      },
    },
  ];
  const optionsIndicatorsDemoResultados = {
    filterType: "dropdown",
    filter: false,
    selectableRows: "none",
    pagination: false,
    searchable: true,
    serverSide: true,
    selectToolbarPlacement: "none",
    MuiTableCell: {
      head: {
          backgroundColor: "#c1e1ec !important",
          position: 'sticky',
      }
    },
    textLabels: {
      body: {
        noMatch: "Nenhum resultado encontrado",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: "Próxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Itens por Página:",
        displayRows: "of",
      },
    },
  };

  const optionsIndicators = {
    changeRowsPerPage: perPage,
    filterType: "dropdown",
    filter: false,
    selectableRows: "none",
    pagination: false,
    searchable: true,
    serverSide: true,
    page: page,
    count: count,
    selectToolbarPlacement: "none",
    MuiTableCell: {
      head: {
          backgroundColor: "#c1e1ec !important",
          position: 'sticky',
      }
    },
    textLabels: {
      body: {
        noMatch: "Nenhum resultado encontrado",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: "Próxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Itens por Página:",
        displayRows: "of",
      },
    },
  };

  const optionsSetorProdutivo = {
    changeRowsPerPage: perPage,
    filterType: "dropdown",
    filter: false,
    selectableRows: "none",
    pagination: false,
    searchable: true,
    serverSide: true,
    page: page,
    count: count,
    selectToolbarPlacement: "none",
    tableBodyHeight: "700px",
    MuiTableCell: {
      head: {
          backgroundColor: "#c1e1ec !important",
          position: 'sticky',
      }
    },
    setRowProps: row => { 
      var bgColor = "#fff";
      // console.log(row.length)
      const length = row[0].length;;

      switch (length) {
        case 1:
          bgColor = "#d1d1d1"
          break;
        case 2:
          bgColor = "#e8e8e8"
          break;
        default:
          bgColor = "#fff"
      }

      if(row[1] == '30'){
        bgColor = "#d9d9d9"
      }

      return {
        style: { background: bgColor }
      };
    },
    textLabels: {
      body: {
        noMatch: "Nenhum resultado encontrado",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: "Próxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Itens por Página:",
        displayRows: "of",
      },
    },
  };

  const options = {
    changeRowsPerPage: perPage,
    filterType: "dropdown",
    filter: false,
    selectableRows: "none",
    pagination: false,
    searchable: true,
    serverSide: true,
    page: page,
    count: count,
    selectToolbarPlacement: "none",
    tableBodyHeight: "700px",
    MuiTableCell: {
      head: {
          backgroundColor: "#c1e1ec !important",
          position: 'sticky',
      }
    },
    setRowProps: row => { 
      var bgColor = "#fff";

      const length = row[39].length;

      switch (length) {
        case 1:
          bgColor = "#d1d1d1"
          break;
        case 2:
          bgColor = "#e8e8e8"
          break;
        default:
          bgColor = "#fff"
      }

      if(row[1] == '30'){
        bgColor = "#d9d9d9"
      }

      return {
        style: { background: bgColor }
      };
    },
    textLabels: {
      body: {
        noMatch: "Nenhum resultado encontrado",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: "Próxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Itens por Página:",
        displayRows: "of",
      },
    },
  };

  return (
    <JumboContentLayout
      header={<PageHeader title={"Consolidação FC"} />}
      layoutOptions={layoutOptions}
    >
      {lg && (
        <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}></Stack>
      )}

      <Grid container spacing={3} mb={2}>
        <Grid item xs={6} sm={6} md={4} lg={3} xl={3} mb={3}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={pt}
            localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}
          >
              <Stack>
                  <DesktopDatePicker
                      sx={{ background: "#ffffff" }}
                      views={['year']}
                      label="Filtrar por ano"
                      value={date}
                      onChange={(event) => {
                        const newValue = dayjs(event.$d).startOf('month')
                        setDate(newValue)
                      }}
                      // renderInput={(params) => <TextField {...params} />}
                  />
              </Stack>
          </LocalizationProvider>
        </Grid>
      </Grid>
      
      {isLoading ?
          <Grid item xs={6} sm={6} md={4} lg={3} xl={3} mb={3} 
            sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex'}}
          >
            <CircularProgress
              color="success"
              disableShrink
            />
          </Grid>
        
      : <React.Fragment>
        <Accordion sx={{ marginBottom: 2 }}>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography>Indicadores - Fluxo de caixa</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <MUIDataTable
              title={""}
              data={indicators}
              columns={columnsIndicators}
              options={optionsIndicators}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{ marginBottom: 2 }}>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography>Consolidação - Fluxo de caixa</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <MUIDataTable
              title={""}
              data={consolidacao}
              columns={columns}
              options={options}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{ marginBottom: 2 }}>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography>Indicadores - Demonstrativo de resultados</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <MUIDataTable
              title={""}
              data={indicatorsDemonstrativoResultados}
              columns={columnsIndicatorsDemoResultados}
              options={optionsIndicatorsDemoResultados}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{ marginBottom: 2 }}>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography>Consolidação - Demonstrativo de resultados</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <MUIDataTable
              title={""}
              data={demonstrativoResultados}
              columns={columns}
              options={options}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{ marginBottom: 2 }}>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography>Indicadores - Demonstrativo de resultados setores produtivos</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3} mb={3}>
              <Grid item xs={12}>
                <Typography>Agrossilvopastoril</Typography>
              </Grid>

              <Grid item xs={12} md={6} lg={3} >
                <MetricCard title={"Resultado líquido s/ vendas"} value={indicatorsSetorProdutivo?.agro?.resultadoLiquidoSemVendas} />
              </Grid>
              <Grid item xs={12} md={6} lg={3} >
                <MetricCard title={"Margem líquida"} value={indicatorsSetorProdutivo?.agro?.margemLiquida} />
              </Grid>
              <Grid item xs={12} md={6} lg={3} >
                <MetricCard title={"Margem de contribuição"} value={indicatorsSetorProdutivo?.agro?.margemContribuicao} />
              </Grid>
              <Grid item xs={12} md={6} lg={3} >
                <MetricCard title={"MC%"} value={indicatorsSetorProdutivo?.agro?.margemContribuicaoPorcentagem} />
              </Grid>
              <Grid item xs={12} md={6} lg={3} >
                <MetricCard title={"Representatividade custo fixo"} value={indicatorsSetorProdutivo?.agro?.representatividadeCustoFixo} />
              </Grid>
              <Grid item xs={12} md={6} lg={3} >
                <MetricCard title={"Ponto de equilíbrio"} value={indicatorsSetorProdutivo?.agro?.pontoEquilibrio} />
              </Grid>
              <Grid item xs={12} md={6} lg={3} >
                <MetricCard title={"Ponto de equilíbrio econômico 15%"} value={indicatorsSetorProdutivo?.agro?.pontoEquilibrio15} />
              </Grid>
              <Grid item xs={12} md={6} lg={3} >
                <MetricCard title={"Ponto de equilíbrio econômico 20%"} value={indicatorsSetorProdutivo?.agro?.pontoEquilibrio20} />
              </Grid>
            </Grid>

            <Grid container spacing={3} mb={3}>
              <Grid item xs={12}>
                <Typography>Mineração</Typography>
              </Grid>

              <Grid item xs={12} md={6} lg={3} >
                <MetricCard title={"Resultado líquido s/ vendas"} value={indicatorsSetorProdutivo?.mineracao?.resultadoLiquidoSemVendas} />
              </Grid>
              <Grid item xs={12} md={6} lg={3} >
                <MetricCard title={"Margem líquida"} value={indicatorsSetorProdutivo?.mineracao?.margemLiquida} />
              </Grid>
              <Grid item xs={12} md={6} lg={3} >
                <MetricCard title={"Margem de contribuição"} value={indicatorsSetorProdutivo?.mineracao?.margemContribuicao} />
              </Grid>
              <Grid item xs={12} md={6} lg={3} >
                <MetricCard title={"MC%"} value={indicatorsSetorProdutivo?.mineracao?.margemContribuicaoPorcentagem} />
              </Grid>
              <Grid item xs={12} md={6} lg={3} >
                <MetricCard title={"Representatividade custo fixo"} value={indicatorsSetorProdutivo?.mineracao?.representatividadeCustoFixo} />
              </Grid>
              <Grid item xs={12} md={6} lg={3} >
                <MetricCard title={"Ponto de equilíbrio"} value={indicatorsSetorProdutivo?.mineracao?.pontoEquilibrio} />
              </Grid>
              <Grid item xs={12} md={6} lg={3} >
                <MetricCard title={"Ponto de equilíbrio econômico 15%"} value={indicatorsSetorProdutivo?.mineracao?.pontoEquilibrio15} />
              </Grid>
              <Grid item xs={12} md={6} lg={3} >
                <MetricCard title={"Ponto de equilíbrio econômico 20%"} value={indicatorsSetorProdutivo?.mineracao?.pontoEquilibrio20} />
              </Grid>
            </Grid>

            <Grid container spacing={3} mb={3}>
              <Grid item xs={12}>
                <Typography>Prestadores de serviços</Typography>
              </Grid>

              <Grid item xs={12} md={6} lg={3} >
                <MetricCard title={"Resultado líquido s/ vendas"} value={indicatorsSetorProdutivo?.prestadores?.resultadoLiquidoSemVendas} />
              </Grid>
              <Grid item xs={12} md={6} lg={3} >
                <MetricCard title={"Margem líquida"} value={indicatorsSetorProdutivo?.prestadores?.margemLiquida} />
              </Grid>
              <Grid item xs={12} md={6} lg={3} >
                <MetricCard title={"Margem de contribuição"} value={indicatorsSetorProdutivo?.prestadores?.margemContribuicao} />
              </Grid>
              <Grid item xs={12} md={6} lg={3} >
                <MetricCard title={"MC%"} value={indicatorsSetorProdutivo?.prestadores?.margemContribuicaoPorcentagem} />
              </Grid>
              <Grid item xs={12} md={6} lg={3} >
                <MetricCard title={"Representatividade custo fixo"} value={indicatorsSetorProdutivo?.prestadores?.representatividadeCustoFixo} />
              </Grid>
              <Grid item xs={12} md={6} lg={3} >
                <MetricCard title={"Ponto de equilíbrio"} value={indicatorsSetorProdutivo?.prestadores?.pontoEquilibrio} />
              </Grid>
              <Grid item xs={12} md={6} lg={3} >
                <MetricCard title={"Ponto de equilíbrio econômico 15%"} value={indicatorsSetorProdutivo?.prestadores?.pontoEquilibrio15} />
              </Grid>
              <Grid item xs={12} md={6} lg={3} >
                <MetricCard title={"Ponto de equilíbrio econômico 20%"} value={indicatorsSetorProdutivo?.prestadores?.pontoEquilibrio20} />
              </Grid>
            </Grid>

            <Grid container spacing={3} mb={3}>
              <Grid item xs={12}>
                <Typography>Conferência</Typography>
              </Grid>

              <Grid item xs={12} md={6} lg={3} >
                <MetricCard title={"Resultado líquido s/ vendas"} value={indicatorsSetorProdutivo?.conferencia?.resultadoLiquidoSemVendas} />
              </Grid>
              <Grid item xs={12} md={6} lg={3} >
                <MetricCard title={"Margem líquida"} value={indicatorsSetorProdutivo?.conferencia?.margemLiquida} />
              </Grid>
              <Grid item xs={12} md={6} lg={3} >
                <MetricCard title={"Margem de contribuição"} value={indicatorsSetorProdutivo?.conferencia?.margemContribuicao} />
              </Grid>
              <Grid item xs={12} md={6} lg={3} >
                <MetricCard title={"MC%"} value={indicatorsSetorProdutivo?.conferencia?.margemContribuicaoPorcentagem} />
              </Grid>
              <Grid item xs={12} md={6} lg={3} >
                <MetricCard title={"Representatividade custo fixo"} value={indicatorsSetorProdutivo?.conferencia?.representatividadeCustoFixo} />
              </Grid>
              <Grid item xs={12} md={6} lg={3} >
                <MetricCard title={"Ponto de equilíbrio"} value={indicatorsSetorProdutivo?.conferencia?.pontoEquilibrio} />
              </Grid>
              <Grid item xs={12} md={6} lg={3} >
                <MetricCard title={"Ponto de equilíbrio econômico 15%"} value={indicatorsSetorProdutivo?.conferencia?.pontoEquilibrio15} />
              </Grid>
              <Grid item xs={12} md={6} lg={3} >
                <MetricCard title={"Ponto de equilíbrio econômico 20%"} value={indicatorsSetorProdutivo?.conferencia?.pontoEquilibrio20} />
              </Grid>
            </Grid>
            {/* <MUIDataTable
              title={""}
              data={indicatorsSetorProdutivo.agro}
              columns={columnsIndicatorsSetorProdutivo}
              options={optionsIndicatorsDemoResultados}
            /> */}
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{ marginBottom: 2 }}>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography>Consolidação - Demonstrativo de resultados setores produtivos</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <MUIDataTable
              title={""}
              data={demonstrativoSetorProdutivo}
              columns={columnsSetorProdutivo}
              options={optionsSetorProdutivo}
            />
          </AccordionDetails>
        </Accordion>
        
      </React.Fragment>
        }
    </JumboContentLayout>
  );
};

export default ConsolidacaoFcList;
