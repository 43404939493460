import {
  Box,
  Button,
  Divider,
  Fade,
  FormLabel,
  Grid,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ApiService from "app/services/config";
import { ErrorMessage, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import moment from "moment";
import ArquivoWrapper from "../ArquivoWrapper/ArquivoWrapper";
import { v4 } from "uuid";
import * as yup from "yup";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { LoadingButton } from "@mui/lab";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const validationSchema = yup.object().shape({
  observacao: yup
    .string()
    .required("Campo obrigatório")
    .typeError("Campo obrigatório"),
});

const ModalEditarDocumentoLicenca = ({
  aberto,
  handleClose,
  documento,
  documentoId,
  userId,
  atualizarUnidade,
}) => {
  const initialValues = {
    observacao: "",
  };
  const [modalAberto, setModalAberto] = useState(aberto);
  const [isDadosCarregados, setIsDadosCarregados] = useState(false);
  const [id, setId] = useState(documentoId);
  const [usuarioId, setUsuarioId] = useState(userId);
  const [documentoLicenca, setDocumentoLicenca] = useState(initialValues);
  const [arquivos, setArquivos] = useState([]);
  const [arquivosUpload, setArquivosUpload] = useState([]);
  const Swal = useSwalWrapper();
  const toast = (variant, message, type = false) => {
    Swal.fire({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      icon: type ? "success" : "error",
      title: message,
      didOpen: (toast) => {
        toast.style.zIndex = 10000;
      },
      timer: 3000,
    });
  };

  const getDocumentoComplementar = async () => {
    try {
      await ApiService.get(
        `/documentocomplementar/${Number(documentoId)}`
      ).then((response) => {
        if (response.status === 200) {
          setDocumentoLicenca({
            observacao: response.data.observacao,
            path_documento: response.data.path_documento,
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const modalAlert = (idDocumento) => {
    Swal.fire({
      title: "Tem certeza que deseja apagar o arquivo?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
      didOpen: () => {
        Swal.getContainer().style.zIndex = "9999";
      },
    }).then((result) => {
      if (result.value) {
        handleDeleteDocumento(idDocumento);
      }
    });
  };

  const handleDeleteDocumento = async (id) => {
    try {
      await ApiService.delete(`/documentocomplementar/documento/${id}`, userId).then(
        (response) => {
          if (response.status === 200) {
            toast(null, "Documento excluído", "success");
            getDocumentoComplementar();
            atualizarUnidade();
          }
        }
      );
    } catch (error) {
      toast("error", "Ocorreu um erro!");
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsDadosCarregados(false);
      await getDocumentoComplementar();
      setIsDadosCarregados(true);
    };
    fetchData();
  }, [documentoId, aberto]);

  useEffect(() => {
    setId(documentoId);
  }, [documentoId]);

  useEffect(() => {
    setUsuarioId(userId);
  }, [userId]);

  useEffect(() => {
    setModalAberto(aberto);
  }, [aberto]);

  const handleRemoverArquivo = (arquivoRemovido) => {
    const novosArquivos = arquivos.filter(
      (arquivo) => arquivo !== arquivoRemovido
    );
    setArquivos(novosArquivos);
  };

  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    delete values.unidadeId;
    delete values.alvaraLicenca;
    delete values.alvaraLicencaId;
    delete values.licencaId;
    delete values.identificacao;
    delete values.unidade;
    delete values.createdAt;
    delete values.updatedAt;
    delete values.deletedAt;
    delete values.users;
    delete values.is_arquivada;
    delete values.documento_licencas;
    delete values.path_documento;

    const files = new FormData();

    for (let i = 0; i < arquivosUpload.length; i++) {
      const file = arquivosUpload[i];
      files.append(`files[${i}]`, file);
    }

    try {
      await ApiService.put(
        `/documentocomplementar/${documentoId}`,
        values
      ).then((response) => {
        if (response.status === 200) {
          toast(null, "Documento atualizada", "success");
          atualizarUnidade();
          handleClose();
        }
      });
      if (arquivosUpload.length > 0) {
        await ApiService.put(
          `documentocomplementar/upload/${documentoId}`,
          files
        ).then((response) => {
          if (response.status === 200) {
            atualizarUnidade();
          }
        });
      }
    } catch (error) {
      if (error.message === "Request failed with status code 422") {
        toast("error", "Formato ou tamanho dos arquivos inválidos!");
      } else {
        toast("error", "Ocorreu um erro");
      }
    }
    setArquivos([]);
  };
  return (
    <>
      <Paper>
        <Modal
          open={modalAberto}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          closeAfterTransition
        >
          <Fade in={modalAberto}>
            <Box
              sx={{
                ...style,
              }}
            >
              {isDadosCarregados && (
                <Box>
                  <Typography variant={"h2"}>Atualizar documento</Typography>
                  <Typography variant={"h5"} color={"#A5A5A5"}>
                    {documento.identificacao}
                  </Typography>
                  <Divider />
                  <Formik
                    enableReinitialize
                    initialValues={documentoLicenca}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({
                      values,
                      handleChange,
                      handleSubmit,
                      handleBlur,
                      errors,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <Form
                        onSubmit={handleSubmit}
                        style={{
                          width: "100%",
                          maxHeight: 500,
                          marginTop: 20,
                          overflowY: "auto",
                        }}
                      >
                        {console.log(errors)}
                        <Box my={1} width="100%">
                          <Typography>Observação</Typography>
                          <TextField
                            type="textarea"
                            multiline
                            rows={4}
                            value={values.observacao}
                            onChange={handleChange}
                            sx={{ width: "100%" }}
                            name="observacao"
                          />
                          <ErrorMessage
                            name="observacao"
                            component={"div"}
                            style={{ color: "red" }}
                          />
                        </Box>
                        <Box mt={2} width="100%">
                          <Typography variant="h6">Anexos</Typography>
                          <Typography variant="h6">
                            Formatos de arquivos permitidos: .jpg, .pdf e .png |
                            Tamanho máximo: 10 MB
                          </Typography>
                          <input
                            accept=".pdf, .png, .jpg, .jpeg"
                            style={{ display: "none" }}
                            id="file-upload"
                            multiple
                            type="file"
                            onChange={(event) => {
                              const files = event.target.files;
                              setArquivosUpload((prevArquivos) => [
                                ...prevArquivos,
                                ...Array.from(files),
                              ]);
                              {console.log(arquivosUpload)}
                              const novosArquivos = Array.from(files).map(
                                (file) => ({
                                  nome: file.name,
                                  tamanho_documento: file.size,
                                  extensao: file.name
                                    .split(".")
                                    .pop()
                                    .toUpperCase(),
                                  dataUpload: moment().format("DD/MM/YYYY"),
                                })
                              );
                              setArquivos((arquivos) => [
                                ...arquivos,
                                ...novosArquivos,
                              ]);
                            }}
                          />

                          <label htmlFor="file-upload">
                            <Button
                              variant="contained"
                              component="span"
                              sx={{
                                width: "100%",
                                background: "transparent",
                                p: 1,
                                color: "#A7A7A7",
                                border: "1px dashed #A7A7A7",
                                "&:hover": {
                                  background: "#2CB3C7",
                                  color: "#FFF",
                                  border: "none",
                                },
                              }}
                              startIcon={<CloudUploadIcon />}
                            >
                              Upload
                            </Button>
                          </label>
                          {arquivos.length === 0 &&
                          documentoLicenca.path_documento?.length === 0 ? (
                            <Typography my={1} variant="body2">
                              Nenhum anexo enviado
                            </Typography>
                          ) : (
                            <>
                              <Stack
                                style={{
                                  maxHeight: "150px",
                                  overflowY: "auto",
                                }}
                              >
                                {documentoLicenca.path_documento?.map(
                                  (arquivo, index) => (
                                    <ArquivoWrapper
                                      key={v4()}
                                      arquivo={arquivo}
                                      tipoLicenca={documento.identificacao}
                                      onRemoverArquivo={modalAlert}
                                      tipoArquivo={"documentoLicenca"}
                                    />
                                  )
                                )}
                              </Stack>
                              <Stack
                                style={{
                                  maxHeight: "150px",
                                  overflowY: "auto",
                                }}
                              >
                                {arquivos.map((arquivo, index) => (
                                  <ArquivoWrapper
                                    key={v4()}
                                    arquivo={arquivo}
                                    tipoLicenca={documento.identificacao}
                                    onRemoverArquivo={handleRemoverArquivo}
                                  />
                                ))}
                              </Stack>
                            </>
                          )}
                        </Box>

                        <input
                          type="number"
                          name="userId"
                          value={usuarioId}
                          hidden
                        />
                        <Box my={2}>
                          <Box
                            mt={3}
                            display="flex"
                            justifyContent="space-evenly"
                          >
                            <Button
                              loading={isSubmitting}
                              type="submit"
                              variant="contained"
                              color="success"
                              onClick={handleSubmit}
                            >
                              Salvar
                            </Button>
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() => handleClose(documento.id)}
                            >
                              Fechar
                            </Button>
                          </Box>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </Box>
              )}
            </Box>
          </Fade>
        </Modal>
      </Paper>
    </>
  );
};

export default ModalEditarDocumentoLicenca;
