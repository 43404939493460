import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { PermissionContext } from "app/contexts/PermissionContext";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import ApiService from "app/services/config";
import { ErrorMessage, Form, Formik } from "formik";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CreatableSelect from "react-select/creatable";
import moment from "moment";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "transparent" : "white",
    border: state.isFocused
      ? "1px solid #005D5F"
      : "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    padding: "8px",
    boxShadow: "none",
    "&:hover": {
      borderColor: "black",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#005D5F" : "white",
    color: state.isSelected ? "white" : "black",
    "&:hover": {
      backgroundColor: "lightblue",
      color: "white",
    },
  }),
};

const validationSchema = yup.object().shape({
  data_submissao: yup
    .date("Campo obrigatório")
    .optional()
    .typeError("Data inválida"),
  processo_administrativo_id: yup.number().required("Campo obrigatório"),
  imovel_id: yup.number().required("Campo obrigatório"),
});

const linkStyle = {
  color: "#302D2E",
  fontSize: 20,
  textDecoration: "none",
  "&:visited": {
    color: "#302D2E",
  },
};

const ProcessosContratoForm = () => {
  const userId = Number(localStorage.getItem("id"));
  const { contratoId, processoId, id, clienteId } = useParams();
  const initialValues = {
    status: 1, // Processo padrão é 'Não iniciado' conforme a RN da Ambiental
    contrato_id: Number(contratoId),
    processo_administrativo_id: "",
    imovel_id: "",
  };
  const [processoAdministrativo, setProcessosAdministrativo] =
    useState(initialValues);
  const [breadCrumbsInfo, setBreadCrumbsInfo] = useState([]);
  const [processosAdministrativos, setProcessosAdministrativos] = useState([]);
  const [statusProcessosAdministrativos, setStatusProcessosAdministrativos] =
    useState([]);
  const [isDeleteMode, setIsDeleteMode] = useState(false);
  const [imoveis, setImoveis] = useState([]);
  const [isDadosCarregados, setIsDadosCarregados] = useState(false);

  const Swal = useSwalWrapper();
  const navigate = useNavigate();
  const location = useLocation();

  const { hasPermission } = useContext(PermissionContext);

  if (!hasPermission("Contratos", "read")) {
    navigate("/app");
  }

  const { theme } = useJumboTheme();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const getImoveis = async () => {
    try {
      await ApiService.get(`/imoveis/cliente/${clienteId}`).then((response) => {
        if (response.status === 200) {
          setImoveis(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getProcessosAdministrativo = useCallback(async () => {
    try {
      ApiService.get(`/servicos/processo/${processoId}`)
        .then((response) => {
          setProcessosAdministrativo({
            ...response.data,
            data_submissao: moment
              .utc(response.data?.data_submissao)
              .format("YYYY-MM-DD"),
            processo_administrativo_id:
              response.data?.processo_administrativo?.id,
            status:
              response.data?.processo_administrativo?.status[0]
                ?.status_processo_id,
            status_orcamentario: response.data?.contrato?.status_orcamentario?.descricao,
            imovel_id: response.data?.imovel?.id 
          });
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getStatusProcessosAdministrativos = async () => {
    try {
      await ApiService.get("/status/processos/admin").then((response) => {
        if (response.status === 200) {
          setStatusProcessosAdministrativos(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateProcessoAdministrativo = useCallback(async (descricao) => {
    const response = await ApiService.post("/processosadmin", { descricao })
      .then((response) => {
        if (response.status === 201) {
          toast("success", "Criado com sucesso!");
          getProcessosAdministrativos();
          return response.data;
        }
      })
      .catch((error) => {
        toast("error", error.message);
      });
    return response;
  });

  const getProcessosAdministrativos = useCallback(async () => {
    try {
      await ApiService.get("/processosadmin/all").then((response) => {
        setProcessosAdministrativos(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const modalAlertDeleteProcesso = (processoId) => {
    Swal.fire({
      title: "Tem certeza que deseja apagar o processo?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteProcesso(processoId);
      }
    });
  };

  const deleteProcesso = useCallback(async (processoId) => {
    try {
      ApiService.delete(`/servicos/processo/${processoId}`)
        .then((response) => {
          toast("success", "Removido com sucesso");
          navigate(
            `/app/contrato/${contratoId}/cliente/${clienteId}/processos`
          );
        })
        .catch((error) => {
          const message = error.response.data.message;
          toast("error", message);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    if (processoId) {
      getProcessosAdministrativo();
    }
    getImoveis();
    getProcessosAdministrativos();
    getStatusProcessosAdministrativos();
    if (location && String(location?.pathname).includes("deletar")) {
      setIsDeleteMode(true);
    }
    setIsDadosCarregados(true);
  }, [processoId]);

  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    const { processo_administrativo_id } = values;
    const newValues = {
      ...values,
      contrato_id: Number(contratoId),
      processo_administrativo_id,
      userId,
    };
    if(values.data_submissao && values.data_submissao !== "") {
      newValues.data_submissao = new Date(values.data_submissao).toISOString()
    }
    delete newValues.id;
    delete newValues.processo_administrativo;
    delete newValues.status_orcamentario;
    delete newValues.contrato;
    delete newValues.prioridade;
    delete newValues.descricao;
    delete newValues.imovel;
    if (processoId) {
      await ApiService.put(`/servicos/processo/${processoId}`, newValues).then(
        (response) => {
          if (response.status === 200) {
            toast("success", "Atualizado com sucesso!");
            navigate(
              `/app/contrato/${contratoId}/cliente/${clienteId}/processos`
            );
          }
        }
      );
    } else {
      await ApiService.post("/servicos/processo", newValues)
        .then((response) => {
          if (response.status === 201) {
            toast("success", "Criado com sucesso");
            navigate(
              `/app/contrato/${contratoId}/cliente/${clienteId}/processos`
            );
            resetForm();
          }
        })
        .catch((error) => {
          console.log(error);
          let message = error;
          toast("error", message);

          if (error.response.data) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    }
    setSubmitting(false);
  };

  const getBreadCrumbsInfo = async () => {
    try {
      return await ApiService.get(
        `/contratos/breadcrumbs?contratoId=${contratoId}&processoId=${processoId}`
      ).then((response) => {
        if (response.status === 200) {
          setBreadCrumbsInfo(response.data);
          if(String(response.data?.status_orcamentario?.descricao).toLowerCase() === "aprovado") {
            setProcessosAdministrativo(prevState => ({
              ...prevState,
              status_orcamentario: response.data?.status_orcamentario?.descricao,
              data_submissao: moment
              .utc(response.data?.data_submissao)
              .format("YYYY-MM-DD"),
            }))
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (contratoId) {
      getBreadCrumbsInfo();
    }
  }, [contratoId]);

  return (
    <JumboContentLayout layoutOptions={layoutOptions}>
      {lg && (
        <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}></Stack>
      )}
      <Stack width={"100%"}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link to={"/app/listar-contratos"} color="success" style={linkStyle}>
            Contratos
          </Link>
          {breadCrumbsInfo && (
            <Link
              to={`/app/editar-contrato/${breadCrumbsInfo?.id}`}
              color="success"
              style={linkStyle}
            >
              {breadCrumbsInfo?.descricao}
            </Link>
          )}
          <Link
            to={`/app/contrato/${contratoId}/cliente/${clienteId}/processos`}
            color="primary"
            style={linkStyle}
          >
            Processos Administrativos
          </Link>
          {location?.state?.processoDescricao && (
            <Link
              to={`/app/contrato/${contratoId}/cliente/${clienteId}/processos`}
              color="success"
              style={linkStyle}
            >
              {location?.state?.processoDescricao}
            </Link>
          )}
          <Link
            to={
              processoId
                ? `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/deletar`
                : `/app/contrato/${contratoId}/cliente/${clienteId}/processos/novo`
            }
            color="primary"
            style={linkStyle}
          >
            {processoId ? "Editar" : "Novo"} Processo Administrativo
          </Link>
        </Breadcrumbs>
      </Stack>
      {isDeleteMode && (
        <Stack width={"100%"} p={1}>
          <Alert severity="error">
            Você está prestes a excluir o processo administrativo abaixo:
          </Alert>
        </Stack>
      )}
      <Paper sx={{ p: "15px" }}>
        <Formik
          initialValues={processoAdministrativo}
          validationSchema={validationSchema}
          enableReinitialize
          validateOnChange={false}
          onSubmit={handleSubmit}
        >
          {({ values, isSubmitting, handleChange, setFieldValue, errors }) => (
            <Form style={{ width: "100%" }} noValidate autoComplete="off">
              <Grid container alignContent={"center"} spacing={2}>
                {(String(processoAdministrativo?.status_orcamentario).toLowerCase() === "aprovado") && (
                  <Grid item lg={3}>
                    <FormGroup>
                      <InputLabel>Data de submissão</InputLabel>
                      <TextField
                        name="data_submissao"
                        type="date"
                        fullWidth
                        disabled={isDeleteMode}
                        value={values.data_submissao}
                        onChange={handleChange}
                        placeholder="Data de submissão"
                      />
                      <ErrorMessage
                        name="data_submissao"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </FormGroup>
                  </Grid> 
                )}
                <Grid item lg={4} xs={12}>
                  <Typography>Processo Administrativo</Typography>
                  <div style={{ position: "relative" }}>
                    <CreatableSelect
                      isClearable
                      styles={customStyles}
                      isDisabled={isDeleteMode}
                      name={`processo_administrativo_id`}
                      onChange={(option) => {
                        setFieldValue(
                          `processo_administrativo_id`,
                          option ? option.id : ""
                        );
                      }}
                      onCreateOption={async (value) => {
                        const { id } = await handleCreateProcessoAdministrativo(
                          value
                        );
                        setFieldValue(`processo_administrativo_id`, id);
                      }}
                      options={processosAdministrativos}
                      getOptionLabel={(option) =>
                        option.__isNew__ ? option.label : option.descricao
                      }
                      value={processosAdministrativos.find(
                        (processo) =>
                          processo.id ===
                          (values.processo_administrativo_id
                            ? values.processo_administrativo_id
                            : "")
                      )}
                      placeholder={"Selecione o Processo Administrativo"}
                    />
                    <ErrorMessage
                      component={"div"}
                      name={`processo_administrativo_id`}
                      style={{ color: "red" }}
                    />
                  </div>
                </Grid>
                {console.log(imoveis)}
                <Grid item lg={4}>
                  <Typography>Imóvel</Typography>
                  <Select
                    fullWidth
                    value={values.imovel_id}
                    name="imovel_id"
                    disabled={isDeleteMode}
                    onChange={handleChange}
                  >
                    {imoveis.length > 0 &&
                      imoveis.map((imovel) => (
                        <MenuItem value={imovel.id}>
                          {imovel.descricao}
                        </MenuItem>
                      ))}
                  </Select>
                  {/* <Select
                    styles={customStyles}
                    options={imoveis}
                    name="imovel_id"
                    fullWidth
                    required
                    isDisabled={isDeleteMode}
                    getOptionLabel={(option) => option.descricao}
                    getOptionValue={(option) => option.id}
                    onChange={(selectedOptions) => {
                      setFieldValue(
                        "imovel_id",
                        selectedOptions
                          ? selectedOptions.map((option) => option.id)
                          : []
                      );
                    }}
                    placeholder="Selecione o imóvel"
                    value={imoveis.filter((imovel) =>
                      values.imovel_id?.includes(imovel.id)
                    )}
                  /> */}
                  <ErrorMessage
                    component={"div"}
                    name="imovel_id"
                    style={{ color: "red" }}
                  />
                </Grid>
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  marginTop={3}
                >
                  {isDeleteMode ? (
                    <Stack direction="row" gap={2} display={"flex"}>
                      <Box>
                        <Button
                          color="error"
                          variant="contained"
                          onClick={() => {
                            modalAlertDeleteProcesso(processoId);
                          }}
                        >
                          Excluir processo
                        </Button>
                      </Box>
                      <Box>
                        <Button
                          color="primary"
                          variant="outlined"
                          onClick={() => {
                            navigate(
                              `/app/contrato/${contratoId}/cliente/${clienteId}/processos`
                            );
                          }}
                        >
                          Cancelar
                        </Button>
                      </Box>
                    </Stack>
                  ) : (
                    <LoadingButton
                      loading={isSubmitting}
                      type="submit"
                      style={{ margin: "auto", display: "block" }}
                      variant="contained"
                      color="primary"
                    >
                      {processoId ? "Atualizar" : "Criar"}
                    </LoadingButton>
                  )}
                </Box>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </JumboContentLayout>
  );
};

export default ProcessosContratoForm;
