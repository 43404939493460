import {
  Avatar,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import EditIcon from "@mui/icons-material/Edit";

const ListImoveisCliente = ({
  imoveis,
  setImovelSelecionado,
  setIndiceImovelSelecionado,
  handleOpenModal,
  handleDelete,
  removeImovel
}) => {
  const [imoveisCliente, setImoveisCliente] = useState(imoveis);

  useEffect(() => {
    setImoveisCliente(imoveis);
  }, [imoveis])
  return (
    <List>
      {imoveisCliente.length > 0 &&
        imoveisCliente.map((imovel, index) => (
          <ListItem
            key={index}
            secondaryAction={
              <Stack direction={"row"} gap={2}>
                <IconButton edge="end" aria-label="edit">
                  <EditIcon
                    color="info"
                    cursor="pointer"
                    onClick={() => {
                      setImovelSelecionado(imovel);
                      setIndiceImovelSelecionado(index);
                      handleOpenModal();
                    }}
                  />
                </IconButton>
                <IconButton edge="end" aria-label="delete">
                  <DeleteIcon
                    color="error"
                    cursor="pointer"
                    onClick={() => {
                      setIndiceImovelSelecionado(index);
                      handleDelete(imovel, removeImovel);
                    }}
                  />
                </IconButton>
              </Stack>
            }
          >
            <ListItemAvatar>
              <Avatar color="success">
                <MapsHomeWorkIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={imovel?.descricao} />
            <Divider my={1} />
          </ListItem>
        ))}
    </List>
  );
};

export default ListImoveisCliente;
