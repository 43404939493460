import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Divider,
  Fade,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  Select as SelectMUI,
  Slider,
  Stack,
  Switch,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Select from "react-select";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import * as yup from "yup";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import ApiService from "app/services/config";
import DeleteIcon from "@mui/icons-material/Delete";
import { v4 } from "uuid";
import CreatableSelect from "react-select/creatable";
import moment from "moment";
import TaskIcon from "@mui/icons-material/Task";
import { List } from "antd";

const ATIVIDADES_DEPENDE = {
  ambiental: {
    atividade: "Ambiental",
    cor: "#EC5F6F",
  },
  cliente: {
    atividade: "Cliente",
    cor: "#F5AB3E",
  },
  orgao: {
    atividade: "Órgão",
    cor: "#5FDBB3",
  },
  protocolado: {
    atividade: "Protocolado",
    cor: "#82A2B9",
  },
  nao_informado: {
    atividade: "Não informado",
    cor: "#F0F0F0",
  },
};

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "transparent" : "white",
    border: state.isFocused
      ? "1px solid #005D5F"
      : "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    padding: "8px",
    boxShadow: "none",
    "&:hover": {
      borderColor: "black",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#005D5F" : "white",
    color: state.isSelected ? "white" : "black",
    "&:hover": {
      backgroundColor: "lightblue",
      color: "white",
    },
  }),
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1200,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const validationSchema = yup.object().shape({
  idColaborador: yup.number().required(),
  contratoExiste: yup.boolean().required(),
  descricao: yup.string().optional(),
  clienteId: yup.number().required("Campo obrigatório"),
  imovelId: yup
    .number()
    .required("Campo obrigatório")
    .typeError("Campo obrigatório"),
  centrosDeCustoId: yup
    .number()
    .required("Campo obrigatório")
    .typeError("Campo obrigatório"),
  processoAdministrativoId: yup
    .number()
    .required("Campo obrigatório")
    .typeError("Campo obrigatório"),
  tipoServicoId: yup
    .number()
    .required("Campo obrigatório")
    .typeError("Campo obrigatório"),
  setorId: yup.number().required("Campo obrigatório"),
  tarefas: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.number().nullable(),
        descricao: yup.string().required("Campo obrigatório"),
      })
    )
    .required("Insira ao menos 1 tarefa")
    .min(1, "Insira ao menos 1 tarefa"),
  prazo: yup.date("Campo obrigatório").required("Campo obrigatório").typeError("Data inválida"),
  municipio: yup.string().optional().nullable(),
  documentacao_necessaria: yup.string().optional().nullable(),
  observacao: yup.string()
    .nullable(),
  status_servico_id: yup
    .number("Campo obrigatório")
    .required("Campo obrigatório")
    .typeError("Campo obrigatório"),
  colaboradores: yup
    .array("Insira ao menos 1 colaborador")
    .of(
      yup.object().shape({
        id: yup.number("Campo obrigatório"),
        name: yup.string(),
      })
    )
    .required("Insira ao menos 1 colaborador")
    .min(1, "Insira ao menos 1 colaborador"),
  prioridade: yup.number().required("Campo obrigatório"),
  responsavel: yup
    .string()
    .required("Campo obrigatório")
    .typeError("Campo obrigatório"),
});

const ModalCadastroServico = ({
  aberto,
  handleClose,
  servicoTarefaId,
  atualizarDashboard,
  colaborador,
}) => {
  const initialValues = {
    idColaborador: Number(colaborador.id),
    contratoExiste: true,
    descricao: "",
    clienteId: "",
    imovelId: "",
    setorId: "",
    centrosDeCustoId: "",
    processoAdministrativoId: "",
    tipoServicoId: "",
    colaboradores: [colaborador],
    descricao: "",
    tarefas: [],
    realizacao: 0,
    municipio: "",
    prazo: "",
    documentacao_necessaria: "",
    observacao: "",
    status_servico_id: "",
    prioridade: 0,
    responsavel: "",
  };
  const [servicoTarefa, setServicoTarefa] = useState(initialValues);
  const [servicoTarefaSelecionadaId, setServicoTarefaSelecionadaId] =
    useState();
  const [isDadosCarregados, setIsDadosCarregados] = useState(false);
  const [statusServico, setStatusServico] = useState([]);
  const [statusServicoSelecionado, setStatusServicoSelecionado] =
    useState(null);
  const [centroDeCustos, setCentroDeCustos] = useState([]);
  const [tiposServicos, setTiposServicos] = useState([]);
  const [processosAdministrativos, setProcessosAdministrativos] = useState([]);
  const [tarefas, setTarefas] = useState([]);
  const [statusOrcamentos, setStatusOrcamentos] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [imoveis, setImoveis] = useState([]);
  const [setores, setSetores] = useState([]);
  const [colaboradores, setColaboradores] = useState([]);
  const [colaboradorServico, setColaboradorServico] = useState(colaborador);
  const [modalAberto, setModalAberto] = useState(aberto);
  const [isServicoExistente, setIsServicoExistente] = useState(true);

  const Swal = useSwalWrapper();
  const toast = (variant, message, type = false) => {
    Swal.fire({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      icon: type ? "success" : "error",
      title: message,
      didOpen: (toast) => {
        toast.style.zIndex = 10000;
      },
      timer: 3000,
    });
  };

  useEffect(() => {
    setModalAberto(aberto);
  }, [aberto]);

  const getCentroDeCustos = useCallback(async () => {
    try {
      await ApiService.get("/centrosdecustos/all").then((response) => {
        setCentroDeCustos(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getTiposServicos = useCallback(async () => {
    try {
      await ApiService.get("/tiposservico").then((response) => {
        setTiposServicos(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getProcessosAdministrativos = useCallback(async () => {
    try {
      await ApiService.get("/processosadmin/all").then((response) => {
        setProcessosAdministrativos(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getTarefas = useCallback(async () => {
    try {
      await ApiService.get("/tarefas/all").then((response) => {
        setTarefas(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getStatusOrcamento = useCallback(async () => {
    try {
      await ApiService.get("/statusorcamento").then((response) => {
        setStatusOrcamentos(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getStatusServicos = async () => {
    try {
      await ApiService.get("/statusservico").then((response) => {
        setStatusServico(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateStatusServico = async (descricao) => {
    try {
      const response = await ApiService.post("/statusservico", {
        descricao,
      });

      if (response.status === 201) {
        toast(null, "Criado com sucesso", "success");
        const novoId = response.data.id;
        setStatusServicoSelecionado(novoId);
        getStatusServicos();
        return novoId;
      }
    } catch (error) {
      toast(null, "Ocorreu um erro", "error");
      console.log(error);
    }
  };

  const handleCreateCentroDeCustos = useCallback(async (descricao) => {
    const response = await ApiService.post("/centrosdecustos", { descricao })
      .then((response) => {
        if (response.status === 201) {
          toast(null, "Criado com sucesso!", "success");
          getCentroDeCustos();
          return response.data;
        }
      })
      .catch((error) => {
        toast("error", error.message);
      });
    return response;
  });

  const handleCreateTipoServico = useCallback(async (descricao) => {
    const response = await ApiService.post("/tiposservico", { descricao })
      .then((response) => {
        if (response.status === 201) {
          toast(null, "Criado com sucesso!", "success");
          getTiposServicos();
          return response.data;
        }
      })
      .catch((error) => {
        toast("error", error.message);
      });
    return response;
  });

  const handleCreateTarefa = useCallback(async (descricao) => {
    const response = await ApiService.post("/tarefas", { descricao })
      .then((response) => {
        if (response.status === 201) {
          toast(null, "Criado com sucesso!", "success");
          getTarefas();
          return response.data;
        }
      })
      .catch((error) => {
        toast("error", error.message);
      });
    return response;
  });

  const handleCreateProcessoAdministrativo = useCallback(async (descricao) => {
    const response = await ApiService.post("/processosadmin", { descricao })
      .then((response) => {
        if (response.status === 201) {
          toast(null, "Criado com sucesso!", "success");
          getProcessosAdministrativos();
          return response.data;
        }
      })
      .catch((error) => {
        toast("error", error.message);
      });
    return response;
  });

  const handleCreateStatusOrcamentario = useCallback(async (descricao) => {
    const response = await ApiService.post("/statusorcamento", { descricao })
      .then((response) => {
        if (response.status === 201) {
          toast(null, "Criado com sucesso!", "success");
          getStatusOrcamento();
          return response.data;
        }
      })
      .catch((error) => {
        toast("error", error.message);
      });
    return response;
  });

  const handleCreateSetor = useCallback(async (descricao) => {
    const response = await ApiService.post("/setores", { descricao })
      .then((response) => {
        if (response.status === 201) {
          toast(null, "Criado com sucesso!", "success");
          getSetores();
          return response.data;
        }
      })
      .catch((error) => {
        toast("error", error.message);
      });
    return response;
  });

  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    try {
      const valoresFormatados = {
        ...values,
        prazo: moment(values.prazo).format('YYYY-MM-DD'),
        idColaborador: Number(values.idColaborador)
      };
      delete valoresFormatados.deletedAt;
      delete valoresFormatados.updatedAt;
      delete valoresFormatados.createdAt;
      delete valoresFormatados.status_servico;
      delete valoresFormatados.status_orcamento_id;

      await ApiService.post(
        `/servicotarefa/inserir/novo-servico/`,
        valoresFormatados
      )
        .then((response) => {
          if (response.status === 201) {
            toast(null, "Criado com sucesso!", "success");
            atualizarDashboard();
            setStatusServicoSelecionado("");
            resetForm();
            handleClose();
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
      toast(null, "Ocorreu um erro", "error");
      console.log(error);
    }
  };

  const getClientes = useCallback(async () => {
    try {
      await ApiService.get("/clientes/all").then((response) => {
        setClientes(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getImoveis = useCallback(async () => {
    try {
      await ApiService.get("/imoveis").then((response) => {
        setImoveis(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getSetores = useCallback(async () => {
    try {
      await ApiService.get("/setores").then((response) => {
        setSetores(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getColaboradores = useCallback(async () => {
    try {
      await ApiService.get("/users/all").then((response) => {
        setColaboradores(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      await getStatusServicos();
      await getCentroDeCustos();
      await getClientes();
      await getImoveis();
      await getSetores();
      await getStatusOrcamento();
      await getProcessosAdministrativos();
      await getTarefas();
      await getTiposServicos();
      await getColaboradores();
    };
    fetchData();
    setIsDadosCarregados(true);
  }, [modalAberto, servicoTarefaId]);

  useEffect(() => {
    setModalAberto(aberto);
    if (!aberto) {
      setServicoTarefa(initialValues);
    }
  }, [aberto]);

  useEffect(() => {
    setColaboradorServico(colaborador);
  }, [colaborador]);


  const optionsStatusServicos = statusServico?.map((status) => {
    return {
      id: status.id,
      descricao: status.descricao,
    };
  });

  return (
    <>
      {isDadosCarregados && (
        <Paper>
          <Modal
            open={modalAberto}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            closeAfterTransition
          >
            <Fade>
              {isDadosCarregados ? (
                <>
                  <Box sx={style}>
                    <Box>
                      <Typography variant={"h2"}>Novo serviço</Typography>
                    </Box>
                    <Divider />
                    <Box my={1}>
                      <Typography variant={"h4"} sx={{ my: 2 }}>
                        Informações sobre o contrato
                      </Typography>
                      <Divider />
                      <Formik
                        enableReinitialize
                        initialValues={servicoTarefa}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                      >
                        {({
                          values,
                          handleChange,
                          handleSubmit,
                          errors,
                          isSubmitting,
                          setFieldValue,
                        }) => (
                          <Form
                            onSubmit={handleSubmit}
                            style={{
                              width: "100%",
                              maxHeight: 500,
                              marginTop: 20,
                              overflowY: "auto",
                            }}
                          >
                            {console.log(errors)}
                            <Box my={1} width={"100%"}>
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <Typography my={1} variant={"h5"}>
                                    O contrato já existe?
                                  </Typography>
                                  <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                  >
                                    <Typography>Não</Typography>
                                    <Switch
                                      color="success"
                                      checked={isServicoExistente}
                                      onChange={(event) => {
                                        setFieldValue('contratoExiste', event.target.checked);
                                        setIsServicoExistente(
                                          event.target.checked
                                        );
                                      }}
                                    />
                                    <Typography>Sim</Typography>
                                  </Stack>
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    label="Código do Contrato"
                                    disabled={!isServicoExistente}
                                    name={"descricao"}
                                    onChange={handleChange}
                                    helperText={
                                      !isServicoExistente &&
                                      "Caso não exista, será gerado automaticamente"
                                    }
                                    fullWidth
                                  />
                                </Grid>
                              </Grid>
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <Typography my={1} variant="h5">
                                    Cliente
                                  </Typography>
                                  {isDadosCarregados && (
                                    <>
                                      <SelectMUI
                                        fullWidth
                                        onChange={(event) => {
                                          setFieldValue(
                                            "clienteId",
                                            event.target.value
                                          );
                                          setFieldValue("imovelId", null);
                                        }}
                                        name="clienteId"
                                      >
                                        {clientes.map((cliente) => {
                                          return (
                                            <MenuItem
                                              key={cliente.id}
                                              value={cliente.id}
                                            >
                                              {cliente.nome}
                                            </MenuItem>
                                          );
                                        })}
                                      </SelectMUI>
                                      <ErrorMessage
                                        name="clienteId"
                                        component={"div"}
                                        style={{ color: "red" }}
                                      />
                                    </>
                                  )}
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography my={1} variant="h5">
                                    Imóvel
                                  </Typography>
                                  {isDadosCarregados && imoveis.length > 0 && (
                                    <>
                                      <Select
                                        styles={customStyles}
                                        options={imoveis.filter(
                                          (imovel) =>
                                            imovel.cliente_id ===
                                            values.clienteId
                                        )}
                                        name="imovelId"
                                        getOptionLabel={(option) =>
                                          option.descricao
                                        }
                                        onChange={(imovel) => {
                                          setFieldValue(
                                            "imovelId",
                                            imovel ? imovel.id : null
                                          );
                                        }}
                                        placeholder="Selecione o imóvel"
                                        value={imoveis.find(
                                          (imovel) =>
                                            imovel.id ===
                                            (values.imovelId && values.imovelId
                                              ? values.imovelId
                                              : "")
                                        )}
                                        fullWidth
                                      />
                                      <ErrorMessage
                                        component={"div"}
                                        name="imovelId"
                                        style={{ color: "red" }}
                                      />
                                    </>
                                  )}
                                </Grid>
                              </Grid>
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <Typography my={1} variant="h5">
                                    Centro de Custos
                                  </Typography>
                                  <CreatableSelect
                                    isClearable
                                    styles={customStyles}
                                    name={`centrosDeCustoId`}
                                    onChange={(option) => {
                                      setFieldValue(
                                        `centrosDeCustoId`,
                                        option ? option.id : ""
                                      );
                                    }}
                                    onCreateOption={async (value) => {
                                      const { id } =
                                        await handleCreateCentroDeCustos(value);
                                      setFieldValue(`centrosDeCustoId`, id);
                                    }}
                                    options={centroDeCustos}
                                    getOptionLabel={(option) =>
                                      option.__isNew__
                                        ? option.label
                                        : option.descricao
                                    }
                                    value={centroDeCustos.find(
                                      (centroDeCusto) =>
                                        centroDeCusto.id ===
                                        values.centroDeCustoId
                                    )}
                                    placeholder={
                                      "Selecione um Centro de Custos"
                                    }
                                  />
                                  <ErrorMessage
                                    component={"div"}
                                    name={`centrosDeCustoId`}
                                    style={{ color: "red" }}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography my={1} variant="h5">
                                    Processo Administrativo
                                  </Typography>
                                  <CreatableSelect
                                    isClearable
                                    styles={customStyles}
                                    name={"processoAdministrativoId"}
                                    onChange={(option) => {
                                      setFieldValue(
                                        `processoAdministrativoId`,
                                        option ? option.id : ""
                                      );
                                    }}
                                    onCreateOption={async (value) => {
                                      const { id } =
                                        await handleCreateProcessoAdministrativo(
                                          value
                                        );
                                      setFieldValue(
                                        `processoAdministrativoId`,
                                        id
                                      );
                                    }}
                                    options={processosAdministrativos}
                                    getOptionLabel={(option) =>
                                      option.__isNew__
                                        ? option.label
                                        : option.descricao
                                    }
                                    value={processosAdministrativos.find(
                                      (processoAdministrativo) =>
                                        processoAdministrativo.id ===
                                        values.processoAdministrativoId
                                    )}
                                    placeholder={
                                      "Selecione um Processo Administrativo"
                                    }
                                  />

                                  <ErrorMessage
                                    component={"div"}
                                    name={`processoAdministrativoId`}
                                    style={{ color: "red" }}
                                  />
                                </Grid>
                              </Grid>
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <Typography my={1} variant="h5">
                                    Tarefa
                                  </Typography>
                                  <FieldArray name={`tarefas`}>
                                    {({ push, remove }) => (
                                      <div
                                        style={{
                                          position: "relative",
                                        }}
                                      >
                                        <CreatableSelect
                                          isClearable
                                          styles={customStyles}
                                          onChange={(option) => {
                                            if (option) {
                                              const { id, descricao, tarefa } =
                                                option;
                                              push({
                                                id,
                                                descricao,
                                                tarefa,
                                              });
                                            }
                                          }}
                                          onCreateOption={async (value) => {
                                            const { id, descricao } =
                                              await handleCreateTarefa(value);
                                            push({ id, descricao });
                                          }}
                                          options={tarefas}
                                          getOptionLabel={(option) =>
                                            option && option.__isNew__
                                              ? option.label
                                              : option && option.descricao
                                          }
                                          value={null}
                                          placeholder={"Selecione uma Tarefa"}
                                        />
                                        <ErrorMessage
                                          component={"div"}
                                          name={`tarefas`}
                                          style={{ color: "red" }}
                                        />
                                        <Box
                                          style={{
                                            width: "100%",
                                            height: "100px",
                                            overflowY: "auto",
                                          }}
                                        >
                                          <List
                                            style={{
                                              borderBottom: "1px solid #cecece",
                                            }}
                                          >
                                            {values.tarefas.map(
                                              (tarefa, tarefaIndex) => {
                                                return (
                                                  <ListItem>
                                                    <ListItemAvatar>
                                                      <TaskIcon />
                                                    </ListItemAvatar>
                                                    <ListItemText>
                                                      {tarefa
                                                        ? tarefa.descricao
                                                        : "Tarefa não encontrada"}
                                                    </ListItemText>
                                                    <Button
                                                      color="error"
                                                      onClick={() =>
                                                        remove(tarefaIndex)
                                                      }
                                                    >
                                                      <DeleteIcon />
                                                    </Button>
                                                  </ListItem>
                                                );
                                              }
                                            )}
                                          </List>
                                        </Box>
                                      </div>
                                    )}
                                  </FieldArray>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography my={1} variant="h5">
                                    Tipo de Serviço
                                  </Typography>
                                  <CreatableSelect
                                    isClearable
                                    styles={customStyles}
                                    name={`tipoServicoId`}
                                    onChange={(option) => {
                                      setFieldValue(
                                        `tipoServicoId`,
                                        option ? option.id : ""
                                      );
                                    }}
                                    onCreateOption={async (value) => {
                                      const { id } =
                                        await handleCreateTipoServico(value);
                                      setFieldValue(`tipoServicoId`, id);
                                    }}
                                    options={tiposServicos}
                                    getOptionLabel={(option) =>
                                      option.__isNew__
                                        ? option.label
                                        : option.descricao
                                    }
                                    value={tiposServicos.find(
                                      (tipoServico) =>
                                        tipoServico.id === values.tipoServicoId
                                    )}
                                    placeholder={"Selecione um Tipo de Serviço"}
                                  />

                                  <ErrorMessage
                                    component={"div"}
                                    name={`tipoServicoId`}
                                    style={{ color: "red" }}
                                  />
                                </Grid>
                              </Grid>
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <Typography my={1} variant="h5">
                                    Setor responsável
                                  </Typography>
                                  <>
                                    <CreatableSelect
                                      isClearable
                                      styles={customStyles}
                                      name={`setorId`}
                                      onChange={(option) => {
                                        setFieldValue(
                                          `setorId`,
                                          option ? option.id : ""
                                        );
                                      }}
                                      onCreateOption={async (value) => {
                                        const { id } = await handleCreateSetor(
                                          value
                                        );
                                        setFieldValue(`setorId`, id);
                                      }}
                                      options={setores}
                                      getOptionLabel={(option) =>
                                        option.__isNew__
                                          ? option.label
                                          : option.descricao
                                      }
                                      value={setores.find(
                                        (setor) =>
                                          setor.id ===
                                          (values.setorId && values.setorId
                                            ? values.setorId
                                            : "")
                                      )}
                                      placeholder={"Selecione um Setor"}
                                    />
                                    <ErrorMessage
                                      component={"div"}
                                      name={`setorId`}
                                      style={{ color: "red" }}
                                    />
                                  </>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography my={1} variant="h5">
                                    Colaboradores
                                  </Typography>
                                  <FieldArray name={`colaboradores`}>
                                    {({ push, remove }) => (
                                      <Box>
                                        <Select
                                          isMulti
                                          styles={customStyles}
                                          options={colaboradores.filter(
                                            (option) =>
                                              !values.colaboradores.some(
                                                (colaborador) =>
                                                  colaborador.id === option.id
                                              )
                                          )}
                                          getOptionLabel={(option) =>
                                            option.name
                                          }
                                          getOptionValue={(option) => option.id}
                                          onChange={(selectedOptions) => {
                                            const novosColaboradores =
                                              selectedOptions.filter(
                                                (option) =>
                                                  !values.colaboradores.some(
                                                    (colaborador) =>
                                                      colaborador.id ===
                                                      option.id
                                                  )
                                              );
                                            novosColaboradores.forEach(
                                              (colaborador) => {
                                                push(colaborador);
                                              }
                                            );
                                            values.colaboradores.forEach(
                                              (colaborador, i) => {
                                                if (
                                                  !selectedOptions.some(
                                                    (option) =>
                                                      option.id ===
                                                      colaborador.id
                                                  )
                                                ) {
                                                  remove(`colaboradores`, i);
                                                }
                                              }
                                            );
                                          }}
                                          value={values.colaboradores}
                                          placeholder="Selecione um colaborador"
                                        />
                                      </Box>
                                    )}
                                  </FieldArray>
                                  <ErrorMessage
                                    component={"div"}
                                    name={`colaboradores`}
                                    style={{ color: "red" }}
                                  />
                                </Grid>
                              </Grid>
                              <Grid container width={"100%"}>
                                <Grid item>
                                  <Typography variant={"h4"} sx={{ my: 2 }}>
                                    Informações sobre o serviço
                                  </Typography>
                                  <Divider />
                                </Grid>
                              </Grid>
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <Typography marginY={1} variant="h5">
                                    Município:
                                  </Typography>
                                  <TextField
                                    name="municipio"
                                    onChange={handleChange}
                                    value={values.municipio}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography marginY={1} variant="h5">
                                    Realização:
                                  </Typography>
                                  <Box
                                    display="flex"
                                    gap={1}
                                    alignItems="center"
                                    justifyContent="space-around"
                                  >
                                    <Slider
                                      value={values.realizacao}
                                      onChange={(event, novoValor) => {
                                        setFieldValue("realizacao", novoValor);
                                      }}
                                      key={v4()}
                                      defaultValue={values.realizacao}
                                      color={"success"}
                                      min={0}
                                      max={100}
                                      sx={{ flex: 4 }}
                                    />
                                    <TextField
                                      name="realizacao"
                                      type="number"
                                      onChange={(event) => {
                                        setFieldValue(
                                          "realizacao",
                                          event.target.value
                                        );
                                      }}
                                      value={values.realizacao}
                                      variant="outlined"
                                      size="small"
                                      InputProps={{
                                        endAdornment: "%",
                                      }}
                                      sx={{ flex: 3 }}
                                    />
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                            <Box my={1} width={"100%"}>
                              <Typography marginY={1} variant="h5">
                                Documentação necessária:
                              </Typography>
                              <TextareaAutosize
                                minRows={5}
                                style={{
                                  width: "100%",
                                  fontFamily: "sans-serif",
                                  fontSize: "16px",
                                }}
                                name="documentacao_necessaria"
                                onChange={handleChange}
                                value={values.documentacao_necessaria}
                              />

                              <ErrorMessage
                                component={"div"}
                                name="documentacao_necessaria"
                                style={{ color: "red" }}
                              />
                            </Box>
                            <Box my={1} width={"100%"}>
                              <Typography marginY={1} variant="h5">
                                Observação:
                              </Typography>
                              <TextareaAutosize
                                minRows={5}
                                style={{
                                  width: "100%",
                                  fontFamily: "sans-serif",
                                  fontSize: "16px",
                                }}
                                name="observacao"
                                onChange={handleChange}
                                value={values.observacao}
                              />

                              <ErrorMessage
                                component={"div"}
                                name="observacao"
                                style={{ color: "red" }}
                              />
                            </Box>
                            <Grid container spacing={2}>
                              <Grid item lg={6}>
                                <Box my={1} width={"100%"}>
                                  <Typography marginY={1} variant="h5">
                                    Prazo:
                                  </Typography>
                                  <TextField
                                    name="prazo"
                                    type="date"
                                    onChange={(event) => {
                                      setFieldValue(
                                        "prazo",
                                        event.target.value
                                      );
                                    }}
                                    value={values.prazo}
                                    variant="outlined"
                                    fullWidth
                                  />

                                  <ErrorMessage
                                    component={"div"}
                                    name="prazo"
                                    style={{ color: "red" }}
                                  />
                                </Box>
                              </Grid>
                              <Grid item lg={6}>
                                <Box my={1} width={"100%"}>
                                  <Typography marginY={1} variant="h5">
                                    Status Serviço:
                                  </Typography>
                                  <CreatableSelect
                                    key={v4()}
                                    isClearable
                                    styles={customStyles}
                                    name={`status_servico_id`}
                                    onChange={(option) => {
                                      if (option) {
                                        setStatusServicoSelecionado({
                                          id: option.id,
                                          descricao: option.descricao,
                                        });
                                        setFieldValue(
                                          `status_servico_id`,
                                          option ? option.id : ""
                                        );
                                      }
                                    }}
                                    onCreateOption={async (value) => {
                                      const novoId =
                                        await handleCreateStatusServico(value);
                                      setStatusServicoSelecionado({
                                        novoId,
                                        descricao: value,
                                      });
                                      setFieldValue(
                                        `status_servico_id`,
                                        novoId
                                      );
                                    }}
                                    options={optionsStatusServicos}
                                    getOptionLabel={(option) =>
                                      option.__isNew__
                                        ? option.label
                                        : option.descricao
                                    }
                                    value={statusServicoSelecionado}
                                    placeholder={
                                      "Selecione um Status de Serviço"
                                    }
                                  />
                                  <ErrorMessage
                                    name="status_servico_id"
                                    component={"div"}
                                    style={{ color: "red" }}
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                              <Grid item lg={6}>
                                <FormLabel id="prioridade-radio-group">
                                  Prioridade
                                </FormLabel>
                                <RadioGroup
                                  row
                                  id="prioridade-radio-group"
                                  name="prioridade"
                                  value={values.prioridade ?? 0}
                                  onChange={(event) => {
                                    setFieldValue(
                                      "prioridade",
                                      Number(event.target.value)
                                    );
                                  }}
                                >
                                  <FormControlLabel
                                    value={0}
                                    label={0}
                                    control={<Radio />}
                                  />
                                  <FormControlLabel
                                    value={1}
                                    label={1}
                                    control={<Radio />}
                                  />
                                  <FormControlLabel
                                    value={2}
                                    label={2}
                                    control={<Radio />}
                                  />
                                  <FormControlLabel
                                    value={3}
                                    label={3}
                                    control={<Radio />}
                                  />
                                </RadioGroup>
                                <ErrorMessage
                                  component={"div"}
                                  name="prioridade"
                                />
                              </Grid>
                              <Grid item lg={6}>
                                <FormLabel id="select-responsavel">
                                  Atividade depende:
                                </FormLabel>
                                <SelectMUI
                                  name="responsavel"
                                  id="select-responsavel"
                                  fullWidth
                                  onChange={handleChange}
                                  renderValue={(value) => {
                                    const atividade = ATIVIDADES_DEPENDE[value];
                                    return (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            width: "10px",
                                            height: "10px",
                                            borderRadius: "50%",
                                            backgroundColor: atividade?.cor,
                                            marginRight: "8px",
                                          }}
                                        />
                                        <Typography>
                                          {atividade?.atividade}
                                        </Typography>
                                      </Box>
                                    );
                                  }}
                                  value={values.responsavel ?? "nao_informado"}
                                  sx={{
                                    "& .MuiSelect-selectMenu": {
                                      display: "flex",
                                      alignItems: "center",
                                    },
                                  }}
                                >
                                  {Object.keys(ATIVIDADES_DEPENDE).map(
                                    (key) => {
                                      const atividade = ATIVIDADES_DEPENDE[key];
                                      return (
                                        <MenuItem
                                          key={key}
                                          value={key}
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              width: "10px",
                                              height: "10px",
                                              borderRadius: "50%",
                                              backgroundColor: atividade.cor,
                                              marginRight: "8px",
                                            }}
                                          />
                                          <Typography>
                                            {atividade.atividade}
                                          </Typography>
                                        </MenuItem>
                                      );
                                    }
                                  )}
                                </SelectMUI>
                                <ErrorMessage
                                  name="responsavel"
                                  component={"div"}
                                  style={{ color: "red" }}
                                />
                              </Grid>
                            </Grid>
                            <Divider />
                            {/* <Grid container my={2}>
                              <Typography variant="h4">Observações</Typography>
                              <FieldArray name="observacao">
                                {({ push, remove }) => (
                                  <Box my={1} width={"100%"}>
                                    {values.observacao &&
                                    values.observacao.length === 0 ? (
                                      <Box
                                        display="flex"
                                        flexDirection="column"
                                        alignItems="center"
                                        justifyContent="center"
                                        height={150}
                                      >
                                        <Typography my={1}>
                                          Nenhuma observação registrada
                                        </Typography>
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          onClick={() =>
                                            push({ descricao: "" })
                                          }
                                        >
                                          Adicionar nova observação
                                        </Button>
                                      </Box>
                                    ) : (
                                      <>
                                        {values.observacao &&
                                          values.observacao.map(
                                            (observacao, index) => (
                                              <Box
                                                key={index}
                                                my={2}
                                                display="flex"
                                                alignItems="center"
                                              >
                                                <Field
                                                  name={`observacao.${index}.descricao`}
                                                  as={TextField}
                                                  label={`Observação ${
                                                    index + 1
                                                  }`}
                                                  variant="outlined"
                                                  fullWidth
                                                  multiline
                                                />
                                                <IconButton
                                                  variant="outlined"
                                                  color="error"
                                                  onClick={() => remove(index)}
                                                >
                                                  <DeleteIcon />
                                                </IconButton>
                                              </Box>
                                            )
                                          )}
                                        <Button
                                          sx={{ backgroundColor: "info" }}
                                          onClick={() =>
                                            push({ descricao: "" })
                                          }
                                        >
                                          Adicionar nova observação
                                        </Button>
                                      </>
                                    )}
                                  </Box>
                                )}
                              </FieldArray>
                            </Grid> */}
                            <Box
                              mt={3}
                              display="flex"
                              justifyContent="space-evenly"
                            >
                              <Button
                                variant="contained"
                                color="success"
                                type="submit"
                              >
                                Salvar
                              </Button>
                              <Button
                                variant="contained"
                                color="error"
                                onClick={() => {
                                  setServicoTarefa(initialValues);
                                  handleClose();
                                }}
                              >
                                Fechar
                              </Button>
                            </Box>
                            
                          </Form>
                        )}
                      </Formik>
                    </Box>
                  </Box>
                </>
              ) : (
                <>Carregando...</>
              )}
            </Fade>
          </Modal>
        </Paper>
      )}
    </>
  );
};

export default ModalCadastroServico;
