import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import MoneyInput from "app/components/MoneyInput/MoneyInput";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import ApiService from "app/services/config";
import { useMediaQuery } from "beautiful-react-hooks";
import { ErrorMessage, Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  descricao: yup.string().required("Campo obrigatório"),
  horario: yup.string().typeError("Valor inválido"),
  status_agendamento: yup.string().nullable(),
  recorrencia: yup
    .string("Campo obrigatório")
    .required("Campo obrigatório")
    .typeError("Campo obrigatório"),
  inicio_recorrencia: yup
    .date("Campo obrigatório")
    .typeError("Data inválida")
    .when("recorrencia", {
      is: (value) => value !== "nenhuma",
      then: yup.date().required("Campo obrigatório"),
      otherwise: yup.date().nullable(),
    }),
  fim_recorrencia: yup
    .date("Campo obrigatório")
    .typeError("Data inválida")
    .when("recorrencia", {
      is: (value) => value !== "nenhuma",
      then: yup.date().required("Campo obrigatório"),
      otherwise: yup.date().nullable(),
    }),
  dias: yup
    .array()
    .of(
      yup.object().shape({
        dia: yup.string().when("recorrencia", {
          is: "semanal",
          then: yup.string().required("Escolha pelo menos um dia"),
        }),
        id: yup.number(),
      })
    )
    .when("recorrencia", {
      is: "semanal",
      then: yup
        .array()
        .min(1, "Escolha pelo menos um dia")
        .required("Campo obrigatório"),
      otherwise: yup.array().nullable(),
    }),
  usuario: yup.number("Campo obrigatório").required("Campo obrigatório"),
});

const DIAS_SEMANA = {
  0: "Domingo",
  1: "Segunda-feira",
  2: "Terça-feira",
  3: "Quarta-feira",
  4: "Quinta-feira",
  5: "Sexta-feira",
  6: "Sábado",
};

const AgendaContaForm = () => {
  const initialValues = {
    descricao: "",
    horario: "",
    status_agendamento: "ativo",
    recorrencia: "nenhuma",
    inicio_recorrencia: "",
    fim_recorrencia: "",
    usuario: "",
    dias: [],
  };
  const [agendaConta, setAgendaConta] = useState(initialValues);
  const [usuarios, setUsuarios] = useState([]);
  const [isModoEdicao, setIsModoEdicao] = useState(false);
  const { id } = useParams();
  const { theme } = useJumboTheme();
  const [selectedDays, setSelectedDays] = useState([]);
  const formikRef = useRef();
  const [idAgenda, setIdAgenda] = useState(null);

  const navigate = useNavigate();

  const Swal = useSwalWrapper();

  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const valuesEdited = {
        ...values,
        conta_id: Number(id),
      };
      console.log(idAgenda, " ID AGENDA");
      if (idAgenda) {
        await ApiService.put(`/agenda-contas/${idAgenda}`, valuesEdited).then(
          (response) => {
            if (response.status === 200) {
              toast(
                "success",
                "Agendamento da Conta atualizada com sucesso!",
                "success"
              );
              navigate("/app/contas-a-pagar");
              resetForm();
            }
          }
        );
      } else {
        await ApiService.post(`/agenda-contas/`, valuesEdited).then(
          (response) => {
            if (response.status === 201) {
              toast(
                "success",
                "Agendamento da Conta atualizada com sucesso!",
                "success"
              );
              navigate("/app/contas-a-pagar");
              resetForm();
            }
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getAgendaConta = async () => {
    try {
      await ApiService.get(`/contas-a-pagar/${id}`).then((response) => {
        if (response.status === 200) {
          const { agenda_conta, data_vencimento, descricao } = response.data;
          console.log(agenda_conta);
          // Se tiver uma agenda relacionada a essa conta
          // Então o usuário vai tá editando a agenda
          // E aí os valores do banco de dados vão preencher o state da Agenda do initial values
          if (agenda_conta) {
            setIdAgenda(agenda_conta.id);

            let dias = agenda_conta.dias_agenda_contas_pivot.filter((dia) => dia.dias_agenda !== null).map((dia) => {
              if (
                dia?.dias_agenda?.id &&
                dia?.dias_agenda.dia &&
                dia?.dias_agenda !== undefined &&
                dia?.dias_agenda !== null
              ) {
                return {
                  dia: dia?.dias_agenda?.dia,
                  id: dia?.dias_agenda?.id,
                };
              }
            });

            setAgendaConta({
              descricao: agenda_conta.descricao,
              inicio_recorrencia: moment
                .utc(agenda_conta.inicio_recorrencia)
                .format("YYYY-MM-DD"),
              fim_recorrencia: moment
                .utc(agenda_conta.fim_recorrencia)
                .format("YYYY-MM-DD"),
              horario: agenda_conta.horario,
              recorrencia: agenda_conta.recorrencia,
              status_agendamento: agenda_conta.status_agendamento,
              usuario: agenda_conta.usuario_id,
              dias,
            });

            setIsModoEdicao(true);
          }
          setAgendaConta((prevAgenda) => ({
            ...prevAgenda,
            data: moment.utc(data_vencimento).format("YYYY-MM-DD"),
            descricao,
          }));
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const modalAlert = () => {
    Swal.fire({
      title: "Tem certeza que deseja apagar o agendamento?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        handleDeleteAgendamento();
      }
    });
  };

  const handleDeleteAgendamento = async () => {
    try {
      await ApiService.delete(`/agenda-contas/${id}`).then((response) => {
        if (response.status === 200) {
          toast("success", "Agendamento excluído!", "success");
          navigate("/app/contas-a-pagar");
        }
      });
    } catch (error) {
      toast("error", "Erro ao excluir o agendamento!", "error");
      console.log(error);
    }
  };

  const getUsuarios = async () => {
    try {
      await ApiService.get("/users/all").then((response) => {
        if (response.status === 200) {
          setUsuarios(response.data);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleDayChange = (value) => {
    if (selectedDays.some((dia) => dia.dia === value[value.length - 1])) {
      const index = selectedDays.findIndex(
        (dia) => dia.dia === value[value.length - 1]
      );
      const newSelectedDays = [
        ...selectedDays.slice(0, index),
        ...selectedDays.slice(index + 1),
      ];
      setSelectedDays(newSelectedDays);
      formikRef.current.setFieldValue("dias", newSelectedDays);
      if (selectedDays[index].id) {
        //handleDeleteDiaAgenda(selectedDays[index].id);
      }
    } else {
      const newSelectedDays =
        typeof value === "string"
          ? value.split(",")
          : value.map((dia) => (typeof dia === "object" ? dia : { dia }));

      setSelectedDays(newSelectedDays);
      formikRef.current.setFieldValue("dias", newSelectedDays);
    }
  };

  const handleDeleteDiaAgenda = async (id) => {
    try {
      await ApiService.delete(`/agenda-contas/dia/agenda/${id}`).then(
        (response) => {
          if (response.status === 200) {
            console.log("Dia deletado!");
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  useEffect(() => {
    if (id) {
      getAgendaConta();
    }
    getUsuarios();
  }, []);

  console.log(`ID AGENDA: ${idAgenda}`);

  return (
    <JumboContentLayout
      header={
        <HeaderBreadcrumbs
          id={id}
          title={"Contas a pagar"}
          subtitle={
            isModoEdicao ? "Editar Agenda de Conta" : "Nova Agenda de Conta"
          }
          titleUrl={"/app/contas-a-pagar"}
        />
      }
      layoutOptions={layoutOptions}
    >
      {lg && (
        <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}></Stack>
      )}
      <Paper width="100%" sx={{ padding: 2, my: 1 }}>
        <Formik
          innerRef={formikRef}
          initialValues={agendaConta}
          validationSchema={validationSchema}
          validateOnBlur
          validateOnChange
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            values,
            isSubmitting,
            handleChange,
            handleBlur,
            updateInputValue,
            setFieldValue,
            errors,
          }) => (
            <Form width="100%">
              <Grid container width="100%" spacing={2} p={2}>
                <Grid item xs={4} lg={4}>
                  <FormGroup>
                    <InputLabel>Descrição</InputLabel>
                    <TextField
                      name="descricao"
                      type="text"
                      value={values.descricao}
                      onChange={handleChange}
                      fullWidth
                      placeholder="Descrição"
                    />
                    <ErrorMessage
                      component={"div"}
                      name="descricao"
                      style={{ color: "red" }}
                    />
                  </FormGroup>
                  {console.log(values)}
                </Grid>
                <Grid item xs={4} lg={4}>
                  <FormGroup>
                    <InputLabel>Horário</InputLabel>
                    <TextField
                      name="horario"
                      type="time"
                      value={values.horario}
                      onChange={handleChange}
                      fullWidth
                      placeholder="Horário"
                    />
                    <FormHelperText>
                      Caso tenha algum horário específico, informar neste campo
                      (OPCIONAL).
                    </FormHelperText>
                    <ErrorMessage
                      component={"div"}
                      name="horario"
                      style={{ color: "red" }}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={4} lg={4}>
                  <FormGroup>
                    <InputLabel>Status do Agendamento</InputLabel>
                    <Select
                      value={values.status_agendamento}
                      onChange={handleChange}
                      name="status_agendamento"
                    >
                      <MenuItem value="ativo">Ativo</MenuItem>
                      <MenuItem value="cancelado">Cancelado</MenuItem>
                      <MenuItem value="concluido">Concluído</MenuItem>
                    </Select>
                    {console.log(errors)}
                    <ErrorMessage
                      component={"div"}
                      name="status_agendamento"
                      style={{ color: "red" }}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={4} lg={4}>
                  <FormGroup>
                    <InputLabel>Colaborador responsável</InputLabel>
                    <Select
                      value={values.usuario}
                      onChange={handleChange}
                      name="usuario"
                      defaultValue={"selecionar"}
                    >
                      <MenuItem value="selecionar">Selecionar</MenuItem>
                      {usuarios &&
                        usuarios.length > 0 &&
                        usuarios.map((usuario) => (
                          <MenuItem value={usuario.id}>{usuario.name}</MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>
                      Este colaborador receberá notificações / atualizações
                      sobre o estado da agenda.
                    </FormHelperText>
                    <ErrorMessage
                      component={"div"}
                      name="usuario"
                      style={{ color: "red" }}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={4} lg={4}>
                  <FormGroup>
                    <InputLabel>Recorrência</InputLabel>
                    <Select
                      value={values.recorrencia}
                      onChange={handleChange}
                      name="recorrencia"
                    >
                      <MenuItem value="nenhuma">Nenhuma</MenuItem>
                      <MenuItem value="diaria">Diária</MenuItem>
                      <MenuItem value="semanal">Semanal</MenuItem>
                      <MenuItem value="mensal">Mensal</MenuItem>
                      <MenuItem value="anual">Anual</MenuItem>
                    </Select>
                    <ErrorMessage
                      component={"div"}
                      name="recorrencia"
                      style={{ color: "red" }}
                    />
                  </FormGroup>
                </Grid>
                {values.recorrencia !== "nenhuma" && (
                  <>
                    <Grid item xs={4} lg={4}>
                      <FormGroup>
                        <InputLabel>Início da Recorrência</InputLabel>
                        <TextField
                          type="date"
                          name="inicio_recorrencia"
                          value={values.inicio_recorrencia}
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          component={"div"}
                          name="inicio_recorrencia"
                          style={{ color: "red" }}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={4} lg={4}>
                      <FormGroup>
                        <InputLabel>Fim da Recorrência</InputLabel>
                        <TextField
                          type="date"
                          name="fim_recorrencia"
                          value={values.fim_recorrencia}
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          component={"div"}
                          name="fim_recorrencia"
                          style={{ color: "red" }}
                        />
                      </FormGroup>
                    </Grid>
                  </>
                )}
                {values.recorrencia === "semanal" && (
                  <Grid item xs={4} lg={4}>
                    <FormGroup>
                      <InputLabel>Dias da Semana</InputLabel>
                      <Select
                        multiple
                        value={values.dias ?? []}
                        name={"dias"}
                        onChange={(event) => {
                          handleDayChange(event.target.value);
                        }}
                        onBlur={handleBlur}
                        input={
                          <OutlinedInput
                            id="select-multiple-chip-checkbox"
                            label="Selecionar Dias"
                          />
                        }
                        renderValue={() => (
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                          >
                            {values.dias.length === 1 ? (
                              <Chip label={DIAS_SEMANA[values.dias[0]?.dia]} />
                            ) : (
                              <Chip
                                label={`${values.dias.length} dias selecionados`}
                              />
                            )}
                          </Box>
                        )}
                      >
                        {Object.entries(DIAS_SEMANA).map(
                          ([chave, valor], index) => (
                            <MenuItem key={index} value={chave}>
                              <Checkbox
                                checked={values?.dias?.some(
                                  (dia) => String(dia?.dia) === String(chave)
                                )}
                              />
                              <ListItemText primary={valor} />
                            </MenuItem>
                          )
                        )}
                      </Select>
                      <ErrorMessage
                        component={"div"}
                        name="dias"
                        style={{ color: "red" }}
                      />
                    </FormGroup>
                  </Grid>
                )}
              </Grid>
              <Grid
                container
                width="100%"
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                spacing={2}
                style={{ my: 1 }}
              >
                <Grid item>
                  <LoadingButton
                    loading={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    {isModoEdicao ? "Atualizar" : "Criar"}
                  </LoadingButton>
                </Grid>

                {isModoEdicao && (
                  <Grid item>
                    <Button
                      onClick={modalAlert}
                      variant="contained"
                      color="error"
                    >
                      Excluir Agendamento
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </JumboContentLayout>
  );
};

export default AgendaContaForm;
