import { InputLabel } from "@mui/material";
import React from "react";
import CurrencyInput from 'react-currency-input-field';

const style = {
  width: "100%",
  height: 50,
  padding: "10px 14px",
  fontSize: "1rem",
  borderRadius: 4,
  border: "1px solid #ced4da",
  backgroundColor: "#fff",
  boxSizing: "border-box",
};

const MoneyInput = ({ name, label, setFieldValue, defaultValue }) => {

  return (
    <>
      <InputLabel htmlFor={name}>{label}</InputLabel>

      <CurrencyInput
        style={style}
        id={name}
        name={name}
        prefix={"R$ "}
        decimalsLimit={2}
        decimalSeparator=","
        groupSeparator="."
        step={0.01}
        value={defaultValue}
        onValueChange={(value) => {
          if (
            value === undefined ||
            value === null ||
            value === ""
          ) {
            setFieldValue(name, 0);
          } else {
            setFieldValue(name, value)
          }
        }}
      />
    </>
  );
};

export default MoneyInput;
