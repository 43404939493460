import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "moment/locale/pt-br";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Divider,
  Tooltip,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Autocomplete,
  CircularProgress,
  OutlinedInput,
  Pagination,
  PaginationItem,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Chart from "react-apexcharts";
import MUIDataTable from "mui-datatables";
import CustomChip from "app/components/CustomChip/CustomChip";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import { useJumboTheme } from "@jumbo/hooks";
import { useMediaQuery } from "beautiful-react-hooks";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import moment, { now } from "moment";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import FilterListIcon from "@mui/icons-material/FilterList";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ApiService from "app/services/config";
import { DateRangePicker } from "react-dates";
import { PieChart } from "@mui/x-charts";
import ModalCadastroServico from "app/components/ModalCadastroServico/ModalCadastroServico";
import { PermissionContext } from "app/contexts/PermissionContext";
import { useLocation, useNavigate } from "react-router-dom";
import CustomChipLicencasUnidade from "app/components/CustomChipLicencasUnidade/CustomChipLicencasUnidade";
import ListaServicosDashboard from "app/components/ListaServicosDashboard/ListaServicosDashboard";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SearchIcon from "@mui/icons-material/Search";

const coresPorAtividade = {
  orgao: "#5FDBB3",
  protocolado: "#82A2B9",
  cliente: "#F5AB3E",
  ambiental: "#EC5F6F",
};
const CORES_DONUT = ["#98A94A", "#77C2C6", "#E5CB74", "#FF7878"];

const Dashboard = () => {
  const roleId = Number(localStorage.getItem("role_id"));
  const userId = Number(localStorage.getItem("id"));
  const { theme } = useJumboTheme();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const [dadosAcoes, setDadosAcoes] = useState([]);
  const [dadosPlanilha, setDadosPlanilha] = useState([]);
  const [dadosPlanilhaFormatados, setDadosPlanilhaFormatados] = useState([]);
  const [dadosGraficoDeBarras, setDadosGraficoDeBarras] = useState([]);
  const [tarefas, setTarefas] = useState([]);
  const [colaboradores, setColaboradores] = useState([]);
  const [setores, setSetores] = useState([]);
  const [statusOrcamento, setStatusOrcamento] = useState([]);
  const [statusServico, setStatusServico] = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");
  const [count, setCount] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [atividadesSelecionadas, setAtividadesSelecionadas] = useState([]);
  const [isModalCadastroServicoAberto, setIsModalCadastroServicoAberto] =
    useState(false);
  const atividadesGrafico = {
    orgao: "Órgão",
    protocolado: "Protocolado",
    cliente: "Cliente",
    ambiental: "Ambiental",
  };
  const [scrollToServicoId, setScrollToServicoId] = useState(null);
  const servicosIdsRef = useRef({});
  const location = useLocation();
  const [abaSelecionada, setAbaSelecionada] = useState("em_andamento");

  const [filtrosPesquisa, setFiltrosPesquisa] = useState({
    colaboradoresServico: [],
    depende: [],
    setores: [],
    situacao: null,
    data_inicio: null,
    data_final: null,
    periodo: "selecionar",
    status: "em_andamento",
  });
  const [isDadosCarregando, setIsDadosCarregando] = useState(false);
  const navigate = useNavigate();
  const { hasPermission } = useContext(PermissionContext);
  if (!hasPermission("Gestão de Serviços", "read")) {
    navigate("/app");
  }

  const Swal = useSwalWrapper();
  const toast = (variant, message, type = false) => {
    Swal.fire({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      icon: type ? "success" : "error",
      title: message,
      didOpen: (toast) => {
        toast.style.zIndex = 10000;
      },
      timer: 3000,
    });
  };

  const idColaborador = localStorage.getItem("id");
  const nomeColaborador = localStorage.getItem("username");

  const subtitleGraficos = () => {
    let texto = "Depende de: ";
    if (
      filtrosPesquisa.depende &&
      filtrosPesquisa.depende.length > 0 &&
      filtrosPesquisa.depende.length < 4
    ) {
      const atividades = filtrosPesquisa.depende.map((atividade) => {
        switch (atividade) {
          case "orgao":
            return "Órgão";
          case "protocolado":
            return "Protocolado";
          case "cliente":
            return "Cliente";
          case "ambiental":
            return "Ambiental";
          default:
            return "";
        }
      });
      texto += atividades.join(", ");
    } else if (
      filtrosPesquisa.depende.length === 4 &&
      filtrosPesquisa.depende
    ) {
      texto += "Geral";
    } else {
      texto += "Geral";
    }

    if (
      filtrosPesquisa.periodo !== "selecionar" &&
      filtrosPesquisa.data_final &&
      filtrosPesquisa.data_final
    ) {
      const FORMATAR_PERIODOS = {
        data_conclusao: "Data de Conclusão",
        prazo: "Prazo",
        createdAt: "Data de Registro",
      };
      texto += ` | ${FORMATAR_PERIODOS[filtrosPesquisa.periodo]} - De ${moment(
        filtrosPesquisa.data_inicio
      ).format("DD/MM/YYYY")} à ${moment(filtrosPesquisa.data_final).format(
        "DD/MM/YYYY"
      )}`;
    } else {
      texto += " | Período: Geral";
    }

    return texto;
  };

  const handleIniciarServico = async (id, status_servico) => {
    try {
      setScrollToServicoId(id);
      if (status_servico === 1) {
        toast("info", "O serviço já foi iniciado!");
        return;
      }

      await ApiService.put(`/servicos/iniciar/${id}`).then((response) => {
        if (response.status === 200) {
          toast(null, "Serviço iniciado!", "success");
          getDadosPlanilha();
          getDadosGraficoDeBarras();
        }
      });
    } catch (error) {
      console.log(error);
      toast(null, "Ocorreu um erro", "error");
    }
  };

  const handleConcluirServico = async (id, status_servico) => {
    try {
      setScrollToServicoId(id);
      if (status_servico === 12) {
        toast("info", "O serviço já foi concluído!");
        return;
      }
      await ApiService.put(`/servicos/concluir/${id}`).then((response) => {
        if (response.status === 200) {
          toast(null, "Ação concluída", "success");
          getDadosPlanilha();
          getDadosGraficoDeBarras();
        }
      });
    } catch (error) {
      console.log(error);
      toast(null, "Ocorreu um erro", "error");
    }
  };

  const handleAumentarPrioridadeProcesso = async (processoId) => {
    try {
      // setScrollToServicoId(id);
      await ApiService.put(
        `/servicos/aumentar/prioridade/${processoId}`
      ).then((response) => {
        if (response.status === 200) {
          toast(null, "Prioridade do processo aumentada!", "success");
          getDadosPlanilha();
          getDadosGraficoDeBarras();
        }
      });
    } catch (error) {
      console.log(error);
      toast(null, "Ocorreu um erro", "error");
    }
  };

  const handleDiminuirPrioridadeProcesso = async (processoId) => {
    try {
      // setScrollToServicoId(id);
      await ApiService.put(
        `/servicos/diminuir/prioridade/${processoId}`
      ).then((response) => {
        if (response.status === 200) {
          toast(null, "Prioridade do processo diminuida!", "success");
          getDadosPlanilha();
          getDadosGraficoDeBarras();
        }
      });
    } catch (error) {
      console.log(error);
      toast(null, "Ocorreu um erro", "error");
    }
  };

  const handleParalisarServico = async (id, status_servico) => {
    try {
      setScrollToServicoId(id);
      if (status_servico === 6) {
        toast("info", "O serviço já foi paralisado!");
        return;
      }
      await ApiService.put(`/servicos/paralisar/${id}`).then((response) => {
        if (response.status === 200) {
          toast(null, "Serviço paralisado!", "success");
          getDadosPlanilha();
          getDadosGraficoDeBarras();
        }
      });
    } catch (error) {
      console.log(error);
      toast(null, "Ocorreu um erro", "error");
    }
  };

  const handleSearch = async (newSearchTerm) => {
    if (searchTerm === "" || searchTerm === null || searchTerm === undefined) {
      setPage(1);
    }
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
    if (startDate && endDate) {
      setFiltrosPesquisa((prevFiltros) => ({
        ...prevFiltros,
        data_inicio: moment(startDate._d).format("YYYY-MM-DD"),
        data_final: moment(endDate._d).format("YYYY-MM-DD"),
      }));
    }
  };

  const handleLegendClick = (chartContext, seriesIndex, config) => {
    setTimeout(() => {
      let filtroDepende = config.config.labels[seriesIndex];
      let atividadeSelecionada = null;

      switch (filtroDepende) {
        case "Órgão":
          atividadeSelecionada = "orgao";
          break;
        case "Protocolado":
          atividadeSelecionada = "protocolado";
          break;
        case "Cliente":
          atividadeSelecionada = "cliente";
          break;
        case "Ambiental":
          atividadeSelecionada = "ambiental";
          break;
      }

      if (atividadeSelecionada) {
        setFiltrosPesquisa((prevFiltros) => ({
          ...prevFiltros,
          depende: prevFiltros.depende.includes(atividadeSelecionada)
            ? prevFiltros.depende.filter(
                (atividade) => atividade !== atividadeSelecionada
              )
            : [...prevFiltros.depende, atividadeSelecionada],
        }));
        setAtividadesSelecionadas((prevSelecionadas) =>
          prevSelecionadas.includes(atividadeSelecionada)
            ? prevSelecionadas.filter(
                (atividade) => atividade !== atividadeSelecionada
              )
            : [...prevSelecionadas, atividadeSelecionada]
        );
      }
    }, 1000);
  };

  const handleModalCadastroServico = () => {
    setIsModalCadastroServicoAberto(!isModalCadastroServicoAberto);
  };

  const modalAlert = (id, acao, status_tarefa) => {
    Swal.fire({
      title:
        acao === "concluir"
          ? "Tem certeza que deseja concluir a ação?"
          : "Tem certeza que deseja iniciar a ação?",
      text:
        acao === "concluir"
          ? "Você poderá iniciá-la novamente depois"
          : "Você poderá atribuir outra data de início posteriormente",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        if (acao === "concluir") {
          handleConcluirServico(id);
        }
        if (acao === "iniciar") {
          handleIniciarServico(id, status_tarefa);
        }
      }
    });
  };

  const getTarefas = async () => {
    try {
      await ApiService.get("/tarefas/all").then((response) => {
        if (response.status === 200) {
          setTarefas(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getSetores = async () => {
    try {
      await ApiService.get("/setores").then((response) => {
        if (response.status === 200) {
          setSetores(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getStatusOrcamento = async () => {
    try {
      await ApiService.get("/statusorcamento").then((response) => {
        if (response.status === 200) {
          setStatusOrcamento(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getStatusServico = async () => {
    try {
      await ApiService.get("/statusservico").then((response) => {
        if (response.status === 200) {
          setStatusServico(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleFocusChange = (focusedInput) => {
    setFocusedInput(focusedInput);
  };

  const getDadosAcoes = useCallback(async () => {
    try {
      let apiUrl = `/servicos/dashboard/count`;
      let filtros = {};
      if (filtrosPesquisa.depende.length > 0) {
        filtros.depende = filtrosPesquisa.depende;
      }

      if (filtrosPesquisa.colaboradoresServico.length > 0) {
        filtros.colaboradores = filtrosPesquisa.colaboradoresServico.map(
          (colaborador) => colaborador.id
        );
      }

      if (filtrosPesquisa.setores.length > 0) {
        filtros.setores = filtrosPesquisa.setores.map((setor) => setor.id);
      }

      if (filtrosPesquisa.situacao) {
        filtros.situacao = filtrosPesquisa.situacao.id;
      }

      if (filtrosPesquisa.data_inicio) {
        filtros.data_inicio = filtrosPesquisa.data_inicio;
      }

      if (filtrosPesquisa.data_final) {
        filtros.data_final = filtrosPesquisa.data_final;
      }

      if (filtrosPesquisa.status) {
        filtros.status = abaSelecionada;
      }

      if (
        filtrosPesquisa.periodo !== "selecionar" &&
        filtrosPesquisa.data_inicio !== null &&
        filtrosPesquisa.data_final !== null
      ) {
        console.log(filtrosPesquisa.periodo);
        filtros.periodo = filtrosPesquisa.periodo;
        filtros.data_inicio = filtrosPesquisa.data_inicio;
        filtros.data_final = filtrosPesquisa.data_final;
      }

      filtros.page = page;
      filtros.perPage = perPage;
      filtros.sortOrder = sortOrder;
      filtros.sortBy = sortBy;
      filtros.searchTerm = searchTerm;
      filtros.roleId = roleId;
      filtros.userId = userId;

      await ApiService.post(apiUrl, filtros).then((response) => {
        if (response.status === 201) {
          setDadosAcoes({
            orgao: response.data[0],
            protocolado: response.data[1],
            cliente: response.data[2],
            ambiental: response.data[3],
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  });

  const getDadosPlanilha = useCallback(async () => {
    try {
      let filtros = {};
      let apiUrl = `/processos-contrato/`;

      if (filtrosPesquisa.depende.length > 0) {
        filtros.depende = filtrosPesquisa.depende;
      }

      if (filtrosPesquisa.colaboradoresServico.length > 0) {
        filtros.colaboradores = filtrosPesquisa.colaboradoresServico.map(
          (colaborador) => colaborador.id
        );
      }

      if (filtrosPesquisa.setores.length > 0) {
        filtros.setores = filtrosPesquisa.setores.map((setor) => setor.id);
      }

      if (filtrosPesquisa.situacao) {
        filtros.situacao = filtrosPesquisa.situacao.id;
      }

      if (filtrosPesquisa.data_inicio) {
        filtros.data_inicio = filtrosPesquisa.data_inicio;
      }

      if (filtrosPesquisa.data_final) {
        filtros.data_final = filtrosPesquisa.data_final;
      }

      if (filtrosPesquisa.status) {
        filtros.status = abaSelecionada;
      }

      if (
        filtrosPesquisa.periodo !== "selecionar" &&
        filtrosPesquisa.data_inicio !== null &&
        filtrosPesquisa.data_final !== null
      ) {
        console.log(filtrosPesquisa.periodo);
        filtros.periodo = filtrosPesquisa.periodo;
        filtros.data_inicio = filtrosPesquisa.data_inicio;
        filtros.data_final = filtrosPesquisa.data_final;
      }

      filtros.page = page;
      filtros.perPage = perPage;
      filtros.sortOrder = sortOrder;
      filtros.sortBy = sortBy;
      filtros.searchTerm = searchTerm;
      filtros.roleId = roleId;
      filtros.userId = userId;

      console.log(filtros);

      await ApiService.post(apiUrl, filtros)
        .then((response) => {
          if (response.status === 201) {
            setDadosPlanilhaFormatados(response.data.processos);
            setDadosPlanilha(response.data.processos);
            setCount(response.data.count);
          }
        })
        .catch((err) => console.log(err));
    } catch (error) {
      console.error(error);
    }
  }, [page, perPage, sortBy, sortOrder, searchTerm, filtrosPesquisa, abaSelecionada]);

  const getDadosGraficoDeBarras = async () => {
    try {
      setIsDadosCarregando(true);
      let apiUrl = `/servicotarefa/tarefas/dashboard/prazos`;

      let filtros = {};

      filtros.depende = filtrosPesquisa.depende;

      if (filtrosPesquisa.colaboradoresServico.length > 0) {
        filtros.colaboradores = filtrosPesquisa.colaboradoresServico.map(
          (colaborador) => colaborador.id
        );
      }

      if (filtrosPesquisa.setores.length > 0) {
        filtros.setores = filtrosPesquisa.setores.map((setor) => setor.id);
      }

      if (filtrosPesquisa.situacao) {
        filtros.situacao = filtrosPesquisa.situacao.id;
      }

      if (filtrosPesquisa.data_inicio) {
        filtros.data_inicio = filtrosPesquisa.data_inicio;
      }

      if (filtrosPesquisa.data_final) {
        filtros.data_final = filtrosPesquisa.data_final;
      }

      if (filtrosPesquisa.status) {
        filtros.status = abaSelecionada;
      }

      if (
        filtrosPesquisa.periodo !== "selecionar" &&
        filtrosPesquisa.data_inicio !== null &&
        filtrosPesquisa.data_final !== null
      ) {
        console.log(filtrosPesquisa.periodo);
        filtros.periodo = filtrosPesquisa.periodo;
        filtros.data_inicio = filtrosPesquisa.data_inicio;
        filtros.data_final = filtrosPesquisa.data_final;
      }

      filtros.page = page;
      filtros.perPage = perPage;
      filtros.sortOrder = sortOrder;
      filtros.sortBy = sortBy;
      filtros.searchTerm = searchTerm;
      filtros.roleId = roleId;
      filtros.userId = userId;

      await ApiService.post(apiUrl, filtros).then((response) => {
        setTimeout(() => {
          if (response.status === 201) {
            console.log(response.data);
            const dados = [
              { tipo: "geral", dados: response?.data?.[0]["geral"] },
              { tipo: "ambiental", dados: response?.data?.[0]["ambiental"] },
              { tipo: "orgao", dados: response?.data?.[0]["orgao"] },
              {
                tipo: "protocolado",
                dados: response?.data?.[0]["protocolado"],
              },
              { tipo: "cliente", dados: response?.data?.[0]["cliente"] },
            ];

            const dadosFormatados = dados.reduce((acc, curr) => {
              acc[curr.tipo] = curr.dados;
              return acc;
            }, {});

            setDadosGraficoDeBarras(dadosFormatados);
          }
        }, 1000);
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsDadosCarregando(false);
    }
  };

  const getColaboradores = async () => {
    try {
      await ApiService.get("/users/all").then((response) => {
        if (response.status === 200) {
          setColaboradores(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleLimparFiltros = () => {
    setSearchTerm("");
    setAtividadesSelecionadas([]);
    setEndDate(null);
    setStartDate(null);
    setFiltrosPesquisa({
      depende: [],
      colaboradoresServico: [],
      data_inicio: null,
      data_final: null,
      periodo: "selecionar",
      prazo: "",
      setores: [],
      situacao: null,
      status: "em_andamento",
    });
    setAbaSelecionada("em_andamento");
  };

  useEffect(() => {
    getStatusOrcamento();
    getStatusServico();
    getColaboradores();
    getSetores();
  }, []);

  useEffect(async () => {
    setIsDadosCarregando(true);
    await Promise.all([
      getTarefas(),
      getDadosPlanilha(),
      getDadosAcoes(),
      getDadosGraficoDeBarras(),
    ]);
    setIsDadosCarregando(false);
  }, [page, filtrosPesquisa, searchTerm, sortBy, sortOrder, page, perPage, abaSelecionada]);

  useEffect(() => {
    setCount(dadosPlanilhaFormatados?.length ?? 0);
  }, [dadosPlanilhaFormatados]);

  useEffect(() => {
    if (scrollToServicoId) {
      const elemento = document.getElementById(scrollToServicoId);

      const rect = elemento.getBoundingClientRect();
      const scrollTopAtual =
        window.pageYOffset || document.documentElement.scrollTop;
      const posicaoYElemento = rect.top + scrollTopAtual;

      const alturaJanela = window.innerHeight;
      const deslocamento = alturaJanela / 2 - rect.height / 2;

      window.scrollTo({
        top: posicaoYElemento - deslocamento - 175,
        behavior: "smooth",
      });

      console.log("Coordenada Y do elemento:", posicaoYElemento);
      console.log("Scroll Y Atual:", scrollTopAtual);
      console.log("Altura da Janela:", alturaJanela);
      console.log("Deslocamento calculado:", deslocamento);
    }
  }, [scrollToServicoId]);

  return (
    <JumboContentLayout
      header={<PageHeader title={"Dashboard"} />}
      layoutOptions={{
        sidebar: {
          sx: {
            [theme.breakpoints.up("lg")]: {
              position: "sticky",
              zIndex: 5,
              top: 96,
              minHeight: "auto",
            },
            [theme.breakpoints.down("lg")]: {
              display: "none",
            },
          },
        },
        wrapper: {
          sx: {
            alignItems: "flex-start",
          },
        },
      }}
    >
      {location.pathname === "/" && roleId !== 1 ? (
        <Box>
          <Typography variant={"h1"}>Bem-vindo (a)!</Typography>
        </Box>
      ) : (
        <>
          <Paper
            elevation={2}
            sx={{
              width: "100%",
              background: "white",
              p: 4,
              my: -5,
              maxWidth: "100%",
            }}
          >
            <Typography variant={"h2"} sx={{ mb: 5 }}>
              Dashboard de ações
            </Typography>

            {isDadosCarregando ? (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  p: 2,
                }}
              >
                <CircularProgress color="success" />
              </Box>
            ) : (
              <>
                <Grid
                  container
                  spacing={3}
                  sx={{ marginTop: 2, maxWidth: "100%" }}
                >
                  <Grid item xs={12} md={6}>
                    {dadosAcoes && (
                      <Chart
                        type="donut"
                        series={
                          atividadesSelecionadas &&
                          atividadesSelecionadas.length > 0
                            ? atividadesSelecionadas.map(
                                (atividade) => dadosAcoes[atividade]
                              )
                            : Object.values(dadosAcoes)
                        }
                        width={"80%"}
                        height={"auto"}
                        options={{
                          noData: {
                            align: "center",
                            verticalAlign: "middle",
                            text: "Nenhum dado disponível",
                          },
                          tooltip: {
                            enabled: true,
                          },
                          chart: {
                            events: {
                              legendClick: handleLegendClick,
                            },
                            toolbar: {
                              show: true,
                              offsetX: 0,
                              offsetY: 0,
                              tools: {
                                download: true,
                                selection: true,
                                zoom: true,
                                zoomin: true,
                                zoomout: true,
                                pan: true,
                                reset:
                                  true |
                                  '<img src="/static/icons/reset.png" width="20">',
                                customIcons: [],
                              },
                              export: {
                                csv: {
                                  filename:
                                    "gestao-ambiental_controle-acoes-csv",
                                  columnDelimiter: ",",
                                  headerCategory: "category",
                                  headerValue: "value",
                                  dateFormatter(timestamp) {
                                    return new Date(timestamp).toDateString();
                                  },
                                },
                                svg: {
                                  filename:
                                    "gestao-ambiental_controle-acoes-svg",
                                },
                                png: {
                                  filename:
                                    "gestao-ambiental_controle-acoes-png",
                                },
                              },
                              autoSelected: "zoom",
                            },
                          },
                          dataLabels: {
                            enabled: true,
                          },
                          fill: {
                            colors:
                              atividadesSelecionadas.length > 0
                                ? atividadesSelecionadas.map(
                                    (atividade) => coresPorAtividade[atividade]
                                  )
                                : CORES_DONUT,
                          },
                          legend: {
                            show: true,
                            position: "bottom",
                            labels: {
                              colors: theme.palette.text.primary,
                            },
                            onItemClick: {
                              toggleDataSeries: true,
                            },
                            markers: {
                              fillColors:
                                atividadesSelecionadas.length > 0
                                  ? atividadesSelecionadas.map((atividade) => [
                                      coresPorAtividade[atividade],
                                    ])
                                  : Object.keys(dadosAcoes).map(
                                      (atividade) =>
                                        coresPorAtividade[atividade]
                                    ),
                            },
                          },
                          labels:
                            atividadesSelecionadas &&
                            atividadesSelecionadas.length > 0
                              ? atividadesSelecionadas.map(
                                  (atividade) => atividadesGrafico[atividade]
                                )
                              : [
                                  "Órgão",
                                  "Protocolado",
                                  "Cliente",
                                  "Ambiental",
                                ],
                          title: {
                            align: "center",
                            margin: 10,
                            style: {
                              fontSize: "18px",
                              fontWeight: "bold",
                            },
                            text: "Controle de Atividades",
                          },
                          subtitle: {
                            text: subtitleGraficos(),
                            align: "center",
                            margin: 10,
                            style: {
                              fontSize: "14px",
                              fontWeight: "normal",
                              color: "gray",
                            },
                          },
                          plotOptions: {
                            pie: {
                              expandOnClick: true,
                              donut: {
                                labels: {
                                  value: {
                                    color: "#FFFFFF",
                                  },
                                  total: {
                                    showAlways: true,
                                    show: true,
                                    label: "Total de Ações",
                                  },
                                },
                              },
                            },
                          },
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {Object.keys(dadosGraficoDeBarras).length > 0 &&
                      !isDadosCarregando && (
                        <Chart
                          type="bar"
                          width={"100%"}
                          height={"auto"}
                          series={
                            filtrosPesquisa.depende.length === 0 ||
                            (filtrosPesquisa.depende.length === 4 &&
                              !isDadosCarregando)
                              ? [
                                  {
                                    name: "Concluídas no prazo",
                                    data: [
                                      dadosGraficoDeBarras["geral"][
                                        "acoesGeralConcluidasNoPrazo"
                                      ],
                                    ],
                                  },
                                  {
                                    name: "Concluídas com atraso",
                                    data: [
                                      dadosGraficoDeBarras["geral"][
                                        "acoesGeralConcluidasComAtraso"
                                      ],
                                    ],
                                  },
                                  {
                                    name: "Atrasadas",
                                    data: [
                                      dadosGraficoDeBarras["geral"][
                                        "acoesGeralAtrasadas"
                                      ],
                                    ],
                                  },
                                  {
                                    name: "Pendentes",
                                    data: [
                                      dadosGraficoDeBarras["geral"][
                                        "acoesGeralPendentes"
                                      ],
                                    ],
                                  },
                                ]
                              : [
                                  {
                                    name: "Concluídas no prazo",
                                    data: atividadesSelecionadas.map(
                                      (atividade) => ({
                                        x: String(atividade)
                                          .charAt(0)
                                          .toUpperCase()
                                          .concat(String(atividade).slice(1)),
                                        y: dadosGraficoDeBarras[atividade][
                                          `acoes${String(atividade)
                                            .charAt(0)
                                            .toUpperCase()
                                            .concat(
                                              String(atividade).slice(1)
                                            )}ConcluidasNoPrazo`
                                        ],
                                      })
                                    ),
                                  },
                                  {
                                    name: "Concluídas com atraso",
                                    data: atividadesSelecionadas.map(
                                      (atividade) => ({
                                        x: String(atividade)
                                          .charAt(0)
                                          .toUpperCase()
                                          .concat(String(atividade).slice(1)),
                                        y: dadosGraficoDeBarras[atividade][
                                          `acoes${String(atividade)
                                            .charAt(0)
                                            .toUpperCase()
                                            .concat(
                                              String(atividade).slice(1)
                                            )}ConcluidasComAtraso`
                                        ],
                                      })
                                    ),
                                  },
                                  {
                                    name: "Atrasadas",
                                    data: atividadesSelecionadas.map(
                                      (atividade) => ({
                                        x: String(atividade)
                                          .charAt(0)
                                          .toUpperCase()
                                          .concat(String(atividade).slice(1)),
                                        y: dadosGraficoDeBarras[atividade][
                                          `acoes${String(atividade)
                                            .charAt(0)
                                            .toUpperCase()
                                            .concat(
                                              String(atividade).slice(1)
                                            )}Atrasadas`
                                        ],
                                      })
                                    ),
                                  },
                                  {
                                    name: "Pendentes",
                                    data: atividadesSelecionadas.map(
                                      (atividade) => ({
                                        x: String(atividade)
                                          .charAt(0)
                                          .toUpperCase()
                                          .concat(String(atividade).slice(1)),
                                        y: dadosGraficoDeBarras[atividade][
                                          `acoes${String(atividade)
                                            .charAt(0)
                                            .toUpperCase()
                                            .concat(
                                              String(atividade).slice(1)
                                            )}Pendentes`
                                        ],
                                      })
                                    ),
                                  },
                                ]
                          }
                          options={{
                            chart: {
                              height: 250,
                              width: "50%",
                            },
                            colors: ["#98A94A", "#77C2C6", "#FF7878", "#E5CB74"],
                            noData: {
                              align: "center",
                              verticalAlign: "middle",
                              text: "Nenhum dado disponível",
                            },
                            plotOptions: {
                              bar: {
                                horizontal: false,
                                columnWidth: "55%",
                                endingShape: "rounded",
                              },
                            },
                            dataLabels: {
                              enabled: true,
                            },
                            stroke: {
                              show: true,
                              width: 2,
                              colors: ["transparent"],
                            },
                            xaxis: {
                              categories:
                                filtrosPesquisa.depende.length === 0 ||
                                filtrosPesquisa.depende.length === 4
                                  ? ["Ações"]
                                  : atividadesSelecionadas.map((atividade) => [
                                      String(atividade)
                                        .charAt(0)
                                        .toUpperCase()
                                        .concat(String(atividade).slice(1)),
                                    ]),
                            },
                            yaxis: {
                              title: {
                                text: "Total de Ações",
                              },
                            },
                            fill: {
                              opacity: 1,
                            },
                            tooltip: {
                              y: {
                                formatter: function (val) {
                                  return val + " ações";
                                },
                              },
                            },
                            legend: {
                              position: "top",
                              horizontalAlign: "left",
                              offsetX: 40,
                            },
                            title: {
                              align: "center",
                              margin: 10,
                              style: {
                                fontSize: "18px",
                                fontWeight: "bold",
                              },
                              text: "Placar de Atividades",
                            },
                            subtitle: {
                              text: subtitleGraficos(),
                              align: "center",
                              margin: 10,
                              style: {
                                fontSize: "14px",
                                fontWeight: "normal",
                                color: "gray",
                              },
                            },
                          }}
                        />
                      )}
                  </Grid>
                </Grid>
              </>
            )}

            <Divider sx={{ my: 2 }} />
            <Box sx={{ width: "100%", my: 2 }}>
              <Box sx={{ display: "flex", gap: 5 }}>
                <Typography variant={"h2"} sx={{ mb: 5 }}>
                  Planilha de ações
                </Typography>
                <Button
                  variant="contained"
                  color={"primary"}
                  sx={{ height: 30 }}
                  onClick={() => {
                    navigate(
                      `/app/contrato/${null}/cliente/${null}/processos/${null}/procadmin/${null}/servicos/novo`
                    );
                  }}
                >
                  Adicionar serviço
                </Button>
              </Box>
              <Accordion sx={{ my: 2 }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography
                    variant={"h3"}
                    sx={{ display: "flex", alignItems: "center", gap: 2 }}
                  >
                    <FilterListIcon /> Filtros
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2} width={"100%"}>
                    <Grid item lg={2}>
                      <FormControl fullWidth>
                        <InputLabel id="depende-de-select">
                          Atividade depende de:
                        </InputLabel>
                        <Select
                          labelId="depende-de-select"
                          id="depende-de-select"
                          multiple
                          value={filtrosPesquisa.depende ?? []}
                          onChange={(event) => {
                            const selectedValues = event.target.value;

                            setAtividadesSelecionadas(selectedValues);

                            setFiltrosPesquisa((prevFiltros) => ({
                              ...prevFiltros,
                              depende: selectedValues,
                            }));
                          }}
                          fullWidth
                        >
                          <MenuItem value={"ambiental"}>Ambiental</MenuItem>
                          <MenuItem value={"cliente"}>Cliente</MenuItem>
                          <MenuItem value={"orgao"}>Órgão</MenuItem>
                          <MenuItem value={"protocolado"}>Protocolado</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item lg={2}>
                      <FormControl fullWidth sx={{ marginTop: -2.6 }}>
                        <Typography>Colaboradores</Typography>
                        <Autocomplete
                          disablePortal
                          id="colaborador-autocomplete"
                          getOptionLabel={(option) => option.name}
                          options={colaboradores}
                          multiple
                          value={filtrosPesquisa.colaboradoresServico ?? null}
                          onChange={(event, newValue) => {
                            setFiltrosPesquisa((prevFiltros) => ({
                              ...prevFiltros,
                              colaboradoresServico: newValue,
                            }));
                          }}
                          renderInput={(params) => (
                            <TextField {...params} placeholder="Selecionar" />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item lg={2}>
                      <FormControl fullWidth sx={{ marginTop: -2.6 }}>
                        <Typography>Setores</Typography>
                        <Autocomplete
                          disablePortal
                          getOptionLabel={(option) => option.descricao}
                          options={setores}
                          multiple
                          value={filtrosPesquisa.setores ?? null}
                          onChange={(event, newValue) => {
                            setFiltrosPesquisa((prevFiltros) => ({
                              ...prevFiltros,
                              setores: newValue,
                            }));
                          }}
                          renderInput={(params) => (
                            <TextField {...params} placeholder="Selecionar" />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item lg={2}>
                      <FormControl fullWidth sx={{ marginTop: -2.6 }}>
                        <Typography>Situação</Typography>
                        <Autocomplete
                          disablePortal
                          getOptionLabel={(option) => option.descricao}
                          options={statusServico}
                          value={filtrosPesquisa.situacao}
                          onChange={(event, newValue) => {
                            setFiltrosPesquisa((prevFiltros) => ({
                              ...prevFiltros,
                              situacao: newValue,
                            }));
                          }}
                          renderInput={(params) => (
                            <TextField {...params} placeholder="Selecionar" />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item lg={4} my={-2.6}>
                      <Typography>Período de ações</Typography>
                      <FormControl
                        fullWidth
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 2,
                          alignItems: "center",
                        }}
                      >
                        <Box>
                          <Select
                            value={filtrosPesquisa.periodo ?? "selecionar"}
                            onChange={(event) => {
                              setFiltrosPesquisa((prevFiltros) => ({
                                ...prevFiltros,
                                periodo: event.target.value,
                              }));
                            }}
                            fullWidth
                          >
                            <MenuItem value={"selecionar"}>Selecionar</MenuItem>
                            <MenuItem value="data_conclusao">
                              Data de Conclusão
                            </MenuItem>
                            <MenuItem value="createdAt">
                              Data de Registro
                            </MenuItem>
                            <MenuItem value="prazo">Prazo</MenuItem>
                          </Select>
                        </Box>
                        <Box>
                          <DateRangePicker
                            small={true}
                            startDate={startDate}
                            startDatePlaceholderText="Data inicial"
                            startDateId="data_inicio"
                            endDate={endDate}
                            endDateId="data_final"
                            endDatePlaceholderText="Data final"
                            onDatesChange={handleDatesChange}
                            focusedInput={focusedInput}
                            onFocusChange={handleFocusChange}
                            isOutsideRange={() => false}
                          />
                        </Box>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-end",
                      my: 2,
                    }}
                  >
                    <Grid item lg={6} display="flex" justifyContent="flex-end">
                      <Button variant="outlined" onClick={handleLimparFiltros}>
                        Resetar
                      </Button>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box
              p={3}
              my={1}
              style={{
                width: "100%",
                background: "white",
                minHeight: "120px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 10,
              }}
            >
              <Box>
                <TextField
                  label="Buscar"
                  variant="outlined"
                  value={searchTerm}
                  onChange={(e) => handleSearch(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => handleSearch(searchTerm)}>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box>
                <Button
                  variant={
                    abaSelecionada === "nao_iniciados"
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() => {
                    setAbaSelecionada("nao_iniciados");
                  }}
                >
                  Não iniciados
                </Button>
              </Box>
              <Box>
                <Button
                  variant={
                    abaSelecionada === "em_andamento" ? "contained" : "outlined"
                  }
                  onClick={() => {
                    setAbaSelecionada("em_andamento");
                  }}
                >
                  Em andamento
                </Button>
              </Box>
              <Box>
                <Button
                  variant={
                    abaSelecionada === "finalizados" ? "contained" : "outlined"
                  }
                  onClick={() => {
                    setAbaSelecionada("finalizados");
                  }}
                >
                  Finalizados
                </Button>
              </Box>
              <Box>
                <Button
                  variant={
                    abaSelecionada === "paralisados" ? "contained" : "outlined"
                  }
                  onClick={() => {
                    setAbaSelecionada("paralisados");
                  }}
                >
                  Paralisados
                </Button>
              </Box>
            </Box>
            {dadosPlanilhaFormatados && dadosPlanilhaFormatados.length > 0 ? (
              dadosPlanilhaFormatados.map((processo) => (
                <ListaServicosDashboard
                  processo={processo}
                  aumentarPrioridade={handleAumentarPrioridadeProcesso}
                  diminuirPrioridade={handleDiminuirPrioridadeProcesso}
                  iniciarServico={handleIniciarServico}
                  concluirServico={handleConcluirServico}
                  paralisarServico={handleParalisarServico}
                  scrollToServicoId={scrollToServicoId}
                  setScrollToServicoId={setScrollToServicoId}
                  servicosRefs={servicosIdsRef}
                />
              ))
            ) : (
              <Box
                width="100%"
                sx={{ background: "#FFF", textAlign: "center" }}
                p={2}
              >
                Nenhum serviço encontrado.
              </Box>
            )}
            <Paper
              style={{
                background: "white",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FormControl>
                <Grid
                  container
                  spacing={2}
                  alignItems={"center"}
                  display={"flex"}
                  justifyContent={"space-evenly"}
                >
                  <Grid item>
                    <Box my={2}>
                      <Typography>Página</Typography>
                      <Pagination
                        count={Math.ceil(count / perPage)}
                        page={page + 1}
                        onChange={(event, value) => setPage(value - 1)}
                        variant="outlined"
                        shape="rounded"
                        size="large"
                        renderItem={(item) => (
                          <PaginationItem
                            slots={{
                              previous: ArrowBackIcon,
                              next: ArrowForwardIcon,
                            }}
                            {...item}
                          />
                        )}
                        style={{ marginTop: 10 }}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box>
                      <Typography>Itens por página</Typography>
                      <TextField
                        select
                        label={"Itens por página"}
                        value={perPage}
                        onChange={(e) => setPerPage(parseInt(e.target.value))}
                        style={{ marginTop: 10 }}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                      </TextField>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box>
                      <Typography>Ordenar por</Typography>
                      <TextField
                        select
                        label={"Ordenação"}
                        value={sortBy}
                        onChange={(e) => setSortBy(e.target.value)}
                        style={{ marginTop: 10 }}
                      >
                        <MenuItem value={"id"}>ID</MenuItem>
                        <MenuItem value={"createdAt"}>Data de Criação</MenuItem>
                      </TextField>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box>
                      <Typography>Ordem</Typography>
                      <TextField
                        select
                        label={"Ordem"}
                        value={sortOrder}
                        onChange={(e) => setSortOrder(e.target.value)}
                        style={{ marginTop: 10 }}
                      >
                        <MenuItem value={"asc"}>Ascendente</MenuItem>
                        <MenuItem value={"desc"}>Descendente</MenuItem>
                      </TextField>
                    </Box>
                  </Grid>
                </Grid>
              </FormControl>
            </Paper>
          </Paper>
        </>
      )}
    </JumboContentLayout>
  );
};

export default Dashboard;
