import React from 'react'
import ArchiveIcon from "@mui/icons-material/Archive";
import ErrorIcon from "@mui/icons-material/Error";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import { Box, Stack } from '@mui/material';
import CardIconText from '@jumbo/shared/CardIconText';

const ScoreUnidades = ({isOcultarScores, unidadesCadastradas, unidadesArquivadas, unidadesRegulares, unidadesIrregulares, unidadesNovas}) => {
  return (
    <Stack
        spacing={2}
        direction={"row"}
        sx={{
          my: 1,
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", gap: 2, flexShrink: 1 }}>
          <React.Fragment>
            <CardIconText
              icon={<WorkspacePremiumIcon fontSize={"large"} />}
              onHoverIcon={<WorkspacePremiumIcon fontSize={"large"} />}
              title={unidadesCadastradas}
              subTitle={"Unidades cadastradas"}
              color={"#005D5F"}
              sx={{ visibility: isOcultarScores ? "hidden" : "visible" }}
            />
            <CardIconText
              icon={<CheckCircleIcon fontSize={"large"} />}
              onHoverIcon={<CheckCircleIcon fontSize={"large"} />}
              title={unidadesRegulares}
              subTitle={"Regular"}
              color={"#005D5F"}
              sx={{ visibility: isOcultarScores ? "hidden" : "visible" }}
            />
            <CardIconText
              icon={<AddCircleIcon fontSize={"large"} />}
              onHoverIcon={<AddCircleIcon fontSize={"large"} />}
              title={unidadesNovas}
              subTitle={"Nova"}
              color={"#005D5F"}
              sx={{ visibility: isOcultarScores ? "hidden" : "visible" }}
            />
            <CardIconText
              icon={<ArchiveIcon fontSize={"large"} />}
              onHoverIcon={<ArchiveIcon fontSize={"large"} />}
              title={unidadesArquivadas}
              subTitle={"Arquivada"}
              color={"#005D5F"}
              sx={{ visibility: isOcultarScores ? "hidden" : "visible" }}
            />
            <CardIconText
              icon={<ErrorIcon fontSize={"large"} />}
              onHoverIcon={<ErrorIcon fontSize={"large"} />}
              title={unidadesIrregulares}
              subTitle={"Irregular"}
              color={"#005D5F"}
              sx={{ visibility: isOcultarScores ? "hidden" : "visible" }}
            />
          </React.Fragment>
        </Box>
      </Stack>
  )
}

export default ScoreUnidades